const React = require('react');
const { useContext } = require('react');
const { string, shape } = require('prop-types');

const IconCoupon = require('../icons/icon-coupons');
const Promotions = require('../card/promotions');
const StaticPropsContext = require('../context/static-props');

const Pill = ({ element }) => {
  const { deviceType } = useContext(StaticPropsContext);

  let deviceSize = 155;

  if (typeof window !== 'undefined') {
    deviceSize = window.innerWidth > 377 ? 155 : 150;
  }

  const { promotion_type, label, currency, background, color } = element;

  const calculateContentWidth = {
    desktop: 210,
    mobile: deviceSize,
  };

  const getIconComponent = {
    coupon: IconCoupon,
  };

  const getContentWitdh = calculateContentWidth[deviceType];
  const promotionIcon = getIconComponent[promotion_type] || null;

  return (
    <Promotions
      label={label}
      currency={currency}
      deviceType={deviceType}
      background={background}
      color={color}
      contentWidth={getContentWitdh}
      promotionIcon={promotionIcon}
    />
  );
};

Pill.propTypes = {
  element: shape({}),
  label: string,
  promotion_type: string,
};

Pill.defaultProps = {
  element: null,
  label: '',
  promotion_type: '',
};

module.exports = Pill;
