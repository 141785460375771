const { cloneElement } = require('react');

const get = require('lodash/get');

const {
  DEFAULT_DPI,
  LAZY_OFF,
  LAZY_ON,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_SRV,
  ESTIMATED_CONTENT_WIDTHS,
} = require('../constants');
const { ITEM_INTERVENTIONS, INTERVENTIONS_IDS } = require('../../../../constants');

const normalizePictureData = (picture, title, options, isNotCropImage) => {
  const imgSrc = get(picture, options.imageResolution || DEFAULT_DPI);

  return {
    ...picture,
    src: imgSrc,
    lazyload: options.preload ? LAZY_OFF : LAZY_ON,
    lowEnd: options.lowEnd,
    preload: options.preload || false,
    title,
    isNotCropImage,
    width: get(picture, 'tags.width'),
    height: get(picture, 'tags.heigth'),
  };
};

const mapWithKey = (components, keyPrefix) =>
  components.map((component, index) => component && cloneElement(component, { key: `${keyPrefix}-${index}` }));

const IsNotCropImage = (vertical, image_ratio) =>
  (vertical === VERTICAL_TYPE_MOT || vertical === VERTICAL_TYPE_SRV) && image_ratio > 1.6;

const getEstimatedCardContentWidth = (device, layout) =>
  get(ESTIMATED_CONTENT_WIDTHS, `${device}.${layout}`, ESTIMATED_CONTENT_WIDTHS.DEFAULT);

const isItemIntervention = (item) => ITEM_INTERVENTIONS.includes(item.id);

const isIntervention = (elem) => elem && INTERVENTIONS_IDS.includes(elem.id);

module.exports = {
  normalizePictureData,
  mapWithKey,
  IsNotCropImage,
  getEstimatedCardContentWidth,
  isItemIntervention,
  isIntervention,
};
