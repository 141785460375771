const React = require('react');

const has = require('lodash/has');

const {
  isLayoutGallery,
  renderHighlightGroup,
  truncateString,
  renderMediaTagGroup,
} = require('../desktop-card-content/common');
const Group = require('../../../card/group/group');
const { CARD_DATA_GROUPS, STACK } = require('../constants');
const ItemAttributes = require('../../../card/attributes/attributes.desktop');
const ItemPrice = require('../../../card/price');

function renderPrice(item) {
  if (!has(item, 'price.amount')) {
    return <div className="ui-search-price__part">{item.price.label.text}</div>;
  }

  return (
    <ItemPrice
      {...item.price}
      discountSource={item.discount_source}
      from={item.from}
      showOriginalValue={!!item.price.original_price}
      billing
    />
  );
}

const CardContent = (cardContentProps) => {
  const { item, layout } = cardContentProps;

  if (isLayoutGallery(layout)) {
    return (
      <>
        <div className="ui-search-item__title-description-container">
          <Group noSeparator name={CARD_DATA_GROUPS.DETAILS}>
            <div className="ui-search-item__subtitle-grid">{item.subtitles.operation}</div>
          </Group>
          {item?.subtitles?.development ? (
            <div className="ui-search-item__title-label-grid">{item.subtitles.development}</div>
          ) : (
            <div className="ui-search-item__title-label-grid">{item.subtitles.item_title}</div>
          )}
        </div>
        <Group noSeparator name={CARD_DATA_GROUPS.PRICE}>
          {renderPrice(item)}
          <div className="ui-search-item__rental-type-container">{renderHighlightGroup(item)}</div>
          {item.descriptions && (
            <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES} className="ui-search-item__attributes-grid-container">
              <ItemAttributes attributes={item.descriptions} className="ui-search-item__attributes-grid" />
            </Group>
          )}
          {item.location && (
            <div className="ui-search-item__location-container">
              <Group noSeparator name={CARD_DATA_GROUPS.LOCATION}>
                <p className="ui-search-item__location-label">{truncateString(item.location, 35)}</p>
              </Group>
            </div>
          )}
          {item.available_units && (
            <Group
              noSeparator
              name={CARD_DATA_GROUPS.AVAILABLE_UNITS}
              className="ui-search-item__available-units-container-grid"
            >
              <span className="ui-search-item__available-units-label">{item.available_units}</span>
            </Group>
          )}
          {item?.possession && (
            <div className="ui-search-item__possession-container">
              <span className="ui-search-item__possession">{item.possession.replace(':', '')}</span>
            </div>
          )}
        </Group>
      </>
    );
  }

  return (
    <>
      {renderHighlightGroup(item)}
      {renderMediaTagGroup(item)}
      <Group noSeparator name={CARD_DATA_GROUPS.PRICE}>
        {renderPrice(item)}
      </Group>
      <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES}>
        <ItemAttributes attributes={item.descriptions} />
      </Group>
      <Group noSeparator name={CARD_DATA_GROUPS.DETAILS}>
        <span className="ui-search-item__subtitle">{item.subtitles.operation}</span>
      </Group>
      {item.location && (
        <Group noSeparator name={CARD_DATA_GROUPS.LOCATION}>
          <p className="ui-search-item__location">{item.location}</p>
          <p className="ui-search-item__information">{item.subtitles.development}</p>
          <p className="ui-search-item__information">{item.subtitles.item_title}</p>
        </Group>
      )}
      {layout === STACK && item.available_units && (
        <Group noSeparator name={CARD_DATA_GROUPS.AVAILABLE_UNITS}>
          <span className="ui-search-item__available-units">{item.available_units}</span>
        </Group>
      )}
    </>
  );
};

module.exports = CardContent;
