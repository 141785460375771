const React = require('react');
const { bool, func, number, shape, string } = require('prop-types');

const {
  ARROW_DIRECTIONS: { BACK, NEXT },
} = require('../../pagination/constants');
const { ArrowButton, Content, Node } = require('../../pagination/components');

const namespace = 'ui-search-map-pagination';

const Pagination = ({ currentPage, previousPage, nextPage }) => (
  <Content className={namespace}>
    <ArrowButton
      direction={BACK}
      disabled={!previousPage.show}
      title={previousPage.title}
      href={previousPage.href}
      handleChange={previousPage.onClick}
    />

    <Node isActualPage value={currentPage} />

    <ArrowButton
      direction={NEXT}
      disabled={!nextPage.show}
      title={nextPage.title}
      href={nextPage.href}
      handleChange={nextPage.onClick}
    />
  </Content>
);

const PAGE_PROPTYPES = shape({
  show: bool.isRequired,
  title: string.isRequired,
  href: string,
  onClick: func.isRequired,
}).isRequired;

Pagination.propTypes = {
  currentPage: number.isRequired,
  nextPage: PAGE_PROPTYPES,
  previousPage: PAGE_PROPTYPES,
};

module.exports = Pagination;
