const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');
const Image = require('nordic/image');

const namespace = 'ui-search-icon ui-search-icon--eshop-image';

const IconEshop = ({ className, image }) => <Image className={classnames(namespace, className)} src={image} alt="" />;

IconEshop.propTypes = {
  className: string,
  image: string,
};

IconEshop.defaultProps = {
  className: null,
};

module.exports = React.memo(IconEshop);
