const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--alert-edit';

const IconAlertEdit = ({ className }) => (
  <svg
    data-testid="edit_alert_icon"
    className={classnames(namespace, className)}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1.0" fillRule="evenodd">
      <path d="M32 25.6l-4.533-4.4c0.533-0.8 0.8-1.733 0.8-2.8 0-2.8-2.267-5.067-4.933-5.067-2.8 0-4.933 2.267-4.933 5.067s2.267 5.067 4.933 5.067c1.067 0 2-0.267 2.8-0.8l4.533 4.4 1.333-1.467zM20.267 18.4c0-1.733 1.333-3.067 2.933-3.067s2.933 1.333 2.933 3.067-1.333 3.067-2.933 3.067c-1.6 0-2.933-1.333-2.933-3.067zM18.667 13.6c0-0.267 0-0.533 0-0.933v-1.733c-0.133-3.6-3.2-6.4-6.8-6.4s-6.667 2.667-6.8 6.4v1.733c0.133 4.4-4.133 6-4.4 6.133l-0.667 0.133v3.467h7.733c0.267 2.133 2 3.467 4.133 3.467s3.733-1.333 4.133-3.467h2c-0.8-1.067-1.333-2.533-1.333-4-0.133-1.867 0.667-3.6 2-4.8z" />
    </g>
  </svg>
);

IconAlertEdit.propTypes = {
  className: string,
};

IconAlertEdit.defaultProps = {
  className: null,
};

module.exports = IconAlertEdit;
