const React = require('react');
const { useContext } = require('react');
const { bool } = require('prop-types');

const { WrapperBase: BrandAds } = require('@ads-placements-frontend/brand-wrapper');

const StaticPropsContext = require('../context/static-props');
const IconFactory = require('../icons/recommendations-fe/icon-factory');

const BrandAdsDesktop = (props) => {
  const { lowEnd } = useContext(StaticPropsContext);

  return <BrandAds {...props} IconFactory={IconFactory} lowEnd={lowEnd} />;
};

BrandAdsDesktop.propTypes = {
  lowEnd: bool,
};

module.exports = BrandAdsDesktop;
