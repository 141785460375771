const React = require('react');

const { renderTitleGroup, renderShippingGroup } = require('../core');
const { renderPriceGroup, ContentSkeleton } = require('../common');

function renderContent(item, options, layout) {
  return (
    <ContentSkeleton
      topComponents={[
        renderTitleGroup(item, options, layout),
        renderPriceGroup(item, options),
        renderShippingGroup(item, layout),
      ]}
    />
  );
}

module.exports = renderContent;
