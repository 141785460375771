const React = require('react');

const classnames = require('classnames');

const LoadingCard = require('../loading');
const { namespace } = require('../../constants');

const repeats = Array.from(Array(5));

const createKey = (index) => `list-placeholder--${index}`;

const ListPlaceholder = () => (
  <div>
    {repeats.map((_, index) => (
      <div className={classnames(namespace, `${namespace}__item`)} key={createKey(index)}>
        <LoadingCard />
      </div>
    ))}
  </div>
);

module.exports = ListPlaceholder;
