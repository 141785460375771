const APIService = require('../../../../../services/suggestions');

const ServiceSuggestions = (itemCount, itemId, category, queryParams, suggestionsCallback, newQuantity) => {
  APIService.getSuggestions(category, queryParams)
    .then((response) => {
      const showSuggestionsIfHidden = response.data.show_suggestions_if_currently_hidden;
      const showSuggestionsIfVisible = response.data.show_suggestions_if_currently_visible;
      const showSuggestionsOnSessionFirstCartAdd = response.data.should_show_suggestions_on_session_first_cart_add;

      suggestionsCallback(
        itemCount,
        itemId,
        response.data,
        showSuggestionsIfVisible,
        showSuggestionsIfHidden,
        showSuggestionsOnSessionFirstCartAdd,
        newQuantity,
      );
    })
    .catch((err) => {
      throw new Error(err);
    });
};

module.exports = ServiceSuggestions;
