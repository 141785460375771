/* eslint-disable react-hooks/exhaustive-deps */

const { useMemo } = require('react');

const { useStaticProps } = require('../components/context/static-props');
const {
  MAPPED_FACETED_FILTER_IDS,
  DEFAULT_LOCATION_FILTER_KEY,
  FILTERS: {
    IDS: { STATE, CITY, NEIGHBORHOOD },
  },
} = require('../constants');

const getOptionData = ({ name, value }) => ({ name, value });

const isDefault = (id, value) => id === MAPPED_FACETED_FILTER_IDS.operation && value === null;

const findByValue = (options, expectedValue) =>
  options.find(({ id, value }) => value === expectedValue || isDefault(id, expectedValue));

const getCurrentFilter = (state, options) => {
  const [{ id }] = options;

  return { id, ...state[`${id}`] };
};

const getFilterId = (id) => MAPPED_FACETED_FILTER_IDS[`${id}`];

const createStatusBuilder = (facetedFilters, appliedFilters) => (status, facetedId) => {
  const id = getFilterId(facetedId);
  // We use this flag to identify and discard any applied filter values that
  // should not be reflected in the faceted search UI.
  const hasFalseValue = facetedFilters.selected_filter[`${facetedId}`] === false;

  return {
    ...status,
    [id]: {
      name: facetedFilters.labels.title[`${facetedId}`],
      selectedOption: appliedFilters[`${id}`] && !hasFalseValue ? appliedFilters[`${id}`] : null,
    },
  };
};

const getFacetedStateByFilterId = (facetedFilters, appliedFilters) => {
  const buildStatus = createStatusBuilder(facetedFilters, appliedFilters);

  return Object.keys(facetedFilters.selected_filter).reduce(buildStatus, {});
};

// Turn a recursive structure into a flat array containing the available filters
const buildFacetedFilters = (state, options, filters = []) => {
  const { id, name, selectedOption } = getCurrentFilter(state, options);
  const { contains } = findByValue(options, selectedOption) || {};

  filters.push({
    id,
    name,
    selectedOption,
    options: options.map(getOptionData),
  });

  if (contains) {
    return buildFacetedFilters(state, contains, filters);
  }

  return filters;
};

const getDefaultValues = (state) =>
  Object.keys(state).reduce(
    (values, id) => ({
      ...values,
      [id]: null,
    }),
    {},
  );

// We need to iterate over the recursive data structure to validate
// nested filter values are valid when a filter changes. If not, all
// the nested filter values are nulled.
const getFilterChanges = (state, options, filterId, optionId, changes) => {
  const { id, selectedOption } = getCurrentFilter(state, options);
  const isFilterChanging = filterId === id;
  const filterValue = isFilterChanging ? optionId : selectedOption;
  const selectedFilter = findByValue(options, filterValue);

  changes[`${id}`] = selectedFilter ? filterValue : null;

  if (selectedFilter?.contains) {
    const nextFilterId = isFilterChanging ? null : filterId;

    return getFilterChanges(state, selectedFilter.contains, nextFilterId, optionId, changes);
  }

  return changes;
};

const getLocationFilterSuffix = (appliedFilters) =>
  [STATE, CITY, NEIGHBORHOOD].reduce((suffix, key) => (appliedFilters[`${key}`] ? `${suffix}--${key}` : key), '');

const useFacetedFilters = ({ facetedFilters, appliedFilters, autosuggestEnabledSites }) => {
  const { siteId } = useStaticProps();
  const { filters, onFilterChange } = useMemo(() => {
    const state = getFacetedStateByFilterId(facetedFilters, appliedFilters);
    const availableFilters = buildFacetedFilters(state, facetedFilters.available_filters);

    const handleChange = (callback, filterId) => (_, optionId) => {
      const changes = getFilterChanges(
        state,
        facetedFilters.available_filters,
        filterId,
        optionId,
        getDefaultValues(state),
      );

      return callback(changes);
    };

    return {
      filters: availableFilters,
      onFilterChange: handleChange,
    };
  }, [facetedFilters, appliedFilters]);

  const isLocationFilterEnabled = useMemo(
    () => !!autosuggestEnabledSites.find((id) => id === siteId),
    [autosuggestEnabledSites],
  );

  const locationFilter = useMemo(() => {
    if (!isLocationFilterEnabled) {
      return null;
    }

    const { selected_filter: selectedFilter, labels } = facetedFilters;
    const { id = null, name = null } = selectedFilter.location || {};
    const filter = {
      id,
      name,
      key: `${id || DEFAULT_LOCATION_FILTER_KEY}${getLocationFilterSuffix(appliedFilters)}`,
      placeholder: labels.autocomplete.label,
    };

    return filter;
  }, [facetedFilters, siteId, isLocationFilterEnabled, appliedFilters]);

  return {
    dropdownFilters: filters.slice(0, 2).filter((filter) => !!filter),
    switchFilter: filters[2],
    locationFilter,
    onFilterChange,
  };
};

module.exports = useFacetedFilters;
