const React = require('react');
const { arrayOf, func, element, oneOfType, string, shape } = require('prop-types');

const GridLayout = require('../grid-rows');

const TwoColumnGridLayout = ({ itemComponent, layout, results, deviceType }) => (
  <GridLayout itemComponent={itemComponent} layout={layout} results={results} deviceType={deviceType} cols={2} />
);

TwoColumnGridLayout.propTypes = {
  deviceType: string,
  itemComponent: oneOfType([element, func]).isRequired,
  layout: string.isRequired,
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

TwoColumnGridLayout.defaultProps = {
  deviceType: null,
  results: null,
};

module.exports = TwoColumnGridLayout;
