const React = require('react');

const { loadable } = require('nordic/lazy');

const useBillboard = require('./use-billboard');

const ChunkBillboard = () => {
  const { billboard, lowEnd, billboardState, bookmark } = useBillboard();
  const Billboard = loadable(async () => import(/* webpackChunkName: "chunk-billboard" */ './billboard.desktop'));

  if (!billboard || lowEnd) {
    return null;
  }

  return (
    <Billboard
      {...billboard}
      lowEnd={lowEnd}
      state={billboardState}
      showUserBookmarks={bookmark?.show_user_bookmarks}
    />
  );
};

module.exports = ChunkBillboard;
