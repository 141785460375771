const { useContext } = require('react');

const SnackbarContext = require('../context/snackbar');

const useSnackbar = () => {
  const { currentSnackbar, setSnackbar } = useContext(SnackbarContext);

  return { currentSnackbar, setSnackbar };
};

module.exports = useSnackbar;
