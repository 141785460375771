const React = require('react');
const { string } = require('prop-types');
const { useEffect, useState } = require('react');

const useFullFreeshipping = require('./use-full-freeshipping');
const FullTopEmpty = require('./full-shipping-empty.desktop');
const FullTop = require('./full-shipping-top.mobile');

const FullFreeshippingContainer = ({ disabledByCookie }) => {
  const { currentFullFreeshipping } = useFullFreeshipping();
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 500);
  }, [currentFullFreeshipping]);

  if (!currentFullFreeshipping) {
    return null;
  }

  if (
    currentFullFreeshipping.progress_bar &&
    currentFullFreeshipping.progress_bar.color === 'neutral' &&
    currentFullFreeshipping.progress_bar.percent === 0
  ) {
    return isOpen && <FullTopEmpty {...currentFullFreeshipping} disabledByCookie={disabledByCookie} />;
  }

  return <FullTop {...currentFullFreeshipping} key={currentFullFreeshipping.key} />;
};

FullFreeshippingContainer.propTypes = {
  disabledByCookie: string.isRequired,
};

module.exports = FullFreeshippingContainer;
