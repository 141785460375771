const React = require('react');
const { bool, number, func, string, shape } = require('prop-types');

const Snackbar = require('@andes/snackbar');
const classnames = require('classnames');

const useSnackbar = require('./use-snackbar');

const namespace = 'ui-search-snackbar';

const SnackBar = ({ className, color, message, action, delay, show }) => {
  const { text, onClick, position } = action ?? {};

  return (
    <Snackbar
      className={classnames(namespace, className)}
      message={message}
      delay={delay}
      color={color}
      action={{
        text,
        onClick,
        position,
      }}
      show={show}
    />
  );
};

SnackBar.propTypes = {
  action: shape({
    text: string,
    onClick: func,
    position: string,
  }),
  className: string,
  color: string.isRequired,
  delay: number,
  message: string.isRequired,
  show: bool,
};

SnackBar.defaultProps = {
  className: null,
  actionMessage: null,
  delay: 4000,
  onActionClick: null,
  show: true,
};

const SnackbarContainer = () => {
  const { currentSnackbar } = useSnackbar();

  return currentSnackbar ? <SnackBar {...currentSnackbar} /> : null;
};

module.exports = SnackbarContainer;
module.exports.Snackbar = SnackBar;
