const React = require('react');
const { arrayOf, element, shape, string } = require('prop-types');

const { Snackbar } = require('../snackbar/snackbar');
const MapMobile = require('./map.mobile');
const { useMapView } = require('./hooks/use-map-view');
const AutoHeight = require('../auto-height');
const { Carousel } = require('./carousel/carousel');
const { useSelectedItem } = require('./hooks/use-selected-item');

const MapMobileContainer = ({ className, itemComponent, results }) => {
  const {
    isLoading,
    showZrp,
    showError,
    fitMarkersInMap,
    map_configuration,
    fixedCoords,
    updateResults,
    reloadResults,
  } = useMapView({
    results,
  });
  const { selectedMarker, setSelectedMarker } = useSelectedItem();

  return (
    <>
      <AutoHeight className={className}>
        <MapMobile
          fitMarkersInMap={fitMarkersInMap}
          fixedCoords={fixedCoords}
          isLoadingResults={isLoading}
          markers={results}
          showError={showError}
          updateResults={updateResults}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
        />
        <Carousel
          ItemComponent={itemComponent}
          results={results}
          onSlideChange={setSelectedMarker}
          selectedItemIndex={selectedMarker}
        />
      </AutoHeight>

      {showZrp && <Snackbar className="ui-search-map-snackbar" message={map_configuration.zrp.zrp_message || ''} />}

      {showError && (
        <Snackbar
          className="ui-search-map-snackbar"
          action={{ text: map_configuration.error_message.button_retry, onClick: reloadResults }}
          delay={null}
          message={map_configuration.error_message.message}
          color="red"
        />
      )}
    </>
  );
};

MapMobileContainer.propTypes = {
  className: string,
  itemComponent: element.isRequired,
  results: arrayOf(shape()),
};

MapMobileContainer.defaultProps = {
  className: null,
  results: [],
};

module.exports = MapMobileContainer;
