const React = require('react');
const { useState, useEffect, useRef } = require('react');
const { string, oneOf, shape, bool, number } = require('prop-types');

const classnames = require('classnames');
const Snackbar = require('@andes/snackbar');

const FullSnackbar = require('./full-shipping-info');
const useIntersectionObserver = require('../../hooks/use-intersection-observer');

const namespace = 'ui-search-fullpopup';

const FullShippingTop = ({ className, ...props }) => {
  const [visible, setVisible] = useState(true);
  const refCruce = useRef();
  const [isOnScreen] = useIntersectionObserver(refCruce, { threshold: 0.2 }, 0.1, true);

  useEffect(() => {
    document.querySelector('.nav-header').style.zIndex = 10;

    const timer = setTimeout(() => {
      setVisible(false);
      document.querySelector('.nav-header').style = null;
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div ref={refCruce} />
      <div
        className={classnames(className, {
          'ui-search-fullpopup-up-float': isOnScreen,
          'ui-search-fullpopup-up-sticky': !isOnScreen,
        })}
      >
        <Snackbar
          show={visible}
          className={classnames(namespace, `${namespace}-up`, className, {
            [`${namespace}-up--animate-show`]: visible,
            [`${namespace}-up--animate-hide`]: !visible,
          })}
        >
          <FullSnackbar {...props} />
        </Snackbar>
      </div>
    </>
  );
};

FullShippingTop.propTypes = {
  bottom: string,
  className: string,
  progressPercentage: number,
  title: shape({
    preIcon: string,
    postIcon: string,
    icon: bool,
  }),
  type: oneOf(['zero', 'complete', 'progress', 'error']),
};

FullShippingTop.defaultProps = {
  bottom: null,
  className: null,
  progressPercentage: null,
  title: null,
  type: 'zero',
};

module.exports = FullShippingTop;
