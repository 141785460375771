const { bool, func, node, string, shape } = require('prop-types');

const BLACKLISTED_PARAMS = ['redirectedFromOfficialStoreZrp', 'redirectedFromDealZrp'];

const linkPropTypes = {
  ariaLabel: string,
  children: node.isRequired,
  className: string,
  href: string,
  isInternal: bool,
  keepLayout: bool,
  role: string,
  title: string,
  linkTag: string,
  target: string,
  tracks: shape({
    analyticsTrack: shape(),
    melidataTrack: shape(),
  }),

  onClick: func,
};

module.exports = {
  BLACKLISTED_PARAMS,
  linkPropTypes,
};
