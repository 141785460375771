const React = require('react');
const { number, string } = require('prop-types');

const classnames = require('classnames');

const { LEVELS } = require('./constants');

const namespace = 'ui-search-reputation-scale';

const ReputationScale = ({ className, currentLevelID }) => (
  <ol className={classnames(namespace, className)}>
    {LEVELS.map((level) => (
      <li
        key={level.id}
        className={classnames(`${namespace}__level`, `${namespace}__level--${level.id}`, {
          [`${namespace}__level--active`]: currentLevelID === level.id,
        })}
        title={level.text}
      />
    ))}
  </ol>
);

ReputationScale.propTypes = {
  className: string,
  currentLevelID: number,
};

ReputationScale.defaultProps = {
  className: null,
  currentLevelID: null,
};

module.exports = ReputationScale;
