const React = require('react');
const { shape, string, number } = require('prop-types');

const ProgressBarEshops = React.memo(({ namespace, progress_bar }) => (
  <div className={`${namespace}__progressbar-container`}>
    <div className={`${namespace}__progressbar-sizer`}>
      <div
        className={`${namespace}__progressbar`}
        style={{ width: `${progress_bar.percent}%`, backgroundColor: progress_bar.color_hex }}
      >
        <div className={`${namespace}__dot`} style={{ backgroundColor: progress_bar.color_hex }} />
      </div>
    </div>
  </div>
));

ProgressBarEshops.displayName = 'ProgressBarEshops';
ProgressBarEshops.propTypes = {
  namespace: string.isRequired,
  progress_bar: shape({
    color_hex: string,
    percent: number,
  }),
};

module.exports = ProgressBarEshops;
