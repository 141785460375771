const React = require('react');
const { string } = require('prop-types');

const useFilterSearch = require('../../hooks/use-filter-search');
const Link = require('./link');
const { linkPropTypes } = require('./constants');
const { useSearch, useFilters } = require('../../hooks/context');
const { LAYOUTS } = require('../../constants');
const useMapConfig = require('../map/hooks/use-map-config');
const { trackClickEvent } = require('../map/track');
const {
  FILTERS: {
    IDS: { STATE, CITY, OPERATION, OPERATION_SUBTYPE, NEIGHBORHOOD, CATEGORY },
  },
} = require('../../constants');

const FilterLink = ({ href, name, children, filterId, ...props }) => {
  const { layout_options } = useSearch();
  const filterSearch = useFilterSearch(href, filterId);
  const { changeSecondaryFilter } = useFilters();
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = (event) => {
    filterSearch(event);
    trackClickEvent(null, track.melidata);

    if (
      filterId === OPERATION ||
      filterId === STATE ||
      filterId === CITY ||
      filterId === NEIGHBORHOOD ||
      filterId === CATEGORY ||
      filterId === OPERATION_SUBTYPE
    ) {
      changeSecondaryFilter(true);
    }
  };

  return (
    <Link
      {...props}
      href={href}
      onClick={layout_options?.current === LAYOUTS.TYPE_MAP ? handleClick : filterSearch}
      ariaLabelledBy={name}
    >
      {children}
    </Link>
  );
};

FilterLink.propTypes = {
  ...linkPropTypes,
  filterId: string,
};

FilterLink.defaultProps = {
  href: '',
};

module.exports = FilterLink;
