const React = require('react');
const { shape, string, array } = require('prop-types');
const serialize = require('serialize-javascript');

const Head = require('nordic/head');

const Schema = ({ data }) => (
  <Head>
    {/* eslint-disable-next-line @meli-lint/react/no-script-tags */}
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: serialize(data, { isJSON: true }) }}
    />
  </Head>
);

const SchemaContainer = (props) => {
  const seo = props;
  const { schema } = seo;

  const buildSchema = (graphArray) => ({
    '@context': 'https://schema.org',
    '@graph': graphArray,
  });

  const searchResultsPageObject = {
    '@type': 'SearchResultsPage',
    id: schema?.search?.url,
    name: seo.h1,
    about: seo.description,
  };

  const buildBrandNodeData = (brandAttribute) => ({
    '@type': 'Brand',
    name: brandAttribute?.name,
  });

  const buildProductListItem = (productListItem) => {
    let aggregateRating;
    let brand;
    let offers;

    const { name, image, brand_attribute, aggregate_rating, item_offered } = productListItem;

    if (brand_attribute) {
      brand = buildBrandNodeData(brand_attribute);
    }

    if (aggregate_rating) {
      aggregateRating = {
        '@type': 'AggregateRating',
        ratingCount: aggregate_rating?.rating_count,
        ratingValue: aggregate_rating?.rating_value,
      };
    }

    if (item_offered) {
      offers = {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        price: item_offered?.price,
        priceCurrency: item_offered?.price_currency,
        url: item_offered?.url,
      };
    }

    return {
      '@type': 'Product',
      name,
      image,
      brand,
      aggregateRating,
      offers,
    };
  };

  const buildProductListData = (productList) => {
    const formattedProducts = productList.map((productListItem) => buildProductListItem(productListItem));

    formattedProducts.push(searchResultsPageObject);

    const experimentSchema = buildSchema(Array.from(formattedProducts));

    return experimentSchema;
  };

  const buildProductData = (product) => {
    const {
      aggregate_rating: aggregateRating,
      brand_attribute: brand,
      item_offered: offer,
      name,
      search,
      url,
    } = product;

    return {
      '@type': 'ItemList',
      id: `${schema.search.url}/#itemList`,
      numberOfItems: 1,
      url,
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@type': 'Product',
            name,
            url: search?.url,
            aggregateRating: {
              '@type': 'AggregateRating',
              ratingCount: aggregateRating.rating_count,
              bestRating: 5,
              ratingValue: aggregateRating.rating_value,
              worstRating: 1,
            },
            offers: {
              '@type': 'AggregateOffer',
              price: offer.price,
              lowPrice: offer.price,
              priceCurrency: offer.price_currency,
              offerCount: 1,
              availability: 'https://schema.org/InStock',
              itemCondition: 'https://schema.org/NewCondition',
            },
            brand: buildBrandNodeData(brand),
          },
        },
      ],
    };
  };

  if (schema) {
    const { product, product_list: productList } = schema;

    if (productList) {
      const experimentSchema = buildProductListData(productList);

      return <Schema data={experimentSchema} />;
    }

    if (product) {
      const productData = buildProductData(product);
      const schemaData = buildSchema([searchResultsPageObject, productData]);

      return <Schema data={schemaData} />;
    }
  }

  const schemaDataWithoutProduct = {
    '@context': 'https://schema.org',
    '@graph': [searchResultsPageObject],
  };

  return <Schema data={schemaDataWithoutProduct} />;
};

Schema.propTypes = {
  data: shape({
    '@context': string,
    '@graph': array,
  }).isRequired,
};

module.exports = SchemaContainer;
