const { useCallback, useContext, useState } = require('react');

const AutocompleteUrlService = require('../../services/autocomplete');
const StaticPropsContext = require('../components/context/static-props');

const useAutocompleteUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { siteId } = useContext(StaticPropsContext);

  const doAutocomplete = useCallback(
    (value) => {
      setIsLoading(true);

      return AutocompleteUrlService.getAutocompleteUrl(value, siteId)
        .then(({ data }) => data)
        .catch(() => [])
        .then((data) => {
          setIsLoading(false);

          return data;
        });
    },
    [setIsLoading, siteId],
  );

  return [isLoading, doAutocomplete];
};

module.exports = useAutocompleteUrl;
