const React = require('react');
const { string } = require('prop-types');

const Highlight = require('../../../card/highlight/highlight-label');
const IconLabel = require('../../../icon-label/icon-label');
const StyledLabel = require('../../../styled-label/styled-label');
const { FILTER_BASE_PROPS } = require('./constants');

// Default filter, only shows name
const HighlightedFilterContent = ({ namespace, title, highlight, subtitle, label, htmlForIDValue = '', hasHtml }) => (
  <>
    {title && (
      <IconLabel className={`${namespace}__title`} htmlForIDValue={htmlForIDValue} hasHtml={hasHtml} {...title} />
    )}
    {highlight && <Highlight htmlForIDValue={htmlForIDValue} {...highlight} />}
    {subtitle && <IconLabel className={`${namespace}__subtitle`} htmlForIDValue={htmlForIDValue} {...subtitle} />}
    {label && <StyledLabel className={`${namespace}__label`} htmlForIDValue={htmlForIDValue} {...label} />}
  </>
);

HighlightedFilterContent.propTypes = {
  ...FILTER_BASE_PROPS,
  namespace: string,
};

HighlightedFilterContent.defaultProps = {
  highlight: null,
  label: null,
  namespace: '',
  subtitle: null,
  title: null,
};

module.exports = HighlightedFilterContent;
