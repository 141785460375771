/* eslint-disable react-hooks/exhaustive-deps */
const { useCallback, useState } = require('react');

const INITIAL_SELECTED_RESULTS = { index: null, items: null };

const useSelectedItem = () => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedResults, setSelectedResults] = useState(INITIAL_SELECTED_RESULTS);

  const handleMarkerSelection = useCallback(
    (index, items) => {
      setSelectedMarker(index);

      if (items !== undefined) {
        setSelectedResults({ index, items });
      }
    },
    [setSelectedMarker, setSelectedMarker],
  );

  return {
    selectedMarker,
    selectedResults: selectedResults.items,
    selectedResultsIndex: selectedResults.index,
    setSelectedMarker: handleMarkerSelection,
  };
};

module.exports = {
  useSelectedItem,
};
