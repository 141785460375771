const React = require('react');
const { useState, useContext } = require('react');
const { arrayOf, string, shape } = require('prop-types');

const AndesModal = require('@andes/modal');
const cx = require('classnames');
const Switch = require('@andes/switch');

const useFilterSearch = require('../../../../hooks/use-filter-search');
const StaticPropsContext = require('../../../context/static-props');
const IconLabel = require('../../../icon-label/icon-label');
const LayoutContext = require('../../../context/layout');
const useMapConfig = require('../../../map/hooks/use-map-config');
const { useSearch } = require('../../../../hooks/context');
const { LAYOUTS } = require('../../../../constants');
const IconQuestion = require('../../../icons/question');
const { trackClickEvent } = require('../../../map/track');
const HighlightedFilterContent = require('../highlighted-filter/highlighted-filter-content');
const FilterTooltip = require('../filters-tooltip/filters-tooltip.mobile');
const { buildNewHref } = require('../../../link/link-utils');

const namespace = 'ui-search-filter-highlighted';

const HighlightedFilterVis = ({ filterId, subtitle, switch: { is_on: isOn }, title, tooltip, url, highlight }) => {
  const { queryParams } = useContext(StaticPropsContext);
  const { currentLayout } = React.useContext(LayoutContext);
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = !!filterSearch;
  const [isChecked, setChecked] = useState(isOn);
  const { layout_options } = useSearch();
  const { searchTrack } = useMapConfig();
  const [showModal, toggleModalVisibility] = React.useState(false);
  const [showTooltip, toggleTooltipVisibility] = React.useState(true);
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const onClickTooltip = () => {
    toggleTooltipVisibility(!showTooltip);
  };

  const handleClick = (e) => {
    if (e.type !== 'change') {
      e.preventDefault();
      setChecked(!isOn);
    }

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      return;
    }

    window.location.href = buildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };

  const handleQuestionClick = (e) => {
    e.preventDefault();
    toggleModalVisibility(!showModal);
  };

  if (tooltip.actions[0]) {
    tooltip.actions[0].onClick = onClickTooltip;
  }

  const tooltipData = {
    ...tooltip,
    side: 'right',
    offset_x: 0,
    offset_y: 45,
    filter_content_id: 'APPROVED_CREDIT_LINE_TOOLTIP',
    max_print: 80,
  };

  return (
    <>
      <form action={url} onSubmit={handleClick} className={cx(`${namespace}__container`)}>
        <button name="button" type="submit" className={cx(`${namespace}__content ${namespace}__content-button`)}>
          <div className={`${namespace}__container-vertical`}>
            <div className={`${namespace}__icons-container`}>
              <IconLabel className={`${namespace}__icon-label`} {...highlight.pill} />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={cx(`${namespace}__tooltip`)} onClick={handleQuestionClick}>
                <IconQuestion />
              </div>
            </div>
            <HighlightedFilterContent
              namespace={namespace}
              hasHtml
              subtitle={{ text: subtitle.text, className: `${namespace}__label-subtitle-fade` }}
              title={{ text: title.text, className: `${namespace}__label-title-fade` }}
              htmlForIDValue={filterId}
            />
          </div>
        </button>

        <div className={cx(`${namespace}__switch-container`)}>
          {showTooltip ? (
            <FilterTooltip tooltip={tooltipData} side="left">
              <div className={`${namespace}__tooltip-trigger`} />
              <Switch
                id={filterId}
                className={`${namespace}__switch-icon ${namespace}__tooltip-trigger`}
                label=""
                checked={isChecked}
                autoComplete="off"
                onChange={handleClick}
              />
            </FilterTooltip>
          ) : (
            <Switch
              id={filterId}
              className={`${namespace}__switch-icon ${namespace}__tooltip-trigger`}
              label=""
              checked={isChecked}
              autoComplete="off"
              onChange={handleClick}
            />
          )}
        </div>
      </form>
      {showModal && (
        <AndesModal
          title={highlight.help_button?.modal_title}
          className={`${namespace}-modal`}
          closeButtonSrLabel="Cerrar"
          open={showModal}
          onClose={() => toggleModalVisibility(false)}
          data-testid="top-switch-filter-modal"
        >
          <iframe
            style={{ width: '100%', height: '100%' }}
            id="modalIframe"
            title="Modal Iframe"
            scrolling="no"
            src={highlight.help_button?.url}
          />
        </AndesModal>
      )}
    </>
  );
};

HighlightedFilterVis.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      label: shape({
        text: string,
      }),
    }),
  ),
  filterId: string,
  highlight: shape({
    pill: shape({
      text: string,
      icon: arrayOf(string),
    }),
    help_button: shape({
      modal_title: string,
      url: string,
    }),
  }),
  subtitle: shape({
    text: string,
    icon: arrayOf(string),
  }),
  switch: shape({}),
  title: shape({
    text: string,
    icon: arrayOf(string),
  }),
  tooltip: shape({
    title: string,
    content: string,
    url: string,
  }),
  url: string,
};

module.exports = HighlightedFilterVis;
