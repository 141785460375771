module.exports = {
  SIDEBAR: 'SIDEBAR',
  GROUPED: 'GROUPED',
  BREADCRUMB: 'BREADCRUMB',
  MAP_LINK: 'MAP_LINK',
  MAP_BUTTON: 'MAP_BUTTON',
  APPLIED_FILTERS: 'APPLIED_FILTERS',
  AVAILABLE_FILTERS: 'AVAILABLE_FILTERS',
  ALERT: 'ALERT',
  ALERT_V2: 'ALERT_V2',
  VIEW_OPTIONS: 'VIEW_OPTIONS',
  TOTAL_RESULTS: 'TOTAL_RESULTS',
  SIDEBAR_ADVERTISING: 'SIDEBAR_SKY_BANNER',
  BRAND_PERFORMANCE: 'SIDEBAR_BRAND_PERFORMANCE',
  SEARCH_SHOPS_ADS: 'SEARCH_SHOPS_ADS',
  ADN: 'ADN',
  ACCESIBLE_LABEL: 'ACCESIBLE_LABEL',
};
