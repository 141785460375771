const React = require('react');

const BaseModal = require('./base.mobile');
const useModal = require('./hooks/use-modal');

// Default Modal implementation
const Modal = (props) => {
  const [isOpen, openModal, hideModal] = useModal();

  return <BaseModal {...props} isOpen={isOpen} openModal={openModal} hideModal={hideModal} />;
};

module.exports = Modal;
