/* eslint-disable jsx-a11y/prefer-tag-over-role */
const React = require('react');
const { bool, func, number, string } = require('prop-types');

const useMapConfig = require('../../hooks/use-map-config');
const Link = require('../../../link');
const IconClose = require('../../../icons/close');

const Header = ({ className, clusterSize, isClusterSelected, onUnselectCluster }) => {
  const { badgeText: resultsText, clusterText } = useMapConfig();

  const handleClick = (event) => {
    event.preventDefault();

    onUnselectCluster();
  };

  return (
    <div className={className}>
      {isClusterSelected ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link role="button" onClick={handleClick}>
          <span>{`${clusterSize} ${clusterText}`}</span>
          <IconClose />
        </Link>
      ) : (
        resultsText
      )}
    </div>
  );
};

Header.propTypes = {
  className: string,
  clusterSize: number,
  isClusterSelected: bool,
  onUnselectCluster: func.isRequired,
};

Header.defaultProps = {
  clusterSize: 0,
  isClusterSelected: false,
};

module.exports = Header;
