/**
 * Styles
 */
require('../../../pages/search/styles/search.desktop.scss');

/**
 * Module dependencies
 */
const React = require('react');

const { AndesProvider } = require('@andes/context');
const { hydrateRoot } = require('nordic/hydrate');
const { loadableReady } = require('nordic/lazy');

const SearchDesktop = require('../../../pages/search/components/views/main/main.desktop');

/**
 * Get server state
 */
const {
  site,
  siteId,
  shops,
  cookies,
  lowEnd,
  deviceType,
  company,
  initialState,
  country,
  queryParams,
  currentUser,
  domain,
  isHoverDisabled,
  deviceOs,
  mapApiKey,
  hotjar,
} = window.__PRELOADED_STATE__;

/**
 * Mount SearchView on client
 */
loadableReady(() => {
  hydrateRoot(
    document.getElementById('root-app'),
    <AndesProvider locale={country?.andesLocale} device={{ type: deviceType }}>
      <SearchDesktop
        site={site}
        siteId={siteId}
        shops={shops}
        cookies={cookies}
        lowEnd={lowEnd}
        deviceType={deviceType}
        company={company}
        initialState={initialState}
        country={country}
        queryParams={queryParams}
        currentUser={currentUser}
        domain={domain}
        isHoverDisabled={isHoverDisabled}
        mapApiKey={mapApiKey}
        hotjar={hotjar}
        deviceOs={deviceOs}
      />
    </AndesProvider>,
  );
});
