const React = require('react');
const { shape, arrayOf, string } = require('prop-types');

const Search16 = require('@andes/icons/Search16');

const TextIconCarousel = require('../filter-intervention/text-icon-carousel.mobile');

const SeoTopKeywordsIntervention = ({ keywords, title }) => {
  const namespace = 'ui-search-seo-top-keywords-intervention';
  const icon = () => <Search16 />;

  return (
    <ol className={`${namespace}__container`}>
      <h2 className={`${namespace}__title`}>{title}</h2>
      <TextIconCarousel tags={keywords} Icon={icon} />
    </ol>
  );
};

SeoTopKeywordsIntervention.propTypes = {
  keywords: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
};

module.exports = SeoTopKeywordsIntervention;
