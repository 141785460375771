/**
 * Module dependencies
 */

const React = require('react');

/**
 * Icon
 */

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="258" height="121" viewBox="0 0 258 121">
    <defs>
      <linearGradient id="a" x1="68.984%" x2="0%" y1="-23.532%" y2="100%">
        <stop offset="0%" stopColor="#999" stopOpacity="0" />
        <stop offset="33.815%" stopColor="#999" />
        <stop offset="100%" stopColor="#999" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DEDEDE"
        fillRule="nonzero"
        d="M162 12.77C134.259-3.245 98.787 6.26 82.77 34 66.755 61.741 76.26 97.213 104 113.23c27.741 16.016 63.213 6.511 79.23-21.23 16.016-27.741 6.511-63.213-21.23-79.23z"
      />
      <path
        fill="#CCC"
        fillRule="nonzero"
        d="M129 118c32.585 0 59-26.415 59-59S161.585 0 129 0 70 26.415 70 59c0 27.34 18.749 50.913 44.945 57.315a1 1 0 0 0 .475-1.942C90.114 108.188 72 85.413 72 59c0-31.48 25.52-57 57-57s57 25.52 57 57-25.52 57-57 57a1 1 0 1 0 0 2z"
      />
      <path
        stroke="#EEE"
        strokeLinecap="round"
        strokeWidth="8"
        d="M77 37h18.006C96 37 97 38 97 38.996v56.008C97 96 96 97 95.006 97H77V53m105 44h-18.006C163 97 162 96 162 95.004V38.996C162 38 163 37 163.994 37H182v44"
      />
      <path stroke="url(#a)" strokeDasharray="6" d="M40 0C17.909 0 0 17.909 0 40" transform="matrix(1 0 0 -1 1 67)" />
      <path stroke="#999" d="M41 67a39.815 39.815 0 0 1-22.6-6.992" />
      <path stroke="url(#a)" strokeDasharray="6" d="M40 0C17.909 0 0 17.909 0 40" transform="matrix(-1 0 0 1 257 68)" />
      <path stroke="#999" d="M217 68c8.387 0 16.17 2.581 22.6 6.992" />
      <g transform="translate(41 37)">
        <rect width="26" height="6" x="56" y="11" rx="1" />
        <rect width="24" height="6" x="60" y="14" fill="#FFF" fillRule="nonzero" rx="1" />
        <path
          stroke="#999"
          strokeLinecap="round"
          strokeWidth="2"
          d="M59.003 12H81.08c.51 0 .923.451.923.99v4.02c0 .546-.415.99-.923.99H59.003"
        />
        <rect width="26" height="6" x="56" y="35" rx="1" />
        <rect width="24" height="6" x="60" y="38" fill="#FFF" fillRule="nonzero" rx="1" />
        <path
          stroke="#999"
          strokeLinecap="round"
          strokeWidth="2"
          d="M59 36h22.077c.51 0 .923.451.923.99v4.02c0 .546-.415.99-.923.99H59"
        />
        <path
          fill="#BBB"
          fillRule="nonzero"
          d="M0 11.007c0-1.66 1.308-3.306 2.924-3.675L35 0h19.005C55.107 0 56 .895 56 1.996v56.008A2.001 2.001 0 0 1 54.005 60H35L2.924 52.668C1.309 52.3 0 50.66 0 48.993V11.007z"
        />
        <path fill="#EEE" fillRule="nonzero" d="M33 0h23v60H33z" />
        <rect width="20" height="60" x="38" y="3" fill="#FFF" fillRule="nonzero" rx="2" />
        <path
          stroke="#999"
          strokeLinecap="round"
          strokeWidth="2"
          d="M36 0h18.006C55 0 56 1 56 1.996v56.008C56 59 55 60 54.006 60H36V16"
        />
      </g>
      <g fill="#999" fillRule="nonzero" transform="rotate(90 28.5 107.5)">
        <rect width="10" height="2" x="3" y="4" rx="1" transform="rotate(-45 8 5)" />
        <rect width="6" height="2" x="6" y="11" rx="1" />
        <rect width="2" height="6" y="1" rx="1" />
      </g>
      <g fill="#999" fillRule="nonzero" transform="translate(122 30)">
        <path d="M16.243 3.343a.997.997 0 0 1 1.414 0 .997.997 0 0 1 0 1.414L15.535 6.88a.997.997 0 0 1-1.414 0 .997.997 0 0 1 0-1.414l2.122-2.122v-.001zm-4.948 4.948a.996.996 0 0 1 1.412.002.999.999 0 0 1 .002 1.412L7.756 14.66a.996.996 0 0 1-1.413-.002.999.999 0 0 1-.002-1.413l4.954-4.953v-.001z" />
        <rect width="12" height="2" x="8.231" y="18.923" rx="1" />
        <rect width="2" height="12" y=".538" rx="1" />
      </g>
      <g transform="rotate(-180 108.5 50)">
        <path
          fill="#BBB"
          fillRule="nonzero"
          d="M0 14.007c0-1.66 1.308-3.306 2.924-3.675L35 3h19.005C55.107 3 56 3.895 56 4.996v56.008A2.001 2.001 0 0 1 54.005 63H35L2.924 55.668C1.309 55.3 0 53.66 0 51.993V14.007z"
        />
        <path fill="#EEE" fillRule="nonzero" d="M32 3h23v60H32z" />
        <rect width="20" height="60" x="32" fill="#FFF" fillRule="nonzero" rx="2" />
        <path
          stroke="#999"
          strokeLinecap="round"
          strokeWidth="2"
          d="M35 3h18.006C54 3 55 4 55 4.996v56.008C55 62 54 63 53.006 63H35V19"
        />
      </g>
    </g>
  </svg>
);

/**
 * Expose Icon
 */

module.exports = Icon;
