const React = require('react');
const { objectOf, shape, string, any, number, func } = require('prop-types');

const InputStepper = require('@andes/input-stepper');

const NoItemsButton = require('./no-items-button');
const OtherOptionsButton = require('./other-options-button');
const { getLabelText } = require('../utils');

const namespace = 'ui-search-card-add-to-cart';
const ADD_TO_CART = 'ADD_TO_CART';
const OTHER_OPTIONS = 'OTHER_OPTIONS';

/* This components decides if we go for add to cart button or options. */
const renderButton = ({
  itemId,
  type,
  action,
  maxValue,
  itemCount,
  label,
  labelCount,
  handleAddToCartPut,
  handleAddToCartDelete,
  sendButtonTracks,
  labelMinQuantity,
}) => {
  const [value, complementaryText] = getLabelText(labelCount);
  const showInputStepper = itemCount > 0;

  switch (type) {
    case ADD_TO_CART:
      return (
        <>
          {showInputStepper ? (
            <InputStepper
              complementaryText={complementaryText}
              decrementButtonProps={{
                onClick: handleAddToCartDelete,
              }}
              id={itemId}
              incrementButtonProps={{
                onClick: handleAddToCartPut,
              }}
              maxValue={maxValue}
              size="small"
              value={itemCount}
              formatValue={() => value}
              srLabel={label}
            />
          ) : (
            <NoItemsButton
              action={action}
              className={`${namespace}__no-items-button`}
              handleAddToCartPut={handleAddToCartPut}
            />
          )}
          {labelMinQuantity && <span className={`${namespace}__minimum-label`}>{labelMinQuantity.text}</span>}
        </>
      );
    case OTHER_OPTIONS:
      return <OtherOptionsButton action={action} sendButtonTracks={sendButtonTracks} />;

    default:
      return null;
  }
};

renderButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
    tracks: objectOf(any),
  }).isRequired,
  handleAddToCartDelete: func,
  handleAddToCartPut: func,
  itemCount: number.isRequired,
  label: string.isRequired,
  labelCount: objectOf(any),
  maxValue: number.isRequired,
  sendButtonTracks: func,
  type: string.isRequired,
};

renderButton.defaultProps = {
  handleAddToCartDelete: () => {},
  handleAddToCartPut: () => {},
  labelCount: {},
  sendButtonTracks: () => {},
};

const AddToCart = (props) => <div className={namespace}>{renderButton({ ...props })}</div>;

module.exports = AddToCart;
