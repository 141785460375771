/* eslint-disable no-plusplus */

const React = require('react');
const { arrayOf, func, element, oneOfType, string, shape, number } = require('prop-types');

const { LazyHydrate: Hydrator } = require('nordic/lazy');

const DesktopGridRow = require('./row.desktop');
const MobileGridRow = require('./row.mobile');
const { DEVICE_TYPES } = require('../../../constants');
const { isItemIntervention, isIntervention } = require('../renderer/utils');
const { renderInterventionsDesktop } = require('../../intervention/utils/render-interventions/interventions.desktop');
const { renderInterventionsMobile } = require('../../intervention/utils/render-interventions/interventions.mobile');

const conditionalChunk = (arr, len) => {
  const chunks = [];

  let currentChunk = [];

  for (let i = 0; i < arr.length; i++) {
    const elem = arr[i];

    if (isIntervention(elem) && !isItemIntervention(elem)) {
      if (currentChunk.length > 0) {
        chunks.push(currentChunk);
        currentChunk = [];
      }

      chunks.push(elem);
    } else {
      currentChunk.push(elem);

      if (currentChunk.length === len) {
        chunks.push(currentChunk);
        currentChunk = [];
      }
    }
  }

  if (currentChunk.length > 0) {
    chunks.push(currentChunk);
  }

  return chunks;
};

const renderMobileComponents = (currentLayoutItem, item, currentLayout, index) => {
  if (isIntervention(item)) {
    return <Hydrator whenVisible>{renderInterventionsMobile[item.id]({ item, position: index })}</Hydrator>;
  }

  return (
    item && <MobileGridRow itemComponent={currentLayoutItem} itemGroup={item} layout={currentLayout} index={index} />
  );
};

const renderDesktopComponents = (currentLayoutItem, item, currentLayout, index) => {
  if (isIntervention(item)) {
    if (item.id === 'CART_INTERVENTION') {
      item.classname = 'grid__intervention';
    }

    if (item.id === 'CAROUSEL_INTERVENTION' && index !== 0) {
      item.content.classname = 'mt-16';
    }

    return <Hydrator whenVisible>{renderInterventionsDesktop[item.id]({ item, position: index })}</Hydrator>;
  }

  return <DesktopGridRow itemComponent={currentLayoutItem} itemGroup={item} layout={currentLayout} />;
};

const chunkResults = (results, cols, LayoutItem, layout, device) => {
  const chunkedResult = conditionalChunk(results, cols);

  return chunkedResult.map((itemGroup, i) =>
    device && device === DEVICE_TYPES.MOBILE
      ? renderMobileComponents(
          LayoutItem,
          itemGroup,
          layout,
          chunkedResult.findIndex((group) => group === itemGroup),
        )
      : renderDesktopComponents(LayoutItem, itemGroup, layout, i),
  );
};

const GridLayout = ({ itemComponent: LayoutItem, results, layout, cols, deviceType }) =>
  chunkResults(results, cols, LayoutItem, layout, deviceType);

GridLayout.propTypes = {
  cols: number.isRequired,
  deviceType: string,
  itemComponent: oneOfType([element, func]).isRequired,
  layout: string.isRequired,
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

GridLayout.defaultProps = {
  deviceType: null,
  results: [],
};

module.exports = GridLayout;
