const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-gallery-desktop';

const IconGalleryDesktop = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.599,14.5993297 L1.399,14.5993297 L1.399,1.40067031 L14.599,1.40067031 L14.599,14.5993297 Z M7.4,8.59867031 L2.598,8.59867031 L2.599,13.3993297 L7.4,13.3986703 L7.4,8.59867031 Z M13.398,8.59867031 L8.6,8.59867031 L8.6,13.3986703 L13.399,13.3993297 L13.398,8.59867031 Z M7.4,2.59967031 L2.599,2.60067031 L2.598,7.39867031 L7.4,7.39867031 L7.4,2.59967031 Z M13.399,2.60067031 L8.6,2.59967031 L8.6,7.39867031 L13.398,7.39867031 L13.399,2.60067031 Z"
    />
  </svg>
);

IconGalleryDesktop.propTypes = {
  className: string,
};

IconGalleryDesktop.defaultProps = {
  className: null,
};

module.exports = IconGalleryDesktop;
