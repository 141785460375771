const React = require('react');
const { useContext } = require('react');

const StaticPropsContext = React.createContext();
const { Provider: StaticPropsProvider, Consumer: StaticPropsConsumer } = StaticPropsContext;
const useStaticProps = () => useContext(StaticPropsContext);

module.exports = StaticPropsContext;
module.exports.StaticPropsProvider = StaticPropsProvider;
module.exports.StaticPropsConsumer = StaticPropsConsumer;
module.exports.useStaticProps = useStaticProps;
