/* eslint-disable react-hooks/exhaustive-deps */
const { memo, useEffect, useState } = require('react');
const { bool, func, number, oneOfType, shape, string } = require('prop-types');

const isEqual = require('lodash/isEqual');
const noop = require('lodash/noop');

const { addListener, getMarker, getMarkerMaxZIndex } = require('./helpers');
const { EVENTS } = require('./constants');

const Marker = ({ bringToFront, icon, label, lat, lng, map, onCleanup, onClick, onMouseOver, onMouseOut, zIndex }) => {
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const markerInstance = getMarker({ icon, map, position: { lat, lng }, label });

    if (markerInstance) {
      setMarker(markerInstance);

      return () => markerInstance.setMap(null);
    }

    return noop;
  }, [map, lat, lng]);

  useEffect(() => {
    if (!marker) {
      return;
    }

    marker.setIcon(icon);
  }, [marker, icon]);

  useEffect(() => {
    if (!marker) {
      return;
    }

    marker.setZIndex(bringToFront ? getMarkerMaxZIndex() + 1 : zIndex);
  }, [marker, zIndex, bringToFront]);

  useEffect(() => {
    if (!marker) {
      return;
    }

    marker.setLabel(label);
  }, [marker, label]);

  useEffect(() => addListener(marker, EVENTS.CLICK, onClick), [marker, onClick]);

  useEffect(() => addListener(marker, EVENTS.MOUSE_OVER, onMouseOver), [marker, onMouseOver]);

  useEffect(() => addListener(marker, EVENTS.MOUSE_OUT, onMouseOut), [marker, onMouseOut]);

  useEffect(() => onCleanup, []);

  return null;
};

Marker.propTypes = {
  bringToFront: bool,
  icon: oneOfType([
    string,
    shape({
      url: string.isRequired,
      scaledSize: shape({ height: number, width: number }),
      anchor: shape({ x: number, y: number }),
      labelAnchor: shape({ x: number, y: number }),
    }),
  ]),
  label: shape({
    text: string,
    className: string,
  }),
  lat: number.isRequired,
  lng: number.isRequired,
  map: shape().isRequired,
  zIndex: number,
  onCleanup: func,
  onClick: func,
  onMouseOut: func,
  onMouseOver: func,
};

Marker.defaultProps = {
  bringToFront: false,
  onCleanup: () => {},
  zIndex: undefined,
};

const arePropsEqual = ({ icon: prevIcon, ...prevProps }, { icon: nextIcon, ...nextProps }) =>
  isEqual(prevIcon, nextIcon) || isEqual(prevProps, nextProps);

module.exports = memo(Marker, arePropsEqual);
