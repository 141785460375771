const React = require('react');
const { shape, string, bool } = require('prop-types');

const classnames = require('classnames');
const isEqual = require('lodash/isEqual');
const Card = require('@andes/card');

const { MainImage } = require('../common/main-image');
const Link = require('../../../../link');
const { useStaticProps } = require('../../../../context/static-props');
const ItemBookmark = require('../../../../bookmark/bookmark');
const { useSearch } = require('../../../../../hooks/context');
const { namespace } = require('../../constants');
const { verticalContentRenderer } = require('../vertical-content');

const arePropsEqual = ({ item: prevItem, options: prevOptions }, { item: nextItem, options: nextOptions }) =>
  prevItem.id === nextItem.id || isEqual(prevOptions, nextOptions);

const MapLayoutItem = ({ item, options }) => {
  const { deviceType } = useStaticProps();
  const { bookmark } = useSearch();
  const { title, permalink, pictures, bookmarked } = item;

  return (
    <Card animated={false} className={namespace}>
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__main-image-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
        >
          <MainImage pictures={pictures} item={item} options={options} />
        </Link>
        <Link
          className={`${namespace}__content-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
        >
          {verticalContentRenderer(item, deviceType)}
        </Link>
        {bookmark?.show_user_bookmarks && (
          <div
            className={classnames(`${namespace}__bookmark`, {
              [`${namespace}__bookmark-active`]: bookmarked,
            })}
          >
            <ItemBookmark item={item} bookmarked={bookmarked} />
          </div>
        )}
      </div>
    </Card>
  );
};

MapLayoutItem.propTypes = {
  item: shape({
    id: string.isRequired,
    title: string.isRequired,
    permalink: string.isRequired,
    vertical: string.isRequired,
    pictures: shape({}),
  }).isRequired,
  options: shape({
    lowEnd: bool,
  }),
};

MapLayoutItem.defaultProps = {
  options: undefined,
};

module.exports = {
  MapLayoutItem: React.memo(MapLayoutItem, arePropsEqual),
};
