/* eslint-disable react-hooks/exhaustive-deps */

const React = require('react');
const { useContext, useState, useCallback } = require('react');
const { arrayOf, objectOf, shape, string, any, bool, number } = require('prop-types');

const debounce = require('lodash/debounce');

const SnackbarContext = require('../../../context/snackbar');
const FullFreeshippingContext = require('../../../context/full-freeshipping');
const FreeShippingThContext = require('../../../context/free-shipping-th');
const LayoutContext = require('../../../context/layout');
const ServiceAddToCart = require('../service');
const PresentationAddToCart = require('../presentation/add-to-cart');
const { sendTracks } = require('../utils');
const { useSearch } = require('../../../../hooks/context');
const StaticPropsContext = require('../../../context/static-props');
const { requestQueueDefault, ACTION } = require('../utils');
const useSuggestions = require('../hooks/useSuggestions');
const useEshopsEnvironment = require('../../../eshop-environment/use-eshops-environment');

const { ADD, REMOVE } = ACTION;
const PARTNER = 'PARTNER';
const FULL_SUPER = 'FULL_SUPER';

const updateCartFreya = () => {
  if (window?.freya) {
    window.freya.emit('cart:refresh');
  }
};

const DEFAULT_DELAY = 500;

const AddToCart = ({
  action,
  availableQuantity,
  cart_request_delay,
  cart_request_initial_delay,
  category,
  isCpg,
  itemId,
  label,
  label_count,
  labelMinQuantity,
  minimumQuantity,
  productId,
  quantity,
  tracks,
  type,
  variation_id,
  weight_data,
  inventoryId,
  groupBy,
  threshold,
  should_call_suggestions,
}) => {
  const {
    getCPGInfo,
    getPreloadedLabelCount,
    getQuantityCart,
    getQuantityCartPrevious,
    getQuantityCartRequested,
    setLabelCount,
    setLabelCountValues,
    setPreloadedLabelCount,
    setQuantityCart,
    setQuantityCartPrevious,
    setQuantityCartRequested,
    sameItemList,
    setSameItemList,
  } = useSearch();
  const { getSuggestions } = useSuggestions();

  const { queryParams } = useContext(StaticPropsContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { setFullFreeshipping } = useContext(FullFreeshippingContext);
  const { isEshopsEnvironment } = useEshopsEnvironment();
  const { thresholdState, thresholdDispatch } = useContext(FreeShippingThContext) || {};
  const { currentLayout } = React.useContext(LayoutContext);
  const { freeShippingSuper, freeShippingPartner } = thresholdState;
  const [itemCount, setItemCount] = useState(quantity);
  const [maxStock, setMaxStock] = useState(availableQuantity);
  const [firstRequest, setFirstRequest] = useState(true);
  const [requestQueue, setRequestQueue] = useState({ ...requestQueueDefault });
  const itemQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].quantity : itemCount;
  const labelQuantity = itemId in sameItemList ? sameItemList[`${itemId}`].label_count : label_count;
  const itemMaxStock = itemId in sameItemList ? sameItemList[`${itemId}`].max_stock : maxStock;
  const addEnabled = itemQuantity < itemMaxStock;

  const getLabelsForQuantity = (newQuantity) => {
    const cpgInfo = getCPGInfo(itemId);

    let newLabels = getPreloadedLabelCount(itemId, newQuantity);

    if (!newLabels) {
      if (cpgInfo?.label_count?.text.includes('{quantity_text}') && !cpgInfo.preloaded_label_counts) {
        newLabels = cpgInfo.label_count;

        const value = cpgInfo.label_count.values.find((val) => val.key === 'quantity_text');

        value.text = newQuantity.toString();
      }
    }

    return newLabels;
  };

  const apiError = () => {
    let previousQuantity = parseInt(getQuantityCartPrevious(itemId), 10);

    if (Number.isNaN(previousQuantity)) {
      previousQuantity = 0;
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: previousQuantity,
        },
      });
    } else {
      setItemCount(previousQuantity);
    }

    setQuantityCart(itemId, previousQuantity);
    setQuantityCartRequested(itemId, null);
    setSnackbar({
      color: 'red',
      message: 'No se pudo actualizar la cantidad.', // TODO FIXME translate traducir esto
      className: 'ui-search-snackbar-add-cart--error',
    });

    setTimeout(() => {
      setSnackbar(null);
    }, 5000);
  };

  const updateItemsFromCart = (newQuantity) => {
    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: newQuantity,
        },
      });
    } else {
      setItemCount(newQuantity);
    }

    setQuantityCartPrevious(itemId, newQuantity);
  };

  const processRequest = (callback) => {
    requestQueue.isRequestInProgress = false;

    if (requestQueue.pendingRequest) {
      requestQueue.pendingRequest();
      requestQueue.pendingRequest = null;
      setRequestQueue(requestQueue);
    } else {
      callback();
      setRequestQueue(requestQueue);
    }
  };

  const checkAvailableQuantity = (finalQuantity) => {
    const newLabels = getLabelsForQuantity(finalQuantity);

    if (newLabels) {
      setLabelCount(itemId, newLabels);
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: finalQuantity,
          max_stock: finalQuantity,
          label_count: newLabels,
        },
      });
    } else {
      setMaxStock(finalQuantity);
      setItemCount(finalQuantity);
    }

    setQuantityCart(itemId, finalQuantity);
    setQuantityCartPrevious(itemId, finalQuantity);
  };

  const changeFreeShippingText = (cartInfo) => {
    if (cartInfo.group_by === PARTNER && freeShippingPartner !== cartInfo.threshold_label_state) {
      thresholdDispatch({
        type: 'CHANGE_THRESHOLD_PARTNER_TEXT',
        payload: cartInfo,
      });
    } else if (cartInfo.group_by === FULL_SUPER && freeShippingSuper !== cartInfo.threshold_label_state) {
      thresholdDispatch({
        type: 'CHANGE_THRESHOLD_SUPER_TEXT',
        payload: cartInfo,
      });
    }
  };

  const a2cServiceHooks = {
    apiError,
    checkAvailableQuantity,
    changeFreeShippingText,
    processRequest,
    setFirstRequest,
    setFullFreeshipping,
    setItemCount: updateItemsFromCart,
    setLabelCountValues,
    setMaxStock,
    setPreloadedLabelCount,
    setQuantityCartRequested,
    setSnackbar,
  };

  const callServiceAddToCart = () => {
    const newQuantity = getQuantityCart(itemId);
    const alreadyRequested = getQuantityCartRequested(itemId) || quantity;
    const viewMode = currentLayout?.id;

    ServiceAddToCart(
      itemId,
      category,
      variation_id,
      availableQuantity,
      newQuantity,
      isCpg,
      productId,
      inventoryId,
      alreadyRequested <= newQuantity ? ADD : REMOVE,
      a2cServiceHooks,
      queryParams,
      weight_data,
      requestQueue || requestQueueDefault,
      tracks,
      updateCartFreya,
      firstRequest,
      groupBy,
      threshold,
      viewMode,
      isEshopsEnvironment,
    );
  };

  const getDelay = () => {
    if (firstRequest) {
      return cart_request_initial_delay || cart_request_delay || DEFAULT_DELAY;
    }

    const quantityNew = getQuantityCart(itemId);
    const alreadyRequested = getQuantityCartRequested(itemId);

    return (
      (quantityNew === 1 && (alreadyRequested === 0 || !alreadyRequested)
        ? cart_request_initial_delay
        : cart_request_delay) || DEFAULT_DELAY
    );
  };

  const debouncedCartService = useCallback(debounce(callServiceAddToCart, getDelay()), [
    itemId,
    firstRequest,
    freeShippingSuper,
    freeShippingPartner,
  ]);

  const changeCart = () => {
    const newQuantity = getQuantityCart(itemId);

    if (should_call_suggestions) {
      getSuggestions(category, itemQuantity, itemId, newQuantity, queryParams);
    }

    debouncedCartService();
  };

  const updateQuantity = (newQuantity) => {
    setQuantityCartPrevious(itemId, itemQuantity);

    updateItemsFromCart(newQuantity);

    setQuantityCart(itemId, newQuantity);

    const newLabels = getLabelsForQuantity(newQuantity);

    if (newLabels) {
      setLabelCount(itemId, newLabels);
    }

    if (itemId in sameItemList) {
      setSameItemList({
        ...sameItemList,
        [itemId]: {
          ...sameItemList[`${itemId}`],
          quantity: newQuantity,
          label_count: newLabels,
        },
      });
    }

    changeCart();
  };

  const handleAddToCartDelete = (e) => {
    e.preventDefault();

    const itemMinusOne = parseInt(itemQuantity, 10) - 1;

    if (minimumQuantity > 0 && itemMinusOne < minimumQuantity) {
      updateQuantity(0);
    } else {
      updateQuantity(itemMinusOne);
    }
  };

  const handleAddToCartPut = (e) => {
    e.preventDefault();

    if (addEnabled) {
      if (minimumQuantity > 0 && itemQuantity < minimumQuantity) {
        if (minimumQuantity > maxStock) {
          updateQuantity(maxStock);
        } else {
          updateQuantity(minimumQuantity);
        }
      } else {
        const itemPlusOne = parseInt(itemQuantity, 10) + 1;

        updateQuantity(itemPlusOne);
      }
    }
  };

  return (
    <PresentationAddToCart
      action={action}
      handleAddToCartDelete={handleAddToCartDelete}
      handleAddToCartPut={handleAddToCartPut}
      itemCount={itemQuantity}
      itemId={itemId}
      label={label}
      labelCount={labelQuantity}
      labelMinQuantity={labelMinQuantity}
      maxValue={itemMaxStock}
      sendButtonTracks={sendTracks}
      type={type}
    />
  );
};

AddToCart.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
  }).isRequired,
  availableQuantity: number,
  cart_request_delay: number,
  cart_request_initial_delay: number,
  category: string.isRequired,
  groupBy: string,
  inventoryId: string,
  isCpg: bool,
  itemId: string.isRequired,
  label: string.isRequired,
  label_count: objectOf(any),
  labelMinQuantity: shape({
    color: string,
    text: string,
  }),
  minimumQuantity: number,
  productId: string,
  quantity: number.isRequired,
  should_call_suggestions: bool,
  threshold: number,
  tracks: shape({
    melidata_track: objectOf(any),
  }).isRequired,
  type: string.isRequired,
  variation_id: string,
  weight_data: arrayOf(shape()),
};

AddToCart.defaultProps = {
  availableQuantity: 0,
  cart_request_delay: null,
  cart_request_initial_delay: null,
  isCpg: false,
  label_count: {},
  labelMinQuantity: undefined,
  minimumQuantity: 0,
  productId: null,
  weight_data: undefined,
};

module.exports = AddToCart;
