const React = require('react');
const { string, func, shape } = require('prop-types');

const TextField = require('@andes/textfield');
const Dropdown = require('@andes/dropdown');

const { TYPE_RANGE } = require('../../../../constants');

const { DropdownItem } = Dropdown;

const renderItem = (name, label, setDropdownItemSelected) => (
  <DropdownItem
    onClick={() => setDropdownItemSelected(true)}
    key={name}
    value={name}
    title={name}
    data-testid={`${name}-${label}`}
  />
);

const RangeFilterInput = ({
  id,
  type,
  handleInputChange,
  handleChangeDropdown,
  label,
  name,
  setDropdownItemSelected,
  values,
  className,
  pattern,
}) => (
  <div key={name} className={className}>
    {type === TYPE_RANGE.INPUT && (
      <TextField
        data-testid={`${name}-${id}`}
        name={name}
        label=""
        textbox
        pattern={pattern}
        placeholder={label}
        onChange={handleInputChange}
        enterkeyhint="go"
      />
    )}
    {type === TYPE_RANGE.DROPDOWN && (
      <Dropdown
        value={label}
        menuAlignment="bottom"
        size="small"
        label={label}
        onChange={handleChangeDropdown[`${name}`]}
        data-testid={`${name}-${id}`}
      >
        {
          // TODO: eliminar el concat y agregar el hijo directo, cuando arreglen el fix de andes
          values &&
            [{ name: label, value: label, disabled: false }]
              .concat(values)
              .map((value) => renderItem(value.name, label, setDropdownItemSelected))
        }
      </Dropdown>
    )}
  </div>
);

RangeFilterInput.propTypes = {
  className: string,
  handleChangeDropdown: shape({}),
  handleInputChange: func,
  id: string,
  label: string.isRequired,
  name: string.isRequired,
  pattern: string,
  setDropdownItemSelected: func,
  type: string.isRequired,
  values: shape(),
};

RangeFilterInput.defaultProps = {
  className: '',
};

module.exports = RangeFilterInput;
