const React = require('react');
const { useReducer } = require('react');
const { node } = require('prop-types');

const FreeShippingThContext = React.createContext('');
const { Provider, Consumer: FreeShippingThConsumer } = FreeShippingThContext;

const FreeShippingThProvider = ({ children }) => {
  const initialState = {
    thresholdSuperLabel: '',
    firstLoadSuper: true,
    freeShippingSuper: 'paid_shipping',
    thresholdPartnerLabel: '',
    firstLoadPartner: true,
    freeShippingPartner: 'paid_shipping',
  };

  function reducer(state, action) {
    switch (action.type) {
      case 'FIRST_LOAD_SUPER':
        return {
          ...state,
          firstLoadSuper: false,
          freeShippingSuper: action.payload.thresholdLabelState,
        };
      case 'FIRST_LOAD_PARTNER':
        return {
          ...state,
          firstLoadPartner: false,
          freeShippingPartner: action.payload.thresholdLabelState,
        };
      case 'CHANGE_THRESHOLD_SUPER_TEXT':
        return {
          ...state,
          freeShippingSuper: action.payload.threshold_label_state,
          thresholdSuperLabel: action.payload.threshold_label,
        };
      case 'CHANGE_THRESHOLD_PARTNER_TEXT':
        return {
          ...state,
          freeShippingPartner: action.payload.threshold_label_state,
          thresholdPartnerLabel: action.payload.threshold_label,
        };

      default:
        return state;
    }
  }

  const [thresholdState, thresholdDispatch] = useReducer(reducer, initialState);

  return <Provider value={{ thresholdState, thresholdDispatch }}>{children}</Provider>;
};

FreeShippingThProvider.propTypes = {
  children: node.isRequired,
};

module.exports = FreeShippingThContext;
module.exports.FreeShippingThProvider = FreeShippingThProvider;
module.exports.FreeShippingThConsumer = FreeShippingThConsumer;
