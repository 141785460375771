const React = require('react');
const { useState } = require('react');
const { node, shape } = require('prop-types');

const SuggestContext = React.createContext('');
const { Provider, Consumer: SuggestConsumer } = SuggestContext;

const SuggestProvider = ({ children, suggest }) => {
  const [currentItemSuggestion, setCurrentItemSuggestion] = useState(suggest);
  const [openSuggestions, setOpenSuggestions] = useState(suggest);
  const [suggestions, setSuggestions] = useState(suggest);
  const [cartFetching, setCartFetching] = useState(suggest);

  const state = {
    openSuggestions,
    setOpenSuggestions,
    setCurrentItemSuggestion,
    currentItemSuggestion,
    suggestions,
    setSuggestions,
    cartFetching,
    setCartFetching,
  };

  return <Provider value={state}>{children}</Provider>;
};

SuggestProvider.propTypes = {
  children: node.isRequired,
  suggest: shape({}),
};

SuggestProvider.defaultProps = {
  snackbar: null,
};

module.exports = SuggestContext;
module.exports.SuggestProvider = SuggestProvider;
module.exports.SuggestConsumer = SuggestConsumer;
