const React = require('react');
const { useContext, useLayoutEffect, useMemo, useState } = require('react');
const { arrayOf, element, func, oneOfType, string, shape, number } = require('prop-types');

const classnames = require('classnames');
const get = require('lodash/get');

const StaticPropsContext = require('../../context/static-props');
const LayoutFadeContext = require('../../context/layout-fade');
const { isItemIntervention } = require('../renderer/utils');
const { renderItemsIterventionsMobile } = require('../../intervention/utils/render-item-interventions');

const namespace = 'ui-search-layout';

const renderItem = ({ item, index, layout, options, imageGridHeight, LayoutItem }) => (
  <li key={item.id} className={`${namespace}__item`}>
    <LayoutItem layout={layout} item={item} index={index} options={options} imageGridHeight={imageGridHeight} />
  </li>
);

const GridRows = ({ itemComponent: LayoutItem, itemGroup, layout, index }) => {
  const { lowEnd } = useContext(StaticPropsContext);
  const { isFade } = useContext(LayoutFadeContext);
  const options = useMemo(() => ({ lowEnd, verboseLabels: false, preload: false }), [lowEnd]);
  const [windowWidth, setWindowWidth] = useState(0);

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth);
    const resizeListener = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', resizeListener);

    return () => window.removeEventListener('resize', resizeListener);
  }, []);

  const getMaxImageHeight = (result, value, maxValueAllowed) => {
    const imageLayoutHeight = get(value.pictures, `${layout}.tags.heigth`) || 0;

    if (imageLayoutHeight > maxValueAllowed) {
      return maxValueAllowed;
    }

    if (result > imageLayoutHeight) {
      return result;
    }

    return imageLayoutHeight;
  };

  const MAX_GRID_HEIGHT_320 = 220;
  const MAX_GRID_HEIGHT_360 = 250;
  const imageGridHeight320 = itemGroup.reduce(
    (result, value) => getMaxImageHeight(result, value, MAX_GRID_HEIGHT_320),
    0,
  );
  const imageGridHeight360 = itemGroup.reduce(
    (result, value) => getMaxImageHeight(result, value, MAX_GRID_HEIGHT_360),
    0,
  );

  const imageGridHeight = windowWidth && windowWidth < 330 ? imageGridHeight320 : imageGridHeight360;

  return (
    <ol className={classnames(namespace, { [`${namespace}--${layout}`]: layout }, { [`${namespace}--fade`]: isFade })}>
      {itemGroup.map((item, idx) =>
        isItemIntervention(item)
          ? renderItemsIterventionsMobile[item.id]({ item, idx, imageGridHeight })
          : renderItem({ item, index, layout, options, imageGridHeight, LayoutItem }),
      )}
    </ol>
  );
};

GridRows.propTypes = {
  index: number,
  itemComponent: oneOfType([element, func]).isRequired,
  itemGroup: arrayOf(shape()),
  layout: string.isRequired,
};

GridRows.defaultProps = {
  index: null,
  itemGroup: [],
};

module.exports = GridRows;
