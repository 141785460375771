const React = require('react');
const { func, number, shape } = require('prop-types');

const { OverlayViewPortal, InfowindowInstance } = require('../lib');
const { InfowindowItem } = require('../../layout/renderer/map/infowindow-card-content');

const IMAGE_OPTIONS = { lazyload: 'off', mounted: true };

const Infowindow = ({ containerMeasurements, content, index, map, onClick, onHover }) => {
  const { latitude, longitude } = content;

  return (
    <OverlayViewPortal id="infowindow-portal">
      <InfowindowInstance
        map={map}
        lat={latitude}
        lng={longitude}
        containerMeasurements={containerMeasurements}
        onMouseOver={() => onHover(index, [content])}
        onMouseOut={() => onHover(null, null)}
      >
        <InfowindowItem
          item={content}
          options={IMAGE_OPTIONS}
          onClick={(event) => {
            event.preventDefault();
            onClick(index);
          }}
        />
      </InfowindowInstance>
    </OverlayViewPortal>
  );
};

Infowindow.propTypes = {
  containerMeasurements: shape().isRequired,
  content: shape({}).isRequired,
  index: number,
  map: shape().isRequired,
  onClick: func.isRequired,
  onHover: func.isRequired,
};

module.exports = Infowindow;
