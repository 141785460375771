const React = require('react');
const { memo } = require('react');
const { arrayOf, shape, string, bool } = require('prop-types');

const Tooltip = require('@andes/tooltip');

const FiltersButton = require('./filters-button');
const FiltersContent = require('./filters-content');
const { NAMESPACE } = require('../constants');

const SecondaryFilters = ({ appliedFilters, availableFilters, availableLabels, appliedLabels, isLoading }) => {
  if (!appliedFilters || !availableFilters.length || !availableLabels || !appliedLabels || isLoading) {
    return <FiltersButton label={appliedLabels.filter} totalLabel={appliedLabels.total} />;
  }

  return (
    <div className={`${NAMESPACE}__secondary-container`}>
      <Tooltip
        side="bottomRight"
        trigger="click"
        className={`${NAMESPACE}__filters-tooltip`}
        offsetX={-16}
        offsetY={10}
        zIndex={900}
        content={
          <FiltersContent
            isLoading={isLoading}
            appliedFilters={appliedFilters}
            availableFilters={availableFilters}
            availableLabels={availableLabels}
          />
        }
      >
        <FiltersButton label={appliedLabels.filter} totalLabel={appliedLabels.total} />
      </Tooltip>
    </div>
  );
};

SecondaryFilters.propTypes = {
  appliedFilters: arrayOf(shape({})).isRequired,
  appliedLabels: shape({
    filter: string.isRequired,
    total: string.isRequired,
  }).isRequired,
  availableFilters: arrayOf(shape({})).isRequired,
  availableLabels: shape({}).isRequired,
  isLoading: bool,
};

SecondaryFilters.defaultProps = {
  isLoading: false,
};

module.exports = memo(SecondaryFilters);
