const React = require('react');
const { node, string, objectOf, any } = require('prop-types');

const AndesTooltip = require('@andes/tooltip');

const useTooltip = require('../../../../hooks/use-tooltip');
const { getFilterTooltipKey } = require('./helpers');

const namespace = 'ui-search-tooltip-filter';

const FilterTooltip = ({ children, className, tooltip }) => {
  const { title, offset_x, offset_y, content, side, type, max_print, filter_content_id } = tooltip || {};

  const localStorageKey = getFilterTooltipKey({ filter_content_id });
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);

  if (tooltipStatus.wasClosed || tooltipStatus.views > max_print || !tooltip || !content) {
    return children;
  }

  return (
    <AndesTooltip
      className={`${namespace} ${className}`}
      closable
      onClose={handleClose}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      side={side}
      type={type}
      trigger="no-trigger"
      visible
    >
      {children}
    </AndesTooltip>
  );
};

FilterTooltip.propTypes = {
  children: node.isRequired,
  className: string,
  tooltip: objectOf(any),
};

FilterTooltip.defaultProps = {
  className: '',
  tooltip: {
    side: 'right',
    type: 'highlight',
  },
};

module.exports = FilterTooltip;
