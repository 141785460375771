/* eslint-disable  @typescript-eslint/default-param-last */
const APIService = require('../../../../../services/cart');
const { sendTracks } = require('../utils');
const { setMelidataInfoRemove, setMelidataInfoAdd, ACTION } = require('../utils');

const ServiceAddToCart = (
  itemId,
  category,
  variation_id,
  availableQuantity,
  quantityCart,
  isCpg,
  productId,
  inventoryId,
  add,
  parentHooks,
  queryParams,
  weight_data,
  requestQueue = {},
  tracks,
  updateCartFreya,
  firstRequest,
  groupBy,
  threshold,
  viewMode,
  isEshopsEnvironment,
) => {
  const {
    setFullFreeshipping,
    setSnackbar,
    setPreloadedLabelCount,
    setQuantityCartRequested,
    apiError,
    processRequest,
    checkAvailableQuantity,
    changeFreeShippingText,
    setFirstRequest,
    setMaxStock,
  } = parentHooks;

  const request = () => {
    if (requestQueue) {
      requestQueue.isRequestInProgress = true;
    }

    APIService.addToCart(
      itemId,
      quantityCart,
      category,
      variation_id,
      availableQuantity,
      add,
      isCpg,
      queryParams,
      productId,
      inventoryId,
      weight_data,
      firstRequest,
      groupBy,
      threshold,
      viewMode,
    )
      .then((response) => {
        const callback = () => {
          const { components, modify_cart_cpg } = response.data;

          const { add_to_cart, cart_info, modify_cart, snackbar } = components?.[0] || modify_cart_cpg;

          let fullFreeShipping;

          if (cart_info || modify_cart) {
            fullFreeShipping = cart_info?.free_shipping || modify_cart?.cart_info?.free_shipping;
          }

          const addToCartResponse = add_to_cart || modify_cart;

          if (addToCartResponse) {
            sendTracks(addToCartResponse);

            if (addToCartResponse.preloaded_label_counts) {
              setPreloadedLabelCount(itemId, addToCartResponse.preloaded_label_counts);
            }
          }

          if (snackbar) {
            setSnackbar({
              color: 'red',
              message: snackbar.label.text,
              className: 'ui-search-snackbar-add-cart--error',
            });

            apiError();

            setTimeout(() => {
              setSnackbar(null);
            }, 5000);
          }

          if (fullFreeShipping) {
            setFullFreeshipping({ ...fullFreeShipping, key: Math.floor(Math.random() * 1000) });
          } else if (isEshopsEnvironment) {
            setFullFreeshipping(null);
          }

          if (add === ACTION.ADD) {
            setMelidataInfoAdd(quantityCart, tracks);
          } else {
            setMelidataInfoRemove(quantityCart, tracks);
          }

          const { available_quantity } = components[0].add_to_cart;

          if (available_quantity) {
            if (quantityCart >= available_quantity) {
              checkAvailableQuantity(available_quantity);
              setQuantityCartRequested(itemId, available_quantity);
            }

            setMaxStock(available_quantity);
          } else {
            setQuantityCartRequested(itemId, quantityCart);
          }

          /* istanbul ignore next */
          setFirstRequest(false);

          if (cart_info?.threshold_label_state) {
            changeFreeShippingText(cart_info);
          }

          updateCartFreya();
        };

        processRequest(callback);
      })
      .catch(() => {
        if (requestQueue) {
          requestQueue.isRequestInProgress = false;
        }

        apiError();
      });
  };

  if (requestQueue?.isRequestInProgress) {
    requestQueue.pendingRequest = request;
  } else {
    request();
  }
};

module.exports = ServiceAddToCart;
