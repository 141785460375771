const React = require('react');

const namespace = 'ui-search-icon ui-search-icon--play';

const IconPlay = () => (
  <svg className={namespace} width="13" height="16" viewBox="0 0 13 16" fill="none">
    <path d="M1 14V2L11 8L1 14Z" fill="#3483FA" stroke="#3483FA" />
  </svg>
);

module.exports = IconPlay;
