const React = require('react');
const { shape, arrayOf } = require('prop-types');
const { useContext } = require('react');

// RecommendationsFE Components
const { RecommendationsList, RecommendationsListHorizontal } = require('@recommendations-fe/list');
const { RecommendationsCardHorizontalTitleFirst } = require('@recommendations-fe/card');

const IconFactory = require('../icons/recommendations-fe/icon-factory');
// Search hooks
const UserContext = require('../context/user');
const useBottomAds = require('../advertising-utils/use-bottom-ads');

const namespace = 'ui-search-bottom-ads';

const BottomAds = ({ request: { params }, subtitle, ads_to_show }) => {
  const { id: userId } = useContext(UserContext);
  const callbackNoRecos = () => {
    document.querySelector(`#${namespace}__wrapper`).style.display = 'none';
  };

  return (
    <div id={`${namespace}__wrapper`} className={`${namespace}__wrapper`}>
      <div className={`${namespace}__container`}>
        <RecommendationsList
          ListComponent={RecommendationsListHorizontal}
          listType="horizontal"
          Card={RecommendationsCardHorizontalTitleFirst}
          IconFactory={IconFactory}
          paddingHorizontal={12}
          onCarouselIsEmpty={callbackNoRecos}
          siteId={params.site_id}
          client={params.client}
          d2Id={params.d2_id}
          limit={ads_to_show}
          itemId={params.item_id}
          userId={userId}
          categoryId={params.category_id}
          subtitle={subtitle}
          hideRebates
          {...params}
        />
      </div>
    </div>
  );
};

const BottomAdsContainer = () => {
  const bottomAds = useBottomAds();

  if (!bottomAds) {
    return null;
  }

  return <BottomAds {...bottomAds} />;
};

BottomAds.propTypes = {
  ads_to_show: arrayOf(shape({})),
  request: shape({}).isRequired,
  subtitle: shape({}),
};

BottomAds.defaultProps = {
  ads_to_show: null,
  subtitle: null,
};

module.exports = BottomAdsContainer;
module.exports.BottomAds = BottomAds;
