const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-card-attributes';

const Attributes = ({ className, attributes }) => {
  if (attributes.length <= 0) {
    return null;
  }

  return (
    <ul className={classnames(namespace, className)}>
      {attributes.map((attribute) => (
        <li key={attribute.id} className={`${namespace}__attribute`}>
          {attribute.label}
        </li>
      ))}
    </ul>
  );
};

Attributes.defaultProps = {
  attributes: [],
  className: null,
};

Attributes.propTypes = {
  attributes: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
    }),
  ),
  className: string,
};

module.exports = Attributes;
