const React = require('react');
const { string, shape, arrayOf } = require('prop-types');

const classnames = require('classnames');

const ValueProposition = require('./value-proposition');

const namespace = 'ui-search-value-propositions-list';

/* eslint-disable react/no-array-index-key */
const ValuePropositions = ({ className, propositions }) => (
  <div className={classnames(namespace, className)}>
    {propositions.map((proposition, index) => (
      <ValueProposition key={`${proposition.id}-${index}`} {...proposition} />
    ))}
  </div>
);

/* eslint-enable */
ValuePropositions.propTypes = {
  className: string,
  propositions: arrayOf(shape({ id: string.isRequired })).isRequired,
};

ValueProposition.defaultProps = {
  className: '',
};

module.exports = ValuePropositions;
