const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--heart-empty';

const IconHeartEmpty = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 36 32">
    <path d="M0 10.4q0-1.7.8-4t2.6-3.8Q5 1.2 6.7.6t3.6-.6q2 0 3.8.8t4 2.7q2-2 4-2.7t4-.8 3.4.6 3.3 2Q34.3 4 35 6.3t1 4-.6 4-3 4L18 32 3.4 18.2Q1.8 16.7 1 14.7t-1-4.3z" />
  </svg>
);

IconHeartEmpty.propTypes = {
  className: string,
};

IconHeartEmpty.defaultProps = {
  className: null,
};

module.exports = React.memo(IconHeartEmpty);
