const React = require('react');
const { forwardRef } = require('react');
const { arrayOf, node, oneOfType, string } = require('prop-types');

const cx = require('classnames');

const namespace = 'ui-search-pagination';

const Content = forwardRef(({ className, children }, ref) => (
  <div className={cx(namespace, className, 'shops__pagination-content')} ref={ref}>
    <nav className={cx(namespace, 'andes-pagination', 'shops__pagination')}>{children}</nav>
  </div>
));

Content.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  className: string,
};

Content.defaultProps = {
  className: null,
};

Content.displayName = 'Content';

module.exports = Content;
