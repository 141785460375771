const React = require('react');

const classnames = require('classnames');

const useFullFreeshipping = require('./use-full-freeshipping');
const FullSnackbar = require('./full-shipping-info');

const eshopClassName = 'eshops-fullsnackbar';

const EshopsFreeshippingContainer = () => {
  const { currentFullFreeshipping } = useFullFreeshipping();

  if (!currentFullFreeshipping) {
    return null;
  }

  // Exclude the properties for eshops component and exclude the key because we don't want the component to be completely updated.
  const { title, closeAction, asset_url, className, key, ...rest } = currentFullFreeshipping;

  return <FullSnackbar className={classnames(eshopClassName, className)} {...rest} variant="eshops" />;
};

EshopsFreeshippingContainer.propTypes = {};

module.exports = EshopsFreeshippingContainer;
