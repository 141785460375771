/* eslint-disable react/prop-types */

const React = require('react');
const { useState, useRef, useContext } = require('react');
const { bool, func, number, shape, string } = require('prop-types');

const Image = require('nordic/image');
const classnames = require('classnames');
const get = require('lodash/get');
const Card = require('@andes/card');
const CarouselSnapped = require('@andes/carousel-snapped');
const includes = require('lodash/includes');

const Wrapper = require('../cpg/wrapper');
const ItemBookmark = require('../../../bookmark/bookmark');
const Link = require('../../../link');
const AdLabel = require('../../../card/pads/ad-label');
const useItem = require('../../../../hooks/use-item');
const { normalizePictureData, IsNotCropImage } = require('../utils');
const renderCoreContent = require('../desktop-card-content/core');
const { renderVariationPicker } = require('../desktop-card-content/core');
const renderMotContent = require('../desktop-card-content/mot');
const renderResContent = require('../desktop-card-content/res');
const renderSrvContent = require('../desktop-card-content/srv');
const renderCpgContent = require('../desktop-card-content/cpg');
const renderCatContent = require('../desktop-card-content/cat');
const { renderPills, renderShippingGroup } = require('../desktop-card-content/core');
const Suggestions = require('../../../suggestions/suggestions.desktop');
const SuggestContext = require('../../../context/suggest-cpg');
const { useSearch } = require('../../../../hooks/context');
const {
  CAROUSEL_SETTINGS,
  VERTICAL_TYPE_SRV,
  VERTICAL_TYPE_CORE,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
  VERTICAL_TYPE_CPG,
  GRID,
  STACK,
  namespace,
} = require('../constants');
const { renderMediaTagGroup } = require('../desktop-card-content/common');
const ItemOfficialStore = require('../../../card/official-store/official-store-label');

function verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type) {
  const isCategoryPage = frontend_layout_type === 'CATEGORY';
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_SRV:
      return renderSrvContent(item, options, layout);
    case VERTICAL_TYPE_MOT:
      return renderMotContent(item, options, layout);
    case VERTICAL_TYPE_RES:
      return renderResContent(item, options, layout);
    case VERTICAL_TYPE_CPG:
      return renderCpgContent(item, options, layout);
    case VERTICAL_TYPE_CORE:

    // falls through
    default:
      return isCategoryPage
        ? renderCatContent(item, options, layout)
        : renderCoreContent(item, options, layout, itemContext);
  }
}

const { CarouselSnappedSlide } = CarouselSnapped;

const PictureSlide = ({ picture, item }) => (
  <CarouselSnappedSlide key={picture.src}>
    <Link title={item.title} isInternal={false} href={item.permalink} isAd={item.is_ad} target={item.target}>
      <Image
        alt={picture.title}
        className={classnames(
          `${namespace}-image__element`,
          { [`${namespace}-image__not-crop`]: picture.isNotCropImage },
          'shops__image-element',
        )}
        src={picture.src}
        preload
        lazyload={picture.lazyload}
        lowEnd={picture.lowEnd}
        width={picture.width}
        height={picture.height}
      />
    </Link>
  </CarouselSnappedSlide>
);

const renderContent = (item, options, layout, itemContext, frontend_layout_type) => {
  const isCpg = Boolean(item.cpg);
  const adLabel = item.is_ad && (
    <AdLabel adVersion={item.ad_version} text={item.ad_label} shouldRenderLinks={options.cardSupportsLinks} />
  );

  switch (layout) {
    case GRID:
      return (
        <>
          <Wrapper adLabel={adLabel} className={`${namespace}__content`} isCpg={isCpg} item={item}>
            <div className={classnames(`${namespace}__content-wrapper`, 'shops__result-content-wrapper')}>
              {verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type)}
            </div>
            {!isCpg && adLabel}
          </Wrapper>
          {item.vertical === VERTICAL_TYPE_RES &&
            renderMediaTagGroup({ media: item.media, layout, highlighted: item?.highlighted })}
        </>
      );
    case STACK:

    // falls through
    default:
      return (
        <div className={classnames(`${namespace}__content-wrapper`, 'shops__result-content-wrapper')}>
          {verticalContentRenderer(item, options, layout, itemContext, frontend_layout_type)}
          {adLabel}
        </div>
      );
  }
};

const renderShopsPills = (item, layout) => (
  <div className={`${namespace}__shops-pills--absolute`}>
    {renderPills(item)}
    {renderShippingGroup(item, layout)}
  </div>
);

function renderOfficialStoreItem(official_store, options, layout) {
  return (
    <ItemOfficialStore
      label={official_store.verbose_text}
      permalink={official_store.permalink}
      shouldRenderLinks
      title={official_store.verbose_text.text}
      tracks={official_store.tracks}
      className="official-store--pi"
      layout={layout}
    />
  );
}

function renderRealEstateAgencyLogo(item, options, layout) {
  const superPremium = includes(item.tags, 'super_premium');
  const isMultifamily = item.is_multifamily ?? false;

  if (superPremium || isMultifamily) {
    const realEstateAgencyLogo = get(item, 'seller_info.real_estate_agency_logo');
    const realEstateFantasyName = get(item, 'seller_info.real_estate_agency_fantasy_name');
    const isOfficialStoreAndStackLayout = layout === STACK && item.official_store;

    if (realEstateAgencyLogo) {
      return (
        <div className={`${namespace}-item-super_premium`}>
          <Image
            className={`real-estate-agency-logo__image--${layout}`}
            src={realEstateAgencyLogo}
            lazyload="on"
            lowEnd={options.lowEnd === true}
            alt={realEstateFantasyName}
          />
          {isOfficialStoreAndStackLayout ? (
            renderOfficialStoreItem(item.official_store, options)
          ) : (
            <span className="real-estate-agency-name">{realEstateFantasyName}</span>
          )}
        </div>
      );
    }

    return isOfficialStoreAndStackLayout ? (
      renderOfficialStoreItem(item.official_store, options)
    ) : (
      <span className="real-estate-agency-onlyname">{realEstateFantasyName}</span>
    );
  }

  return null;
}

// TODO: render creado para la VI
const DesktopLayoutItem = ({ index, item: middlewareItem, layout, onMount, options }) => {
  const { bookmark, frontend_layout_type, shops } = useSearch();
  const isShops = Boolean(shops);
  const cardRef = useRef(null);

  const itemContext = useItem({ index, middlewareItem, layout, onMount, cardRef });

  const { item, selectedVariation, itemPictures, isLoadingPictures, handleCardHover } = itemContext;

  const { title, vertical, image_ratio } = item;

  const shouldNotCropImage = IsNotCropImage(vertical, image_ratio);

  const itemVertical = vertical ? vertical.toLowerCase() : null;
  // @TODO Quitar esto cuando se simplifique si es gallery o grid para desktop.
  const isGridLayout = layout === GRID;

  const variationPicker =
    isGridLayout && (options.renderVariationPicker === undefined ? true : options.renderVariationPicker);

  const [carouselSettings] = useState(CAROUSEL_SETTINGS);

  const resultWrapperStyles = classnames(`${namespace}__wrapper`);
  const cardStyles = classnames(namespace, 'shops__cardStyles', { [`${namespace}--${itemVertical}`]: itemVertical });
  const picturesStripStyles = classnames(
    `${namespace}__image`,
    { [`${namespace}__image--loading`]: isLoadingPictures(item.id) },
    ['shops__picturesStyles'],
  );

  const { currentItemSuggestion } = useContext(SuggestContext);

  return (
    <div
      className={classnames(resultWrapperStyles, 'shops__result-wrapper')}
      ref={cardRef}
      onMouseEnter={handleCardHover}
      onFocus={handleCardHover}
    >
      <Card animated={isGridLayout} className={cardStyles}>
        {isShops && renderShopsPills(item, layout)}
        <div className={picturesStripStyles}>
          <CarouselSnapped
            className={`${namespace}__card`}
            key={get(selectedVariation, 'id', get(item, 'id'))}
            strictBoundaries={false}
            pagination={carouselSettings.dots}
            type="full"
            srLabel={item.title}
            slidesPerView={carouselSettings.slidesToShow}
            arrows={{ visibility: carouselSettings.arrowsVisible ? 'always' : 'hover' }}
            infinite={carouselSettings.infinite}
            lazyload
          >
            {itemPictures
              .map((picture) => ({
                picture: normalizePictureData(picture, title, options, shouldNotCropImage),
                item,
              }))
              .map(PictureSlide)}
          </CarouselSnapped>
          <Link
            className={picturesStripStyles}
            href={item.permalink}
            title={title}
            isInternal={false}
            target={item.target}
          >
            {layout === GRID && renderRealEstateAgencyLogo(item, options, layout)}
          </Link>
          {variationPicker && renderVariationPicker(options, layout, itemContext)}
        </div>
        {renderContent(item, options, layout, itemContext, frontend_layout_type)}
        {bookmark?.show_user_bookmarks && !item.pds_info && (
          <div className={classnames(`${namespace}__bookmark`, { [`${namespace}__bookmark-active`]: item.bookmarked })}>
            <ItemBookmark item={item} bookmarked={item.bookmarked} />
          </div>
        )}
        <Suggestions
          permalink={item.permalink}
          title={item.title}
          category={item.category_id}
          itemId={item.id}
          cpg={item.cpg}
          currentItemSuggestion={currentItemSuggestion}
          availableQuantity={item.available_quantity}
        />
      </Card>
    </div>
  );
};

DesktopLayoutItem.propTypes = {
  index: number.isRequired,
  item: shape({
    title: string,
  }).isRequired,
  layout: string.isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onMount: func,
};

DesktopLayoutItem.defaultProps = {
  onMount: undefined,
};

module.exports = DesktopLayoutItem;
