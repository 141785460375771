const React = require('react');
const { string, shape, arrayOf, bool, func } = require('prop-types');

const classNames = require('classnames');
const get = require('lodash/get');

const Link = require('../link');

const namespace = 'ui-search-money-picker';

const MoneyPicker = ({ className, currencies, filterValues, title, activeCurrencyId, handleCurrencyChange }) => {
  const createLiFilterValue = ({ id, name, url, results }) => (
    <li key={id} className={`${namespace}__li`}>
      <Link href={url}>
        <span className="ui-search-filter-name">{name}</span>
        <span className="ui-search-filter-results-qty">{results}</span>
      </Link>
    </li>
  );

  const renderValues = () => {
    const activeValues = get(filterValues, activeCurrencyId, []);

    return activeValues.map((it) => createLiFilterValue(it));
  };

  const handleClickMoneyPicker = (e, value) => {
    e.preventDefault();
    handleCurrencyChange(value.id);
  };

  return (
    <>
      <li>
        <h3 aria-level="3" className={`${namespace}-dt-title`}>
          {title}
        </h3>
        {currencies.length > 1 && (
          <div className={classNames(namespace, className)}>
            <form action={`/search/picker/money/:${activeCurrencyId}`} className={`${namespace}__form`} method="POST">
              {currencies.map((value) => (
                <button
                  key={value.id}
                  type="submit"
                  className={`${namespace}__btn`}
                  id={value.id}
                  disabled={activeCurrencyId === value.id ? 'disabled' : ''}
                  onClick={(e) => {
                    handleClickMoneyPicker(e, value);
                  }}
                >
                  {value.symbol}
                </button>
              ))}
            </form>
          </div>
        )}
      </li>
      {filterValues && renderValues()}
    </>
  );
};

MoneyPicker.propTypes = {
  activeCurrencyId: string.isRequired,
  className: string,
  currencies: arrayOf(
    shape({
      id: string.isRequired,
      symbol: string.isRequired,
      selected: bool,
    }).isRequired,
  ),
  filterValues: shape().isRequired,
  handleCurrencyChange: func,
  title: string.isRequired,
};

MoneyPicker.defaultProps = {
  className: '',
  currencies: [],
  handleCurrencyChange: null,
};

module.exports = MoneyPicker;
