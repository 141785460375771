const get = require('lodash/get');

// In production, export the es5 targeted file which is IE11 compatible.
const googlemapsApi =
  process.env.NODE_ENV === 'production'
    ? require('@googlemaps/js-api-loader/dist/index.min')
    : require('@googlemaps/js-api-loader');

if (process.env.NODE_ENV === 'production') {
  module.exports = get(googlemapsApi, 'google.maps.plugins.loader');
} else {
  module.exports = googlemapsApi;
}
