const React = require('react');
const { bool, string } = require('prop-types');

const classnames = require('classnames');

const NAMESPACE_RAW = 'ui-search-icon';
const NAMESPACE = `${NAMESPACE_RAW} ${NAMESPACE_RAW}--loyalty`;

const IconLoyalty = ({ className, hidden }) => (
  <svg
    className={classnames(NAMESPACE, className, { [`${NAMESPACE_RAW}--hidden`]: hidden })}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1.0" fillRule="evenodd">
      <path d="M8.18 4.4l0.766 6.886c0.402 3.573 3.407 6.325 7.054 6.325s6.652-2.752 7.051-6.293l0.003-0.032 0.764-6.886h-15.636zM9.934 17.81c-5.573-2.329-9.5-7.566-9.902-13.763l-0.002-0.047h5.692l-0.036-0.334c-0.006-0.050-0.009-0.107-0.009-0.166 0-0.828 0.671-1.5 1.499-1.5h17.648c0.828 0 1.499 0.672 1.499 1.5 0 0.058-0.003 0.116-0.010 0.173l0.001-0.007-0.034 0.334h5.692c-0.404 6.244-4.331 11.481-9.798 13.771l-0.106 0.039c-1.128 0.944-2.499 1.635-4.005 1.968l-0.063 0.012v4.21h3.116c0 0 0.001 0 0.001 0 1.757 0 3.249 1.133 3.787 2.708l0.008 0.028 1.088 3.264h-20l1.088-3.264c0.546-1.603 2.038-2.736 3.795-2.736 0 0 0.001 0 0.001 0h3.116v-4.212c-1.568-0.345-2.939-1.037-4.081-1.992l0.015 0.012zM6.854 13.066c-0.126-0.43-0.228-0.944-0.288-1.47l-0.004-0.044-0.506-4.552h-3.060c0.759 2.412 2.095 4.46 3.849 6.056l0.011 0.010zM25.944 7l-0.506 4.552c-0.064 0.57-0.166 1.084-0.308 1.581l0.016-0.067c1.763-1.606 3.097-3.654 3.828-5.97l0.026-0.096h-3.058zM22.634 27.494c-0.218-0.641-0.815-1.094-1.518-1.094-0 0-0 0-0 0h-10.232c-0.001 0-0.001 0-0.002 0-0.703 0-1.3 0.453-1.515 1.083l-0.003 0.011-0.034 0.106h13.34l-0.036-0.106z" />
    </g>
  </svg>
);

IconLoyalty.propTypes = {
  className: string,
  hidden: bool,
};

IconLoyalty.defaultProps = {
  className: null,
};

module.exports = IconLoyalty;
