/* eslint-disable react/prop-types */
const React = require('react');

const { loadable } = require('nordic/lazy');

const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../filter-intervention/filter-intervention.desktop'
  ),
);

const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../carousel/carousel-intervention'
  ),
);

const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shoppingcart" */
    '../../shopping-cart'
  ),
);

const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../compats/compats-intervention'
  ),
);

const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../billboard/billboard-intervention.desktop'
  ),
);

const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../meliplay/stack/meliplay-intervention.desktop'
  ),
);

const VehicleBodySelectorIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-vehicle-body-selector" */
    '../../vehicle-body-selector/vehicle-body-selector-intervention.desktop'
  ),
);

const renderInterventionsDesktop = {
  FILTER_INTERVENTION: ({ item }) => <FilterIntervention intervention={item} />,
  CAROUSEL_INTERVENTION: ({ item }) => <CarouselIntervention intervention={item} />,
  CART_INTERVENTION: ({ item }) => <ShoppingCartIntervention intervention={item} />,
  COMPATS_INTERVENTION: ({ item }) => <CompatsIntervention intervention={item} />,
  BILLBOARD_INTERVENTION: ({ item }) => <BillboardIntervention intervention={item} />,
  MELIPLAY_INTERVENTION: ({ item, position }) => <MeliPlayIntervention intervention={item} position={position} />,
  VEHICLE_BODY_SELECTOR_INTERVENTION: ({ item }) => <VehicleBodySelectorIntervention intervention={item} />,
};

module.exports = {
  renderInterventionsDesktop,
};
