const React = require('react');
const { shape, string } = require('prop-types');

const { Image } = require('nordic/image');
const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--url';

const ImageIconUrl = ({ className, tags }) => (
  <Image
    className={classnames(namespace, className)}
    src={tags.url}
    width={tags.width}
    height={tags.height}
    alt={tags.alt_text}
    preload={tags.preload}
    lazyload="off"
  />
);

ImageIconUrl.propTypes = {
  className: string,
  tags: shape({}),
};
ImageIconUrl.defaultProps = {
  className: null,
  tags: {},
};

module.exports = ImageIconUrl;
