const React = require('react');
const { arrayOf, shape, string, number } = require('prop-types');

const classnames = require('classnames');
const ChevronRight20 = require('@andes/icons/ChevronRight20');

const useFilterRange = require('../../../hooks/use-filter-range');
const FilterRangesInput = require('./components/range-filter-item');
const { TYPE_RANGE } = require('../../../constants');

const namespace = 'ui-search-price-filter';

const FilterRange = ({ labels, values, className, url, activeCurrencyId, type, id, fragment }) => {
  const isDropdown = type === TYPE_RANGE.DROPDOWN;
  const onlyNumbersPattern = '^[0-9]+([,.][0-9]+)?$';

  const { handleChangeDropdown, handleInputChange, handleOnSubmit, labelsData, disabled, setDropdownItemSelected } =
    useFilterRange({
      filterId: id,
      labels,
      url,
      activeCurrencyId,
      type,
      fragment,
    });

  const renderFilterButton = () => (
    <button
      data-testid={`submit-${id}`}
      type="submit"
      className={`${namespace}-action-btn`}
      disabled={disabled}
      aria-label={labels.apply}
    >
      <ChevronRight20 />
    </button>
  );

  return (
    <form onSubmit={handleOnSubmit} className={classnames(namespace, className, 'shops__price-filter')} method="post">
      {labelsData.map((value) => (
        <FilterRangesInput
          className={classnames(`${namespace}-container`, 'shops__price-filter-container')}
          id={id}
          type={type}
          handleInputChange={handleInputChange}
          handleChangeDropdown={handleChangeDropdown}
          label={value.text}
          name={value.label}
          setDropdownItemSelected={setDropdownItemSelected}
          values={isDropdown ? values : null}
          pattern={onlyNumbersPattern}
        />
      ))}
      <div className={`${namespace}-container`}>{renderFilterButton()}</div>
    </form>
  );
};

FilterRange.propTypes = {
  activeCurrencyId: string,
  className: string.isRequired,
  fragment: string,
  id: string,
  labels: arrayOf(
    shape({
      label: string.isRequired,
      type: string.isRequired,
    }),
  ),
  type: string,
  url: string,
  values: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      results: number,
      url: string.isRequired,
    }),
  ),
};

FilterRange.defaultProps = {
  labels: [],
  url: '',
  values: [],
  activeCurrencyId: null,
};

module.exports = FilterRange;
