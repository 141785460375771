const { useContext } = require('react');

const SearchContext = require('../../components/context/search');

const useSearch = () => {
  const context = useContext(SearchContext) || {};

  return context;
};

module.exports = useSearch;
