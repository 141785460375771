const React = require('react');
const { useState, useContext } = require('react');
const { arrayOf, string, shape } = require('prop-types');

const cx = require('classnames');
const Switch = require('@andes/switch');

const useFilterSearch = require('../../../../hooks/use-filter-search');
const HighlightedFilterVis = require('../highlighted-filter-vis/highlighted-fitler-vis');
const FilterTooltip = require('../filters-tooltip/filters-tooltip.mobile');
const IconQuestion = require('../../../icons/question');
const HighlightedFilterContent = require('./highlighted-filter-content');
const { FILTER_EXTENDED_PROPS } = require('./constants');
const StaticPropsContext = require('../../../context/static-props');
const { buildNewHref } = require('../../../link/link-utils');
const useMapConfig = require('../../../map/hooks/use-map-config');
const { LAYOUTS } = require('../../../../constants');
const { trackClickEvent } = require('../../../map/track');
const { useSearch } = require('../../../../hooks/context');
const LayoutContext = require('../../../context/layout');

const namespace = 'ui-search-filter-highlighted';

const HighlightedFilter = ({ filterId, highlight, label, subtitle, switch: { is_on: isOn }, title, tooltip, url }) => {
  const { queryParams } = useContext(StaticPropsContext);
  const { currentLayout } = React.useContext(LayoutContext);
  const filterSearch = useFilterSearch(url, filterId);
  const isClientSideFilter = !!filterSearch;
  const [isChecked, setChecked] = useState(isOn);
  const { layout_options } = useSearch();
  const { searchTrack } = useMapConfig();
  const track = {
    melidata: {
      melidata_track: {
        ...searchTrack,
        type: 'view',
      },
    },
  };

  const handleClick = (e) => {
    if (e.type !== 'change') {
      e.preventDefault();
      setChecked(!isOn);
    }

    if (isClientSideFilter) {
      filterSearch();

      if (layout_options?.current === LAYOUTS.TYPE_MAP) {
        trackClickEvent(null, track.melidata);
      }

      return;
    }

    window.location.href = buildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form
      action={url}
      onSubmit={handleClick}
      className={cx(`${namespace}__container`, 'shops__filter-highlighted')}
      onKeyPress={handleClick}
    >
      <button
        name="button"
        type="submit"
        className={cx(`${namespace}__content ${namespace}__content-button`, 'shops__highlighted-button-tertiary')}
      >
        <HighlightedFilterContent
          namespace={namespace}
          highlight={highlight}
          label={label}
          subtitle={subtitle}
          title={title}
          htmlForIDValue={filterId}
        />
      </button>

      {tooltip && (
        <div className={`${namespace}__tooltip`}>
          <FilterTooltip tooltip={tooltip} side="right">
            <div className={`${namespace}__tooltip-trigger`} />
            <IconQuestion />
          </FilterTooltip>
        </div>
      )}

      <div className={cx(`${namespace}__switch-container`, 'shops__highlighted-switch')}>
        <Switch
          id={filterId}
          className={`${namespace}__switch-icon`}
          label=""
          checked={isChecked}
          autoComplete="off"
          onChange={handleClick}
        />
      </div>
    </form>
  );
};

const HighlightedFilterContainer = ({ className, expanded_values, id }) => (
  <li className={cx(className, namespace, `${namespace}-${id}`, 'shops__highlighted-lists')}>
    {expanded_values.map((value) => {
      if (id === 'approved_loan_highlighted') {
        return <HighlightedFilterVis {...value} filterId={id} key={value.id} />;
      }

      return <HighlightedFilter {...value} filterId={id} key={value.id} />;
    })}
  </li>
);

HighlightedFilter.propTypes = {
  ...FILTER_EXTENDED_PROPS,
  filterId: string.isRequired,
};

HighlightedFilter.defaultProps = {
  highlight: null,
  label: null,
  subtitle: null,
  switch: {},
  title: null,
  tooltip: null,
  url: '',
};

HighlightedFilterContainer.propTypes = {
  className: string,
  expanded_values: arrayOf(shape(FILTER_EXTENDED_PROPS)),
  id: string,
  name: string,
};

HighlightedFilter.propTypes = {
  highlight: shape({}),
  label: string,
  subtitle: shape({}),
  switch: shape({}),
  title: shape({}),
  url: string,
};

HighlightedFilterContainer.defaultProps = {
  className: '',
  expanded_values: [],
  id: '',
  name: '',
};

module.exports = HighlightedFilterContainer;
