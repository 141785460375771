const React = require('react');
const { useState } = require('react');
const { node } = require('prop-types');

const get = require('lodash/get');

const SearchContext = React.createContext(null);
const { Provider, Consumer: SearchConsumer } = SearchContext;
const SearchProvider = ({ children, ...rest }) => {
  const repeatProductObjet = {};

  function getItemById(itemId) {
    const resultItem = rest.results.find((it) => {
      if (it.id === 'BILLBOARD_INTERVENTION') {
        return it.contents;
      }
      return it.id === itemId;
    });

    if (!resultItem && rest.billboard && rest.billboard.items) {
      return rest.billboard.items.find((it) => it.id === itemId);
    }

    return resultItem;
  }

  const getRepeatProductObjet = (results = []) => {
    const repeatProducts = results.filter((obj, index) => results.findIndex((it) => it.id === obj.id) !== index);

    // eslint-disable-next-line array-callback-return
    repeatProducts.map((it) => {
      if (it.cpg) {
        repeatProductObjet[it.id] = {
          quantity: it.cpg.quantity,
          label_count: it.cpg.label_count,
          max_stock: it.available_quantity,
        };
      }
    });

    return repeatProductObjet;
  };

  const [sameItemList, setSameItemList] = useState(getRepeatProductObjet(rest.results));

  const findSameQuantity = (quantity) => (finding) => finding.quantity === quantity;

  const state = {
    ...rest,
    sameItemList,
    setSameItemList,
    toggleBookmark: (itemId) => {
      const item = getItemById(itemId);

      let bookmarked = get(item, 'bookmarked');

      if (Array.isArray(item.contents)) {
        item.contents.map((itemValue) => {
          if (itemValue.item_id === itemId) {
            bookmarked = get(itemValue, 'bookmarked');
            itemValue.bookmarked = !bookmarked;
          }

          return !bookmarked;
        });
      }

      item.bookmarked = !bookmarked;

      return !bookmarked;
    },
    setQuantityCart: (itemId, quantity) => {
      const item = getItemById(itemId);

      item.cpg.quantity = quantity;
    },
    setLabelCount: (itemId, label) => {
      const item = getItemById(itemId);

      item.cpg.label_count.text = label.text;
      item.cpg.label_count.values = label.values;
    },
    setLabelCountValues: (itemId, values) => {
      const item = getItemById(itemId);

      item.cpg.label_count.values = values;
    },
    getQuantityCart: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg.quantity;
    },
    setQuantityCartRequested: (itemId, quantity) => {
      const item = getItemById(itemId);

      item.cpg.quantityRequested = quantity;
    },
    getQuantityCartRequested: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg.quantityRequested;
    },
    setQuantityCartPrevious: (itemId, quantity) => {
      const item = getItemById(itemId);

      item.cpg.quantityPrevious = quantity;
    },
    getQuantityCartPrevious: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg.quantityPrevious;
    },
    setPreloadedLabelCount: (itemId, preloaded) => {
      const item = getItemById(itemId);

      if (!item.cpg.preloaded_label_counts) {
        item.cpg.preloaded_label_counts = [];
      }

      preloaded.forEach((element) => {
        const position = item.cpg.preloaded_label_counts.findIndex(findSameQuantity(element.quantity));

        if (position !== -1) {
          item.cpg.preloaded_label_counts.splice(position, 1);
        }

        item.cpg.preloaded_label_counts.push(element);
      });
    },
    getPreloadedLabelCount: (itemId, quantity) => {
      const item = getItemById(itemId);

      return item?.cpg.preloaded_label_counts?.find(findSameQuantity(quantity));
    },
    getLoadedSuggestions: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg.alreadyHasSuggestions;
    },
    setLoadedSuggestions: (itemId, value) => {
      const item = getItemById(itemId);

      item.cpg.alreadyHasSuggestions = value;
    },
    getCPGInfo: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg;
    },
    getSuggestionsAlreadyOpened: (itemId) => {
      const item = getItemById(itemId);

      return item.cpg.suggestionsAlreadyOpened;
    },
    setSuggestionsAlreadyOpened: (itemId, value) => {
      const item = getItemById(itemId);

      item.cpg.suggestionsAlreadyOpened = value;
    },
  };

  return <Provider value={state}>{children}</Provider>;
};

SearchProvider.propTypes = {
  children: node.isRequired,
};

module.exports = SearchContext;
module.exports.SearchProvider = SearchProvider;
module.exports.SearchConsumer = SearchConsumer;
