const React = require('react');
const { useRef, useCallback, useContext, useEffect, useMemo, useState } = require('react');
const { arrayOf, element, func, oneOfType, string, shape } = require('prop-types');

const classnames = require('classnames');

const StaticPropsContext = require('../../context/static-props');
const LayoutFadeContext = require('../../context/layout-fade');
const { VERTICAL_TYPE_CPG } = require('../renderer/constants');
const { isItemIntervention } = require('../renderer/utils');
const { renderItemsIterventionsDesktop } = require('../../intervention/utils/render-item-interventions');

const namespace = 'ui-search-layout';
const cpgBaseValue = 209; // Base value needed for recomendations on cpg cards

const renderItem = ({ item, idx, layout, options, handleMount, LayoutItem }) => (
  <li key={item.id} className={`${namespace}__item`}>
    <LayoutItem layout={layout} item={item} index={idx} options={options} onMount={handleMount} />
  </li>
);

const GridRows = ({ itemComponent: LayoutItem, itemGroup, layout }) => {
  const { lowEnd, vertical } = useContext(StaticPropsContext);
  const { isFade } = useContext(LayoutFadeContext);
  const [maxImageHeight, setMaxImageHeight] = useState(0);
  const [maxContentHeight, setMaxContentHeight] = useState(0);
  const listRef = useRef(null);
  const options = useMemo(() => ({ lowEnd, verboseLabels: false, preload: false }), [lowEnd]);

  const safeSetMaxContentHeight = useCallback(
    (newValue) => {
      const baseCpgVal = newValue < cpgBaseValue ? cpgBaseValue : newValue;

      switch (vertical) {
        case VERTICAL_TYPE_CPG:
          return setMaxContentHeight((currentBaseHeight) =>
            baseCpgVal > currentBaseHeight ? baseCpgVal : currentBaseHeight,
          );

        default:
          return setMaxContentHeight((currentBaseHeight) =>
            newValue > currentBaseHeight ? newValue : currentBaseHeight,
          );
      }
    },
    [vertical],
  );

  const handleMount = useCallback(
    ({ imageHeight, contentHeight }) => {
      setMaxImageHeight((currentBaseHeight) => (imageHeight > currentBaseHeight ? imageHeight : currentBaseHeight));
      safeSetMaxContentHeight(contentHeight);
    },
    [safeSetMaxContentHeight],
  );

  useEffect(() => {
    listRef.current.style.setProperty('--min-image-height', `${maxImageHeight}px`);
    listRef.current.style.setProperty('--min-content-height', `${maxContentHeight}px`);
  }, [maxImageHeight, maxContentHeight]);

  return (
    <ol
      className={classnames(namespace, { [`${namespace}--${layout}`]: layout }, { [`${namespace}--fade`]: isFade })}
      ref={listRef}
    >
      {itemGroup.map((item, idx) =>
        isItemIntervention(item)
          ? renderItemsIterventionsDesktop[item.id](item, idx)
          : renderItem({ item, idx, layout, options, handleMount, LayoutItem }),
      )}
    </ol>
  );
};

GridRows.propTypes = {
  itemComponent: oneOfType([element, func]).isRequired,
  itemGroup: arrayOf(shape()),
  layout: string.isRequired,
};

GridRows.defaultProps = {
  itemGroup: [],
};

module.exports = GridRows;
