const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-full';

const IconStarEmpty = ({ className }) => (
  <svg className={classnames(namespace, className)} width="100%" height="100%" viewBox="0 0 11 10">
    <use href="#start-empty" />
  </svg>
);

IconStarEmpty.propTypes = {
  className: string,
};

IconStarEmpty.defaultProps = {
  className: null,
};

module.exports = React.memo(IconStarEmpty);
