/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { string } = require('prop-types');
const { useEffect } = require('react');

const { ErrorScreen } = require('@andes/technical-error');
const Style = require('nordic/style');

const { getMessagesErrorBySiteId } = require('../../lib/get-message-error');
const Icon = require('../icons/error-screen');

const ErrorComponent = ({ siteId }) => {
  const { title, description } = getMessagesErrorBySiteId(siteId);

  useEffect(() => {
    document.title = title;
  }, []);

  return (
    <>
      <Style href="search.error.css" />
      <div className="ui-search ui-search-error">
        <ErrorScreen asset={<Icon />} className="ui-search-error__screen" description={description} title={title} />
      </div>
    </>
  );
};

ErrorComponent.propTypes = {
  siteId: string.isRequired,
};

module.exports = ErrorComponent;
