/* eslint-disable react-hooks/exhaustive-deps */
const { useCallback, useEffect, useMemo, useState } = require('react');

const debounce = require('lodash/debounce');

const useAutocompleteUrl = require('../../../../../hooks/use-autocomplete');

const AUTOCOMPLETE_DEBOUNCE_MS = 300;

const useAutocompleteSearch = (name, selectedOption) => {
  const defaultOptions = useMemo(() => (selectedOption ? [{ id: selectedOption, name }] : []), [selectedOption, name]);

  const [inputValue, setInputValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [options, setOptions] = useState(defaultOptions);
  const [isLoading, doAutocomplete] = useAutocompleteUrl();

  const updateSearch = useCallback(
    debounce((value) => setSearchValue(value), AUTOCOMPLETE_DEBOUNCE_MS),
    [setSearchValue],
  );

  useEffect(() => {
    updateSearch(inputValue);
  }, [inputValue, updateSearch]);

  useEffect(() => {
    if (searchValue) {
      doAutocomplete(searchValue)
        .then((response) => {
          const nextOptions = response.length > 0 ? response : defaultOptions;

          setOptions(nextOptions);
        })
        .catch((err) => {
          throw new Error(err);
        });

      return;
    }

    setOptions(defaultOptions);
  }, [searchValue, defaultOptions, doAutocomplete]);

  return {
    isLoading,
    options,
    search: inputValue,
    setSearch: setInputValue,
  };
};

module.exports = useAutocompleteSearch;
