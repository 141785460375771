const React = require('react');

const { LazyHydrate: Hydrator } = require('nordic/lazy');
const classnames = require('classnames');

const { DEVICE_TYPES } = require('../../../../constants');
const Suggestions = require('../../../suggestions/suggestions.mobile');
const { isIntervention } = require('.');
const { renderInterventionsMobile } = require('../../../intervention/utils/render-interventions/interventions.mobile');
const {
  renderInterventionsDesktop,
} = require('../../../intervention/utils/render-interventions/interventions.desktop');

const { MOBILE } = DEVICE_TYPES;

const renderOneColumnComponents = (item, index, { LayoutItem, deviceType, options, namespace, layout }) => {
  const renderResults = (renderItem, renderIndex) => {
    if (isIntervention(item)) {
      return <Hydrator whenVisible>{renderInterventionsDesktop[item]({ item })}</Hydrator>;
    }

    return (
      <li key={renderItem.id} className={classnames(`${namespace}__item`, 'shops__layout-item')}>
        <LayoutItem layout={layout} item={renderItem} index={renderIndex} options={options} />
      </li>
    );
  };

  const renderMobileComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      return (
        <Hydrator whenVisible>
          {renderInterventionsMobile[internalItem.id]({ item: internalItem, position: internalIndex })}
        </Hydrator>
      );
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  const renderDesktopComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      if (internalItem.id === 'CAROUSEL_INTERVENTION' && internalIndex !== 0) {
        internalItem.content.classname = 'mt-16';
      }

      return <Hydrator whenVisible>{renderInterventionsDesktop[internalItem.id]({ item: internalItem })}</Hydrator>;
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  return deviceType === MOBILE ? renderMobileComponents(item, index) : renderDesktopComponents(item, index);
};

const renderOneColumnStackComponents = (
  item,
  index,
  { LayoutItem, currentItemSuggestion, results, layout, namespace, options, openSuggestions },
) => {
  const renderSuggestions = (element) => {
    if (!currentItemSuggestion) {
      return null;
    }

    if (currentItemSuggestion === results[`${element}`].id) {
      return <Suggestions />;
    }

    return null;
  };

  const renderResults = (itemResults, indexResult) => {
    itemResults.verboseLabels = true;
    itemResults.resultIndex = indexResult;

    return (
      <>
        <li
          key={itemResults.id + itemResults.bookmarked}
          className={classnames(`${namespace}__item`, 'shops__row-item')}
        >
          <LayoutItem item={itemResults} layout={layout} options={options} i={indexResult} />
        </li>
        {openSuggestions && renderSuggestions(indexResult)}
      </>
    );
  };

  const renderComponents = (internalItem, internalIndex) => {
    if (isIntervention(internalItem)) {
      return (
        <Hydrator whenVisible>
          {renderInterventionsMobile[internalItem.id]({ item: internalItem, position: internalIndex })}
        </Hydrator>
      );
    }

    return internalItem && renderResults(internalItem, internalIndex);
  };

  return renderComponents(item, index);
};

module.exports = {
  renderOneColumnComponents,
  renderOneColumnStackComponents,
};
