const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-filter-colors';

const FiltersColors = ({ id, className }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)} />
);

FiltersColors.propTypes = {
  className: string,
  id: string,
};

FiltersColors.defaultProps = {
  id: '',
  className: '',
};

module.exports = FiltersColors;
