const React = require('react');
const { string, bool } = require('prop-types');

const Head = require('nordic/head');
const isEmpty = require('lodash/isEmpty');

const Metadata = ({ canonical, description, title, noIndex, should_add_none_to_response }) => (
  <Head>
    {!isEmpty(title) && <title>{title}</title>}
    {!isEmpty(description) && <meta name="description" content={description} />}
    {noIndex && <meta name="robots" content="noindex" />}
    {!isEmpty(canonical) && <link rel="canonical" href={canonical} />}
    {should_add_none_to_response && <meta name="robots" content="noindex, nofollow" />}
    <link rel="alternate" href={`android-app://com.mercadolibre/meli/search?go=${canonical}`} />
    <link rel="alternate" href={`ios-app://463624852/meli/search?go=${canonical}`} />
  </Head>
);

Metadata.propTypes = {
  canonical: string.isRequired,
  description: string.isRequired,
  noIndex: bool,
  should_add_none_to_response: bool,
  title: string.isRequired,
};

Metadata.defaultProps = {
  noIndex: false,
  should_add_none_to_response: false,
};

module.exports = Metadata;
