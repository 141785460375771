const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--cart-volume-discount';

const IconCartVolumeDiscount = ({ className, color }) => (
  <svg
    className={classnames(namespace, className)}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <use href="#cart-volume-discount" />
  </svg>
);

IconCartVolumeDiscount.propTypes = {
  className: string,
  color: string,
};

IconCartVolumeDiscount.defaultProps = {
  className: null,
  color: 'black',
};

module.exports = IconCartVolumeDiscount;
