const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const Link = require('../../link');

const namespace = 'ui-search-item__pds-options';

const PdsOptions = ({ className, quantity_text, pds_permalink }) => (
  <div className={classnames(namespace, className)}>
    <Link href={pds_permalink} tabIndex="-1">
      {quantity_text}
    </Link>
  </div>
);

PdsOptions.propTypes = {
  className: string,
  pds_permalink: string.isRequired,
  quantity_text: string.isRequired,
};

PdsOptions.defaultProps = {
  className: null,
};

module.exports = PdsOptions;
