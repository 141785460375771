const React = require('react');
const PropTypes = require('prop-types');
const { useState } = require('react');

const classnames = require('classnames');

const CollapsibleCard = require('../collapsible-card/collapsible-card');

const namespace = 'ui-search-collapsible-card-list';

const CollapsibleCardList = ({ className, items }) => {
  const [expandedElementIndex, setExpandedElementIndex] = useState(null);
  const toggleExpanded = (index) =>
    index === expandedElementIndex ? setExpandedElementIndex(null) : setExpandedElementIndex(index);

  const getKey = (index) => `${namespace}--item--${index}`;

  return (
    <div className={classnames(namespace, className)}>
      {items.map((item, index) => (
        <CollapsibleCard
          key={getKey(index)}
          title={item?.title}
          content={item?.content}
          isExpanded={index === expandedElementIndex}
          onClick={() => toggleExpanded(index)}
        />
      ))}
    </div>
  );
};

module.exports = CollapsibleCardList;

CollapsibleCardList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CollapsibleCardList.defaultProps = {
  className: '',
};
