/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const React = require('react');
const { useState, useEffect } = require('react');
const { string, func, shape } = require('prop-types');

const Dropdown = require('@andes/dropdown');
const { ListItem } = require('@andes/list');
const Image = require('nordic/image');
const get = require('lodash/get');
const classnames = require('classnames');

const Link = require('../../link');
const { GRID } = require('../../layout/renderer/constants');

const namespace = 'ui-search-variations-picker';

const getThumbnailsDisplayConfig = (thumbnailsToShow, layout, variationsTotal, windowWidth) => {
  const showAllThumbnailsConfig = { variationsQuantity: variationsTotal };

  const layoutConfig = get(thumbnailsToShow, layout);

  if (!layoutConfig) {
    return showAllThumbnailsConfig;
  }

  const layoutLimitResolution = layoutConfig.limit_resolution;

  if (!layoutLimitResolution || windowWidth < layoutLimitResolution) {
    return {
      variationsQuantity: layoutConfig.max_thumbnails,
      showMoreText: layoutConfig.show_more_text,
    };
  }

  const defaultConfig = get(thumbnailsToShow, 'default');

  if (defaultConfig) {
    return {
      variationsQuantity: defaultConfig.max_thumbnails,
      showMoreText: defaultConfig.show_more_text,
    };
  }

  return showAllThumbnailsConfig;
};

const VariationsPicker = ({
  layout,
  itemContext: { item, variations, handleSelectVariation, selectedVariation },
  label,
  thumbnails_to_show,
  normalizePictureData,
  renderOptions,
}) => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    const resizeHandler = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const { variationsQuantity, showMoreText } = getThumbnailsDisplayConfig(
    thumbnails_to_show,
    layout,
    variations.length,
    windowWidth,
  );
  const variationsToShow = variations.filter((variation, index) => index < variationsQuantity);

  const isViewMoreOption = (index) => showMoreText && index === variationsQuantity - 1;

  const renderGridVariationOption = (variation, index) => {
    const variationImage = (
      <Image
        className={`${namespace}__variation-image`}
        {...normalizePictureData(variation.thumbnail, get(variation, 'thumbnail.tags.alt'), renderOptions)}
        alt={get(variation, 'thumbnail.tags.alt')}
      />
    );

    if (!isViewMoreOption(index)) {
      return (
        <li
          onClick={() => handleSelectVariation(variation.id)}
          className={classnames(`${namespace}__variation`, {
            [`${namespace}__variation--selected`]: variation.id === selectedVariation.id,
          })}
        >
          {variationImage}
        </li>
      );
    }

    return (
      <li className={`${namespace}__variation`} style={{ opacity: 1 }}>
        {variationImage}
        <Link className={`${namespace}__view-more`} href={item.permalink} keepLayout={false}>
          {showMoreText}
        </Link>
      </li>
    );
  };

  const renderStackVariationOption = (variation, index) => {
    const optionProps = {
      value: variation.id,
      primary: variation.name,
      selected: variation.id === selectedVariation.id,
      leftContent: {
        src: get(variation, 'thumbnail.retina'),
        title: get(variation, 'thumbnail.tags.alt'),
      },
    };

    if (!isViewMoreOption(index)) {
      return <ListItem {...optionProps} />;
    }

    return (
      <ListItem {...optionProps} style={{ opacity: 1 }}>
        <Link
          className={`${namespace}__view-more`}
          href={item.permalink}
          onClick={(e) => e.stopPropagation()}
          keepLayout={false}
        >
          {showMoreText}
        </Link>
      </ListItem>
    );
  };

  if (layout === GRID) {
    return (
      <div className={`${namespace} ${namespace}--grid`}>
        <ul className={`${namespace}__variations-list`}>
          {variationsToShow.map((variation, index) => renderGridVariationOption(variation, index))}
        </ul>
      </div>
    );
  }

  return (
    <div className={`${namespace} ${namespace}--stack`}>
      <p className={`${namespace}__label`}>{`${label.text}:`}</p>
      <Dropdown
        label={selectedVariation.name}
        primary={selectedVariation.name}
        value={selectedVariation.id}
        onChange={(e, variationId) => handleSelectVariation(variationId)}
        size="small"
        menuAlignment="bottom"
        width={[100]}
        closable={false}
      >
        {variationsToShow.map((variation, index) => renderStackVariationOption(variation, index))}
      </Dropdown>
    </div>
  );
};

VariationsPicker.propTypes = {
  adaptPictures: func.isRequired,
  className: string,
  handleSelectVariation: func.isRequired,
  itemContext: shape({}).isRequired,
  label: shape({}).isRequired,
  layout: string.isRequired,
  normalizePictureData: shape({}).isRequired,
  renderOptions: shape({}).isRequired,
  selectedVariation: shape().isRequired,
  thumbnails_to_show: string,
};

VariationsPicker.defaultProps = {
  className: null,
};

module.exports = VariationsPicker;
