const { useContext } = require('react');

const FullFreeshippingContext = require('../context/full-freeshipping');

const useFullFreeshipping = () => {
  const { currentFullFreeshipping, setFullFreeshipping } = useContext(FullFreeshippingContext);

  return { currentFullFreeshipping, setFullFreeshipping };
};

module.exports = useFullFreeshipping;
