const { ProfileHeader } = require('eshops-components-library').default;

const FreeShippingBar = require('../../full-snackbar/eshops-freeshipping.desktop');
const sources = require('./component-types');

const Resources = {};

Resources[sources.PROFILE_HEADER] = ProfileHeader;
Resources[sources.FREE_SHIPPING_BAR] = FreeShippingBar;

module.exports = Resources;
