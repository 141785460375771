const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--supermarket-logo';

const DEFAULT_COLOR = '#c70f5a';
const DEFAULT_TEXT = 'supermercado';

const IconSupermarketLogo = ({ className, color }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 213 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color || DEFAULT_COLOR}
  >
    <title>{DEFAULT_TEXT}</title>
    <use href="#supermarket-logo" />
  </svg>
);

IconSupermarketLogo.propTypes = {
  className: string,
  color: string,
};

IconSupermarketLogo.defaultProps = {
  className: '',
  color: '',
};

module.exports = React.memo(IconSupermarketLogo);
