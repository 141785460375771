const React = require('react');

const Card = require('@andes/card');
const classnames = require('classnames');

const { namespace } = require('../../layout/renderer/constants');

const CONTENT_SPACINGS = [1, 2, 2, 3, 3];

const createKey = (index) => `loading-content--${index}`;

const LoadingCard = () => (
  <Card animated={false}>
    <div className={`${namespace}__wrapper`}>
      <div
        className={classnames(
          `${namespace}__main-image-link`,
          `${namespace}__main-image-internal`,
          `${namespace}__loader`,
        )}
      />
      <div className={`${namespace}__content-link`}>
        {CONTENT_SPACINGS.map((spacing, index) => (
          <p
            className={classnames(
              `${namespace}__content-separator--8`,
              `${namespace}__loader`,
              `${namespace}__loader-content-${spacing}`,
              `${namespace}__loader-content-height`,
            )}
            key={createKey(index)}
          />
        ))}
      </div>
    </div>
  </Card>
);

module.exports = LoadingCard;
