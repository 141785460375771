/* eslint-disable react-hooks/exhaustive-deps */

const { useState, useEffect, useRef } = require('react');

const get = require('lodash/get');
const { isCancel, CancelToken } = require('nordic/restclient/cancel');

const ApiService = require('../../services/maps');
const { updateURL } = require('../lib/analytics');
const { LAYOUTS } = require('../constants');

const toPath = (history, { pathname, search }) => history.createHref({ pathname, search });

const updateAnalyticsConfig = (path) => {
  const { location: { protocol, host } = {} } = window || {};
  const url = protocol && host ? `${protocol}//${host}${path}` : path;

  updateURL(url);
};
const isSameLocation = (actualLocation, nextLocation) =>
  actualLocation.pathname === nextLocation.pathname && actualLocation.search === nextLocation.search;

const cleanPageFromHash = (hash = '') => {
  const rawNumber = parseInt(hash.replace('#', ''), 10);

  return Math.abs(rawNumber);
};

const useLocationSearch = ({ history, serverState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [initialState, setInitialState] = useState(serverState);
  const cancelToken = useRef(null);
  const currentLayout = get(initialState, 'layout_options.current');
  const [currentHash, setCurrentHash] = useState(history.location.hash);
  const lastLocation = useRef(history.location);

  const handleServerResponse = ({ data, redirect }) => {
    if (redirect) {
      window.location.href = redirect;

      return { showLoading: true };
    }

    setInitialState(data);

    return { showLoading: false };
  };

  const handleRequestError = (requestError) => {
    const wasCancelled = isCancel(requestError);

    if (!wasCancelled) {
      setError(requestError);
    }

    return { showLoading: wasCancelled };
  };

  const cancelLocationSearch = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
      cancelToken.current = null;
    }
  };

  const cleanPreviousState = () => {
    cancelLocationSearch();
    setError(null);
    setIsLoading(true);
  };

  const getRequestOptions = () => {
    cancelToken.current = CancelToken.source();

    return { cancelToken: cancelToken.current.token };
  };

  const updateHash = (hash) => (loadingState) => {
    setCurrentHash(hash);

    return loadingState;
  };

  const doLocationSearch = (location = {}) => {
    cleanPreviousState();

    if (isSameLocation(lastLocation.current, location)) {
      setCurrentHash(location.hash);
      setIsLoading(false);

      return Promise.resolve(null);
    }

    lastLocation.current = location;
    updateAnalyticsConfig(toPath(history, location));

    return ApiService.getSearchData(location, getRequestOptions())
      .then(handleServerResponse)
      .then(updateHash(location.hash))
      .catch(handleRequestError)
      .then(({ showLoading = false } = {}) => setIsLoading(showLoading));
  };

  useEffect(() => {
    if (currentLayout === LAYOUTS.TYPE_MAP) {
      return history.listen(doLocationSearch);
    }

    return () => {};
  }, [currentLayout]);

  return {
    error,
    isLoading,
    initialState,
    doLocationSearch,
    currentHash,
    currentHashPage: cleanPageFromHash(currentHash),
  };
};

module.exports = useLocationSearch;
