const VERTICAL_TYPE_SRV = 'SRV';
const VERTICAL_TYPE_CORE = 'CORE';
const VERTICAL_TYPE_MOT = 'MOT';
const VERTICAL_TYPE_RES = 'RES';
const VERTICAL_TYPE_CPG = 'CPG';

const MOSAIC = 'mosaic';
const GALLERY = 'gallery';
const STACK = 'stack';
const GRID = 'grid';

const LAZY_ON = 'on';
const LAZY_OFF = 'off';

const DEFAULT_DPI = 'retina';

const configPreload = { mosaic: 1, stack: 1, gallery: 1, grid: 2 };

const CARD_DATA_GROUPS = {
  PRICE: 'price',
  BRAND_DISCOVERABILITY: 'brand',
  INSTALLMENTS: 'installments',
  SHIPPING: 'shipping',
  TITLE: 'title',
  REVIEWS: 'reviews',
  LOCATION: 'location',
  ATTRIBUTES: 'attributes',
  DETAILS: 'details',
  PDS: 'pds',
  POSSESSION: 'possession',
  PRODUCT_ATTRIBUTES: 'product-attributes',
  VARIATIONS_TEXT: 'variations-text',
  ADD_TO_CART: 'add-to-cart',
  MEDIA_TAG: 'media-tag',
  HIGHLIGHT: 'highlight',
  REBATES: 'rebates',
  VERTICAL_HIGHLIGHT: 'vertical-highlight',
  VARIATIONS_PICKER: 'variations-picker-color',
  AVAILABLE_UNITS: 'available-units',
};

const CAROUSEL_SETTINGS = {
  dots: false,
  arrowsVisible: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
};

const GENERIC_KEY_PREFIXES = {
  TOP_COLUMN: 'TOP',
  BOTTOM_COLUMN: 'BOTTOM',
  LEFT_COLUMN: 'LEFT',
  RIGHT_COLUMN: 'RIGHT',
  MODAL_COMPONENTS: 'MODAL_COMPONENTS',
};

const ESTIMATED_CONTENT_WIDTHS = {
  mobile: {
    stack: 180,
    mosaic: 138,
    gallery: 296,
  },
  desktop: {
    stack: 630,
    gallery: 200,
  },
  DEFAULT: 250,
};

const CONTENT_KEYS = {
  PLATFORM: {
    SHOPS: 'SHOPS',
    DEFAULT: 'DEFAULT',
  },
};

const namespace = 'ui-search-result';

module.exports = {
  configPreload,
  namespace,
  CARD_DATA_GROUPS,
  CAROUSEL_SETTINGS,
  DEFAULT_DPI,
  VERTICAL_TYPE_SRV,
  VERTICAL_TYPE_CORE,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
  VERTICAL_TYPE_CPG,
  LAZY_ON,
  LAZY_OFF,
  MOSAIC,
  GALLERY,
  STACK,
  GRID,
  GENERIC_KEY_PREFIXES,
  ESTIMATED_CONTENT_WIDTHS,
  CONTENT_KEYS,
};
