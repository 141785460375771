const React = require('react');
const { string, node, shape } = require('prop-types');

const Tooltip = require('@andes/tooltip');

const Link = require('../../link');

const namespace = 'ui-search-alert-edition-tooltip';

const AlertEditionTooltip = ({ className, label, action, trigger }) => {
  const content = action ? (
    <div className={`${namespace}__content`}>
      <div className={`${namespace}__text`}>{label.text}</div>
      <Link className={`${namespace}__action-link`} href={action.target} tabIndex="-1" isInternal={false}>
        {action.label.text}
      </Link>
    </div>
  ) : (
    label.text
  );

  return (
    <Tooltip
      className={`${namespace} ${className}`}
      side="bottom"
      offsetX={2}
      offsetY={5}
      content={content}
      mask
      maskClosable={false}
      closable
    >
      {trigger}
    </Tooltip>
  );
};

AlertEditionTooltip.propTypes = {
  action: shape({
    label: shape({
      text: string,
    }),
    target: string,
  }),
  className: string,
  label: shape({
    text: string.isRequired,
  }).isRequired,
  trigger: node.isRequired,
};

AlertEditionTooltip.defaultProps = {
  action: null,
  className: null,
};

module.exports = AlertEditionTooltip;
