/* eslint-disable react-hooks/exhaustive-deps */

const React = require('react');
const { useContext, useEffect } = require('react');
const { arrayOf, elementType, shape, string } = require('prop-types');

const cx = require('classnames');
const get = require('lodash/get');

const { markWithCookie } = require('../../../lib/cookies');
const { useStaticProps } = require('../../context/static-props');
const LayoutFadeContext = require('../../context/layout-fade');
const MapMobile = require('../../map/map-container.mobile');
const MapDesktop = require('../../map/map-container.desktop');

const namespace = 'ui-search-layout';

const COMPONENTS = {
  mobile: MapMobile,
  desktop: MapDesktop,
};

const COOKIE_EXPIRATION_DATE = 60 * 60 * 24 * 7; // 7 days

const MapLayout = ({ itemComponent, layout, results }) => {
  const { deviceType, visualId, hotjar } = useStaticProps();
  const { isFade } = useContext(LayoutFadeContext);
  const MapComponent = COMPONENTS[deviceType] || COMPONENTS.desktop;

  useEffect(() => {
    const cookies = get(hotjar, 'extraData.clientSideConfig.cookies', []);

    cookies.forEach((cookieName) => {
      markWithCookie(cookieName, COOKIE_EXPIRATION_DATE);
    });
  }, []);

  return (
    <MapComponent
      className={cx(
        namespace,
        { [`${namespace}--${layout}`]: layout },
        { [`${namespace}--${visualId}`]: visualId },
        { [`${namespace}--fade`]: isFade },
      )}
      itemComponent={itemComponent}
      results={results}
    />
  );
};

MapLayout.propTypes = {
  itemComponent: elementType.isRequired,
  layout: string.isRequired,
  results: arrayOf(shape()),
};

MapLayout.defaultProps = {
  results: [],
};

module.exports = MapLayout;
