const React = require('react');
const { bool, node, shape, string } = require('prop-types');

const { renderAddToCartComponent } = require('../desktop-card-content/common');
const { renderFreeShippingThMessage } = require('../desktop-card-content/core');

const Wrapper = ({ adLabel, children, className, isCpg, item }) => (
  <div className={className}>
    {children}
    {isCpg && renderFreeShippingThMessage(item)}
    {isCpg && renderAddToCartComponent(item)}
    {isCpg && adLabel}
  </div>
);

Wrapper.propTypes = {
  adLabel: node,
  children: node.isRequired,
  className: string,
  isCpg: bool,
  item: shape(),
};

module.exports = Wrapper;
