const React = require('react');

const { useSearch } = require('../../hooks/context');
const BrandAdsDesktop = require('../brand-ads/brand-ads.desktop');
const ComponentList = require('../../utils/component-list/index');

const availableComponents = {
  BRAND_ADS_INTERVENTION: BrandAdsDesktop,
};

const HeaderComponent = () => {
  const { header_components } = useSearch();

  return <ComponentList availableComponents={availableComponents} components={header_components} />;
};

module.exports = HeaderComponent;
