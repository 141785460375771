const { DEVICE_TYPES } = require('../../constants');

const { MOBILE, DESKTOP } = DEVICE_TYPES;

const getIconCenter = ({ width, height }) => ({ x: width / 2, y: height / 2 });

const ICON_SIZE = {
  [MOBILE]: { height: 28, width: 28 },
  [DESKTOP]: { height: 26, width: 26 },
};

const ICON_CENTER = {
  [MOBILE]: getIconCenter(ICON_SIZE[MOBILE]),
  [DESKTOP]: getIconCenter(ICON_SIZE[DESKTOP]),
};

const CLUSTER_MIN_PADDING = 8;

const MIN_PADDINGS = {
  [MOBILE]: {
    top: ICON_CENTER[MOBILE].y,
    right: ICON_CENTER[MOBILE].x,
    bottom: ICON_CENTER[MOBILE].y,
    left: ICON_CENTER[MOBILE].x,
  },
  [DESKTOP]: {
    top: ICON_CENTER[DESKTOP].y,
    right: ICON_CENTER[DESKTOP].x,
    bottom: ICON_CENTER[DESKTOP].y,
    left: ICON_CENTER[DESKTOP].x,
  },
};

// Paddings to apply when calculating the item_location
const BOUNDING_BOX_PADDINGS = {
  [MOBILE]: MIN_PADDINGS[MOBILE],
  [DESKTOP]: {
    top: ICON_CENTER[DESKTOP].y + CLUSTER_MIN_PADDING,
    right: ICON_CENTER[DESKTOP].x + CLUSTER_MIN_PADDING,
    bottom: ICON_CENTER[DESKTOP].y + CLUSTER_MIN_PADDING,
    left: ICON_CENTER[DESKTOP].x + CLUSTER_MIN_PADDING,
  },
};

const HEADER_SIZE = 72;
const CAROUSEL_SIZE = 180;

// Ensure the selected marker is always visible by applying
// these paddings.
const SAFE_AREA_PADDINGS = {
  [MOBILE]: {
    top: HEADER_SIZE + MIN_PADDINGS[MOBILE].top,
    right: MIN_PADDINGS[MOBILE].right,
    bottom: CAROUSEL_SIZE + MIN_PADDINGS[MOBILE].bottom,
    left: MIN_PADDINGS[MOBILE].left,
  },
  [DESKTOP]: MIN_PADDINGS[DESKTOP],
};

const INITIAL_MAP_STATUS = { bounds: null, zoom: null };

const MAX_CLUSTER_COUNT = 99;
const CLUSTER_LABEL_CLASSNAME = {
  DEFAULT: 'ui-search-map-cluster__label',
  SELECTED: 'ui-search-map-cluster__label--selected',
};

const namespace = 'ui-search-map-list';

module.exports = {
  BOUNDING_BOX_PADDINGS,
  CLUSTER_LABEL_CLASSNAME,
  ICON_CENTER,
  ICON_SIZE,
  INITIAL_MAP_STATUS,
  MAX_CLUSTER_COUNT,
  SAFE_AREA_PADDINGS,
  namespace,
};
