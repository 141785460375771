const serialize = ({ next_page, previous_page, selected_page, pagination_nodes_url, show_pagination, page_count }) => ({
  nextPage: next_page,
  previousPage: previous_page,
  selectedPage: selected_page,
  paginationNodes: pagination_nodes_url,
  showPagination: show_pagination,
  pageCount: Number(page_count),
});

module.exports = {
  serialize,
};
