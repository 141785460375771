const {
  FILTERS: {
    TYPES: { HIDDEN },
  },
} = require('../../../constants');

const isFilterVisible = ({ type }) => type !== HIDDEN;

const getVisibleFilters = (appliedFilters = []) => appliedFilters.filter(isFilterVisible);

module.exports = {
  getVisibleFilters,
};
