const IconFactory = require('../factory-builder');
const {
  IconRecommendationsFull,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
} = require('./icon-generator');

const RecommendationsIconFactory = IconFactory([
  IconRecommendationsFull,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
]);

module.exports = RecommendationsIconFactory;
