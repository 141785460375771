const React = require('react');
const { string } = require('prop-types');

const Button = require('@andes/button');
const { ButtonText } = require('@andes/button');

const IconFilter = require('../../../../icons/toolbar/filter');
const { NAMESPACE } = require('../constants');

const FiltersButton = ({ label, totalLabel }) => (
  <Button hierarchy="transparent" className={`${NAMESPACE}__filters-button`}>
    <IconFilter className={`${NAMESPACE}__filters-button--icon`} />
    <ButtonText>{label}</ButtonText>
    {totalLabel && (
      <ButtonText>
        <span className={`${NAMESPACE}__filters-button--text-highlight`}>{totalLabel}</span>
      </ButtonText>
    )}
  </Button>
);

FiltersButton.propTypes = {
  label: string.isRequired,
  totalLabel: string.isRequired,
};

module.exports = FiltersButton;
