/**
 * Module dependencies
 */

const React = require('react');
const { any, bool, node, objectOf, shape, string } = require('prop-types');
const serialize = require('serialize-javascript');

const classnames = require('classnames');
const Script = require('nordic/script');
const Style = require('nordic/style');
const MeliGA = require('nordic/analytics/meli-ga');
const MelidataTrack = require('nordic/melidata/melidata-track');
const RedirectApp = require('redirect-app-web');
const Hotjar = require('nordic/hotjar');
const { Qualtrics } = require('qualtrics-wrapper');

const { StaticPropsProvider } = require('../../../../../components/context/static-props');
const { UserProvider } = require('../../../../../components/context/user');
const { SearchProvider } = require('../../../../../components/context/search');
const { LayoutProvider } = require('../../../../../components/context/layout');
const { SnackbarProvider } = require('../../../../../components/context/snackbar');
const { SuggestProvider } = require('../../../../../components/context/suggest-cpg');
const { FiltersProvider } = require('../../../../../components/context/filters');
const { EshopsEnvironmentProvider } = require('../../../../../components/context/eshop-environment');
const { FullFreeshippingProvider } = require('../../../../../components/context/full-freeshipping');
const { FreeShippingThProvider } = require('../../../../../components/context/free-shipping-th');
const Metadata = require('../../../../../components/metadata');
const history = require('../../../../../lib/history')();
const GoogleTagManager = require('../../../../../components/google-tag-manager');
const { LayoutFadeProvider } = require('../../../../../components/context/layout-fade/layout-fade');
const Schema = require('../../../../../components/schema/schema');
const useHotjarActionsByCookie = require('../../../../../hooks/use-hotjar-actions-by-cookie');
const useLocationSearch = require('../../../../../hooks/use-location-search');
const ErrorBoundary = require('../../../../../components/error/error-boundary');
const ComponentSVGsriptes = require('../../../../../components/svg-sprites');
const { hasChangeLayout } = require('../../../helpers/layout-options');
const { getEmbeddedData } = require('../../../helpers/getEmbeddedData');
const WrapperEshops = require('../../../../../components/eshops/wrapper-eshop');

const namespace = 'ui-search';

/**
 * Search Component
 */

const Search = ({
  children,
  country,
  currentUser,
  deviceType,
  deviceOs,
  initialState: serverState,
  isLegacyBrowser,
  queryParams,
  billboardState,
  lowEnd,
  site,
  siteId,
  cookies,
  domain,
  visualId,
  platformId,
  groupSite,
  isHoverDisabled,
  hotjar,
  mapApiKey,
  openInApp,
}) => {
  const { error, initialState, isLoading, ...locationSearchProps } = useLocationSearch({
    history,
    serverState,
  });

  const { vertical, shops, storefront: sF } = initialState;
  const hasEshopData = sF?.appearance?.hidden === false && sF?.appearance?.children?.length > 0;
  const staticProps = {
    visualId,
    site,
    siteId,
    shops,
    lowEnd,
    deviceType,
    deviceOs,
    country,
    queryParams,
    billboardState,
    changeLayout: hasChangeLayout(initialState.layout_options),
    vertical,
    // @TODO: remove this with Catalog Highlight
    maxViewsCatalogTooltip: 20,
    maxViewsTooltip: 20,
    isHoverDisabled,
    mapApiKey,
    hotjar,
  };

  const embeddedData = getEmbeddedData(initialState, currentUser);

  useHotjarActionsByCookie(hotjar);

  return (
    <div className={classnames(namespace, 'shops__ui-main')}>
      {openInApp?.shouldRedirect && <RedirectApp {...openInApp} />}
      <Style href={`search.${deviceType}${groupSite}.css`} critical={deviceType === 'mobile'} />
      <Script>
        {`
          window.__PRELOADED_STATE__ =
      ${serialize(
        {
          shops,
          site,
          siteId,
          cookies,
          lowEnd,
          deviceType,
          deviceOs,
          country,
          currentUser,
          initialState,
          queryParams,
          domain,
          isHoverDisabled,
          mapApiKey,
          hotjar,
        },
        { isJSON: true },
      )};
        `}
      </Script>
      {isLegacyBrowser && <Script src="polyfill.js" />}
      {serverState.find_user_location && <Script src="location.js" />}
      <Script src={`search.${deviceType}${groupSite}.js`} />
      {initialState?.ads_metadata?.track_url && (
        <Script>
          {`
        var trackMclics = document.createElement('img');
        trackMclics.src = '${initialState.ads_metadata.track_url}';
      `}
        </Script>
      )}
      <Schema {...initialState.seo} />
      <SearchProvider
        {...initialState}
        history={history}
        isLoadingResults={isLoading}
        locationSearchError={error}
        {...locationSearchProps}
      >
        <LayoutProvider layout={initialState.layout_options} history={history}>
          <UserProvider value={currentUser}>
            <StaticPropsProvider value={staticProps}>
              <EshopsEnvironmentProvider value={hasEshopData}>
                <SnackbarProvider>
                  <FiltersProvider history={history}>
                    <SuggestProvider>
                      <FullFreeshippingProvider value={initialState?.cart_info?.free_shipping}>
                        <FreeShippingThProvider>
                          <LayoutFadeProvider>
                            <>
                              <ComponentSVGsriptes />
                              {hasEshopData && <WrapperEshops {...sF.appearance} />}
                              {children}
                              <Metadata {...initialState.seo} {...initialState.canonical_info} />
                            </>
                          </LayoutFadeProvider>
                        </FreeShippingThProvider>
                      </FullFreeshippingProvider>
                    </SuggestProvider>
                  </FiltersProvider>
                </SnackbarProvider>
              </EshopsEnvironmentProvider>
            </StaticPropsProvider>
          </UserProvider>
        </LayoutProvider>
      </SearchProvider>
      {/** @TODO all data shold be part of initialState */}
      <MeliGA {...initialState.analytics_track} />
      {/** @TODO path should be part of initialState.melidata_track */}
      <MelidataTrack {...initialState.melidata_track} />
      <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.0.0.4.js" />
      <Hotjar {...hotjar} />
      {!initialState.shops && (
        <GoogleTagManager
          siteId={siteId}
          results={initialState.results}
          platform={platformId}
          cookies={cookies}
          queryInitialState={initialState.query}
        />
      )}
      {site === 'PI' && (
        <Script
          src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/4a188c736202/challenge.js"
          crossOrigin={null}
          defer
        />
      )}
      <Qualtrics interceptId="ZN_efi6I3nkl8bGcTA" embeddedData={embeddedData} />
    </div>
  );
};

Search.propTypes = {
  billboardState: shape({}),
  children: node.isRequired,
  cookies: objectOf(any),
  country: shape({
    decimal_separator: string.isRequired,
    thousands_separator: string.isRequired,
  }).isRequired,
  currentUser: shape({
    loggedIn: bool.isRequired,
    loginUrl: string.isRequired,
    nickname: string.isRequired,
  }),
  deviceOs: string.isRequired,
  deviceType: string.isRequired,
  domain: string.isRequired,
  groupSite: string,
  hotjar: shape({}),
  initialState: objectOf(any).isRequired,
  isHoverDisabled: bool,
  isLegacyBrowser: bool,
  lowEnd: bool.isRequired,
  mapApiKey: string,
  openInApp: shape({
    shouldRedirect: bool,
  }),
  platformId: string,
  queryParams: objectOf(any),
  site: string,
  siteId: string.isRequired,
  visualId: string,
  webp: bool,
};

Search.defaultProps = {
  currentUser: null,
  isLegacyBrowser: true,
  queryParams: {},
  cookies: {},
  webp: false,
  visualId: '',
  groupSite: '',
  openInApp: null,
  platformId: '',
  billboardState: null,
  isHoverDisabled: false,
  hotjar: {},
  mapApiKey: '',
};

const SearchContainer = (props) => (
  <ErrorBoundary siteId={props.siteId}>
    <Search {...props} />
  </ErrorBoundary>
);

SearchContainer.propTypes = {
  siteId: string.isRequired,
};

/**
 * Inject i18n context as props into Search.
 */
module.exports = SearchContainer;
