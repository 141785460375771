const React = require('react');

const { loadable } = require('nordic/lazy');

const useCarousel = require('./use-carousel.mobile');

const ChunkCarousel = () => {
  const carousel = useCarousel();
  const CarouselSearch = loadable(async () => import(/* webpackChunkName: "chunk-carousel" */ './carousel.desktop'));

  return carousel ? <CarouselSearch items={carousel.values} type={carousel.type} filters={carousel.filters} /> : null;
};

module.exports = ChunkCarousel;
