const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--virtual-tour';

const IconVirtualTour = ({ className }) => (
  <svg className={classnames(namespace, className)} width="23" height="12" viewBox="0 0 23 12">
    <use href="#virtual-tour" />
  </svg>
);

IconVirtualTour.propTypes = {
  className: string,
};

IconVirtualTour.defaultProps = {
  className: null,
};

module.exports = React.memo(IconVirtualTour);
