const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const IconFull = require('../../icons/full');

const namespace = 'ui-search-item__fulfillment';

const Full = ({ className }) => (
  <span className={classnames(namespace, className)}>
    <IconFull />
  </span>
);

Full.propTypes = {
  className: string,
};

Full.defaultProps = {
  className: null,
};

module.exports = Full;
