const React = require('react');
const { useContext, useState } = require('react');
const { func } = require('prop-types');

const UserContext = require('../context/user');
const SnackbarContext = require('../context/snackbar');
const APIService = require('../../../services/alert');
const IconAlertEdit = require('../icons/alert-edit');
const IconAlertNew = require('../icons/alert-new');
const AlertEditionTooltip = require('./components/alert-edition-tooltip');
const { trackClickEvent } = require('../map/track');

const ALERT_TYPE_NEW = 'new';
const ALERT_TYPE_EDIT = 'edit';

const namespace = 'ui-search-alert';

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const renderAlertEdit = (tooltip) => (
  <div className={`${namespace}__card ${namespace}__card--edit`}>
    <span className={`${namespace}__content`}>
      {tooltip ? (
        <AlertEditionTooltip trigger={<IconAlertEdit className={`${namespace}__icon-alert`} />} {...tooltip} />
      ) : (
        <IconAlertEdit className={`${namespace}__icon-alert`} />
      )}
    </span>
  </div>
);

const renderAlertNew = (
  action,
  alertCreationNext,
  trackEvents,
  loggedIn,
  loginUrl,
  handleAlertCreationSuccess,
  handleAlertCreationError,
) => {
  const handleAlert = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (!loggedIn) {
      redirect(loginUrl);

      return;
    }

    if (trackEvents) {
      const { melidata, analytics } = trackEvents;

      trackClickEvent(analytics, melidata);
    }

    APIService.addAlert(action.alert_creation_data)
      .then(() => handleAlertCreationSuccess(alertCreationNext))
      .catch((ex) => {
        if (ex?.response && ex.response.status === 403) {
          redirect(loginUrl);
        }

        handleAlertCreationError(alertCreationNext, handleAlert);
      });
  };

  return (
    <form
      action="/search/alert"
      className={`${namespace}__card ${namespace}__card--new`}
      method="POST"
      onSubmit={handleAlert}
    >
      <button data-testid="add_alert_button" type="submit" className={`${namespace}__btn`}>
        <IconAlertNew className={`${namespace}__icon-alert`} />
      </button>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input
        type="hidden"
        name="alertCreationData"
        value={action?.alert_creation_data ? encodeURIComponent(JSON.stringify(action.alert_creation_data)) : ''}
      />
    </form>
  );
};

const Alert = (props) => {
  const [alertState, setAlertState] = useState(props);
  const { setSnackbar } = useContext(SnackbarContext);
  const { loggedIn, loginUrl } = useContext(UserContext);
  const { alert_type, tooltip, action, alert_creation_next, analytics_track_event, melidata_track_value } = alertState;

  const trackEvents = {
    analytics: analytics_track_event,
    melidata: melidata_track_value,
  };

  function handleAlertCreationSuccess(alertCreationNext) {
    const {
      creation_success_snackbar,
      creation_success_snackbar: { tracks: trackCongrats },
    } = alertCreationNext;

    if (trackCongrats) {
      const tracks = {
        ...trackCongrats,
        melidata: {
          melidata_track: {
            ...trackCongrats.melidata,
            event_data: {
              frequency: 'daily',
              status: 'active',
            },
          },
        },
      };
      const { analytics, melidata } = tracks;

      trackClickEvent(analytics, melidata);
    }

    const snackbar = creation_success_snackbar;

    setAlertState(alertCreationNext);
    setSnackbar({
      color: 'green',
      message: snackbar.label.text,
      className: 'ui-search-snackbar--alert-creation-success',
    });
  }

  function handleAlertCreationError(alertCreationNext, retry) {
    const snackbar = alertCreationNext.creation_error_snackbar;

    setSnackbar({
      color: 'red',
      message: snackbar.label.text,
      action: { text: snackbar.action.label.text, onClick: retry },
      className: 'ui-search-snackbar--alert-creation-error',
    });
  }

  switch (alert_type) {
    case ALERT_TYPE_EDIT:
      return renderAlertEdit(tooltip);
    case ALERT_TYPE_NEW:
      return renderAlertNew(
        action,
        alert_creation_next,
        trackEvents,
        loggedIn,
        loginUrl,
        props.handleAlertCreationSuccess || handleAlertCreationSuccess,
        props.handleAlertCreationError || handleAlertCreationError,
      );

    default:
      return null;
  }
};

Alert.propTypes = {
  handleAlertCreationError: func,
  handleAlertCreationSuccess: func,
};

Alert.defaultProps = {
  message: null,
  tooltip: null,
  action: null,
  alert_creation_next: null,
  handleAlertCreationError: null,
  handleAlertCreationSuccess: null,
};

module.exports = Alert;
