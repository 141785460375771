const React = require('react');
const { shape, string } = require('prop-types');

const get = require('lodash/get');

const ItemPrice = require('../../../../card/price');

const Price = ({ className, price, from, discountSource }) => {
  if (!price.amount) {
    return <div className={className}>{get(price, 'label.text', '')}</div>;
  }

  return <ItemPrice size="small" className={className} discountSource={discountSource} from={from} {...price} />;
};

Price.propTypes = {
  className: string,
  discountSource: string,
  from: shape({
    text: string,
  }),
  price: shape({}).isRequired,
};

Price.defaultProps = {
  className: null,
  discountSource: null,
  from: null,
};

module.exports = {
  Price,
};
