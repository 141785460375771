const React = require('react');

const { loadable } = require('nordic/lazy');

const useValuePropositionsDetails = require('./use-value-propositions-details');

const ChunkPropositionsDetails = () => {
  const valuePropositionProps = useValuePropositionsDetails();
  const ValuePropositionsDetails = loadable(async () =>
    import(/* webpackChunkName: "chunk-propositions-details" */ './value-propositions-details'),
  );

  return valuePropositionProps ? <ValuePropositionsDetails {...valuePropositionProps} /> : null;
};

module.exports = ChunkPropositionsDetails;
