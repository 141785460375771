const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--badge';

const IconBadge = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
    <g fill="none" fillRule="evenodd">
      <g strokeWidth="1.5">
        <path d="M14.35 12a5.351 5.351 0 1 0-10.702.001A5.351 5.351 0 0 0 14.351 12z" />
        <path
          strokeLinecap="round"
          d="M11.114 6.897l5.837-5.804M7 6.943l4.884-5.035M6.366 6.984L1 2M8.837 4.796L5.775 1.87"
        />
      </g>
    </g>
  </svg>
);

IconBadge.propTypes = {
  className: string,
};

IconBadge.defaultProps = {
  className: null,
};

module.exports = IconBadge;
