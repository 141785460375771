const React = require('react');
const { string, shape, number } = require('prop-types');

const classnames = require('classnames');

const IconMeli = require('../../icons/meli');
const GiftIcon = require('../../icons/gift');
const IconAhora30 = require('../../icons/ahora30');
const BlackFriday = require('../../icons/black-friday');
const HotSale = require('../../icons/hot-sale');
const IconVirtualTour = require('../../icons/virtual-tour');
const MeliPlusIcon = require('../../icons/meli-plus');
const IconCartVolumeDiscount = require('../../icons/cart-volume-discount');
const { getStyleProps } = require('../../../lib/style-props');
const StyledLabel = require('../../styled-label/styled-label');
const ComposedLabel = require('../../composed-label/composed-label');
const IconMeliChoice = require('../../icons/melichoice-logo');

const namespace = 'ui-search-item__highlight-label';

const renderIcon = (icon) => {
  switch (icon.id) {
    case 'black_friday_icon':
      return <BlackFriday {...icon} />;
    case 'ic_gift':
      return <GiftIcon {...icon} />;
    case 'ic_ahora30':
      return <IconAhora30 {...icon} />;
    case 'ic_hot_sale':
      return <HotSale {...icon} />;
    case 'meli_icon':
      return <IconMeli {...icon} />;
    case 'virtual_tour_icon':
      return <IconVirtualTour {...icon} />;
    case 'meli_plus_icon':
      return <MeliPlusIcon {...icon} />;
    case 'cart_icon':
      return <IconCartVolumeDiscount {...icon} />;
    case 'melichoice_icon':
      return <IconMeliChoice {...icon} />;

    default:
      return null;
  }
};

const renderComponent = (label, secondary_label, htmlForIDValue) => {
  const { values } = label;

  if (values) {
    return (
      <div>
        <ComposedLabel className={`${namespace}--composed-title`} {...label} />
        {secondary_label && <StyledLabel className={`${namespace}--secondary-label`} {...secondary_label} />}
      </div>
    );
  }

  return <StyledLabel className={`${namespace}__text`} htmlForIDValue={htmlForIDValue} {...label} />;
};

const HighlightLabel = ({ className, type, icon, label, secondary_label, htmlForIDValue, ...props }) => {
  const highlight_position = icon?.highlight_position || 'left';

  return (
    <div
      className={classnames(
        namespace,
        className,
        { [`${namespace}--${type}`]: !!type },
        { [`${namespace}--no-background`]: !props.background },
      )}
      style={getStyleProps(props)}
    >
      <div className={`${namespace}__container`}>
        {icon && highlight_position === 'left' && renderIcon(icon)}
        {label && renderComponent(label, secondary_label, htmlForIDValue)}
        {icon && highlight_position === 'right' && renderIcon(icon)}
      </div>
    </div>
  );
};

HighlightLabel.propTypes = {
  background: string,
  className: string,
  htmlForIDValue: string,
  icon: shape({
    id: string.isRequired,
    color: string,
    highlight_position: string,
  }),
  label: shape({
    text: string.isRequired,
    color: string,
    background: string,
    size: number,
    weight: number,
    style: string,
  }),
  secondary_label: shape({
    text: string.isRequired,
  }),
  type: string,
};

HighlightLabel.defaultProps = {
  className: null,
  type: null,
  icon: { highlight_position: 'left' },
  label: null,
  secondary_label: null,
  background: null,
  htmlForIDValue: '',
};

module.exports = HighlightLabel;
