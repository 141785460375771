const React = require('react');

const classnames = require('classnames');

const Link = require('../../../../../../../link/filter-link');
const FilterTooltip = require('../../../../../filters-tooltip/filters-tooltip.desktop');
const { valueWithLinkConfigPropTypes } = require('../../../../propTypes');

const ValueLinkWithText = ({ namespace, name, results, url, linkConfig, tooltip, filterId }) => (
  <Link href={url} {...linkConfig} ariaLabel={name} filterId={filterId}>
    <FilterTooltip tooltip={tooltip}>
      <span className={classnames(`${namespace}-name`, 'shops-custom-secondary-font')}>{name}</span>
      {results && (
        <span className={classnames(`${namespace}-results-qty`, 'shops__filter-results-qty')}>{results}</span>
      )}
    </FilterTooltip>
  </Link>
);

ValueLinkWithText.propTypes = {
  ...valueWithLinkConfigPropTypes,
};

module.exports = ValueLinkWithText;
