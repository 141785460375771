const componentFactoryFrom = require('./factory');
const types = require('./component-types');

const sources = {};

sources[types.GROUPED] = require('../grouped/grouped');
sources[types.BREADCRUMB] = require('../sidebar/components/breadcrumb/breadcrumb');
sources[types.MAP_LINK] = require('../sidebar/components/map/map-link.desktop');
sources[types.MAP_BUTTON] = require('../sidebar/components/map/map-button.desktop');
sources[types.APPLIED_FILTERS] = require('../filters/applied/applied-filters.desktop');
sources[types.AVAILABLE_FILTERS] = require('../sidebar/components/filters/filters.desktop');
sources[types.ALERT] = require('../alert/alert.desktop');
sources[types.ALERT_V2] = require('../alert-v2/alert.desktop-v2');
sources[types.TOTAL_RESULTS] = require('../sidebar/components/search-result/search-result.desktop');
sources[types.MONEY_PICKER] = require('../money-picker/money-picker.desktop');
sources[types.SIDEBAR_ADVERTISING] = require('../sidebar/components/sky-banner/index');
sources[types.SEARCH_SHOPS_ADS] = require('../sidebar/components/search-shops-ads/index');
sources[types.ADN] = require('../sidebar/components/advertising-adn/index');
sources[types.ACCESIBLE_LABEL] = require('../styled-label/styled-label');

const ComponentFactory = componentFactoryFrom(sources);

module.exports = ComponentFactory;
