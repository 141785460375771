const React = require('react');
const { string } = require('prop-types');

const EmptyState = require('ui-library_ml/ui/empty-state');

const Icon = require('../../icons/map/loader-error');

const namespace = 'ui-search-map-zrp';

const Zrp = ({ description, title }) => (
  <EmptyState
    className={namespace}
    description={description}
    icon={<Icon className={`${namespace}__icon`} />}
    title={title}
  />
);

Zrp.propTypes = {
  description: string,
  title: string,
};

Zrp.defaultProps = {
  description: '',
  title: '',
};

module.exports = Zrp;
