const React = require('react');

const Link = require('../../../../../../../link/filter-link');
const { valueWithLinkConfigPropTypes } = require('../../../../propTypes');

const ValueLinkWithIcon = ({ namespace, Icon, modifier, results, url, linkConfig, name }) => (
  <Link className={`${namespace}-icon`} href={url} {...linkConfig} ariaLabel={name}>
    <Icon className={`${namespace}-icon--${modifier}`} />
    {results && <span className={`${namespace}-results-qty`}>{results}</span>}
  </Link>
);

ValueLinkWithIcon.propTypes = {
  ...valueWithLinkConfigPropTypes,
};

module.exports = ValueLinkWithIcon;
