const React = require('react');
const { string, bool, number } = require('prop-types');

const classnames = require('classnames');

const ItemFull = require('../fulfillment/fulfillment');

const namespace = 'ui-search-item__promise';

const CHAR_WIDTH = 7;
const FULL_WIDTH = 50;

const Promise = ({ className, id, promise_text, hasFulfillment, contentWidth }) => {
  const splitString = (promiseText) => {
    const strSplit = promiseText.split(' ');

    let firstPartLength = 0;
    let lastPartStart = 0;

    strSplit.forEach((it, index) => {
      firstPartLength += it.length;

      const firstPartWidth = firstPartLength * CHAR_WIDTH;

      if (firstPartWidth > contentWidth) {
        lastPartStart = index;

        return;
      }

      firstPartLength += 1; // Space char
    });

    return {
      first: strSplit.slice(0, lastPartStart),
      last: strSplit.slice(lastPartStart),
    };
  };

  const estimatedPromiseSize = promise_text ? promise_text.length * CHAR_WIDTH : 0;
  const shouldSplitText = estimatedPromiseSize > contentWidth;
  const { first, last } = splitString(promise_text);
  const fullDrops = estimatedPromiseSize + FULL_WIDTH > contentWidth;

  const newClassNamePromise = classnames(`${namespace}--${id}`, { [`${namespace}__text--splitted`]: shouldSplitText });

  const constructInnerHTML = () => {
    const newClassName = classnames(
      `${namespace}__text`,
      { [`${namespace}__text--long`]: hasFulfillment && fullDrops },
      `${namespace}__text--last`,
    );

    if (shouldSplitText) {
      return (
        <>
          <span className={`${namespace}__text`}>{first.join(' ')}</span>
          <span className={`${namespace}__text ${namespace}__text--last`}>{last.join(' ')}</span>
        </>
      );
    }

    return <span className={newClassName}>{promise_text}</span>;
  };

  return (
    <p className={classnames(namespace, newClassNamePromise, className)}>
      {constructInnerHTML()}
      {hasFulfillment && <ItemFull />}
    </p>
  );
};

Promise.propTypes = {
  className: string,
  contentWidth: number.isRequired,
  hasFulfillment: bool.isRequired,
  id: string.isRequired,
  promise_text: string.isRequired,
};

Promise.defaultProps = {
  className: null,
};

module.exports = Promise;
