/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { useCallback, useEffect, useRef, useState } = require('react');
const { node, number, string } = require('prop-types');

const AutoHeight = ({ className, children, initialHeight }) => {
  const [height, setHeight] = useState(initialHeight);
  const container = useRef();

  const resizeContainer = useCallback(() => setHeight(window.innerHeight - container.current.offsetTop), []);

  useEffect(() => {
    if (container.current && window) {
      resizeContainer();
    }
  }, []);

  // Some filters may or may not be present, so we need to make sure the
  // container always takes the available height.
  useEffect(() => {
    if (!MutationObserver) {
      return () => {};
    }

    const observer = new MutationObserver(resizeContainer);

    observer.observe(container.current.parentNode, { childList: true });

    return () => observer.disconnect();
  }, []);

  return (
    <div className={className} style={{ height }} ref={container}>
      {children}
    </div>
  );
};

AutoHeight.propTypes = {
  children: node.isRequired,
  className: string,
  initialHeight: number,
};

AutoHeight.defaultProps = {
  initialHeight: 500,
};

module.exports = AutoHeight;
