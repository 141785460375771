const React = require('react');
const { bool, func, string } = require('prop-types');

const Dropdown = require('@andes/dropdown');

const { NAMESPACE } = require('../../constants');
const useAutocompleteSearch = require('../../hooks/use-autocomplete-search');
const {
  FILTERS: {
    IDS: { ITEM_LOCATION, STATE, CITY, NEIGHBORHOOD },
  },
} = require('../../../../../../constants');

const { DropdownItem } = Dropdown;

const INITIAL_LOCATION_VALUES = {
  [STATE]: null,
  [CITY]: null,
  [NEIGHBORHOOD]: null,
  [ITEM_LOCATION]: null,
};

const LocationFilter = ({ disabled, name, placeholder, selectedOption, onChange }) => {
  const { isLoading, search, setSearch, options } = useAutocompleteSearch(name, selectedOption);

  const handleSearchChange = (value) => {
    setSearch(value);
  };

  const updateLocation = ({ id, level, tree = {} }) => {
    if (selectedOption !== id) {
      setSearch('');
      onChange({
        ...INITIAL_LOCATION_VALUES,
        [level]: id,
        ...tree,
      });
    }
  };

  const handleLocationChange = (_, selectedId) => {
    const nextOption = options.find(({ id }) => id === selectedId);

    if (nextOption) {
      updateLocation(nextOption);
    }
  };

  return (
    <Dropdown
      className={`${NAMESPACE}__location-dropdown`}
      menuAlignment="bottom"
      label={placeholder}
      value={selectedOption}
      onChange={handleLocationChange}
      search={{
        defaultValue: search,
        onChange: handleSearchChange,
      }}
    >
      {options.map(({ id, name: optionName }) => (
        <DropdownItem key={id} value={id} title={optionName} disabled={disabled || isLoading} />
      ))}
    </Dropdown>
  );
};

LocationFilter.propTypes = {
  disabled: bool,
  name: string,
  placeholder: string,
  selectedOption: string,
  onChange: func.isRequired,
};

LocationFilter.defaultProps = {
  disabled: false,
  name: null,
  selectedOption: null,
  placeholder: '',
};

module.exports = LocationFilter;
