/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */

const { useState, useEffect } = require('react');

const APIService = require('../../services/availableFilterValues');
const useFilterSearch = require('./use-filter-search');

const getFilterValueWithResults = (value, valuesWithResults) =>
  valuesWithResults
    .filter((availableValue) => availableValue.id === value.id)
    .map((availableValue) => ({
      ...value,
      results: availableValue.results,
    }))
    .pop() || value;

const createRedirectHandler = (url) => () => {
  if (window) {
    window.location.href = url;
  }
};

const useFilterValuesMultiSelect = ({ filterId, multiSelection, allValues, updateAllValues }) => {
  const [selectedValues, setSelectedValues] = useState(multiSelection && new Set(multiSelection.selected_values));
  const [showSubmit, setShowSubmit] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const filterSearch = useFilterSearch(nextUrl, filterId);

  const addResultsToSelectedValues = () => {
    APIService.getAvailableFilterValues(
      filterId,
      Array.from(selectedValues).join(','),
      multiSelection.rest_of_applied_filters_params,
    )
      .then((availableValues) => {
        const valuesWithResults = allValues.map((value) => {
          if (selectedValues.has(value.id)) {
            return getFilterValueWithResults(value, availableValues);
          }

          return value;
        });

        updateAllValues(valuesWithResults);
      })
      .catch(() => console.error('Error searching for available values'));
  };

  const setSelected = (value, action) => {
    const newValues = new Set(selectedValues);

    newValues[`${action}`](value);
    setSelectedValues(newValues);
  };

  const handleSelectValue = (value, event) => {
    const action = event.target.checked ? 'add' : 'delete';

    setSelected(value, action);
    setShowSubmit(true);
  };

  const getSearchUrlFiltersParams = () => {
    const selectedValuesParamStr = `${filterId}=${Array.from(selectedValues).join(',')}`;

    const filtersParamsParts = [multiSelection.rest_of_applied_filters_params, selectedValuesParamStr].filter(
      (paramsPart) => paramsPart,
    );

    return filtersParamsParts.join('&');
  };

  const handleSubmit = () =>
    APIService.getSearchUrlFilter(getSearchUrlFiltersParams()).then(({ url }) => {
      setNextUrl(url);

      return url;
    });

  const isValueSelected = (filterValue) => selectedValues?.has(filterValue.id);

  useEffect(() => {
    if (selectedValues) {
      addResultsToSelectedValues();
    }
  }, []);

  useEffect(() => {
    if (nextUrl) {
      const isClientSideSearch = !!filterSearch;
      const handleSearch = isClientSideSearch ? filterSearch : createRedirectHandler(nextUrl);

      handleSearch();
    }
  }, [nextUrl]);

  return {
    handleSelectValue,
    isValueSelected,
    handleSubmit,
    showSubmit,
  };
};

module.exports = useFilterValuesMultiSelect;
