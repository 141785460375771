const { useContext } = require('react');

const { useSearch } = require('../../hooks/context');
const LayoutContext = require('../context/layout');

const useResults = () => {
  const { results, layout_options } = useSearch();
  const { currentLayout } = useContext(LayoutContext);

  return { currentLayout, results, layout_options };
};

module.exports = useResults;
