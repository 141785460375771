const React = require('react');
const { useState } = require('react');
const { node, shape, string, oneOf, func } = require('prop-types');

const SnackbarContext = React.createContext('');
const { Provider, Consumer: SnackbarConsumer } = SnackbarContext;

const SnackbarProvider = ({ children, snackbar }) => {
  const [currentSnackbar, setSnackbar] = useState(snackbar);

  const state = {
    currentSnackbar,
    setSnackbar,
  };

  return <Provider value={state}>{children}</Provider>;
};

SnackbarProvider.propTypes = {
  children: node.isRequired,
  snackbar: shape({
    className: string,
    color: oneOf(['green', 'red']),
    message: string.isRequired,
    action: shape({
      text: string,
      onClick: func,
      position: string,
    }),
  }),
};

SnackbarProvider.defaultProps = {
  snackbar: null,
};

module.exports = SnackbarContext;
module.exports.SnackbarProvider = SnackbarProvider;
module.exports.SnackbarConsumer = SnackbarConsumer;
