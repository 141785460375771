const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--international-logo';

const DEFAULT_COLOR = '#1F4E96';

const IconInternationalLogo = ({ className, color }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 213 32"
    xmlns="http://www.w3.org/2000/svg"
    fill={color || DEFAULT_COLOR}
  >
    <use href="#international-logo" />
  </svg>
);

IconInternationalLogo.propTypes = {
  className: string,
  color: string,
};

IconInternationalLogo.defaultProps = {
  className: '',
  color: '',
};

module.exports = React.memo(IconInternationalLogo);
