const React = require('react');
const { useState } = require('react');
const { node, string } = require('prop-types');

const FullFreeshippingContext = React.createContext({
  currentFullFreeshipping: null,
  setFullFreeshipping: null,
});
const { Provider, Consumer: FullFreeshippingConsumer } = FullFreeshippingContext;

const FullFreeshippingProvider = ({ children, value }) => {
  const [currentFullFreeshipping, setFullFreeshipping] = useState(value);
  const [fullOnlyFirstFreeshipping, setFullOnlyFirstFreeshipping] = useState(null);

  const state = {
    currentFullFreeshipping,
    setFullFreeshipping,
    fullOnlyFirstFreeshipping,
    setFullOnlyFirstFreeshipping,
  };

  return <Provider value={state}>{children}</Provider>;
};

FullFreeshippingProvider.propTypes = {
  children: node.isRequired,
  value: string,
};

FullFreeshippingProvider.defaultProps = {
  value: '',
};

module.exports = FullFreeshippingContext;
module.exports.FullFreeshippingProvider = FullFreeshippingProvider;
module.exports.FullFreeshippingConsumer = FullFreeshippingConsumer;
