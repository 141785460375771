const React = require('react');

const Group = require('../../../../card/group/group');
const Reviews = require('../../../../reviews/reviews');
const { CARD_DATA_GROUPS, GRID, STACK } = require('../../constants');
const { renderTitle, renderOfficialStore, renderPriceGroup, ContentSkeleton } = require('../common');

const renderTitleGroup = (item, options, layout) => (
  <Group noSeparator name={CARD_DATA_GROUPS.TITLE}>
    {renderTitle(item.title, layout, item.permalink, item.target, item.is_ad, item.title_compats)}
    {renderOfficialStore(item.vertical, item.official_store, options)}
  </Group>
);

const renderDetailsGroup = (item) =>
  item.details && (
    <Group noSeparator name={CARD_DATA_GROUPS.DETAILS}>
      <span className="ui-search-item__details">{item.details}</span>
    </Group>
  );

const renderReviewsGroup = (item) =>
  item.reviews && (
    <Group noSeparator name={CARD_DATA_GROUPS.REVIEWS}>
      <Reviews
        rating={item.reviews.rating_average}
        stars={item.reviews.rating_stars}
        formattedTotal={item.reviews.formatted_total_label || item.reviews.formatted_total}
        formattedAverage={item.reviews.formatted_rating_average || item.reviews.rating_average_formatted}
        accesibilityText={item?.reviews?.accessibility_text || item.reviews.alt_text}
      />
    </Group>
  );

function renderContent(item, options, layout) {
  switch (layout) {
    case GRID:
      return (
        <ContentSkeleton
          topComponents={[
            renderTitleGroup(item, options, layout),
            renderPriceGroup(item, options),
            renderDetailsGroup(item),
            renderReviewsGroup(item),
          ]}
        />
      );
    case STACK:

    // falls through
    default:
      return (
        <ContentSkeleton
          topComponents={[renderTitleGroup(item, options, layout), renderPriceGroup(item, options)]}
          leftColComponents={[renderDetailsGroup(item)]}
          rightColComponents={[renderReviewsGroup(item)]}
        />
      );
  }
}

module.exports = renderContent;
