const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-item__brand-discoverability';

const BrandDiscoverability = ({ className, brand }) => (
  <span className={classnames(namespace, className)}>{brand}</span>
);

BrandDiscoverability.propTypes = {
  brand: string.isRequired,
  className: string,
};

BrandDiscoverability.defaultProps = {
  className: null,
};

module.exports = BrandDiscoverability;
