const { PICTURES_CAROUSEL_SERVICE_BASE_PATH } = require('../constants/paths');
const picturesCarouselClient = require('../client/restclient')({
  timeout: 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});

class Service {
  static getCarouselPictures(itemId, variationIds) {
    return picturesCarouselClient
      .get(`${PICTURES_CAROUSEL_SERVICE_BASE_PATH}?item_id=${itemId}&variation_ids=${variationIds.join(',')}`, {
        headers: {
          Encoding: 'UTF-8',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => response.data.pictures);
  }
}

module.exports = Service;
