const React = require('react');

const IconCoupon = () => (
  <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H11C11.8284 0.5 12.5 1.17157 12.5 2V2.99152C12.5 3.08948 12.4263 3.17177 12.329 3.18259C10.0938 3.43094 10.0938 6.68017 12.329 6.92852C12.4263 6.93934 12.5 7.02163 12.5 7.11959V8.11111C12.5 8.93954 11.8284 9.61111 11 9.61111H2C1.17157 9.61111 0.5 8.93954 0.5 8.11111V2Z"
      stroke="#3483FA"
    />
    <path
      d="M8.17582 4.20402L6.83448 4.09671C6.78082 4.09671 6.72717 4.06094 6.70928 4.00729L6.20852 2.75537C6.08332 2.45133 5.67198 2.45133 5.54679 2.75537L5.04602 4.00729C5.02814 4.06094 4.97448 4.09671 4.92083 4.09671L3.57949 4.18613C3.25757 4.20402 3.13237 4.61536 3.38276 4.81209L4.40218 5.67055C4.43795 5.70632 4.45583 5.75997 4.45583 5.81363L4.11603 7.13708C4.04449 7.44112 4.38429 7.6915 4.65256 7.53054L5.79717 6.81516C5.85083 6.77939 5.90448 6.77939 5.95813 6.81516L7.10274 7.53054C7.37101 7.6915 7.71082 7.45901 7.63928 7.13708L7.29947 5.83151C7.28159 5.77786 7.29947 5.72421 7.35313 5.68844L8.39043 4.81209C8.62293 4.61536 8.49774 4.2219 8.17582 4.20402Z"
      fill="#3483FA"
    />
  </svg>
);

module.exports = IconCoupon;
