/* eslint-disable react/jsx-no-useless-fragment */
const React = require('react');
const { shape, arrayOf } = require('prop-types');

const ComponentFactory = require('./component-factory');

const WrapperEshops = ({ children }) => {
  const componentCreation = (comp) => <ComponentFactory {...comp} key={comp.id} />;
  const componentWrapper = children?.map((component) => componentCreation(component));

  return componentWrapper || <></>;
};

WrapperEshops.propTypes = {
  children: arrayOf(shape).isRequired,
};

module.exports = WrapperEshops;
