const React = require('react');
const { arrayOf, shape, bool } = require('prop-types');

const classnames = require('classnames');

const { useSearch } = require('../../hooks/context');
const ComponentFactory = require('../factory/factory.desktop');

const namespace = 'ui-search-sidebar';

const Sidebar = ({ components, multiNeighborhood }) => (
  <aside
    className={classnames(namespace, 'shops__sidebar', {
      [`${namespace}--h1-multi-neighborhood`]: multiNeighborhood,
    })}
  >
    {components?.map((component) => (
      <ComponentFactory {...component} key={component.id} namespace={namespace} type={component?.type} />
    ))}
  </aside>
);

Sidebar.defaultProps = {
  multiNeighborhood: false,
};

Sidebar.propTypes = {
  components: arrayOf(shape).isRequired,
  multiNeighborhood: bool,
};

const hasMultiNeighborhoods = ({ filters }) => !!filters.find((it) => it.id === 'neighborhood' && it.values.length > 1);

const SidebarContainer = () => {
  const { sidebar } = useSearch();

  if (!sidebar) {
    return null;
  }

  const { components } = sidebar;
  const appliedFilters = components?.find((it) => it.type === 'APPLIED_FILTERS');
  const multiNeighborhood = appliedFilters && hasMultiNeighborhoods(appliedFilters);

  return <Sidebar {...sidebar} multiNeighborhood={multiNeighborhood} />;
};

module.exports = SidebarContainer;
module.exports.Sidebar = Sidebar;
