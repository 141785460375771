const React = require('react');

const EmptyState = require('ui-library_ml/ui/empty-state');

const { getMessagesErrorBySiteId } = require('../../lib/get-message-error');
const Icon = require('../icons/map/loader-error');
const { useStaticProps } = require('../context/static-props');

const namespace = 'ui-search-map-loader-error';

const LoaderError = () => {
  const { siteId } = useStaticProps();
  const { title, description } = getMessagesErrorBySiteId(siteId);

  return (
    <EmptyState
      className={namespace}
      icon={<Icon className={`${namespace}__icon`} />}
      title={title}
      description={description}
    />
  );
};

module.exports = LoaderError;
