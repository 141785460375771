const React = require('react');
const { shape } = require('prop-types');

const { PillsBuilder } = require('@ui-components-web/ui-pills-builder');

const PillBuilder = ({ pills }) => <PillsBuilder pills={pills} />;

PillBuilder.propTypes = {
  pills: shape({}),
};

PillBuilder.defaultProps = {
  pills: [],
};

module.exports = PillBuilder;
