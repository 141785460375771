const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--shippingtruck';

const IconShippingTruck = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fillRule="evenodd"
      d="M7.29 12.292c-.297.985-1.212 1.702-2.293 1.702-1.083 0-1.997-.718-2.294-1.703l-.702-.001c-.994 0-1.8-.806-1.8-1.8V3.795c0-.994.806-1.8 1.8-1.8h6.664c.994 0 1.8.806 1.8 1.8V3.8h2.626l2.708 3.491v4.999l-1.846.002c-.297.985-1.211 1.702-2.293 1.702s-1.996-.717-2.293-1.702zm4.37-1.887c-.66 0-1.195.535-1.195 1.195s.535 1.194 1.195 1.194 1.195-.535 1.195-1.194c0-.66-.535-1.195-1.195-1.195zm-6.663 0c-.66 0-1.195.535-1.195 1.195s.535 1.194 1.195 1.194S6.19 12.26 6.19 11.6c0-.66-.535-1.195-1.194-1.195zm3.668-7.21H2.001c-.331 0-.6.269-.6.6v6.695c0 .331.269.6.6.6h.655c.234-1.078 1.193-1.885 2.34-1.885 1.148 0 2.107.807 2.34 1.884h1.93V3.796c0-.331-.27-.6-.6-.6zM12.503 5h-2.038v4.525c.351-.204.76-.32 1.195-.32 1.147 0 2.106.807 2.34 1.885h.599V7.702L12.503 5z"
    />
  </svg>
);

IconShippingTruck.propTypes = {
  className: string,
};

IconShippingTruck.defaultProps = {
  className: null,
};

module.exports = React.memo(IconShippingTruck);
