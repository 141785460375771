const { node } = require('prop-types');

const useEshopsEnvironment = require('../eshop-environment/use-eshops-environment');

const FullFreeshippingSwitcher = ({ eshopsComponent, defaultComponent }) => {
  const { isEshopsEnvironment } = useEshopsEnvironment();

  return isEshopsEnvironment ? eshopsComponent : defaultComponent;
};

FullFreeshippingSwitcher.defaultProps = {
  defaultComponent: undefined,
  eshopsComponent: undefined,
};

FullFreeshippingSwitcher.propTypes = {
  defaultComponent: node,
  eshopsComponent: node,
};

module.exports = FullFreeshippingSwitcher;
