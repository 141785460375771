const React = require('react');
const { useContext, useMemo } = require('react');
const { arrayOf, element, string, shape } = require('prop-types');

const classnames = require('classnames');

const StaticPropsContext = require('../../context/static-props');
const LayoutFadeContext = require('../../context/layout-fade');
const { renderOneColumnComponents } = require('../renderer/utils/render-results');

const namespace = 'ui-search-layout';

const OneColumnLayout = ({ itemComponent: LayoutItem, results, layout }) => {
  const { lowEnd, visualId, deviceType } = useContext(StaticPropsContext);
  const { isFade } = useContext(LayoutFadeContext);
  const options = useMemo(() => ({ lowEnd, verboseLabels: true, cardSupportsLinks: true }), [lowEnd]);

  return (
    <ol
      className={classnames(
        namespace,
        { [`${namespace}--${layout}`]: layout },
        { [`${namespace}--${visualId}`]: visualId },
        { [`${namespace}--fade`]: isFade },
        'shops__layout',
      )}
    >
      {results.map((item, i) =>
        renderOneColumnComponents(item, i, {
          LayoutItem,
          deviceType,
          options,
          namespace,
          layout,
        }),
      )}
    </ol>
  );
};

// @TODO improve results shape definition
OneColumnLayout.propTypes = {
  itemComponent: element.isRequired,
  layout: string.isRequired,
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

OneColumnLayout.defaultProps = {
  results: [],
};

module.exports = OneColumnLayout;
