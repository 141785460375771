const React = require('react');
const { arrayOf } = require('prop-types');

const Group = require('../group/group');
const ComposedLabel = require('../../composed-label/composed-label');

const namespace = 'ui-search-item__spotlight';

const SpotlightGroup = ({ spotlight }) => (
  <Group noSeparator className={namespace}>
    {spotlight.map((destaque) => (
      <ComposedLabel {...destaque} className={`${namespace}__highlight`} />
    ))}
  </Group>
);

SpotlightGroup.propTypes = {
  spotlight: arrayOf([]),
};

SpotlightGroup.defaultProps = {
  spotlight: [],
};

module.exports = SpotlightGroup;
