/* eslint-disable import/order */
const React = require('react');

const { useContext } = React;

const { string, shape, bool, arrayOf } = require('prop-types');

const classnames = require('classnames');
const Dropdown = require('@andes/dropdown');

const StaticPropsContext = require('../../../context/static-props');
const { buildNewHref } = require('../../../link/link-utils');
const LayoutContext = require('../../../context/layout');

const { DropdownItem } = Dropdown;

const namespace = 'ui-search-sort-filter';

const getSelectedOption = (sortOptions = []) => {
  const selectedOption = sortOptions.find(({ selected }) => selected);
  const defaultOption = sortOptions.length > 0 ? sortOptions[0] : undefined;

  return selectedOption || defaultOption;
};

const SortFilter = ({ values, initialValue, hasDisclaimer }) => {
  const { queryParams } = useContext(StaticPropsContext);
  const { currentLayout } = React.useContext(LayoutContext);

  const onSelectItem = (url) => {
    window.location.href = buildNewHref({ href: url, queryParams, isInternal: true, currentLayout });
  };
  const renderItem = (name, selected, url, id) => (
    <DropdownItem
      onSelect={() => {
        onSelectItem(url);
      }}
      value={id}
      title={name}
      selected={selected}
    />
  );

  if (!values) {
    return '';
  }

  return values.length > 1 ? (
    <div className={classnames(namespace, { [`${namespace}--with-disclaimer`]: hasDisclaimer }, 'shops__sort-filter')}>
      <Dropdown
        label={initialValue.name}
        value={initialValue.id}
        size="small"
        menuAlignment="bottom"
        className={`${namespace}__dropdown`}
      >
        {values.map((sort) => renderItem(sort.name, sort.selected, sort.url, sort.id))}
      </Dropdown>
    </div>
  ) : (
    <span className={`${namespace}-values`}>{values[0].name}</span>
  );
};

const SortOption = shape({
  id: string,
  name: string,
  url: string,
  selected: bool,
});

SortFilter.propTypes = {
  hasDisclaimer: bool,
  initialValue: SortOption,
  values: arrayOf(SortOption).isRequired,
};

SortFilter.defaultProps = {
  hasDisclaimer: false,
  initialValue: {},
};

const SortFilterContainer = ({ sorts, has_disclaimer }) => (
  <SortFilter values={sorts} initialValue={getSelectedOption(sorts)} hasDisclaimer={has_disclaimer} />
);

SortFilterContainer.propTypes = {
  has_disclaimer: bool,
  sorts: arrayOf(shape({})),
};

SortFilterContainer.defaultProps = {
  has_disclaimer: false,
  sorts: null,
};

module.exports = SortFilterContainer;
module.exports.SortFilter = SortFilter;
