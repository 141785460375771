const { useSearch } = require('../../hooks/context');
const { serialize } = require('./helpers');

const usePagination = () => {
  const { pagination } = useSearch();

  return pagination ? serialize(pagination) : null;
};

module.exports = usePagination;
