const React = require('react');
const { string } = require('prop-types');

const Link = require('../../../link');

const ProcessedDescription = ({ id, name, url }) => (
  <Link title={name} href={url}>
    <b>{name}</b>
    <br />({id})
  </Link>
);

ProcessedDescription.propTypes = {
  id: string,
  name: string,
  url: string,
};

ProcessedDescription.defaultProps = {
  id: '',
  name: '',
  url: '',
};

module.exports = ProcessedDescription;
