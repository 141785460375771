const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--map-zoom-in';

const IconZoomIn = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path d="M7.39902 7.4V2H8.59902V7.4H13.999V8.6H8.59902V14H7.39902V8.6H1.99902V7.4H7.39902Z" fill="#3483FA" />
  </svg>
);

IconZoomIn.propTypes = {
  className: string,
};

IconZoomIn.defaultProps = {
  className: null,
};

module.exports = IconZoomIn;
