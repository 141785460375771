const React = require('react');
const { arrayOf, bool, node } = require('prop-types');

const has = require('lodash/has');
const classNames = require('classnames');

const ItemBrandDiscoverability = require('../../../card/brand');
const ItemTitle = require('../../../card/title');
const ItemPrice = require('../../../card/price');
const ItemAttributes = require('../../../card/attributes/attributes.desktop');
const ItemOfficialStore = require('../../../card/official-store/official-store-label');
const ItemInstallments = require('../../../card/installments/installments');
const PriceDetails = require('../../../card/price-details/price-details');
const ItemVerticalHighlight = require('../../../card/vertical-highlight/vertical-highlight');
const ItemValuePropositions = require('../../../card/value-proposition/value-propositions');
const Link = require('../../../link');
const Group = require('../../../card/group/group');
const { mapWithKey } = require('../utils');
const AddToCart = require('../../../card/add-to-cart');
const {
  CARD_DATA_GROUPS,
  namespace,
  GENERIC_KEY_PREFIXES,
  VERTICAL_TYPE_MOT,
  VERTICAL_TYPE_RES,
} = require('../constants');
const HightlightLabel = require('../../../card/highlight/highlight-label');
const { GRID, GALLERY } = require('../constants');

const isLayoutGallery = (layout) => layout === GRID || layout === GALLERY;

const renderAddToCartComponent = (item) => {
  if (item.cpg) {
    return (
      <Group noSeparator name={CARD_DATA_GROUPS.ADD_TO_CART}>
        <AddToCart
          {...item.cpg}
          itemId={item.id}
          productId={item?.product?.id}
          category={item.category_id}
          availableQuantity={item.available_quantity}
          minimumQuantity={item.minimum_quantity}
          labelMinQuantity={item.label_min_quantity}
          inventoryId={item.inventory_id}
          label={item.title}
          groupBy={item.group_by}
          threshold={item.threshold}
        />
      </Group>
    );
  }

  return null;
};
const renderLinkTitle = (permalink, title, titleContent, target, isAd) => (
  <Link href={permalink} title={title} isInternal={false} target={target} isAd={isAd}>
    {titleContent}
  </Link>
);

const handleClick = (target) => {
  window.location = target;
};

const renderPriceWithLink = (item) => (
  /* The <div> element does not require keyboard interactions, it is already in the item title */
  /* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  <div onClick={() => handleClick(item.permalink)}>
    <ItemPrice
      {...item.price}
      discountSource={item.discount_source}
      from={item.from}
      showOriginalValue={!!item.price.original_price}
    />
  </div>
);

const renderPriceWithoutLink = (item) => (
  <div className="ui-search-price__part-without-link">
    <ItemPrice
      {...item.price}
      discountSource={item.discount_source}
      from={item.from}
      showOriginalValue={!!item.price.original_price}
    />
  </div>
);

const renderPrice = (item, options) => {
  const cardSupportsLinks = !!options?.cardSupportsLinks;

  if (!has(item, 'price.amount')) {
    return has(item, 'price.label') ? <div className="ui-search-price__part">{item.price.label.text}</div> : null;
  }

  return cardSupportsLinks && item.tags !== null && item.tags.includes('product_item')
    ? renderPriceWithLink(item)
    : renderPriceWithoutLink(item);
};

const renderBrand = (brand) => {
  const brandContent = <ItemBrandDiscoverability brand={brand} />;

  return brandContent;
};

const renderTitle = (title, layout, permalink, target, isAd, compats) => {
  const titleContent = <ItemTitle title={title} target={target} compats={compats} layout={layout} />;

  switch (layout) {
    default:
      return renderLinkTitle(permalink, title, titleContent, target, isAd);
  }
};

const renderHighlightGroup = ({ highlight, layout }) =>
  highlight && (
    <Group
      noSeparator
      className={classNames(`${namespace}__highlight-container`, {
        [`${namespace}__highlight-container-grid`]: isLayoutGallery(layout),
      })}
    >
      <HightlightLabel {...highlight} />
    </Group>
  );

const renderMediaTagGroup = ({ media, layout, highlighted }) =>
  isLayoutGallery(layout)
    ? (media || highlighted) && (
        <div
          className={classNames('ui-search-item__media-tag-container-grid', {
            'flex-end': !media,
          })}
        >
          {media && <HightlightLabel {...media} />}
          {highlighted && <div className="ui-search-item__highlighted-label">{highlighted}</div>}
        </div>
      )
    : media && (
        <Group noSeparator name={CARD_DATA_GROUPS.MEDIA_TAG}>
          <HightlightLabel {...media} />
        </Group>
      );

const truncateString = (str, n) => (str.length > n ? `${str.slice(0, n - 1)}...` : str);

const renderOfficialStore = (vertical, official_store, options, isAd, layout) => {
  if (!official_store) {
    return null;
  }

  const props = {
    label: official_store.text,
    permalink: official_store.permalink,
    shouldRenderLinks: !!options?.cardSupportsLinks,
    isAd: isAd || false,
    layout,
  };

  const isVerticalVIS = vertical === VERTICAL_TYPE_MOT || vertical === VERTICAL_TYPE_RES;

  if (isVerticalVIS || !!options?.verboseLabels) {
    props.label = official_store.verbose_text;
  }

  return isLayoutGallery(layout) ? (
    <div className="ui-search-item__official-store-grid">
      <ItemOfficialStore {...props} />
    </div>
  ) : (
    <ItemOfficialStore {...props} />
  );
};

const renderPriceGroup = (item, options, layout) => (
  <Group noSeparator name={CARD_DATA_GROUPS.PRICE} className="ui-search-item__group--price-grid-container">
    {isLayoutGallery(layout) ? (
      <div className="ui-search-item__group--price-grid">{item.price && renderPrice(item, options)}</div>
    ) : (
      item.price && renderPrice(item, options)
    )}
    {item.price_details && <PriceDetails priceDetails={item.price_details} />}
    {item.installments && <ItemInstallments installments={item.installments} price={item.price} />}
  </Group>
);

const renderAttributesGroup = (item, layout) => {
  const itemDescription = item.descriptions ?? item.verticalInfo?.descriptions;

  if (isLayoutGallery(layout)) {
    return (
      <div className="ui-search-item__attributes-container-grid">
        <ItemAttributes attributes={itemDescription} className="ui-search-item__attributes-grid" />
      </div>
    );
  }

  return (
    <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES}>
      <ItemAttributes attributes={itemDescription} />
    </Group>
  );
};

const renderGoodPrice = (item) => {
  const itemGoodPrice = item.good_price ?? item.verticalInfo?.good_price;

  return (
    itemGoodPrice && (
      <Group noSeparator name={CARD_DATA_GROUPS.ATTRIBUTES} className="ui-search-item__group--good-price">
        <HightlightLabel {...itemGoodPrice} />
      </Group>
    )
  );
};

const renderVerticalHighlightGroup = (item) =>
  (item.vertical_highlight || (item.value_propositions && item.value_propositions.length > 0)) && (
    <Group name={CARD_DATA_GROUPS.VERTICAL_HIGHLIGHT}>
      {item.vertical_highlight && <ItemVerticalHighlight {...item.vertical_highlight} />}
      {item.value_propositions && item.value_propositions.length > 0 && (
        <ItemValuePropositions propositions={item.value_propositions} />
      )}
    </Group>
  );

const ContentSkeleton = ({ topComponents, leftColComponents, rightColComponents, extraPadding, bottomComponents }) => (
  <>
    {mapWithKey(topComponents, GENERIC_KEY_PREFIXES.TOP_COLUMN)}
    {(leftColComponents || rightColComponents) && (
      <div className={classNames(`${namespace}__content-columns`, 'shops__content-columns')}>
        {leftColComponents && (
          <div
            className={classNames(
              `${namespace}__content-column ${namespace}__content-column--left`,
              'shops__content-columns-left',
            )}
          >
            {mapWithKey(leftColComponents, GENERIC_KEY_PREFIXES.LEFT_COLUMN)}
          </div>
        )}
        {rightColComponents && (
          <div
            className={classNames(
              `${namespace}__content-column`,
              `${namespace}__content-column--right`,
              'shops__content-columns-right',
              { [`${namespace}__content-column--extra-padding`]: extraPadding },
            )}
          >
            {mapWithKey(rightColComponents, GENERIC_KEY_PREFIXES.RIGHT_COLUMN)}
          </div>
        )}
      </div>
    )}
    {bottomComponents && (
      <div
        className={classNames(
          `${namespace}__content-column ${namespace}__content-column--bottom`,
          'shops__content-columns-bottom',
        )}
      >
        {mapWithKey(bottomComponents, GENERIC_KEY_PREFIXES.BOTTOM_COLUMN)}
      </div>
    )}
  </>
);

ContentSkeleton.propTypes = {
  bottomComponents: arrayOf(node),
  extraPadding: bool,
  leftColComponents: arrayOf(node),
  rightColComponents: arrayOf(node),
  topComponents: arrayOf(node).isRequired,
};

ContentSkeleton.defaultProps = {
  extraPadding: false,
  leftColComponents: null,
  rightColComponents: null,
  bottomComponents: null,
};

module.exports = {
  renderPrice,
  renderTitle,
  renderBrand,
  renderOfficialStore,
  renderPriceGroup,
  renderAttributesGroup,
  renderHighlightGroup,
  renderGoodPrice,
  renderMediaTagGroup,
  renderVerticalHighlightGroup,
  renderAddToCartComponent,
  ContentSkeleton,
  truncateString,
  isLayoutGallery,
};
