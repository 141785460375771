const React = require('react');
const { string, shape } = require('prop-types');

const Script = require('nordic/script');
const classnames = require('classnames');

const namespace = 'ui-search-sidebar-advertising';

const AdvertisingAdn = ({ title, content }) => {
  const contentClass = classnames(`${namespace} title-advertising`);
  const [adLoaded, setAdLoaded] = React.useState(false);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const loaded = () => setAdLoaded(true);
    const node = ref.current;

    node.addEventListener('adn-banner-loaded', loaded);

    return () => {
      node.removeEventListener('adn-banner-loaded', loaded);
    };
  }, []);

  return (
    <div className={`${namespace}__display`}>
      <div className={contentClass}>
        {adLoaded && (
          <div className={`${namespace}__title-container`}>
            <span className={`${namespace}__title`}>{title}</span>
          </div>
        )}
        <Script src="https://http2.mlstatic.com/frontend-assets/adn-frontend-library/adn-script.js" />
        <div data-component="adn" data-content={JSON.stringify(content)} ref={ref} />
      </div>
    </div>
  );
};

AdvertisingAdn.propTypes = {
  content: shape({
    category: string.isRequired,
    page: string.isRequired,
    placement: string.isRequired,
    platform: string.isRequired,
    site_id: string.isRequired,
    slot_id: string.isRequired,
    title: string.isRequired,
  }).isRequired,
  title: string.isRequired,
};

module.exports = AdvertisingAdn;
