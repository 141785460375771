const { useSearch } = require('../../../hooks/context');

const buildTrackData = ({ analytics_track_event: analytics, melidata_track }) => ({
  analytics,
  melidata: { melidata_track },
});

const useMapConfig = () => {
  const {
    map_configuration: {
      autosuggest_enabled_sites = [],
      cluster_message,
      custom_controls: { map_view, zoom } = {},
      error_message: { button_retry, message } = {},
      loading_message,
      open_carousel = {},
      pagination: { selected_page, next_page, previous_page, show_pagination, page_count } = {},
      results_message,
      zrp: { zrp_message, zrp_title } = {},
      filters,
    } = {},
    new_faceted_search,
    melidata_track,
  } = useSearch();

  return {
    autosuggestEnabledSites: autosuggest_enabled_sites,
    badgeText: results_message,
    controls: { mapType: map_view, zoom },
    clusterText: cluster_message,
    error: { action: button_retry, message },
    loadingText: loading_message,
    pagination: {
      currentPage: selected_page,
      nextPage: next_page,
      previousPage: previous_page,
      showPagination: show_pagination,
      pageCount: page_count,
    },
    track: {
      openCarousel: buildTrackData(open_carousel),
    },
    zrp: { message: zrp_message, title: zrp_title },
    filters,
    searchTrack: melidata_track,
    newFacetedSearch: new_faceted_search,
  };
};

module.exports = useMapConfig;
