const React = require('react');
const { shape, string, arrayOf, bool, number, oneOfType } = require('prop-types');

const usePrefetch = require('./use-prefetch');
const { PREFETCH_THRESHOLD, ARROW_DIRECTIONS } = require('./constants');
const { ArrowButton, Container, Content, Node } = require('./components');

const Pagination = ({ className, nextPage, previousPage, paginationNodes, pageCount }) => {
  const ref = usePrefetch(nextPage.url, PREFETCH_THRESHOLD);

  return (
    <Content className={className} ref={ref}>
      {previousPage.show && (
        <ArrowButton direction={ARROW_DIRECTIONS.BACK} title={previousPage.value} href={previousPage.url} />
      )}

      {paginationNodes.map(({ url, value, is_actual_page }) => (
        <Node isActualPage={is_actual_page} key={url} url={url} value={value} />
      ))}
      <li className="andes-pagination__page-count">de {pageCount}</li>

      {nextPage.show && <ArrowButton direction={ARROW_DIRECTIONS.NEXT} title={nextPage.value} href={nextPage.url} />}
    </Content>
  );
};

Pagination.propTypes = {
  className: string,
  nextPage: shape({
    url: string,
  }).isRequired,
  pageCount: string.isRequired,
  paginationNodes: arrayOf(
    shape({
      value: oneOfType([number, string]),
      url: string,
      is_actual_page: bool,
    }),
  ).isRequired,
  previousPage: shape({
    url: string,
  }).isRequired,
};

Pagination.defaultProps = {
  className: '',
};

const DesktopPagination = () => <Container Content={Pagination} />;

module.exports = DesktopPagination;
