const LEVELS = [
  { id: 1, text: 'Rojo' },
  { id: 2, text: 'Naranja' },
  { id: 3, text: 'Amarillo' },
  { id: 4, text: 'Verde Claro' },
  { id: 5, text: 'Verde' },
];

module.exports = {
  LEVELS,
};
