const React = require('react');
const { useState } = require('react');
const { shape, string, arrayOf } = require('prop-types');

const cx = require('classnames');

const { NAMESPACE, MAP_TYPE } = require('./constants');

const componentClass = cx(NAMESPACE, `${NAMESPACE}-button`, `${NAMESPACE}-map-type-button`);

const getNextIndexInBounds = (currentIndex, length) => (currentIndex + 1) % length;

const useMapType = (mapInstance, mapTypes) => {
  const [currentIndex, setTypeIndex] = useState(0);
  const nextIndex = getNextIndexInBounds(currentIndex, mapTypes.length);
  const nextType = mapTypes[nextIndex];

  return {
    nextType,
    toggleType: () => {
      mapInstance.setMapTypeId(nextType);
      setTypeIndex(nextIndex);
    },
  };
};

const MapTypeControl = ({ map, types, labels }) => {
  const { nextType, toggleType } = useMapType(map, types);
  const nextTypeLabel = labels[nextType] || nextType;

  return (
    <div className={`${NAMESPACE}-button-container`}>
      <button type="button" onClick={toggleType} className={componentClass}>
        {nextTypeLabel}
      </button>
    </div>
  );
};

MapTypeControl.propTypes = {
  labels: shape({}),
  map: shape({}).isRequired,
  types: arrayOf(string),
};

MapTypeControl.defaultProps = {
  types: [MAP_TYPE.ROADMAP, MAP_TYPE.SATELLITE],
  labels: {},
};

module.exports = MapTypeControl;
