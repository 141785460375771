const { useState } = require('react');

const SearchUrlService = require('../../services/search-url');

const useLazyCategoryId = (siteId) => {
  const [categoryId, setCategoryId] = useState(null);

  const getCategoryId = () => {
    if (categoryId) {
      return Promise.resolve(categoryId);
    }

    return SearchUrlService.readSearchUrl(window.location.href, siteId).then(({ data: { category } }) => {
      setCategoryId(category);

      return category;
    });
  };

  return { getCategoryId };
};

module.exports = useLazyCategoryId;
