/* eslint-disable jsx-a11y/prefer-tag-over-role */
/**
 * Module dependencies
 */
const React = require('react');
const { string, func, bool } = require('prop-types');

const cx = require('classnames');

const { ARROW_DIRECTIONS } = require('../constants');
const Link = require('../../link');

const { BACK, NEXT } = ARROW_DIRECTIONS;

const Title = ({ title }) => <span className="andes-pagination__arrow-title">{title}</span>;

const ArrowButton = ({ disabled, direction, title, handleChange, href }) => (
  <li
    className={cx(
      'andes-pagination__button',
      `andes-pagination__button--${direction}`,
      { 'andes-pagination__button--disabled': disabled },
      'shops__pagination-button',
    )}
  >
    <Link
      href={href}
      role="button"
      className={cx('andes-pagination__link', 'shops__pagination-link')}
      onClick={handleChange}
      title={title}
    >
      {direction === NEXT && <Title title={title} />}
      <span className="andes-pagination__arrow" />
      {direction === BACK && <Title title={title} />}
    </Link>
  </li>
);

ArrowButton.propTypes = {
  direction: string.isRequired,
  disabled: bool,
  handleChange: func,
  href: string,
  title: string,
};

Title.propTypes = {
  title: string.isRequired,
};

ArrowButton.defaultProps = {
  disabled: false,
  href: '',
  title: '',
  handleChange: null,
};

module.exports = ArrowButton;
