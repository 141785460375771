const React = require('react');
const { useContext } = require('react');
const { string, arrayOf, shape, number } = require('prop-types');

const useVehicleBodySelector = require('./use-vehicle-body-selector');
const LayoutContext = require('../context/layout');
const useCarouselBreakpoints = require('../../hooks/use-carousel-breakpoints');
const VehicleBodyItem = require('./vehicle-body-item');
const VehicleBodyViewMore = require('./vehicle-body-viewmore');

const VEHICLE_BODY_SELECTOR = 'VEHICLE_BODY_SELECTOR';

const namespace = 'ui-search-vehicle-body-selector';

const VehicleBodySelector = ({ image, values, modal, total_body_types }) => {
  const { currentLayout } = useContext(LayoutContext);
  const { slidesToShow, viewMoreText } = useCarouselBreakpoints(
    VEHICLE_BODY_SELECTOR,
    currentLayout.id,
    total_body_types,
  );

  return (
    <div className={namespace}>
      <ul>
        {values
          .filter((_, index) => index < slidesToShow)
          .map((value) => (
            <VehicleBodyItem key={value.id} value={value} image={image} />
          ))}
        {viewMoreText && <VehicleBodyViewMore modal={modal} text={viewMoreText} />}
      </ul>
    </div>
  );
};

VehicleBodySelector.propTypes = {
  image: string.isRequired,
  modal: shape().isRequired,
  total_body_types: number.isRequired,
  values: arrayOf().isRequired,
};

const VehicleBodySelectorContainer = () => {
  const vehicleBodySelector = useVehicleBodySelector();

  return vehicleBodySelector ? <VehicleBodySelector {...vehicleBodySelector} /> : null;
};

module.exports = VehicleBodySelectorContainer;
module.exports.VehicleBodySelector = VehicleBodySelector;
