const React = require('react');
const { bool, string } = require('prop-types');

const cx = require('classnames');
const { Pill } = require('@andes/badge');
const ProgressIndicatorCircular = require('@andes/progress-indicator-circular');

const useMapConfig = require('../hooks/use-map-config');

const namespace = 'ui-search-map-header';

const Header = ({ badgeText, loadingText, isLoadingResults }) => (
  <div className={`${namespace}__container`}>
    <Pill className={cx(`${namespace}__pill`, { [`${namespace}__pill--loading`]: isLoadingResults })} color="gray">
      {isLoadingResults && <ProgressIndicatorCircular modifier="inline" size="xsmall" />}
      <span className={cx({ [`${namespace}__pill-text--loading`]: isLoadingResults })}>
        {isLoadingResults ? loadingText : badgeText}
      </span>
    </Pill>
  </div>
);

Header.propTypes = {
  badgeText: string,
  isLoadingResults: bool,
  loadingText: string,
};

Header.defaultProps = {
  badgeText: '',
  loadingText: '',
  isLoadingResults: false,
};

const HeaderContainer = ({ isLoadingResults }) => {
  const { badgeText, loadingText } = useMapConfig();

  return <Header badgeText={badgeText} loadingText={loadingText} isLoadingResults={isLoadingResults} />;
};

HeaderContainer.propTypes = {
  isLoadingResults: bool,
};

HeaderContainer.defaultProps = {
  isLoadingResults: false,
};

module.exports = HeaderContainer;
module.exports.Header = Header;
