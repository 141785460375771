const React = require('react');
const { Children, cloneElement, useEffect, useRef } = require('react');
const { createPortal } = require('react-dom');
const { string, shape, node } = require('prop-types');

const cx = require('classnames');

const { CONTROL_POSITION, NAMESPACE } = require('./constants');
const { getControlPosition } = require('../helpers');

const CustomControls = ({ map, position, children }) => {
  const controlRef = useRef(document.createElement('div'));

  useEffect(() => {
    const mapControls = map.controls[getControlPosition(position)];
    const controlIndex = mapControls.length;

    mapControls.push(controlRef.current);

    return () => mapControls.splice(controlIndex, 1);
  }, [map, position, controlRef]);

  if (!controlRef) {
    return null;
  }

  return createPortal(
    <div className={cx(NAMESPACE, `${NAMESPACE}-custom-controls`)}>
      {Children.map(children, (child) => cloneElement(child, { map }))}
    </div>,
    controlRef.current,
  );
};

CustomControls.propTypes = {
  children: node.isRequired,
  map: shape({}).isRequired,
  position: string,
};

CustomControls.defaultProps = {
  position: CONTROL_POSITION.rightBottom,
};

module.exports = CustomControls;
