const React = require('react');
const PropTypes = require('prop-types');

const Button = require('@andes/button');
const Card = require('@andes/card');
const Image = require('nordic/image');

const IconPlay = require('../../../icons/play');
const HighlightLabel = require('../../../card/highlight/highlight-label');
const Link = require('../../../link');

const className = 'ui-search-meliplay-intervention--grid';

const MeliPlayIntervention = ({ intervention, position, imageGridHeight }) => {
  const { item } = intervention;
  const { title, play_button, description, cover_image, additional_text, highlight } = item;

  return (
    <Card className={className} component="li">
      <div style={{ height: `${imageGridHeight}px` }} className={`${className}__cover`}>
        <Link
          title={title}
          alt={title}
          isInternal={false}
          href={play_button.url}
          className={`${className}__cover--link`}
        >
          <Image src={cover_image} preload={position < 4} alt={title} className={`${className}__cover--image`} />
        </Link>
      </div>
      <section className={`${className}__data`}>
        <Link title={title} isInternal={false} href={play_button.url}>
          <span>
            <h2 className={`${className}__title`}>{title}</h2>
            <p className={`${className}__description`}>{description}</p>
          </span>
          <span className={`${className}__label`}>
            <HighlightLabel {...highlight} />
            <p className={`${className}__label-text`}>{additional_text}</p>
          </span>
        </Link>
        <Button href={play_button.url} size="medium" hierarchy="transparent" className={`${className}__button`}>
          <IconPlay />
          {play_button.text}
        </Button>
      </section>
    </Card>
  );
};

module.exports = MeliPlayIntervention;

MeliPlayIntervention.propTypes = {
  imageGridHeight: PropTypes.number,
  intervention: PropTypes.shape({
    item: PropTypes.shape({
      additional_text: PropTypes.string,
      cover_image: PropTypes.string,
      description: PropTypes.string,
      highlight: PropTypes.shape({}),
      play_button: PropTypes.shape({
        text: PropTypes.string,
        url: PropTypes.string,
      }),
      title: PropTypes.string,
    }).isRequired,
  }),
  position: PropTypes.number,
};
