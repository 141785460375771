const React = require('react');
const { arrayOf, bool, func, shape, string, number } = require('prop-types');

const Header = require('./header');
const FullMap = require('./full-map');
const { useMapState } = require('./hooks/use-map-state');
const useMapConfig = require('./hooks/use-map-config');
const { trackClickEvent } = require('./track');

const MapMobile = ({
  fitMarkersInMap,
  className,
  isLoadingResults,
  markers,
  updateResults,
  fixedCoords,
  selectedMarker,
  setSelectedMarker,
  showError,
}) => {
  const { handleDragEnd, handleMapLoad, handleZoomChanged, handleBoundsChanged, mapStatus } = useMapState({
    fitMarkersInMap,
    isLoadingResults,
    markers,
    updateResults,
    fixedCoords,
    selectedMarker,
    setSelectedMarker,
    panToSelectedMarker: true,
  });
  const {
    track: {
      openCarousel: { analytics, melidata },
    },
  } = useMapConfig();
  const handleMarkerClick = (markerIndex) => {
    trackClickEvent(analytics, melidata);
    setSelectedMarker(markerIndex);
  };

  return (
    <FullMap
      className={className}
      mapStatus={mapStatus}
      markers={markers}
      handleBoundsChanged={handleBoundsChanged}
      handleMapLoad={handleMapLoad}
      handleDragEnd={handleDragEnd}
      handleZoomChanged={handleZoomChanged}
      handleMapClick={() => setSelectedMarker(null)}
      handleMarkerClick={handleMarkerClick}
      selectedMarker={selectedMarker}
    >
      {!showError && <Header isLoadingResults={isLoadingResults} />}
    </FullMap>
  );
};

MapMobile.propTypes = {
  className: string,
  fitMarkersInMap: bool,
  fixedCoords: arrayOf(shape({ lat: number, lng: number })),
  isLoadingResults: bool,
  markers: arrayOf(shape()),
  selectedMarker: number,
  setSelectedMarker: func.isRequired,
  showError: bool,
  updateResults: func.isRequired,
};

MapMobile.defaultProps = {
  className: null,
  fitMarkersInMap: false,
  fixedCoords: undefined,
  isLoadingResults: false,
  markers: [],
  selectedMarker: null,
  showError: false,
};

module.exports = MapMobile;
