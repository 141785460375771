/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { arrayOf, bool, shape, string } = require('prop-types');
const { useState, useEffect } = require('react');

const { Pill } = require('@andes/badge');
const classnames = require('classnames');
const ProgressIndicatorCircular = require('@andes/progress-indicator-circular');

const IconClose = require('../icons/close');
const { handleClickTrackEvents } = require('../../lib/handleClickTrackEvents');
const useSuggestions = require('./use-suggestions');

const namespace = 'ui-search-suggestions';

const Suggestions = ({ suggestions, itemId, currentItemSuggestion, cartFetching }) => {
  const [animatedOpen, setAnimated] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isFeching, setIsFeching] = useState(false);

  const handleClose = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (animatedOpen) {
      setIsClosing(true);
      setIsFeching(false);
      setAnimated(false);
      setTimeout(() => setIsOpen(false), 250);
    }
  };

  useEffect(() => {
    if (currentItemSuggestion === itemId && !isClosing) {
      setIsOpen(true);
      setIsFeching(cartFetching);
      setTimeout(() => {
        setAnimated(true);
      }, 250);
    } else if (animatedOpen && !isClosing) {
      handleClose();
    }
  }, [currentItemSuggestion, suggestions, cartFetching, isClosing, animatedOpen, isOpen]);

  return isOpen ? (
    <>
      <div
        className={classnames(
          `${namespace}__wrapper`,
          { [`${namespace}__wrapper--animated-open`]: animatedOpen },
          { [`${namespace}__wrapper--animated-close`]: isClosing },
        )}
      >
        <div className={classnames(namespace)}>
          <div className={`${namespace}__wrapper-tag`}>
            <div className={`${namespace}__header`}>
              <div className={`${namespace}__header__notification`}>
                <Pill className={`${namespace}__header__icon`} color="green" size="small" contentType="icon" />
                <h3 className={`${namespace}__header__title`}>{suggestions.title.text}</h3>
              </div>
              <button className={`${namespace}__close`} onClick={handleClose} type="button" tabIndex={0}>
                <IconClose />
              </button>
            </div>
            <h3 className={`${namespace}__subtitle`}>{suggestions.subtitle.text}</h3>
            <ul className={`${namespace}__list`}>
              {suggestions.tags.map((tag, index) => {
                const handleClick = handleClickTrackEvents(tag);

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={`${tag.category_id}-${index}`} className={`${namespace}__tag__item`}>
                    <a className={`${namespace}__tag`} onClick={handleClick} href={tag.url}>
                      {tag.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      {isFeching && currentItemSuggestion === itemId && (
        <>
          <ProgressIndicatorCircular className={`${namespace}__spinner`} modifier="inline" size="small" />
          <div className={`${namespace}__fade`} />
        </>
      )}
    </>
  ) : null;
};

Suggestions.propTypes = {
  cartFetching: bool.isRequired,
  currentItemSuggestion: string,
  itemId: string.isRequired,
  suggestions: shape({
    subtitle: shape({
      text: string,
    }),
    tags: arrayOf(
      shape({
        category_id: string,
        deeplink: string,
        name: string,
        tracks: shape({
          analytics_track: shape({
            action: string,
            category: string,
            label: string,
          }),
        }),
        url: string,
      }),
    ),
    title: shape({
      text: string,
    }),
    tracks: shape({
      analytics_track: shape({
        action: string,
        category: string,
        label: string,
      }),
      melidata_track: shape({
        clean: bool,
        event_data: shape(),
        path: string,
        type: string,
      }),
    }),
  }).isRequired,
};

Suggestions.defaultProps = {
  currentItemSuggestion: null,
};

module.exports = Suggestions;

const SuggestionsContainer = (props) => {
  const { suggestions, cartFetching, openSuggestions } = useSuggestions();

  return openSuggestions ? <Suggestions suggestions={suggestions} cartFetching={cartFetching} {...props} /> : null;
};

module.exports = SuggestionsContainer;
module.exports.Snackbar = Suggestions;
