/**
 * Module dependencies
 */
const React = require('react');
const { useEffect } = require('react');
const { string } = require('prop-types');

const { LazyHydrate: Hydrator } = require('nordic/lazy');
const classnames = require('classnames');
const CPGDesktop = require('cpg-nordic/lib/CPGDesktop').default;

const Search = require('./main');
const Header = require('../../../../../components/header/chunk-header.desktop');
const Banner = require('../../../../../components/banner/banner.desktop');
const TopKeywords = require('../../../../../components/top-keywords/top-keywords.desktop');
const SideBar = require('../../../../../components/sidebar/sidebar.desktop');
const Pagination = require('../../../../../components/pagination/pagination.desktop');
const ViewOptions = require('../../../../../components/sidebar/components/view-options/view-options.desktop');
const Billboard = require('../../../../../components/billboard/chunk-billboard.desktop');
const LayoutManager = require('../../../../../components/layout/manager.desktop');
const SnackBar = require('../../../../../components/snackbar/snackbar');
const Carousel = require('../../../../../components/carousel-search/chunk-carousel.desktop');
const CarouselAndes = require('../../../../../components/carousel-andes-search/chunk-carousel.desktop');
const CrossSiteModal = require('../../../../../components/cross-site-modal/chunk-cross-site');
const BottomAds = require('../../../../../components/bottom-ads/bottom-ads');
const ListingDisclaimer = require('../../../../../components/listing-disclaimer/chunk-listing-disclaimer.mobile');
const FooterDisclaimer = require('../../../../../components/footer-disclaimer/chunk-footer-disclaimer');
const VehicleBodySelector = require('../../../../../components/vehicle-body-selector/vehicle-body-selector');
const ZrpDisclaimer = require('../../../../../components/zrp-disclaimer/chunk-zrp-disclaimer');
const FullShipping = require('../../../../../components/full-snackbar/full-freeshipping.desktop');
const FullFreeshippingSwitcher = require('../../../../../components/full-snackbar/full-freeshipping-switcher');
const PricingBanner = require('../../../../../components/pricing-banner/chunk-pricing-banner');
const BoletoDiscountMessage = require('../../../../../components/boleto-discount-message/chunk-discount-message');
const ValuePropositionsDetails = require('../../../../../components/value-propositions-details/chunk-propositions-details');
const HeaderComponent = require('../../../../../components/header-component');
const FooterComponents = require('../../../../../components/footer-components/footer-components');
const useElementSmoothScroll = require('../../../../../hooks/use-element-smooth-scroll');
const { trackSellerPixelsInfo } = require('../../../../../lib/tracking');
const { HEADER_TYPES } = require('../../../../../components/header/constants');
const { MAIN_VARIANTS } = require('./constants');
const { LAYOUTS } = require('../../../../../constants');

const namespace = 'ui-search-main';
const sectionNamespace = 'ui-search-results';

const getMainVariant = ({ headerType }) => {
  switch (headerType) {
    case HEADER_TYPES.OFFICIAL_STORE:
      return MAIN_VARIANTS.OFFICIAL_STORE;
    case HEADER_TYPES.DEAL:
      return MAIN_VARIANTS.DEALS;
    case HEADER_TYPES.EXHIBITOR:
      return MAIN_VARIANTS.EXHIBITOR;

    default:
      return null;
  }
};

const getSectionVariationClass = (initialState) => {
  if (initialState.layout_options.current === LAYOUTS.TYPE_MAP) {
    return `${sectionNamespace}--map`;
  }

  if (initialState.listing_disclaimer) {
    return classnames(sectionNamespace, `${sectionNamespace}--without-disclaimer`, 'shops__search-results');
  }

  return sectionNamespace;
};

/**
 * View Component
 */
const SearchDesktop = ({ baseURL, ...rest }) => {
  const { initialState } = rest;
  const { shops, carousel, billboard, vehicle_body_selector, sidebar } = initialState;
  const headerType = initialState?.header?.type;
  const mainVariant = getMainVariant({ headerType });
  const sectionClass = getSectionVariationClass(rest.initialState);
  const withoutHeaderClass = rest.initialState.top_keywords ? '' : `${namespace}--without-header`;
  const onlyProductsItemsClass = carousel || billboard || vehicle_body_selector ? '' : `${namespace}--only-products`;
  const withoutBreadcrumbClass = sidebar?.components?.find((c) => c.type === 'BREADCRUMB')
    ? ''
    : `${namespace}--without-breadcrumb`;
  const isMapLayout = initialState.layout_options.current === LAYOUTS.TYPE_MAP;
  const withTopKeyWords = rest.initialState.top_keywords ? `${namespace}--with-topkeywords` : '';

  const container = useElementSmoothScroll(isMapLayout);

  useEffect(() => {
    if (shops && window.tracking_mediator) {
      trackSellerPixelsInfo(window.tracking_mediator, initialState.results);
    }
  }, [initialState.results, shops]);

  return (
    <Search baseURL={baseURL} {...rest} platformId="STD">
      <FullFreeshippingSwitcher
        defaultComponent={<FullShipping disabledByCookie={initialState.fullFreeShippingDisabled} />}
      />

      {initialState.cpg_header && <CPGDesktop {...initialState.cpg_header} />}
      <Hydrator whenVisible>
        <Header />
      </Hydrator>
      {!isMapLayout && <TopKeywords />}

      <div
        className={classnames(
          namespace,
          mainVariant && `${namespace}--${mainVariant}`,
          withoutHeaderClass,
          withoutBreadcrumbClass,
          onlyProductsItemsClass,
          withTopKeyWords,
          'shops__search-main',
        )}
      >
        <SideBar />
        <section className={sectionClass} ref={container}>
          <Hydrator whenVisible>
            <HeaderComponent />
          </Hydrator>
          <ViewOptions key={`${namespace}-sort-filter`} />
          <Banner />
          <Hydrator whenVisible>{carousel && (carousel.type === 'CPG' ? <CarouselAndes /> : <Carousel />)}</Hydrator>
          <Hydrator whenVisible>
            <ZrpDisclaimer />
          </Hydrator>
          <Hydrator whenVisible>
            <Billboard />
          </Hydrator>
          <VehicleBodySelector />
          <Hydrator ssrOnly>
            <PricingBanner />
          </Hydrator>
          <Hydrator whenVisible>
            <BoletoDiscountMessage />
          </Hydrator>
          <LayoutManager />
          <Hydrator ssrOnly>
            <ListingDisclaimer />
          </Hydrator>
          <Pagination />
          <Hydrator ssrOnly>
            <FooterDisclaimer />
          </Hydrator>
          <SnackBar />
          <Hydrator whenVisible>
            <CrossSiteModal />
          </Hydrator>
          <Hydrator whenVisible>
            <ValuePropositionsDetails />
          </Hydrator>
        </section>
      </div>
      {isMapLayout && <TopKeywords />}
      <BottomAds />
      <FooterComponents />
    </Search>
  );
};

SearchDesktop.propTypes = {
  baseURL: string.isRequired,
};

/**
 * Inject i18n context as props into View.
 */
module.exports = SearchDesktop;
