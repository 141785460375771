const React = require('react');
const { string, shape, arrayOf } = require('prop-types');

const classnames = require('classnames');

const HighlightLabel = require('../highlight/highlight-label');

const namespace = 'ui-search-value-proposition';

/* eslint-disable react/no-array-index-key */
const ValueProposition = ({ className, id, pills, styled_icon: icon }) => (
  <div className={classnames(namespace, className, { [`${namespace}--${id}`]: !!id })}>
    <div className={`${namespace}__pills`}>
      {pills.map((pill, index) => (
        <HighlightLabel key={`${pill.type}-${index}`} className={`${namespace}__pill`} icon={icon} {...pill} />
      ))}
    </div>
  </div>
);

/* eslint-enable */
ValueProposition.propTypes = {
  className: string,
  id: string,
  pills: arrayOf(
    shape({
      type: string,
      icon: shape(),
      label: shape(),
    }),
  ).isRequired,
  styled_icon: shape({
    id: string,
    color: string,
  }),
};

ValueProposition.defaultProps = {
  id: null,
  className: null,
  styled_icon: { highlight_position: 'left' },
};

module.exports = ValueProposition;
