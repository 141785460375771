const { ZOOM_LEVELS } = require('./controls/constants');

const EVENTS = {
  DRAG_END: 'dragend',
  ZOOM_CHANGED: 'zoom_changed',
  BOUNDS_CHANGED: 'bounds_changed',
  CLICK: 'click',
  ZINDEX_CHANGED: 'zindex_changed',
  IDLE: 'idle',
  MOUSE_OVER: 'mouseover',
  MOUSE_OUT: 'mouseout',
};

const MAP_POI_ICON_COLOR = '#a2b3be';
const MAP_POI_LABEL_COLOR = '#889caa';
const MAP_POI_TRANSIT_LABEL_COLOR = '#798f9c';

const DEBOUNCE_MILLISECONDS = 100;

const DEFAULT_MAP_OPTIONS = {
  disableDefaultUI: true,
  zoom: ZOOM_LEVELS.DEFAULT,
  minZoom: ZOOM_LEVELS.MIN,
  maxZoom: ZOOM_LEVELS.MAX,
  clickableIcons: false,
  gestureHandling: 'greedy',
  tilt: 0,
  styles: [
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: MAP_POI_LABEL_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.attraction',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.business',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.government',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.medical',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi.place_of_worship',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.school',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'poi.sports_complex',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.icon',
      stylers: [
        {
          color: MAP_POI_ICON_COLOR,
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: MAP_POI_TRANSIT_LABEL_COLOR,
        },
      ],
    },
  ],
};

const INFOWINDOW_SIZE = {
  width: 200,
  height: 300,
};

const INFOWINDOW_MARGIN = 12;

const DEFAULT_CLUSTER_OPTIONS = {
  radius: 1,
  minZoom: 18,
  maxZoom: 18,
};

const GEOJSON_TYPES = {
  FEATURE: 'Feature',
  POINT: 'Point',
};

const INITIAL_INDEXED_CLUSTERS = {
  clusters: [],
  indices: {},
};

const LEAVES_PAGINATION = Infinity;

const MAP_LANGUAGES = {
  BRAZIL: 'pt-BR',
  LATIN_AMERICA: 'es-419',
};

const LANGUAGE_TO_MAP_LANGUAGE = {
  es: MAP_LANGUAGES.LATIN_AMERICA,
  pt: MAP_LANGUAGES.BRAZIL,
};

module.exports = {
  DEFAULT_CLUSTER_OPTIONS,
  DEFAULT_MAP_OPTIONS,
  INFOWINDOW_SIZE,
  INFOWINDOW_MARGIN,
  EVENTS,
  GEOJSON_TYPES,
  INITIAL_INDEXED_CLUSTERS,
  LEAVES_PAGINATION,
  MAP_LANGUAGES,
  LANGUAGE_TO_MAP_LANGUAGE,
  DEBOUNCE_MILLISECONDS,
};
