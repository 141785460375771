const React = require('react');
const { node, string, func, bool, shape } = require('prop-types');

const AndesModal = require('@andes/modal');
const classnames = require('classnames');

const Link = require('../link');

const namespace = 'ui-search-modal';

const Modal = ({
  actions,
  children,
  className,
  componentAsLabel,
  headerSuffix,
  icon,
  label,
  modalClassName,
  modalTitle,
  modalUrl,
  closeText,
  url,
  hideModal,
  openModal,
  isOpen,
  type,
  withLink,
  focusTab,
  ...rest
}) => (
  <>
    {withLink && (
      <Link
        className={classnames(`${namespace}__link`, className)}
        href={focusTab && !url ? ' ' : url}
        onClick={openModal}
      >
        {icon && <div className={classnames(className, { [`${namespace}__icon`]: !componentAsLabel })}>{icon}</div>}
        {label || componentAsLabel}
      </Link>
    )}
    <AndesModal
      actions={actions}
      headerSuffix={headerSuffix}
      closeButtonSrLabel="close"
      url={modalUrl}
      type={type}
      className={classnames(namespace, modalClassName, className, 'shops__modal')}
      open={isOpen}
      onClose={hideModal}
      title={modalTitle}
      closeText={closeText}
      showCloseButton
      {...rest}
    >
      {children}
    </AndesModal>
  </>
);

Modal.propTypes = {
  actions: shape({
    buttonFilled: node,
  }),
  children: node.isRequired,
  className: string,
  closeText: string,
  componentAsLabel: node,
  focusTab: bool,
  headerSuffix: string,
  hideModal: func,
  icon: node,
  isOpen: bool,
  label: string.isRequired,
  modalClassName: string,
  modalTitle: string,
  modalUrl: string,
  openModal: func,
  type: string,
  url: string,
  withLink: bool,
};

Modal.defaultProps = {
  actions: null,
  className: '',
  closeText: '',
  componentAsLabel: null,
  headerSuffix: '',
  icon: null,
  linkClassName: '',
  modalClassName: '',
  modalTitle: '',
  modalUrl: '',
  url: '',
  isOpen: false,
  openModal: null,
  hideModal: null,
  type: 'full',
  withLink: false,
  focusTab: false,
};

module.exports = Modal;
