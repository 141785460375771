/* eslint-disable jsx-a11y/click-events-have-key-events */

const React = require('react');
const { string, shape, bool } = require('prop-types');

const classnames = require('classnames');

const Link = require('../../link');
const { trackEvent } = require('../../../lib/tracking');
const { GRID, GALLERY } = require('../../layout/renderer/constants');

const namespace = 'ui-search-official-store-label';

const handleStoreRedirect = (event, permalink) => {
  event.preventDefault();

  if (permalink) {
    window.location.href = permalink;
  }
};

function trackingClick(tracks) {
  trackEvent(tracks);
}

function capitalize(stringSet) {
  if (stringSet) {
    return stringSet.charAt(0).toUpperCase() + stringSet.slice(1);
  }

  return null;
}

const renderTypeLabel = (className, label, permalink, shouldRenderLinks, title, tracks, isAd, layout) => {
  const officialStoreText = capitalize(label.text.split(' ')?.slice(1)?.join(' '));

  const labelContent = (
    <p
      className={classnames(namespace, className, `ui-search-color--${label.color}`)}
      onClick={shouldRenderLinks ? undefined : (e) => handleStoreRedirect(e, permalink)}
    >
      {layout === GRID || layout === GALLERY ? officialStoreText : label.text}
    </p>
  );

  if (permalink && shouldRenderLinks) {
    return (
      <Link
        className="ui-search-official-store-item__link"
        href={permalink}
        title={title}
        isAd={isAd}
        onClick={() => {
          trackingClick(tracks);
        }}
      >
        {labelContent}
      </Link>
    );
  }

  return labelContent;
};

const OfficialStoreLabel = ({ className, label, permalink, shouldRenderLinks, title, tracks, isAd, layout }) =>
  label.text !== null && label.text !== undefined
    ? renderTypeLabel(className, label, permalink, shouldRenderLinks, title, tracks, isAd, layout)
    : null;

OfficialStoreLabel.propTypes = {
  className: string,
  isAd: bool,
  label: shape({
    text: string,
    color: string.isRequired,
  }).isRequired,
  permalink: string,
  shouldRenderLinks: bool,
  title: string,
  tracks: shape({
    melidata_track: string,
    analytics_track: string,
  }),
};

OfficialStoreLabel.defaultProps = {
  className: null,
  permalink: null,
  shouldRenderLinks: false,
  tracks: null,
};

module.exports = OfficialStoreLabel;
