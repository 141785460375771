const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--chevron';

const IconChevron = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
    <path fill="none" fillRule="evenodd" d="M1 1.343L6.657 7 1 12.657" />
  </svg>
);

IconChevron.propTypes = {
  className: string,
};

IconChevron.defaultProps = {
  className: null,
};

module.exports = React.memo(IconChevron);
