/* eslint-disable react/prop-types */
const React = require('react');

const StaticPropsContext = require('../context/static-props');
const LayoutContext = require('../context/layout');
const { linkPropTypes } = require('./constants');
const { buildNewHref, buildProps } = require('./link-utils');

const namespace = 'ui-search-link';

const NO_INDEX_PARAM = '_NoIndex_True';
const shouldAddNoFollow = (href) => href && href.indexOf(NO_INDEX_PARAM) !== -1;

const Link = ({
  children,
  className,
  href,
  title,
  role,
  onClick,
  isInternal,
  keepLayout,
  ariaLabel,
  ariaLabelledBy,
  target,
  tracks,
  linkTag,
  isAd,
  adLabel,
}) => {
  const { queryParams } = React.useContext(StaticPropsContext);
  const { currentLayout, initialLayout } = React.useContext(LayoutContext);
  const newHref = buildNewHref({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout });
  const addNoFollow = shouldAddNoFollow(href) || adLabel;
  const linkProps = buildProps({
    linkTag,
    newHref,
    href,
    className,
    namespace,
    title,
    role,
    onClick,
    target,
    ariaLabel,
    ariaLabelledBy,
    tracks,
    shouldAddNoFollow: addNoFollow,
    isAd,
  });

  return React.createElement(linkTag, { ...linkProps }, children);
};

Link.propTypes = linkPropTypes;

Link.defaultProps = {
  className: '',
  href: '',
  linkTag: 'a',
  title: null,
  role: null,
  onClick: null,
  isInternal: true,
  keepLayout: true,
  ariaLabel: null,
  ariaHidden: null,
  tabIndex: null,
  ariaLabelledBy: null,
  tracks: null,
  target: null,
};

module.exports = Link;
