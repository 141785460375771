const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const ICON_ID = 'ic_gift';
const namespace = 'ui-search-icon ui-search-icon--gift';

const IconGift = ({ className }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" className={classnames(namespace, className)}>
    <path fillRule="evenodd" clipRule="evenodd" d="M5.48877 4.10871H7.48008V3.47949H5.48877V4.10871Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.81641 4.10871H8.80771V3.47949H6.81641V4.10871Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.49756 2.22119C3.49756 3.2636 4.38922 4.10885 5.48886 4.10885V3.47963C4.75606 3.47963 4.16133 2.91627 4.16133 2.22119H3.49756Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48886 0.333496C4.38922 0.333496 3.49756 1.17875 3.49756 2.22115H4.16133C4.16133 1.52607 4.75606 0.962714 5.48886 0.962714V0.333496Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.48877 0.962714C6.22201 0.962714 6.81631 1.52607 6.81631 2.22115H7.48008C7.48008 1.17875 6.58841 0.333496 5.48877 0.333496V0.962714Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7989 2.22119C10.7989 3.2636 9.90726 4.10885 8.80762 4.10885V3.47963C9.54086 3.47963 10.1352 2.91627 10.1352 2.22119H10.7989Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.80762 0.333496C9.90726 0.333496 10.7989 1.17875 10.7989 2.22115H10.1352C10.1352 1.52607 9.54086 0.962714 8.80762 0.962714V0.333496Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.80771 0.962714C8.07491 0.962714 7.48017 1.52607 7.48017 2.22115H6.81641C6.81641 1.17875 7.70807 0.333496 8.80771 0.333496V0.962714Z"
      fill="white"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.81641 4.10885H7.48017V2.22119H6.81641V4.10885Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.47635 5.94638C1.46981 5.94638 1.4633 5.94625 1.45682 5.94599C1.21341 5.93629 1.01921 5.74616 1.01921 5.51303V4.5819C1.01921 4.34257 1.22388 4.14856 1.47635 4.14856H12.524C12.7764 4.14856 12.9811 4.34257 12.9811 4.5819V5.51303C12.9811 5.71827 12.8306 5.89018 12.6285 5.935C12.6243 5.93593 12.6201 5.93681 12.6158 5.93764C12.5861 5.94337 12.5554 5.94638 12.524 5.94638H1.47635ZM1.42874 6.59548C0.81964 6.57182 0.333496 6.09624 0.333496 5.51303V4.5819C0.333496 3.98358 0.845171 3.49854 1.47635 3.49854H12.524C13.1552 3.49854 13.6668 3.98358 13.6668 4.5819V5.51303C13.6668 6.03742 13.2738 6.47479 12.7518 6.57488V13.0179C12.7518 13.0179 12.7518 13.1807 12.6285 13.3432C12.5528 13.4431 12.4305 13.5429 12.233 13.6047C12.1104 13.643 11.9588 13.6668 11.7714 13.6668H2.28058C2.16036 13.6668 2.04595 13.6446 1.94224 13.6047C1.79084 13.5464 1.66224 13.4501 1.5716 13.3294C1.4935 13.2255 1.44359 13.1033 1.43157 12.9717L1.42874 12.9096V6.59548ZM11.7714 6.5964H2.42874V12.7922L11.7714 12.7922V6.5964Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19615 6.03613C7.43284 6.03613 7.62472 6.21802 7.62472 6.4424V13.063C7.62472 13.2874 7.43284 13.4693 7.19615 13.4693C6.95946 13.4693 6.76758 13.2874 6.76758 13.063V6.4424C6.76758 6.21802 6.95946 6.03613 7.19615 6.03613Z"
      fill="white"
    />
  </svg>
);

IconGift.propTypes = {
  className: string,
};

IconGift.defaultProps = {
  className: null,
};

module.exports = React.memo(IconGift);
module.exports.ICON_ID = ICON_ID;
