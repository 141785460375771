const qs = require('querystring');

const addToCartClient = require('../client/restclient')({
  timeout: process.env.NODE_ENV === 'production' ? 5000 : 5000,
  baseURL: '/',
  retry: {
    maxRetries: 1,
    delay: 0,
  },
});
const { CART_SERVICE_BASE_PATH } = require('../constants/paths');

class Service {
  static addToCart(
    itemId,
    quantity,
    category,
    variationId,
    availableQuantity,
    action,
    isCpg,
    queryParams,
    productId,
    inventoryId,
    weightData,
    firstRequest,
    groupBy,
    threshold,
    viewMode,
  ) {
    let query = '';

    if (queryParams?.samelab) {
      query = qs.stringify(queryParams);
    }

    const cartUrl = `${CART_SERVICE_BASE_PATH}${query}`;

    return addToCartClient
      .put(cartUrl, {
        withCredentials: true,
        data: {
          item_id: itemId,
          variation_id: variationId,
          available_quantity: availableQuantity,
          quantity,
          category,
          action,
          cpg: isCpg ? 'yes' : 'no',
          product_id: productId,
          inventory_id: inventoryId,
          weight_data: weightData,
          first_request: firstRequest,
          group_by: groupBy,
          threshold,
          view_mode: viewMode,
          ...queryParams,
        },
      })
      .then((response) => response);
  }
}

module.exports = Service;
