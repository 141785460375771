const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const classNames = require('classnames');

const useTopKeywords = require('./use-top-keywords');

const namespace = 'ui-search-top-keywords';

const TopKeywords = ({ title, keywords, className }) => {
  if (!keywords.length) {
    return null;
  }

  const classes = classNames(namespace, className);
  const listItems = keywords.map((keyword) => (
    <li className={`${namespace}__item`} key={keyword.label}>
      <a className={`${namespace}__link`} href={keyword.url}>
        {keyword.label}
      </a>
    </li>
  ));

  return (
    <section className={classes}>
      <ul className={`${namespace}__list`}>
        <li className={`${namespace}__item`}>{title}</li>
        {listItems}
      </ul>
    </section>
  );
};

TopKeywords.propTypes = {
  className: string,
  keywords: arrayOf(
    shape({
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
  title: string.isRequired,
};

TopKeywords.defaultProps = {
  className: null,
  keywords: [],
};

const TopKeywordsContainer = () => {
  const top_keywords = useTopKeywords();

  return <TopKeywords {...top_keywords} />;
};

module.exports = TopKeywordsContainer;
module.exports.TopKeywords = TopKeywords;
