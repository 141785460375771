const React = require('react');
const { func } = require('prop-types');

const usePagination = require('../use-pagination');

const Container = ({ Content }) => {
  const pagination = usePagination();
  const hasPagination = pagination?.showPagination;

  return hasPagination ? <Content {...pagination} /> : null;
};

Container.propTypes = {
  Content: func.isRequired,
};

module.exports = Container;
