const { useLayout, useFilters } = require('./context');
const {
  DISPLAY_TYPES: { MAP },
  FILTERS: {
    IDS: { DISPLAY_TYPE },
  },
} = require('../constants');

const useClientSideOptions = () => {
  const { isMapLayout = false } = useLayout();
  const { filterSearch } = useFilters();
  const isClientSideSearch = isMapLayout && !!filterSearch;

  const clientSideFilters = isClientSideSearch ? { [DISPLAY_TYPE]: MAP } : {};

  return { isClientSideSearch, clientSideFilters };
};

module.exports = useClientSideOptions;
