const React = require('react');
const { func, bool, shape, string } = require('prop-types');

const classnames = require('classnames');
const Card = require('@andes/card');

const { MainImage } = require('../common/main-image');
const Link = require('../../../../link');
const { useStaticProps } = require('../../../../context/static-props');
const { namespace } = require('../../constants');
const { verticalContentRenderer } = require('../vertical-content');

const InfowindowItem = ({ item, options, onClick }) => {
  const { deviceType } = useStaticProps();
  const { title, permalink, pictures } = item;

  return (
    <Card animated={false} className={classnames(namespace, `${namespace}__infowindow`)}>
      <div className={`${namespace}__wrapper`}>
        <Link
          className={`${namespace}__infowindow-image-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
          onClick={onClick}
        >
          <MainImage pictures={pictures} item={item} options={options} />
        </Link>
        <Link
          className={`${namespace}__content-link`}
          href={permalink}
          title={title}
          isInternal={false}
          target="_blank"
          onClick={onClick}
        >
          {verticalContentRenderer(item, deviceType)}
        </Link>
      </div>
    </Card>
  );
};

InfowindowItem.propTypes = {
  item: shape({
    title: string.isRequired,
    permalink: string.isRequired,
    vertical: string.isRequired,
    pictures: shape({}),
  }).isRequired,
  options: shape({
    lowEnd: bool,
  }),
  onClick: func,
};

InfowindowItem.defaultProps = {
  options: undefined,
};

module.exports = {
  InfowindowItem: React.memo(InfowindowItem),
};
