const { useContext } = require('react');

const { useSearch } = require('../../hooks/context');
const LayoutContext = require('../context/layout');

const useListingDisclaimer = () => {
  const { listing_disclaimer } = useSearch();
  const { currentLayout } = useContext(LayoutContext);

  if (listing_disclaimer) {
    listing_disclaimer.currentLayout = currentLayout;
  }

  return listing_disclaimer;
};

module.exports = useListingDisclaimer;
