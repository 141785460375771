const React = require('react');
const { bool, node, string, objectOf, arrayOf, any, func } = require('prop-types');

const classnames = require('classnames');
const CarouselPaged = require('ui-library_ml/ui/carousel');

const namespace = 'ui-search-carousel';

const Carousel = ({ slides, settings, className, slideChange }) => (
  <div className={classnames(namespace, 'swiper-container', className)}>
    <CarouselPaged
      {...settings}
      slides={slides}
      afterChange={(index) => {
        slideChange(index);
      }}
    />
  </div>
);

Carousel.propTypes = {
  children: node,
  className: string,
  rebuildOnUpdate: bool,
  settings: objectOf(any).isRequired,
  shouldSwiperUpdate: bool,
  slideChange: func.isRequired,
  slides: arrayOf().isRequired,
};

Carousel.defaultProps = {
  children: null,
  className: '',
  rebuildOnUpdate: false,
  shouldSwiperUpdate: false,
  settings: {},
};

module.exports = Carousel;
