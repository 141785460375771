const url = require('url');

const classnames = require('classnames');
const omit = require('lodash/omit');

const { BLACKLISTED_PARAMS } = require('./constants');
const { trackEvent } = require('../../lib/tracking');

const layoutRegex = /_DisplayType_[A-Z]{1,2}/;

const NO_FOLLOW = 'nofollow';
const NO_FOLLOW_SPONSORED = 'nofollow,sponsored';

const buildNewHref = ({ href, isInternal, queryParams, keepLayout, currentLayout, initialLayout }) => {
  let newHref;

  if (isInternal && href) {
    const newUrl = new URL(href, currentLayout?.url_host);
    const oldQuery = newUrl.search;
    const urlParams = new URLSearchParams(oldQuery);
    const oldQueryObj = Object.fromEntries(urlParams);
    const newQuery = { ...queryParams, ...oldQueryObj };
    const params = omit(newQuery, BLACKLISTED_PARAMS);

    if (params) {
      Object.keys(params).forEach((key) => {
        if (!newUrl.searchParams.has(key)) {
          newUrl.searchParams.append(key, params[key]);
        }
      });
    }

    if (keepLayout && currentLayout && currentLayout.id !== initialLayout) {
      const oldLayout = layoutRegex.exec(newUrl.pathname);
      const newLayout = currentLayout.url_path.match(layoutRegex);

      if (oldLayout) {
        newUrl.pathname = newUrl.pathname.replace(oldLayout[0], newLayout);
      } else {
        newUrl.pathname += newLayout;
      }
    }

    newHref = url.format(newUrl);
  } else if (href) {
    newHref = href;
  }

  return newHref;
};

const buildProps = ({
  newHref,
  className,
  namespace,
  title,
  role,
  onClick,
  target,
  ariaLabel,
  ariaLabelledBy,
  tracks,
  shouldAddNoFollow,
  isAd,
}) => {
  let relTag;

  if (isAd) {
    relTag = NO_FOLLOW_SPONSORED;
  } else if (shouldAddNoFollow) {
    relTag = NO_FOLLOW;
  } else {
    relTag = undefined;
  }

  const newProps = {
    href: newHref || '#',
    className: classnames(className, namespace),
    title,
    role,
    rel: relTag,
    onClick: tracks
      ? (params) => {
          trackEvent(tracks);
          window.location = newHref;

          if (onClick) {
            onClick(params);
          }
        }
      : onClick,
    target,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
  };

  return newProps;
};

module.exports = {
  buildNewHref,
  buildProps,
};
