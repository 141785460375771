const React = require('react');
const { string, shape } = require('prop-types');

const classnames = require('classnames');

const ComposedLabel = require('../../composed-label/composed-label');

const namespace = 'ui-search-item__title';

const Title = ({ className, title, compats }) => (
  <h2
    aria-level="3"
    className={classnames(namespace, 'shops__item-title', {
      [`${className}`]: className,
      [`${namespace}-compats`]: compats,
    })}
  >
    {title}
    {compats && <ComposedLabel {...compats} className={`${namespace}__compats-label`} />}
  </h2>
);

Title.propTypes = {
  className: string,
  compats: shape({
    bg_color: string,
    color: string,
    font_size: string,
    text: string,
    font_family: string,
    values: shape({
      compatibility: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
      user_selection: shape({
        color: string,
        font_family: string,
        font_size: string,
        text: string,
      }),
    }),
  }),
  title: string.isRequired,
};

Title.defaultProps = {
  className: null,
  compats: null,
};

module.exports = Title;
