const React = require('react');

const classnames = require('classnames');

const genIcon = (id, useId, width, height, viewBox, classNameClient = null) => {
  const obj = ({ className, ariaText }) => {
    const svgClassName = classnames(className, classNameClient);

    return (
      <svg
        className={svgClassName}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={viewBox}
        aria-label={ariaText}
        fill="none"
      >
        <use href={`#${useId}`} />
      </svg>
    );
  };

  obj.ICON_ID = id;

  return obj;
};

const IconRecommendationsFullSuperMarket = genIcon('recommendations-full_icon', 'full', 41, 13, '0 0 41 13');
const IconRecommendationsCpg = genIcon('recommendations-cpg_icon', 'supermarket', 100, 9, '0 0 100 9');

const IconRecommendationsFull = require('../recos-full');
const IconRecommendationsMeliCoins = require('../icon-melicoin');
const IconRecommendationsMeliLogo = require('../meli');
const IconRecommendationsHeartEmpty = require('../heart-empty');
const IconRecommendationsHeartFull = require('../heart-full');
const IconRecommendationsChevron = require('../chevron');
const IconRecommendationsBlackFriday = require('../black-friday');
const IconRecommendationsMShopsLogo = require('../mshops-logo');

IconRecommendationsFull.ICON_ID = 'recommendations-full_icon';
IconRecommendationsMeliCoins.ICON_ID = 'recommendations-meli_coins';
IconRecommendationsMeliLogo.ICON_ID = 'recommendations-meli_logo';
IconRecommendationsHeartEmpty.ICON_ID = 'recommendations-heart-empty';
IconRecommendationsHeartFull.ICON_ID = 'recommendations-heart-full';
IconRecommendationsChevron.ICON_ID = 'recommendations-chevron';
IconRecommendationsBlackFriday.ICON_ID = 'recommendations-black-friday_icon';
IconRecommendationsMShopsLogo.ICON_ID = 'recommendations-mshops_logo';

module.exports = {
  IconRecommendationsFull,
  IconRecommendationsFullSuperMarket,
  IconRecommendationsCpg,
  IconRecommendationsMeliCoins,
  IconRecommendationsMeliLogo,
  IconRecommendationsHeartEmpty,
  IconRecommendationsHeartFull,
  IconRecommendationsChevron,
  IconRecommendationsBlackFriday,
  IconRecommendationsMShopsLogo,
};
