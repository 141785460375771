const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--meliplus';

const MeliPlus = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="43"
    height="17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.004 9.382c0-4.65 3.59-8.421 8.017-8.421H34.48c4.206 0 7.616 3.582 7.616 8s-3.41 8-7.616 8H.004V9.382z"
      fill="url(#a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.24 5.133v8.172h1.748V3.79a.245.245 0 0 1-.037.002c-.255.008-1.71.055-1.71 1.34zm-10.645 8.172h1.698l.025-4.27c0-1.822-1.083-3.102-2.942-3.102-.406 0-.8.086-1.157.258a2.825 2.825 0 0 0-.935.726 2.5 2.5 0 0 0-.936-.738 2.563 2.563 0 0 0-1.169-.246c-1.821 0-2.99 1.341-2.99 3.101v4.27h1.698V8.998a1.172 1.172 0 0 1 .074-.504 1.216 1.216 0 0 1 .701-.714 1.18 1.18 0 0 1 .505-.086.997.997 0 0 1 .492.086c.16.074.308.172.419.295.123.123.209.27.258.43.05.16.074.333.05.505v4.296h1.698V9.009a1.173 1.173 0 0 1 .074-.504 1.215 1.215 0 0 1 .701-.714 1.405 1.405 0 0 1 .997.012c.16.074.308.173.419.296.11.123.197.27.258.43.05.16.074.333.062.505v4.27zm7.323-1.662c.098-.086.184-.172.258-.283l1.982.05a2.474 2.474 0 0 1-.653 1.07 3.495 3.495 0 0 1-1.218.8 3.57 3.57 0 0 1-1.44.222 3.81 3.81 0 0 1-2.486-.874c-.714-.59-1.071-1.526-1.071-2.83 0-1.232.32-2.18.972-2.832a3.438 3.438 0 0 1 2.523-.985 3.823 3.823 0 0 1 1.662.333 2.8 2.8 0 0 1 1.218 1.046c.296.431.493.936.567 1.453.061.467.073.935.061 1.403h-5.145c-.012.295.05.578.185.837.123.258.32.48.566.64.296.172.64.258.985.233.37.037.726-.073 1.034-.283zM18.17 7.582a1.673 1.673 0 0 0-.505.32c-.27.307-.443.689-.492 1.083h3.176a1.719 1.719 0 0 0-.136-.603 1.794 1.794 0 0 0-.357-.505 1.632 1.632 0 0 0-1.095-.381 1.382 1.382 0 0 0-.591.086zm8.332-3.779h1.736V5.54h-1.736V3.803zM28.238 6.5H26.49v6.806h1.748V6.499zm6.917-.96h-1.748v3.015h-3.015v1.748h3.015v3.003h1.748v-3.003h3.003V8.554h-3.003V5.54z"
      fill="#fff"
    />
    <defs>
      <linearGradient id="a" x1=".004" y1="8.947" x2="42.087" y2="8.947" gradientUnits="userSpaceOnUse">
        <stop offset=".298" stopColor="#5A238D" />
        <stop offset="1" stopColor="#AA1192" />
      </linearGradient>
    </defs>
  </svg>
);

MeliPlus.propTypes = {
  className: string,
};

MeliPlus.defaultProps = {
  className: null,
};

module.exports = React.memo(MeliPlus);
