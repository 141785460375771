const React = require('react');
const { string, arrayOf, bool } = require('prop-types');

const classnames = require('classnames');

const IconFull = require('../icons/full');
const IconMelicoin = require('../icons/icon-melicoin');
const IconVirtualTour = require('../icons/virtual-tour');
const IconMelichoice = require('../icons/melichoice');
const IconInternationalFilter = require('../icons/international-filter-cbt');
const MeliPlusIcon = require('../icons/meli-plus');
const IconInternationalFilterMobile = require('../icons/international-filter-cbt-mobile');

const namespace = 'ui-search-icon-label';

const ICON_FORMAT_REGEX = /\{icon\}/gi;

const renderIcon = (type) => {
  switch (type) {
    case 'fulfillment':
      return <IconFull />;
    case 'melicoin':
      return <IconMelicoin />;
    case 'virtual_tour_icon':
      return <IconVirtualTour />;
    case 'cbt_international_desktop':
      return <IconInternationalFilter />;
    case 'cbt_international_mobile':
      return <IconInternationalFilterMobile />;
    case 'meli_plus_icon':
      return <MeliPlusIcon />;
    case 'melichoice_icon':
      return <IconMelichoice />;

    default:
      return null;
  }
};

const IconLabel = ({ className, text, icons, htmlForIDValue, hasHtml }) => {
  const labelClassName = classnames(className, namespace);

  if (hasHtml) {
    // eslint-disable-next-line react/no-danger
    return <span htmlFor={htmlForIDValue} className={labelClassName} dangerouslySetInnerHTML={{ __html: text }} />;
  }

  const iconsMatches = text?.match(ICON_FORMAT_REGEX);

  if (!icons || !iconsMatches || iconsMatches.length < icons.length) {
    return (
      <label htmlFor={htmlForIDValue} className={labelClassName}>
        {text}
      </label>
    );
  }

  const textParts = text.split(ICON_FORMAT_REGEX);

  return (
    <label htmlFor={htmlForIDValue} className={labelClassName}>
      {textParts.map((textPart, index) => (
        <>
          {textPart}
          {index < icons.length && renderIcon(icons[index])}
        </>
      ))}
    </label>
  );
};

IconLabel.propTypes = {
  className: string,
  hasHtml: bool,
  htmlForIDValue: string,
  icons: arrayOf(string),
  text: string.isRequired,
};

IconLabel.defaultProps = {
  className: '',
  icons: [],
  htmlForIDValue: '',
  hasHtml: false,
};

module.exports = IconLabel;
