const React = require('react');
const { shape, string, bool, number } = require('prop-types');

const Image = require('nordic/image');
const get = require('lodash/get');

const { namespace } = require('../../constants');

const MainImage = ({ item: { title, pictures }, options }) => (
  <Image
    alt={title}
    className={`${namespace}__main-image-internal`}
    src={options.mounted ? get(pictures, 'stack.retina') || get(pictures, 'stack.normal') : ''}
    width={get(pictures, 'stack.tags.width')}
    height={get(pictures, 'stack.tags.heigth')}
    lowEnd={options.lowEnd}
    lazyload={options.lazyload}
  />
);

MainImage.propTypes = {
  item: shape({
    pictures: shape({
      retina: string,
      normal: string,
      tags: shape({
        width: number,
        height: number,
      }),
    }).isRequired,
    title: string.isRequired,
  }).isRequired,
  options: shape({
    mounted: bool,
    lazyload: string,
    lowEnd: bool,
  }),
};

MainImage.defaultProps = {
  options: {},
};

module.exports = {
  MainImage,
};
