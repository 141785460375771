const React = require('react');

const { loadable } = require('nordic/lazy');

const useCarousel = require('./use-carousel');

const ChunkCarouselAndes = () => {
  const carousel = useCarousel();
  const CarouselAndesSearch = loadable(async () =>
    import(/* webpackChunkName: "chunk-carousel" */ './components/wrapper'),
  );

  return carousel && <CarouselAndesSearch items={carousel.values} type={carousel.type} filters={carousel.filters} />;
};

module.exports = ChunkCarouselAndes;
