const React = require('react');
const { string, node, oneOf, shape, bool } = require('prop-types');

const classnames = require('classnames');

const { getStyleProps } = require('../../lib/style-props');
const types = require('../factory/component-types');
const { useSearch } = require('../../hooks/context');

const namespace = 'ui-search-styled-label';

const StyledLabel = ({
  className,
  text,
  htmlForIDValue,
  as,
  ariaLevel,
  label,
  linkId,
  isAccLabel,
  href,
  role,
  aria_label,
  ...props
}) => {
  const Element = as;
  const { shops } = useSearch();

  return (
    <Element
      htmlFor={!!htmlForIDValue}
      className={classnames(namespace, className, {
        [`ui-search-size--${props.size}`]: !!props.size,
        [`ui-search-weight--${props.font_weight}`]: !!props.font_weight,
        'screen-reader-only': isAccLabel,
        [`${namespace}--shops`]: Boolean(shops),
      })}
      style={getStyleProps(props)}
      role={role}
      href={href}
      aria-level={ariaLevel}
      id={linkId}
      aria-label={aria_label}
    >
      {text}
    </Element>
  );
};

const StyledLabelWrapper = ({ id, type, linkId, as, label, text, ...props }) => {
  const isAccLabel = type === types?.ACCESIBLE_LABEL;
  const isLink = id === 'A11Y_LABEL_LINK';
  const htmlTag = isLink && isAccLabel ? 'a' : 'div';
  const elementText = text || label?.text;

  return (
    <StyledLabel
      as={isAccLabel ? htmlTag : as}
      href={isAccLabel && isLink ? '#results' : null}
      ariaLevel={isAccLabel && !isLink ? '2' : null}
      role={isAccLabel && !isLink ? 'heading' : null}
      linkId={linkId || null}
      isAccLabel={isAccLabel}
      text={elementText}
      {...props}
    />
  );
};

StyledLabelWrapper.propTypes = {
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'label', 'div', 'pre']),
  id: string,
  label: shape({
    text: string,
  }),
  linkId: string,
  text: string,
  type: string,
};

StyledLabelWrapper.defaultProps = {
  as: 'label',
  id: '',
  label: {},
  linkId: '',
  text: '',
  type: '',
};

StyledLabel.propTypes = {
  aria_label: string,
  ariaLevel: string,
  as: oneOf(['p', 'span', 'h1', 'h2', 'h3', 'h4', 'li', 'a', 'label', 'div', 'pre']),
  background: string,
  className: string,
  color: string,
  font_weight: string,
  href: string,
  htmlForIDValue: string,
  isAccLabel: bool,
  label: shape({
    text: string,
    as: string,
  }),
  linkId: string,
  role: string,
  size: string,
  text: node.isRequired,
  type: string,
};

StyledLabel.defaultProps = {
  as: 'label',
  className: null,
  props: {},
  htmlForIDValue: '',
};

module.exports = StyledLabelWrapper;
module.exports.StyledLabel = StyledLabel;
