const React = require('react');

const { loadable } = require('nordic/lazy');

const useFooterDisclaimer = require('./use-footer-disclaimer');

const ChunkFooterDisclaimer = () => {
  const footerDisclaimerProps = useFooterDisclaimer();
  const FooterDisclaimer = loadable(async () =>
    import(/* webpackChunkName: "chunk-footer-disclaimer" */ './footer-disclaimer'),
  );

  return footerDisclaimerProps ? <FooterDisclaimer {...footerDisclaimerProps} /> : null;
};

module.exports = ChunkFooterDisclaimer;
