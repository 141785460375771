const React = require('react');
const { arrayOf, shape, string, number } = require('prop-types');

const classnames = require('classnames');

const Group = require('../group/group');
const Separator = require('../group/separator');
const ItemFull = require('../fulfillment/fulfillment');
const ItemPromise = require('../promise/promise');
const ItemHighlight = require('../highlight/highlight-label');
const { CARD_DATA_GROUPS } = require('../../layout/renderer/constants');

const namespace = 'ui-search-item__shipping';

const ShippingGroup = ({ shipping, tags, contentWidth }) => {
  const { label, highlight, promise } = shipping;
  const hasFulfillment = tags.indexOf('fulfillment') > -1;
  const shouldShowPromise = promise && promise.id !== 'other';
  const showFirstRow = label || hasFulfillment || shouldShowPromise;
  const genericGroupClassName = 'ui-search-item__group';
  const shippingGroupClassName = classnames(`${genericGroupClassName}--${CARD_DATA_GROUPS.SHIPPING}`, {
    [`${genericGroupClassName}--promise`]: shouldShowPromise,
  });

  if (!showFirstRow && !highlight) {
    return null;
  }

  return (
    <Group noSeparator className={shippingGroupClassName}>
      {showFirstRow && (
        <div className="ui-search-item__group__element--shipping">
          {label && !shouldShowPromise && (
            <p className={classnames(namespace, `${namespace}--free`, 'shops__item-shipping-free')}>{label.text}</p>
          )}
          {hasFulfillment && !shouldShowPromise && <ItemFull />}
          {shouldShowPromise && (
            <ItemPromise {...promise} hasFulfillment={hasFulfillment} contentWidth={contentWidth} />
          )}
        </div>
      )}
      {showFirstRow && highlight && <Separator />}
      {highlight && (
        <div>
          <ItemHighlight {...highlight} className={`${namespace}__highlight`} />
        </div>
      )}
    </Group>
  );
};

ShippingGroup.propTypes = {
  className: string,
  contentWidth: number.isRequired,
  shipping: shape({
    highlight: shape({}),
    label: shape({
      text: string.isRequired,
    }),
    promise: shape({}),
  }),
  tags: arrayOf(string).isRequired,
};

ShippingGroup.defaultProps = {
  className: null,
  shipping: {},
};

module.exports = ShippingGroup;
