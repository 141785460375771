const NAMESPACE = 'ui-search-map-results';

const LAZY_THRESHOLD = 2;
const STOPPED_SPEED = 0;
const ANIMATED_SPEED = 500;
const SLIDER_PROPS = {
  slidesToShow: 1,
  arrowsVisible: false,
  infinite: false,
  lazyLoad: false,
  arrows: false,
};

module.exports = {
  NAMESPACE,
  LAZY_THRESHOLD,
  STOPPED_SPEED,
  ANIMATED_SPEED,
  SLIDER_PROPS,
};
