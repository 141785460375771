const { bool, node, number, string, shape } = require('prop-types');

const MOBILE_PROP_TYPES = {
  children: node.isRequired,
  className: string,
  mask: bool,
  maskClosable: bool,
  side: string,
  tooltip: shape({
    title: string,
    offset_x: number,
    offset_y: number,
    content: string,
    filter_content_id: string,
    max_print: number,
    side: string,
  }),
};

const DEFAULT_SIDE = 'bottom';

const MOBILE_DEFAULT_PROPS = {
  className: '',
  mask: false,
  maskClosable: true,
  side: DEFAULT_SIDE,
  tooltip: {},
};

const FILTER_TOOLTIP_PREFIX = 'filter-tooltip';

module.exports = {
  FILTER_TOOLTIP_PREFIX,
  MOBILE_PROP_TYPES,
  MOBILE_DEFAULT_PROPS,
};
