const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-full';

const IconStarFull = ({ className }) => (
  <svg className={classnames(namespace, className)} width="100%" height="100%" viewBox="0 0 10 10" fill="#3483fa">
    <use href="#start-full" />
  </svg>
);

IconStarFull.propTypes = {
  className: string,
};

IconStarFull.defaultProps = {
  className: null,
};

module.exports = React.memo(IconStarFull);
