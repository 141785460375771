/* eslint-disable react-hooks/exhaustive-deps */
const { useEffect, useState, useCallback, useMemo } = require('react');

const get = require('lodash/get');

const {
  FILTERS: {
    IDS: { ITEM_LOCATION },
  },
} = require('../../../constants');
const useLazyCategoryId = require('../../../hooks/use-lazy-category-id');
const { useStaticProps } = require('../../context/static-props');
const { useSearch, useFilters } = require('../../../hooks/context');
const { trackZrp } = require('../track');

const INITIAL_BOUNDS = { north: null, south: null, east: null, west: null };

const disableScrollRestoration = (history) => {
  if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }
};

const getAllAppliedFilters = ({
  filtering_options: filteringOptions = {},
  map_configuration: mapConfiguration = {},
  new_faceted_search: newFacetedSearch = {},
}) =>
  newFacetedSearch.applied_filters ||
  filteringOptions.applied_filters ||
  get(mapConfiguration, 'filters.new_faceted_search.applied_filters', null) ||
  get(mapConfiguration, 'filters.applied_filters.filters', []);

const useMapView = ({ results }) => {
  const { siteId, visualId } = useStaticProps();
  const { getCategoryId } = useLazyCategoryId(siteId);
  const {
    deserializeItemLocation,
    getPathname,
    lastFilterId,
    removeItemLocation,
    setLastFilterId,
    serializeItemLocation,
  } = useFilters();
  const searchContext = useSearch();
  const {
    analytics_track: analytics,
    history,
    isLoadingResults,
    locationSearchError,
    map_configuration = {},
    view_options: { sort: { sorts } } = {},
  } = searchContext;
  const [bounds, setBounds] = useState(INITIAL_BOUNDS);
  const [error, setError] = useState(null);
  const isRequestPending = bounds !== INITIAL_BOUNDS;
  const isLoading = isRequestPending || isLoadingResults;
  const showZrp = !isLoading && results.length === 0;
  const showError = !isLoading && (!!error || !!locationSearchError);
  const fitMarkersInMap = results.length > 0 && lastFilterId !== ITEM_LOCATION;
  const appliedFilters = getAllAppliedFilters(searchContext);

  // We do not store the item_location filter value in the `bounds` variable since
  // it can lead to stale data because other parts of the app might remove said
  // filter, so we derive the value from the applied_filters array which will always
  // be up to date.
  const fixedCoords = useMemo(
    () =>
      deserializeItemLocation(
        get(
          appliedFilters.find(({ id }) => id === ITEM_LOCATION),
          'values[0].id',
        ),
      ),
    [appliedFilters],
  );

  const getNextPathname = (category, overrides = {}) =>
    getPathname({
      appliedFilters,
      sortingOptions: sorts,
      removeOffset: get(map_configuration, 'pagination.selected_page', 1) > 1,
      DisplayType: 'M',
      category,
      siteId,
      visualId,
      ...overrides,
    }).then((pathname) => pathname || history.location.pathname);

  const addItemLocation = (currentBounds) => (pathname) => `${pathname}${serializeItemLocation(currentBounds)}`;

  const buildNextLocation = (pathname) => ({
    pathname,
    search: history.location.search,
  });

  const updateResults = useCallback((currentBounds) => {
    setBounds(currentBounds);
    setLastFilterId(ITEM_LOCATION);
    setError(null);
  }, []);

  const reloadResults = (event) => {
    event.preventDefault();

    const { pathname, search } = history.location;
    const { location: { protocol, host } = {} } = window || {};
    const url = `${protocol}//${host}${pathname}${search}`;

    window.location = url;
  };

  const getNewResults = () =>
    getCategoryId()
      .then(getNextPathname)
      .then(removeItemLocation)
      .then(addItemLocation(bounds))
      .then(buildNextLocation)
      .then(history.push)
      .catch(setError);

  const applyNewFilters = (newFilters) =>
    getCategoryId()
      .then((category) => getNextPathname(category, { removeOffset: true, useExistingFilters: true, ...newFilters }))
      .then(buildNextLocation)
      .then(history.push)
      .catch(setError);

  useEffect(() => {
    if (isRequestPending) {
      getNewResults()
        .then(() => setBounds(INITIAL_BOUNDS))
        .catch((err) => {
          throw new Error(err);
        });
    }
  }, [isRequestPending]);

  // Discard the local error if a request is made outside of this component's scope.
  useEffect(() => {
    if (isLoadingResults) {
      setError(null);
    }
  }, [isLoadingResults]);

  useEffect(() => {
    if (!results || results.length === 0) {
      trackZrp(analytics);
    }
  }, [results]);

  useEffect(() => {
    if (window) {
      disableScrollRestoration(window.history);
    }
  }, []);

  return {
    applyNewFilters,
    isLoading,
    fitMarkersInMap,
    updateResults,
    reloadResults,
    map_configuration,
    fixedCoords,
    showZrp,
    showError,
  };
};

module.exports = {
  useMapView,
};
