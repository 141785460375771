const REQUEST_STATES = {
  NOT_REQUESTED: 0,
  LOADING: 1,
  LOADED: 2,
  ERROR: 4,
};

const CAROUSEL_SLIDES_TO_SHOW = {
  BRANDS: {
    stack: {
      referenceResolutions: [1164, 1023, 861],
      slidesByResolution: {
        1164: 6,
        1023: 5,
        861: 4,
      },
      default: 8,
    },
    grid: {
      referenceResolutions: [1164, 1023, 861],
      slidesByResolution: {
        1164: 6,
        1023: 5,
        861: 4,
      },
      default: 8,
    },
  },
  OFFICIAL_STORES: {
    stack: {
      referenceResolutions: [1164, 1023],
      slidesByResolution: {
        1164: 3,
        1023: 2,
      },
      default: 4,
    },
    grid: {
      referenceResolutions: [1164, 1023],
      slidesByResolution: {
        1164: 3,
        1023: 2,
      },
      default: 4,
    },
  },
  VEHICLE_BODY_SELECTOR: {
    stack: {
      referenceResolutions: [1023, 861, 773],
      slidesByResolution: {
        1023: 4,
        861: 3,
        773: 2,
      },
      default: 5,
    },
    grid: {
      referenceResolutions: [1023, 861, 773],
      slidesByResolution: {
        1023: 4,
        861: 3,
        773: 2,
      },
      default: 5,
    },
  },
  CPG: {
    stack: {
      referenceResolutions: [1164, 1023, 861],
      slidesByResolution: {
        1164: 4,
        1023: 3,
        861: 2,
      },
      default: 5,
    },
    grid: {
      referenceResolutions: [1164, 1023, 861],
      slidesByResolution: {
        1164: 4,
        1023: 3,
        861: 2,
      },
      default: 5,
    },
  },
  DEFAULT_SLIDES: 1,
};

const CAROUSEL_SIZE_MODIFIER = {
  BRANDS: {
    8: 'xl',
    6: 'l',
    5: 'm',
    4: 's',
  },
  OFFICIAL_STORES: {
    4: 'l',
    3: 'm',
    2: 's',
  },
  DEFAULT_MODIFIER: 'm',
};

const CAROUSEL_VIEW_MORE_TEXT_FN = {
  VEHICLE_BODY_SELECTOR: (optionsToShow, totalOptions) =>
    optionsToShow < totalOptions ? `+${totalOptions - optionsToShow}` : null,
  DEFAULT_FN: () => null,
};

const dispatchResize = () => {
  let event;

  if (typeof Event === 'function') {
    event = new Event('resize');
  } else {
    event = document.createEvent('Event');
    event.initEvent('resize', true, true);
  }

  window.dispatchEvent(event);
};

module.exports = {
  dispatchResize,
  REQUEST_STATES,
  CAROUSEL_SLIDES_TO_SHOW,
  CAROUSEL_SIZE_MODIFIER,
  CAROUSEL_VIEW_MORE_TEXT_FN,
};
