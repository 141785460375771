const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--icon-melicoin';

const IconMelicoin = ({ className, centsType }) => {
  const centsTypeStyles = centsType === 'dot' ? '2px' : '';

  return (
    <svg
      className={classnames(namespace, className)}
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: centsTypeStyles }}
    >
      <g clipPath="url(#clip0_183:55615)">
        <path
          d="M14.7803 7.49999C14.7803 3.47921 11.5208 0.219717 7.5 0.219717C3.47921 0.219717 0.219726 3.47921 0.219726 7.5C0.219726 11.5208 3.47921 14.7803 7.5 14.7803C11.5208 14.7803 14.7803 11.5208 14.7803 7.49999Z"
          fill="#FCEE56"
        />
        <path
          d="M2.10644 2.61002C3.39879 1.43753 5.11427 0.723064 6.99665 0.723064C11.0174 0.723064 14.2769 3.98255 14.2769 8.00334C14.2769 9.88573 13.5625 11.6012 12.39 12.8936C13.8581 11.5616 14.78 9.63861 14.78 7.50023C14.78 3.47943 11.5206 0.219948 7.49977 0.219948C5.36139 0.219948 3.43837 1.14192 2.10644 2.61002V2.61002Z"
          fill="#FBD307"
        />
        <path
          d="M1.66077 3.1513L13.5306 11.5798C13.089 12.2312 12.5448 12.8071 11.9212 13.2842L0.580566 5.23149C0.827685 4.47596 1.19554 3.77515 1.66077 3.1513V3.1513Z"
          fill="#FBF4AF"
        />
        <path
          d="M13.4189 7.50001C13.4189 4.23107 10.7689 1.58107 7.49999 1.58107C4.23105 1.58107 1.58105 4.23107 1.58105 7.50001C1.58105 10.7689 4.23105 13.4189 7.49999 13.4189C10.7689 13.4189 13.4189 10.7689 13.4189 7.50001Z"
          fill="#E6B700"
        />
        <path
          d="M12.7975 7.50042C12.7975 4.57472 10.4258 2.20298 7.50007 2.20298C4.57438 2.20298 2.20264 4.57472 2.20264 7.50042C2.20264 10.4261 4.57438 12.7979 7.50007 12.7979C10.4258 12.7979 12.7975 10.4261 12.7975 7.50042Z"
          fill="url(#paint0_linear_183:55615)"
        />
        <path
          d="M11.7427 11.7427C12.8759 10.6094 13.5 9.10265 13.5 7.5C13.5 6.09644 13.0052 4.73116 12.1069 3.65571C12.0446 3.5812 11.9338 3.57126 11.8593 3.63351C11.7847 3.69572 11.7748 3.80658 11.837 3.88111C12.6827 4.89344 13.1484 6.17866 13.1484 7.5C13.1484 10.6146 10.6146 13.1484 7.5 13.1484C4.38543 13.1484 1.85156 10.6146 1.85156 7.5C1.85156 4.38543 4.38543 1.85156 7.5 1.85156C8.90062 1.85156 10.2441 2.36789 11.283 3.30544C11.355 3.37045 11.4662 3.36476 11.5312 3.29269C11.5962 3.22069 11.5906 3.10952 11.5185 3.04444C10.4149 2.04848 8.98779 1.5 7.5 1.5C5.89734 1.5 4.39061 2.12411 3.25734 3.25734C2.12411 4.39059 1.5 5.89734 1.5 7.5C1.5 9.10265 2.12411 10.6094 3.25734 11.7427C4.39061 12.8759 5.89734 13.5 7.5 13.5C9.10265 13.5 10.6094 12.8759 11.7427 11.7427Z"
          fill="#9D7100"
        />
        <path
          d="M2.19668 2.19668C0.780143 3.61324 -2.74477e-06 5.49668 -2.16617e-06 7.5C-1.65945e-06 9.25444 0.618456 10.961 1.74143 12.3054C1.81922 12.3985 1.95779 12.4109 2.05093 12.3331C2.14406 12.2554 2.15651 12.1168 2.0787 12.0236C1.02161 10.7582 0.439451 9.15167 0.439451 7.5C0.43945 3.6068 3.60679 0.439453 7.49999 0.439453C11.3932 0.439453 14.5605 3.6068 14.5605 7.5C14.5605 11.3932 11.3932 14.5605 7.5 14.5605C5.74922 14.5605 4.06986 13.9151 2.77128 12.7432C2.68119 12.6619 2.54224 12.6691 2.46094 12.7591C2.3797 12.8491 2.3867 12.9881 2.47684 13.0695C3.85635 14.3144 5.64026 15 7.5 15C9.50332 15 11.3867 14.2199 12.8033 12.8033C14.2198 11.3868 15 9.50332 15 7.5C15 5.49668 14.2198 3.61324 12.8033 2.19668C11.3867 0.780146 9.50331 1.7555e-07 7.49999 1.88803e-07C5.49667 2.02056e-07 3.61327 0.780146 2.19668 2.19668V2.19668Z"
          fill="#9D7100"
        />
        <path
          d="M8.75295 5.33203C8.24296 5.33203 7.7997 5.55605 7.50182 5.91589C7.21823 5.55605 6.7845 5.33203 6.25069 5.33203C5.17591 5.33203 4.48242 6.12322 4.48242 7.16702V9.67404H5.48809V7.14081C5.48809 6.67848 5.80028 6.35915 6.25069 6.35915C6.91319 6.35915 6.98229 6.90964 6.98229 7.14081V9.67404H7.98796V7.14081C7.98796 6.67848 8.3073 6.35915 8.75056 6.35915C9.41306 6.35915 9.48932 6.90964 9.48932 7.14081V9.67404H10.495V7.16702C10.4998 6.09224 9.85156 5.33203 8.75295 5.33203Z"
          fill="#9D7100"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_183:55615"
          x1="11.25"
          y1="11.2502"
          x2="3.48215"
          y2="4.01811"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBD307" />
          <stop offset="1" stopColor="#FCEE56" />
        </linearGradient>
        <clipPath id="clip0_183:55615">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconMelicoin.propTypes = {
  centsType: string,
  className: string,
};

IconMelicoin.defaultProps = {
  className: null,
  currency: '',
};

module.exports = IconMelicoin;
