/* eslint-disable react/no-danger */

const React = require('react');
const { string, shape } = require('prop-types');

const Message = require('@andes/message');

const Link = require('../link');
const useZrpDisclaimer = require('./use-zrp-disclaimer');

const namespace = 'ui-search-zrp-disclaimer';

const renderZrpMessage = (zrp_query_text, query) => {
  if (query) {
    return (
      <>
        {zrp_query_text}
        <span className={`${namespace}__query`}>{` ${query} `}</span>
      </>
    );
  }

  return <span className={`${namespace}__full-message`} dangerouslySetInnerHTML={{ __html: zrp_query_text }} />;
};

const ZrpDisclaimer = ({ zrp_query_text, className, query, other_sellers_text, link }) => (
  <Message className={`${namespace} ${className}`} closable defaultVisible hierarchy="loud">
    <div>
      {renderZrpMessage(zrp_query_text, query)}
      {other_sellers_text && `${other_sellers_text} `}
      {link && (
        <Link href={link.url} className={`${namespace}__link`} isInternal={false}>
          {link.text}
        </Link>
      )}
    </div>
  </Message>
);

ZrpDisclaimer.propTypes = {
  className: string.isRequired,
  link: shape({
    url: string.isRequired,
    text: string.isRequired,
  }),
  other_sellers_text: string,
  query: string,
  zrp_query_text: string.isRequired,
};

ZrpDisclaimer.defaultProps = {
  link: null,
  other_sellers_text: null,
  query: null,
};

const ZrpDisclaimerContainer = () => {
  const zrp_disclaimer = useZrpDisclaimer();

  return zrp_disclaimer ? <ZrpDisclaimer {...zrp_disclaimer} /> : null;
};

module.exports = ZrpDisclaimerContainer;
module.exports.ZrpDisclaimer = ZrpDisclaimer;
