const get = require('lodash/get');

const { useSearch } = require('../../hooks/context');

const useTopKeywords = () => {
  const { top_keywords } = useSearch();

  return get(top_keywords, 'keywords', false) ? top_keywords : null;
};

module.exports = useTopKeywords;
