const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const ICON_ID = 'fulfillment';
const namespace = 'ui-search-icon ui-search-icon--full';

const IconFull = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    viewBox="0 0 100 32"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="full"
  >
    <use href="#full" />
  </svg>
);

IconFull.propTypes = {
  className: string,
};

IconFull.defaultProps = {
  className: null,
};

module.exports = React.memo(IconFull);
module.exports.ICON_ID = ICON_ID;
