const React = require('react');
const { string, shape, bool, arrayOf, func } = require('prop-types');
const { useContext } = require('react');

const classnames = require('classnames');
const get = require('lodash/get');

const LayoutContext = require('../../../context/layout');
const SortFilter = require('../sort-filter/sort.desktop');
const ViewChange = require('../../../view-change/view-change.desktop');
const { useSearch } = require('../../../../hooks/context');
const { useStaticProps } = require('../../../context/static-props');
const MapButtonDesktop = require('../map/map-button.desktop');
const Breadcrumbs = require('../breadcrumb/breadcrumb');
const StyledLabel = require('../../../styled-label/styled-label');
const { LAYOUTS } = require('../../../../constants');
const types = require('../../../factory/component-types');

const namespace = 'ui-search-view-options';
const LAYOUT_TYPE_GALLERY = 'gallery';

const getContainerClass = (layout) => {
  switch (layout) {
    case LAYOUTS.TYPE_MAP:
      return `${namespace}__container--map`;

    default:
      return `${namespace}__container`;
  }
};

const getGroupClass = (layout) => {
  switch (layout) {
    case LAYOUTS.TYPE_MAP:
      return `${namespace}__group--map`;

    default:
      return `${namespace}__group`;
  }
};

const ViewOptions = ({
  onChangeLayout,
  views,
  currentLayout,
  sort,
  className,
  mapLink,
  breadcrumbs,
  showViewChange,
  heading,
}) => {
  const contentClass = classnames(`${namespace}__content`, className);
  const hasMapLink = !!mapLink;

  return (
    <div className={getContainerClass(currentLayout)}>
      {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}
      {heading && <StyledLabel type={types?.ACCESIBLE_LABEL} text={heading} linkId="results" />}
      <div className={classnames(namespace, 'shops__view-options')}>
        <div className={contentClass}>
          {hasMapLink && <MapButtonDesktop {...mapLink} />}
          <div className={getGroupClass(currentLayout)}>
            <div className={classnames(`${namespace}__title`, 'shops-custom-primary-font')}>{sort.label} </div>
            <SortFilter {...sort} />
            {showViewChange && (
              <ViewChange onChangeLayout={onChangeLayout} views={views} currentLayout={currentLayout} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ViewOptions.propTypes = {
  breadcrumbs: shape({}),
  className: string.isRequired,
  currentLayout: string.isRequired,
  heading: string,
  mapLink: shape({}),
  showViewChange: bool,
  sort: shape({}).isRequired,
  values: arrayOf(
    shape({
      id: string,
      name: string,
      url: string,
      selected: bool,
    }),
  ).isRequired,
  views: Object.isRequired,
  onChangeLayout: func.isRequired,
};

ViewOptions.defaultProps = {
  breadcrumbs: null,
  className: '',
  mapLink: null,
  showViewChange: true,
};

const ViewOptionsContainer = ({ sort, className, ...rest }) => {
  const { changeLayout } = useStaticProps();
  const contextValues = useSearch();

  let sortData = sort;
  let { heading } = rest;

  if (!sort) {
    sortData = contextValues.view_options ? contextValues.view_options.sort : {};
  }

  if (!heading) {
    heading = contextValues.view_options ? contextValues.view_options.heading : '';
  }

  const { onChangeLayout, views, currentLayout } = useContext(LayoutContext);

  // TODO: Borrar la siguiente línea cuando se arregle lo del middleware y usar únicamente el currentLayout.id
  // trello card: https://trello.com/c/W9sRFdMR/445-no-viene-el-currentlayout-en-en-algunos-listados-como-casa-auto-zapatillas-etc
  const currentLayoutID = currentLayout ? currentLayout.id : LAYOUT_TYPE_GALLERY;

  return (
    <ViewOptions
      sort={sortData}
      className={className}
      views={views}
      onChangeLayout={onChangeLayout}
      currentLayout={currentLayoutID}
      mapLink={contextValues.map_link}
      breadcrumbs={get(contextValues, 'map_configuration.breadcrumb')}
      showViewChange={changeLayout}
      heading={heading}
    />
  );
};

ViewOptionsContainer.propTypes = {
  className: string,
  heading: string,
  sort: shape({}).isRequired,
};

ViewOptionsContainer.defaultProps = {
  className: '',
};

module.exports = ViewOptionsContainer;
module.exports.ViewOptions = ViewOptions;
