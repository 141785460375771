/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { arrayOf, shape } = require('prop-types');
const { useContext, useEffect, useState } = require('react');

const classnames = require('classnames');

const FilterOfficialStore = require('../filter-official-store/filter-official-store.desktop');
const FilterHighlighted = require('../highlighted-filter/highlighted-filter.desktop');
const RenderValueLink = require('./components/value-link');
const RenderFilterRange = require('./components/filter-range/filter-range');
const RenderColorsValues = require('./components/colors-values/colors-values');
const Modal = require('../../../modal/base.mobile');
const useModal = require('../../../modal/hooks/use-modal');
const { filterPropTypes, objValuesPropTypes } = require('./propTypes');
const { STYLE_MODIFIERS } = require('./constants');
const SearchModalContainer = require('../search-modal/search-modal.desktop');
const MoneyPicker = require('../../../money-picker/money-picker.desktop');
const StaticPropsContext = require('../../../context/static-props');
const {
  FILTERS: { IDS, TYPES },
} = require('../../../../constants');
const { useFilters } = require('../../../../hooks/context');

const namespace = 'ui-search-filter';

const renderValues = ({ id, name, results, url, tooltip, tracks }, filterId) => (
  <li key={id} className={classnames(`${namespace}-container`, 'shops__container-lists')}>
    <RenderValueLink
      namespace={namespace}
      id={id}
      name={name}
      results={results}
      url={url}
      tooltip={tooltip}
      filterId={filterId}
      tracks={tracks}
      ids={IDS}
      modifier={STYLE_MODIFIERS.FULL_ICON}
    />
  </li>
);

renderValues.propTypes = {
  ...objValuesPropTypes,
};

const getFilterValuesContent = (id, name, type, values, expanded_values, currencies) => {
  if (type === TYPES.HIGHLIGHTED) {
    return <FilterHighlighted id={id} values={values} expanded_values={expanded_values} />;
  }

  if (id === IDS.COLOR) {
    return (
      <li>
        <ul className={`${namespace}-row wrap`}>
          {expanded_values.map((value) => (
            <RenderColorsValues
              id={value.id}
              namespace={namespace}
              name={value.name}
              results={value.results}
              url={value.url}
            />
          ))}
        </ul>
      </li>
    );
  }

  if (id === IDS.OFFICIAL_STORE && type === TYPES.WITH_PICTURES) {
    return <FilterOfficialStore id={id} values={values} expanded_values={expanded_values} name={name} />;
  }

  if (id === IDS.PRICE && currencies) {
    return null;
  }

  return expanded_values.map((ev) => renderValues({ ...ev }, id));
};

const getFilterRangeContent = (id, values, url_templates, type, activeCurrencyId, labels, fragment) => {
  if (url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION)) {
    return id === IDS.VEHICLE_YEAR ? (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          type="dropdown"
          modifier={STYLE_MODIFIERS.YEAR_RANGE}
          fragment={fragment}
        />
      </li>
    ) : (
      <li>
        <RenderFilterRange
          values={values}
          url_templates={url_templates}
          labels={labels}
          modifier={id}
          activeCurrencyId={activeCurrencyId}
          type="input"
          fragment={fragment}
        />
      </li>
    );
  }

  return null;
};

const getFilterRangeContentPI = (id, values, url_templates, type, activeCurrencyId, labels, fragment) =>
  url_templates && (type === TYPES.RANGE || type === TYPES.PRICE_WITH_CONVERSION) ? (
    <li>
      <RenderFilterRange
        values={values}
        url_templates={url_templates}
        labels={labels}
        modifier={id}
        type="input"
        activeCurrencyId={activeCurrencyId}
        fragment={fragment}
      />
    </li>
  ) : null;

const getModalModifier = (filterId) => {
  switch (filterId) {
    case IDS.VEHICLE_BODY_TYPE:
      return STYLE_MODIFIERS.VEHICLE_BODY_MODAL;

    default:
      return STYLE_MODIFIERS.DEFAULT_MODAL;
  }
};

const getActiveCurrency = (currencies) => {
  const activeCurrency = currencies?.filter((c) => c.selected).pop();

  return activeCurrency?.id;
};

const FilterItem = ({
  id,
  name,
  values,
  modal,
  expanded_values,
  url_templates,
  url,
  currencies,
  values_with_conversion,
  type,
  labels,
  fragment,
} = {}) => {
  const hasMoneyPicker = id === IDS.PRICE && currencies;
  const [activeCurrencyId, setActiveCurrencyId] = useState(getActiveCurrency(currencies));
  const { visualId } = useContext(StaticPropsContext);
  const { setHideModal } = useFilters();
  const filterRangeRenderer = visualId === 'pi' ? getFilterRangeContentPI : getFilterRangeContent;
  const [isOpen, openModal, hideModal] = useModal();

  useEffect(() => {
    if (setHideModal && isOpen) {
      setHideModal(() => hideModal);
    }
  }, [isOpen]);

  useEffect(() => {
    setActiveCurrencyId(getActiveCurrency(currencies));
  }, [currencies]);

  return (
    <div className={classnames(`${namespace}-dl`, 'shops__filter-items')} key={id}>
      {!hasMoneyPicker && type !== TYPES.HIGHLIGHTED && (
        <h3 aria-level="3" className={classnames(`${namespace}-dt-title`, 'shops-custom-primary-font')}>
          {name}
        </h3>
      )}
      <ul>
        {hasMoneyPicker && (
          <MoneyPicker
            currencies={currencies}
            filterValues={values_with_conversion}
            title={name}
            activeCurrencyId={activeCurrencyId}
            handleCurrencyChange={setActiveCurrencyId}
          />
        )}
        {getFilterValuesContent(id, name, type, values, expanded_values, currencies, fragment)}
        {modal && (
          <li>
            <Modal
              className={`ui-search-modal--${getModalModifier(id)}`}
              label={modal.labels.modal_label}
              modalUrl="#filter"
              url={url}
              type="small"
              withLink
              open={isOpen}
              openModal={openModal}
              hideModal={hideModal}
              modalTitle={name}
            >
              <SearchModalContainer values={values} id={id} {...modal} title={name} />
            </Modal>
          </li>
        )}
        {filterRangeRenderer(id, values, url_templates, type, activeCurrencyId, labels, fragment)}
      </ul>
    </div>
  );
};

FilterItem.propTypes = {
  ...filterPropTypes,
};

const Filters = ({ filters, labels }) =>
  filters && (
    <section className={classnames(`${namespace}-groups`, 'shops__filter-groups')}>
      {filters.map((filter) => (
        <FilterItem {...filter} labels={labels} />
      ))}
    </section>
  );

Filters.propTypes = {
  filters: arrayOf(shape({})),
  labels: arrayOf(shape({})),
};

Filters.defaultProps = {
  filters: [],
  labels: [],
};

module.exports = Filters;
