const React = require('react');

const { ResContent } = require('./res-content');
const { VERTICAL_TYPE_RES } = require('../../constants');

const verticalContentRenderer = (item, deviceType) => {
  const { vertical } = item;

  switch (vertical) {
    case VERTICAL_TYPE_RES:
      return <ResContent item={item} device={deviceType} />;

    default:
      return null;
  }
};

module.exports = {
  verticalContentRenderer,
};
