const { trackEvent: trackAnalyticsEvent, trackPageview } = require('../../lib/analytics');
const { trackEvent: trackMelidataEvent } = require('../../lib/melidata');

const SEARCH_PREFIX = 'SEARCH';

const trackClickEvent = (analytics, melidata) => {
  if (melidata?.melidata_track) {
    trackMelidataEvent(melidata.melidata_track);
  }

  if (analytics) {
    trackAnalyticsEvent({
      category: analytics.analytics_track_category,
      action: analytics.analytics_track_action,
      label: analytics.analytics_track_label,
    });
  }
};

const trackZrp = ({ page } = {}) =>
  trackPageview({
    page: `/${SEARCH_PREFIX}/${page}/`,
  });

module.exports = {
  trackClickEvent,
  trackZrp,
};
