const React = require('react');
const { objectOf, shape, string, any, func } = require('prop-types');

const Button = require('@andes/button');

/* Other Options Button */
const OtherOptionsButton = (props) => {
  const { action, sendButtonTracks } = props;
  const { label, target, tracks } = action;

  return (
    <Button
      fullWidth
      hierarchy="quiet"
      href={target}
      onClick={tracks && (() => sendButtonTracks(action))}
      size="medium"
    >
      {label?.text}
    </Button>
  );
};

/* PropTypes */
OtherOptionsButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    target: string,
    tracks: objectOf(any),
  }).isRequired,
  sendButtonTracks: func.isRequired,
};

module.exports = OtherOptionsButton;
