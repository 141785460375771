const React = require('react');

const { renderPriceGroup, renderVerticalHighlightGroup, ContentSkeleton } = require('../common');
const {
  renderTitleGroup,
  renderHighlightInfo,
  renderShippingGroup,
  renderDetailsGroup,
  renderVariationsTextGroup,
  renderRebates,
  renderMeliCoinGroup,
  renderPillPromotions,
  renderPills,
  renderBottomPills,
  renderPdsInfoGroup,
} = require('../core');

const renderContent = (item, options, layout) => (
  <ContentSkeleton
    topComponents={[
      renderHighlightInfo(item),
      renderPriceGroup(item, options),
      renderMeliCoinGroup(item, layout),
      renderPillPromotions(item, layout),
      renderPills(item, layout),
      renderRebates(item),
      renderShippingGroup(item, layout),
      renderDetailsGroup(item),
      renderTitleGroup(item, options, layout, true),
      renderVerticalHighlightGroup(item),
      renderVariationsTextGroup(item),
      renderPdsInfoGroup(item),
      renderBottomPills(item, layout),
    ]}
  />
);

module.exports = renderContent;
