const { useContext } = require('react');

const { useSearch } = require('../../hooks/context');
const StaticPropsContext = require('../context/static-props');

const useBillboard = () => {
  const { lowEnd, billboardState } = useContext(StaticPropsContext);
  const { billboard, bookmark } = useSearch();

  return { billboard, lowEnd, billboardState, bookmark };
};

module.exports = useBillboard;
