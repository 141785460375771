const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--elipse-check';

const IconElipseCheck = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.7998 14.6667C12.4817 14.6667 15.4665 11.6819 15.4665 8C15.4665 4.3181 12.4817 1.33333 8.7998 1.33333C5.11791 1.33333 2.13314 4.3181 2.13314 8C2.13314 11.6819 5.11791 14.6667 8.7998 14.6667ZM8.7998 16C13.2181 16 16.7998 12.4183 16.7998 8C16.7998 3.58172 13.2181 0 8.7998 0C4.38153 0 0.799805 3.58172 0.799805 8C0.799805 12.4183 4.38153 16 8.7998 16Z"
      fill="#3483FA"
    />
    <path
      d="M7.72251 9.24098L11.7906 5.17285L12.7049 6.08709L7.72251 11.0695L4.89502 8.24197L5.80926 7.32773L7.72251 9.24098Z"
      fill="#3483FA"
    />
  </svg>
);

IconElipseCheck.propTypes = {
  className: string,
};

IconElipseCheck.defaultProps = {
  className: null,
};

module.exports = React.memo(IconElipseCheck);
