/* eslint-disable import/order */
const React = require('react');
const { string } = require('prop-types');
const { useContext, useState, useEffect } = require('react');

const get = require('lodash/get');

const useResults = require('./use-results');
const StaticPropsContext = require('../context/static-props');
const { LAYOUTS, VISUAL_IDS } = require('../../constants');

// Layout item
const DesktopLayoutItem = require('./renderer/layout-item/desktop');
const DesktopLayoutItemPI = require('./renderer/layout-item/desktop.pi');
const MapLayoutItem = require('./renderer/layout-item/map');
const OneColumnLayout = require('./columns/one-column');
const TwoColumnGridLayout = require('./columns/two-column-grid');
const ThreeColumnLayout = require('./columns/three-column');
const MapLayout = require('./columns/map');

const getLayoutByName = (name, useItemsPerRow) => {
  switch (name) {
    case LAYOUTS.TYPE_GRID:
      return useItemsPerRow === 3 ? ThreeColumnLayout : TwoColumnGridLayout;
    case LAYOUTS.TYPE_MAP:
      return MapLayout;

    default:
      return OneColumnLayout;
  }
};

const LayoutItems = {
  desktop: {
    [VISUAL_IDS.DEFAULT]: DesktopLayoutItem,
    [VISUAL_IDS.PI]: DesktopLayoutItemPI,
  },
  tablet: {
    [VISUAL_IDS.DEFAULT]: DesktopLayoutItem,
    [VISUAL_IDS.PI]: DesktopLayoutItemPI,
  },
};

const getLayoutItem = (layoutName, deviceType, visualId) => {
  if (layoutName === LAYOUTS.TYPE_MAP) {
    return MapLayoutItem;
  }

  return get(LayoutItems, `${deviceType}.${visualId || VISUAL_IDS.DEFAULT}`);
};
const LayoutManager = ({ deviceType, name, layout, ...rest }) => {
  const resultLength = deviceType === 'tablet' ? 2 : 3;
  const [useItemsPerRow, setUseItemsPerRow] = useState(resultLength);
  const { visualId } = useContext(StaticPropsContext);
  const LayoutComponent = layout || getLayoutByName(name, useItemsPerRow);
  const LayoutItemComponent = getLayoutItem(name, deviceType, visualId);

  useEffect(() => {
    const itemsPerRow = window.innerWidth < 1024 ? 2 : 3;

    setUseItemsPerRow(itemsPerRow);
  }, []);

  return <LayoutComponent layout={name} itemComponent={LayoutItemComponent} {...rest} />;
};

LayoutManager.propTypes = {
  deviceType: string,
  layout: string,
  name: string.isRequired,
};

LayoutManager.defaultProps = {
  layout: '',
  deviceType: '',
};

const LayoutManagerContainer = () => {
  const { currentLayout, results, layout_options } = useResults();
  const current = currentLayout === undefined ? layout_options.current : currentLayout.id;
  const { deviceType } = useContext(StaticPropsContext);

  return <LayoutManager name={current} results={results} deviceType={deviceType} />;
};

module.exports = LayoutManagerContainer;
module.exports.LayoutManager = LayoutManager;
