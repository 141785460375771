const React = require('react');
const { Children, cloneElement } = require('react');
const { bool, string, node } = require('prop-types');

const classnames = require('classnames');

const Separator = require('./separator');

const namespace = 'ui-search-item__group';

const Group = ({ className, children, name, noSeparator }) => {
  if (children && (!Array.isArray(children) || (children.length > 0 && children.some((ch) => !!ch)))) {
    return (
      <div className={classnames(namespace, name && `${namespace}--${name}`, className, 'shops__items-group')}>
        {Children.map(children, (child) => {
          if (!child) {
            return null;
          }

          return (
            <>
              {!noSeparator && <Separator />}
              {cloneElement(child, {
                className: classnames(`${namespace}__element`, child.props.className, 'shops__items-group-details'),
              })}
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

Group.propTypes = {
  children: node,
  className: string,
  name: string,
  noSeparator: bool,
};

Group.defaultProps = {
  className: null,
  children: null,
  noSeparator: false,
  name: null,
};

module.exports = Group;
