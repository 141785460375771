/* eslint-disable react/jsx-no-useless-fragment */
const React = require('react');
const PropTypes = require('prop-types');

const classnames = require('classnames');
const CardBlogCarousel = require('@seo-frontend-components/card-blog-carousel');
const CardBlogCarouselMobile = require('@seo-frontend-components/card-blog-carousel-mobile');

const { useStaticProps } = require('../../context/static-props');
const { useSearch } = require('../../../hooks/context');

const namespace = 'search-ui-seo-contents';

const BlogsIntervention = ({ isSeoIntervention, seo, title, seo_blogs, isMobile }) => {
  const blogs = seo_blogs.map((blog) => ({
    title: blog.title,
    url: blog.blog_id,
    image: {
      url: blog.image,
      alt: blog.alt_image,
    },
    description: blog.description,
    tags: blog.tags,
  }));

  const searchName = seo.h1;
  const blogsTitle = title && searchName ? `${title} ${searchName}` : '';

  return (
    <div className={classnames(`${namespace}__blogs-container ${isSeoIntervention ? 'seo-intervention-on' : ''}`)}>
      {isMobile ? (
        <CardBlogCarouselMobile title={blogsTitle} jsDisabled={false} supportWebP blogEntries={blogs} />
      ) : (
        <CardBlogCarousel title={blogsTitle} jsDisabled={false} supportWebP blogEntries={blogs} />
      )}
    </div>
  );
};

const BlogsComponent = ({ isSeoIntervention, seoBlogsInterventionObject }) => {
  const { deviceType } = useStaticProps();
  const { seo } = useSearch();
  const { title, seo_blogs } = seoBlogsInterventionObject;

  const isMobile = deviceType === 'mobile';

  return (
    <>
      {seoBlogsInterventionObject && (
        <BlogsIntervention
          isSeoIntervention={isSeoIntervention}
          seo={seo}
          title={title}
          seo_blogs={seo_blogs}
          isMobile={isMobile}
        />
      )}
    </>
  );
};

BlogsComponent.propTypes = {
  isSeoIntervention: PropTypes.bool.isRequired,
  seoBlogsInterventionObject: PropTypes.object.isRequired,
};
BlogsIntervention.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isSeoIntervention: PropTypes.bool.isRequired,
  seo: PropTypes.shape().isRequired,
  seo_blogs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

module.exports = BlogsComponent;
