const React = require('react');
const { arrayOf, string, shape } = require('prop-types');

const classnames = require('classnames');
const Image = require('nordic/image');
const get = require('lodash/get');
const Card = require('@andes/card');

const IconBadge = require('../../icons/badge');
const ReputationScale = require('../../reputation-scale/reputation-scale');

const namespace = 'ui-search-banner';

const ShowcaseBanner = ({ className, descriptions, title, sub_title, logo, reputation }) => (
  <div className={`${namespace}__wrapper`}>
    <Card className={classnames(namespace, className)}>
      {logo && (
        <div className={`${namespace}__logo`}>
          <Image alt={title.text} className={`${namespace}__image`} src={get(logo, 'pictures.2x')} lowEnd />
        </div>
      )}
      <div className={`${namespace}__first-block`}>
        <p className={`${namespace}__title`}>{title.text}</p>
        {sub_title && <p className={`${namespace}__subtitle`}>{sub_title.text}</p>}
        {descriptions.map(
          (description) =>
            description && (
              <p
                className={classnames(`${namespace}__description`)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: description.text }}
              />
            ),
        )}
      </div>
      {reputation && (
        <div className={`${namespace}__second-block`}>
          <p className={`${namespace}-reputation__title`}>{reputation.title}</p>
          <ReputationScale currentLevelID={reputation.level} />
          {reputation.text && (
            <p className={classnames(`ui-search-color--${reputation.text.color}`, `${namespace}-reputation__text`)}>
              {reputation.text.text}
            </p>
          )}
          {reputation.badge && (
            <div className={`${namespace}-reputation__badge-wrapper`}>
              <IconBadge />
              <div className={`${namespace}-reputation__badge-text`}>
                <p
                  className={classnames(
                    `ui-search-color--${reputation.badge.title.color}`,
                    `${namespace}-reputation__badge-title`,
                  )}
                >
                  {reputation.badge.title.text}
                </p>
                <p className={`${namespace}-reputation__badge-subtitle`}>{reputation.badge.sub_title.text}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </Card>
  </div>
);

ShowcaseBanner.propTypes = {
  className: string,
  descriptions: arrayOf(
    shape({
      text: string,
    }),
  ),
  logo: shape({
    pictures: shape({
      '2x': string.isRequired,
    }),
  }),
  reputation: shape({
    title: string,
    level: string,
    text: shape({
      text: string,
      color: string,
    }),
    badge: shape({
      sub_title: shape({
        text: string,
        color: string,
      }),
      title: shape({
        text: string,
        color: string,
      }),
    }),
  }),
  sub_title: shape({
    text: string,
  }),
  title: shape({
    text: string,
  }).isRequired,
};

ShowcaseBanner.defaultProps = {
  className: null,
  descriptions: [],
  logo: null,
  reputation: null,
  sub_title: null,
};

module.exports = ShowcaseBanner;
