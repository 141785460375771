const React = require('react');
const { useState, useRef, useEffect } = require('react');
const { string, bool } = require('prop-types');

const classnames = require('classnames');
const Snackbar = require('@andes/snackbar');

const FullSnackbar = require('./full-shipping-info');
const useIntersectionObserver = require('../../hooks/use-intersection-observer');
const { markWithCookie, isCookieMarked } = require('../../lib/cookies');

const COOKIE_NAME = 'searchFullCPG';
const namespace = 'ui-search-fullpopup';

const FullTopEmpty = ({ className, disabledByCookie, ...props }) => {
  let initialState = !isCookieMarked(COOKIE_NAME);

  const refCruce = useRef();

  const [isOnScreen] = useIntersectionObserver(refCruce, { threshold: 0.2 }, 0.1, true);

  if (disabledByCookie) {
    initialState = false;
  }

  const [visible, setVisible] = useState(initialState);

  useEffect(() => {
    if (visible) {
      document.querySelector('.nav-header').style.zIndex = 10;
    }
  }, [visible]);

  const disableBottom = () => {
    setVisible(false);
    document.querySelector('.nav-header').style = null;
    markWithCookie(COOKIE_NAME, 86400);
  };

  return (
    <div ref={refCruce}>
      <div
        className={classnames(
          className,
          {
            'ui-search-fullpopup-up-float': isOnScreen,
            'ui-search-fullpopup-up-sticky': !isOnScreen,
          },
          { 'ui-search-fullpopup-up-float-closeable': isOnScreen },
        )}
      >
        <Snackbar
          show={visible}
          className={classnames(namespace, `${namespace}-up`, {
            [`${namespace}-up--animate-show`]: visible,
            [`${namespace}-up--animate-hide`]: !visible,
          })}
        >
          <FullSnackbar closeAction={() => disableBottom()} {...props} />
        </Snackbar>
      </div>
    </div>
  );
};

FullTopEmpty.propTypes = {
  className: string,
  disabledByCookie: bool,
};

FullTopEmpty.defaultProps = {
  className: null,
  disabledByCookie: false,
};

module.exports = FullTopEmpty;
module.exports.COOKIE_NAME = COOKIE_NAME;
