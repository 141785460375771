const React = require('react');

const classnames = require('classnames');

const { useSearch } = require('../../hooks/context');
const ComponentList = require('../../utils/component-list/index');
const SeoContents = require('../seo-contents/seo-contents');

const SEO_CONTENT_INTERVENTION = 'SEO_CONTENT_INTERVENTION';
const SEO_BLOGS_INTERVENTION = 'SEO_BLOGS_INTERVENTION';

const availableComponents = {
  SEO_CONTENT_INTERVENTION: SeoContents,
};

const namespace = 'ui-search-footer-components';

const showReducedContainer = (footer_components) => {
  const seoContentIntervention = footer_components?.find((component) => component.id === SEO_CONTENT_INTERVENTION);
  const seoBlogs = seoContentIntervention?.items.find((intervention) => intervention.id === SEO_BLOGS_INTERVENTION);

  return seoContentIntervention?.items.length === 1 && seoBlogs?.seo_blogs.length < 4;
};

const FooterComponents = () => {
  const { footer_components } = useSearch();

  const showFooterComponents = footer_components && footer_components.length > 0;
  const reduceBlogsContainer = showReducedContainer(footer_components);

  if (!showFooterComponents) {
    return null;
  }

  return (
    <div className={classnames(namespace)}>
      <div className={classnames(`${namespace}__wrapper ${reduceBlogsContainer ? 'reduced--search' : ''}`)}>
        <div className={classnames(`${namespace}__container ${reduceBlogsContainer ? 'reduced--search' : ''}`)}>
          <ComponentList availableComponents={availableComponents} components={footer_components} />
        </div>
      </div>
    </div>
  );
};

module.exports = FooterComponents;
