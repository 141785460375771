/* eslint-disable react-hooks/exhaustive-deps */

const { useState, useLayoutEffect, useCallback } = require('react');

const get = require('lodash/get');
const merge = require('lodash/merge');

const { GRID, GALLERY } = require('../components/layout/renderer/constants');
const APIService = require('../../services/picturesCarousel');
const { REQUEST_STATES, dispatchResize } = require('./constants');

const getSelectedItem = (selectedVariationId, variationsArray, middlewareItem) => {
  const selectedVariationInfo = variationsArray.filter((variation) => variation.id === selectedVariationId).pop();
  const itemPropsToChange = selectedVariationInfo ? selectedVariationInfo.item : {};

  return merge({}, middlewareItem, itemPropsToChange);
};

const getSelectedVariationInfo = (selectedVariationId, variationsArray) =>
  variationsArray.filter((variationInfo) => variationInfo.id === selectedVariationId).pop();

const getSelectedItemPictures = (selectedItem, layout, requestedPictures, selectedVariationId) => {
  let carouselPictures = requestedPictures;

  if (selectedVariationId) {
    carouselPictures = requestedPictures.filter((picture) => picture.variation_id === selectedVariationId);
  }

  return [selectedItem.pictures[`${layout}`], ...carouselPictures.slice(1)];
};

const useItem = ({ index, middlewareItem, layout, onMount, cardRef }) => {
  const variationsArray = middlewareItem?.variations_picker?.options
    ? Array.from(middlewareItem.variations_picker.options)
    : [];

  const [carouselState, setCarouselState] = useState({
    requestedPictures: [],
    requestState: REQUEST_STATES.NOT_REQUESTED,
  });
  const selectedVariation = variationsArray.find((item) => item.is_selected);
  const [selectedVariationId, setSelectedVariationId] = useState(
    selectedVariation ? selectedVariation.id : get(variationsArray, '[0].id') || null,
  );
  const [selectedItem, setSelectedItem] = useState(middlewareItem);

  const selectedVariationInfo = getSelectedVariationInfo(selectedVariationId, variationsArray);

  const selectedItemPictures = getSelectedItemPictures(
    selectedItem,
    layout,
    carouselState.requestedPictures,
    selectedVariationId,
  );

  useLayoutEffect(() => {
    if (cardRef.current && onMount) {
      const imageWrapper = cardRef.current.querySelector('.ui-search-result__image');
      const contentWrapper = cardRef.current.querySelector('.ui-search-result__content');
      const imageHeight = imageWrapper.getBoundingClientRect().height;
      const contentHeight = contentWrapper.getBoundingClientRect().height;

      onMount({ ...selectedItem, index, imageHeight, contentHeight });
    }
  }, []);

  const isLoadingPictures = () => carouselState.requestState === REQUEST_STATES.LOADING;
  const hasLoadedPictures = () => carouselState.requestState === REQUEST_STATES.LOADED;

  const shouldLoadImages = (pictures_quantity) => {
    if (pictures_quantity && pictures_quantity <= 1) {
      return false;
    }

    return (layout === GRID || layout === GALLERY) && !isLoadingPictures() && !hasLoadedPictures();
  };

  const loadImagesCarousel = (itemId, pictures_quantity) => {
    if (shouldLoadImages(pictures_quantity)) {
      setCarouselState({ ...carouselState, requestState: REQUEST_STATES.LOADING });

      APIService.getCarouselPictures(
        itemId,
        variationsArray.map((v) => v.id),
      )
        .then((pictures) => {
          if (pictures && pictures.length > 1) {
            setCarouselState({ requestedPictures: pictures, requestState: REQUEST_STATES.LOADED });
            setTimeout(() => dispatchResize(), 0);
          }
        })
        .catch(() => {
          setCarouselState({ ...carouselState, requestState: REQUEST_STATES.ERROR });
        });
    }
  };

  const handleSelectVariation = useCallback(
    (variationId) => {
      setSelectedVariationId(variationId);

      const newItem = getSelectedItem(variationId, variationsArray, middlewareItem);

      setSelectedItem(newItem);
      loadImagesCarousel(newItem.id, newItem.pictures_quantity);
    },
    [middlewareItem, variationsArray],
  );

  const handleCardHover = useCallback(() => {
    loadImagesCarousel(selectedItem.id, selectedItem.pictures_quantity);
  }, [variationsArray, layout, carouselState, selectedItem]);

  return {
    middlewareItem,
    item: selectedItem,
    variations: variationsArray,
    selectedVariation: selectedVariationInfo,
    itemPictures: selectedItemPictures,
    isLoadingPictures,
    handleSelectVariation,
    handleCardHover,
  };
};

module.exports = useItem;
