const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--star ui-search-icon--star-half';

const IconStarHalf = ({ className }) => (
  <svg className={classnames(namespace, className)} width="100%" height="100%" viewBox="0 0 10 10" fill="#3483fa">
    <use href="#start-half" />
  </svg>
);

IconStarHalf.propTypes = {
  className: string,
};

IconStarHalf.defaultProps = {
  className: null,
};

module.exports = React.memo(IconStarHalf);
