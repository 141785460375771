const React = require('react');
const { string, shape, func, arrayOf } = require('prop-types');

const Tooltip = require('@andes/tooltip');
const { RadioGroup } = require('@andes/radio-button');
const RadioButton = require('@andes/radio-button');

const AlertSaveTooltip = ({ title, content, actions, trigger, handleChangeAlertFrequency }) => {
  const showSelected = () => content.options.find((item) => item.selected)?.value;

  const handleChange = (event) => {
    if (event.target.value === 'only_save') {
      return handleChangeAlertFrequency('daily', 'inactive');
    }

    return handleChangeAlertFrequency(event.target.value, 'active');
  };

  return (
    <Tooltip
      id="tooltip-id2"
      className="tooltip-periodicity"
      trigger="click"
      title={title}
      actions={actions}
      content={
        <RadioGroup onChange={handleChange} defaultValue={showSelected()} name="group1">
          {content.options.map((item) => (
            <RadioButton
              key={item.value}
              id={`id-${item.value}`}
              name={item.value}
              value={item.value}
              label={item.text}
            />
          ))}
        </RadioGroup>
      }
      offsetX={1}
      offsetY={8}
      side="bottomLeft"
    >
      {trigger}
    </Tooltip>
  );
};

AlertSaveTooltip.propTypes = {
  actions: arrayOf(shape({})),
  content: shape({}),
  handleChangeAlertFrequency: func,
  title: string,
  trigger: string,
};

AlertSaveTooltip.defaultProps = {
  actions: null,
  content: null,
  handleChangeAlertFrequency: () => undefined,
  title: '',
  trigger: '',
};

module.exports = AlertSaveTooltip;
