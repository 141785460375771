const React = require('react');
const { string, objectOf, any, bool } = require('prop-types');

const IconMap = require('../../../icons/map.desktop');
const FilterTooltip = require('../filters-tooltip/filters-tooltip.desktop');
const { trackClickEvent } = require('../../../map/track');

const namespace = 'ui-search-map';

const MapLinkDesktop = ({ url, label, melidata_track_value, analytics_track_event, tooltip, hasIcon }) => {
  const handleClick = () => {
    trackClickEvent(analytics_track_event, melidata_track_value);
  };

  return (
    <section>
      <a className={`${namespace}__link`} href={url} onClick={handleClick}>
        <FilterTooltip tooltip={tooltip}>
          {hasIcon && <IconMap className={`${namespace}__icon-map`} />}
          <span>{label}</span>
        </FilterTooltip>
      </a>
    </section>
  );
};

MapLinkDesktop.propTypes = {
  analytics_track_event: objectOf(any),
  hasIcon: bool,
  label: string.isRequired,
  melidata_track_value: objectOf(any),
  tooltip: objectOf(any),
  url: string.isRequired,
};

MapLinkDesktop.defaultProps = {
  analytics_track_event: null,
  hasIcon: true,
  melidata_track_value: null,
  tooltip: null,
};

module.exports = MapLinkDesktop;
