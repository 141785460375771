/* eslint-disable react-hooks/exhaustive-deps */

const { useEffect, useState } = require('react');

const { isCookieMarked } = require('../lib/cookies');

const HOTJAR_ACTIONS = {
  TRIGGER: 'trigger',
  TAG_RECORDING: 'tagRecording',
};

const POLLING_RATE = 1000;
const MAX_POLLED_TIMES = 5;

// Send hotjar events only if we previously set a cookie with
// the same name as the tag. This allows us to send hotjar events
// if some precondition or business rule has been met.
const useHotjarActionsByCookie = ({ extraData: { clientSideConfig: { tags = [] } = {} } = {} } = {}) => {
  const [areHjActionsAvailable, setAreHjActionsAvailable] = useState(false);

  useEffect(() => {
    if (!window || tags.length === 0) {
      return () => {};
    }

    let polledTimes = 0;

    const polling = setInterval(() => {
      if (window.hj) {
        setAreHjActionsAvailable(true);
        clearInterval(polling);
      }

      if (polledTimes >= MAX_POLLED_TIMES) {
        clearInterval(polling);
      }

      polledTimes += 1;
    }, POLLING_RATE);

    return () => {
      clearInterval(polling);
    };
  }, [setAreHjActionsAvailable]);

  useEffect(() => {
    if (!areHjActionsAvailable) {
      return;
    }

    const { hj } = window;
    const markedTags = [];

    tags.forEach((tag) => {
      if (isCookieMarked(tag)) {
        markedTags.push(tag);
        hj(HOTJAR_ACTIONS.TRIGGER, tag);
      }
    });

    hj(HOTJAR_ACTIONS.TAG_RECORDING, markedTags);
  }, [areHjActionsAvailable]);
};

module.exports = useHotjarActionsByCookie;
