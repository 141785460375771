const get = require('lodash/get');

const { trackEvent: trackAnalyticsEvent } = require('../../../lib/analytics');
const { trackEvent: trackMelidataEvent } = require('../../../lib/melidata');

// Common variables
const ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

// Common functions
const sendTracks = (response, itemId) => {
  if (response.tracks) {
    const { melidata_track, analytics_track } = response.tracks;

    // Process melidata tracks if they are present
    if (melidata_track) {
      melidata_track.clean = true;

      if (itemId) {
        melidata_track.event_data.item_id = itemId;
      }

      trackMelidataEvent(melidata_track);
    }

    // Process analytics tracks if they are present
    if (analytics_track) {
      trackAnalyticsEvent(analytics_track);
    }
  }
};

const setMelidataInfo = (action) => (quantity, tracks) => {
  tracks.melidata_track.event_data.items[0].quantity = quantity;
  tracks.melidata_track.path = get(tracks.path, action, ACTION.ADD);
  tracks.melidata_track.clean = true;
  trackMelidataEvent(tracks.melidata_track);
};

const setMelidataInfoAdd = setMelidataInfo(ACTION.ADD);
const setMelidataInfoRemove = setMelidataInfo(ACTION.REMOVE);

const requestQueueDefault = {
  pendingRequest: null,
  isRequestInProgress: false,
};

const PLACEHOLDER_FORMAT_REGEX = /\{\w+\}/gi;
const PLACEHOLDER_DELIMITERS_REGEX = /{|}/;

const getLabelText = (label = {}) => {
  const { text, values } = label;
  const result = [];

  if (values) {
    let match = PLACEHOLDER_FORMAT_REGEX.exec(text);

    while (match !== null) {
      const placeHolder = match[0];
      const valueKey = placeHolder.split(PLACEHOLDER_DELIMITERS_REGEX)[1];
      const valueProp = values?.find((value) => value.key === valueKey);

      result.push(valueProp.text);
      match = PLACEHOLDER_FORMAT_REGEX.exec(text);
    }
  } else if (text) {
    result.push(text);
  }

  return result;
};

module.exports = {
  sendTracks,
  setMelidataInfoAdd,
  setMelidataInfoRemove,
  requestQueueDefault,
  ACTION,
  getLabelText,
};
