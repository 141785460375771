const React = require('react');
const { string, shape } = require('prop-types');
const { useContext } = require('react');

const StyledLabel = require('../styled-label/styled-label');
const FreeShippingThContext = require('../context/free-shipping-th');

const namespace = 'ui-search-threshold-label';
const FULL_SUPER = 'FULL_SUPER';
const PARTNER = 'PARTNER';

const FreeShippingThresholdText = ({ groupBy, thresholdLabel, thresholdLabelState }) => {
  const { font_size: size, ...rest } = thresholdLabel;
  const { thresholdState, thresholdDispatch } = useContext(FreeShippingThContext);
  const { firstLoadSuper, firstLoadPartner, thresholdSuperLabel, thresholdPartnerLabel } = thresholdState;

  if (firstLoadSuper && groupBy === FULL_SUPER) {
    thresholdDispatch({
      type: 'FIRST_LOAD_SUPER',
      payload: {
        thresholdLabelState,
      },
    });
  } else if (firstLoadPartner && groupBy === PARTNER) {
    thresholdDispatch({
      type: 'FIRST_LOAD_PARTNER',
      payload: {
        thresholdLabelState,
      },
    });
  }

  if (groupBy === FULL_SUPER) {
    return thresholdSuperLabel === '' && thresholdLabel ? (
      <StyledLabel className="ui-search-threshold-label-initial" size={size} {...rest} />
    ) : (
      <StyledLabel key={thresholdSuperLabel.text} className={namespace} size={size} {...thresholdSuperLabel} />
    );
  }

  return thresholdPartnerLabel === '' && thresholdLabel ? (
    <StyledLabel className="ui-search-threshold-label-initial" size={size} {...rest} />
  ) : (
    <StyledLabel key={thresholdPartnerLabel.text} className={namespace} size={size} {...thresholdPartnerLabel} />
  );
};

FreeShippingThresholdText.propTypes = {
  groupBy: string,
  thresholdLabel: shape({
    id: string,
    text: string,
    color: string,
    font_size: string,
    font_weight: string,
  }).isRequired,
  thresholdLabelState: string,
};

FreeShippingThresholdText.defaultProps = {
  threshold_label: null,
};

module.exports = FreeShippingThresholdText;
