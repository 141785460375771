const React = require('react');
const { string, shape } = require('prop-types');

const classnames = require('classnames');

const IconSupermarketLogo = require('../../icons/supermarket-logo');
const IconInternationalLogo = require('../../icons/international-logo');

const SUPERMARKET_LOGO_ICON = 'supermarket_logo';
const INTERNATIONAL_LOGO = 'international_logo';

const namespace = 'ui-search-vertical-highlight';

const renderIcon = (icon) => {
  switch (icon.id) {
    case SUPERMARKET_LOGO_ICON:
      return <IconSupermarketLogo className={`${namespace}__icon`} {...icon} />;
    case INTERNATIONAL_LOGO:
      return <IconInternationalLogo className={`${namespace}__icon`} {...icon} />;

    default:
      return null;
  }
};

const VerticalHighlight = ({ className, id, icon }) => (
  <div className={classnames(namespace, className, `${namespace}--${id}`)}>{renderIcon(icon)}</div>
);

VerticalHighlight.propTypes = {
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }).isRequired,
  id: string.isRequired,
};

VerticalHighlight.defaultProps = {
  className: null,
};

module.exports = VerticalHighlight;
