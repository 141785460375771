const React = require('react');
const { number, bool, string } = require('prop-types');

const classNames = require('classnames');
const MoneyAmount = require('@andes/money-amount');

const { useStaticProps } = require('../context/static-props');
const {
  getThousandSeparator,
  getFormattedIntFraction,
  getFormattedDecimalFraction,
} = require('../../utils/get-money-amount');

const RenderPrice = ({ className, amount, ...props }) => {
  const { previous, font_size: size, color } = props;
  const { siteId, country } = useStaticProps();
  const { currency_id } = country;
  const thousandSeparator = getThousandSeparator(siteId);
  const newAmount = getFormattedIntFraction(amount, thousandSeparator);
  const amountDecimal = getFormattedDecimalFraction(amount);

  return (
    <span
      style={{ color }}
      className={classNames(className, { [`ui-row-weight--${props.font_weight}`]: !!props.font_weight })}
    >
      <MoneyAmount
        centsType="superscript"
        currencyId={currency_id}
        value={{
          fraction: newAmount,
          cents: amountDecimal,
        }}
        currentValue={{ centsType: 'superscript' }}
        previous={previous}
        size={size}
      />
    </span>
  );
};

RenderPrice.defaultProps = {
  previous: false,
};
RenderPrice.propTypes = {
  amount: number.isRequired,
  className: string,
  color: string,
  font_size: number,
  font_weight: string,
  previous: bool,
};

module.exports.RenderPrice = RenderPrice;
