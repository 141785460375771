const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-list-desktop';

const IconListDesktop = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.599,1.40067031 L14.599,5.39467031 L14.6,5.39490439 L14.6,6.59490439 L14.599,6.59467031 L14.599,9.39667031 L14.6,9.39755201 L14.6,10.597552 L14.599,10.5966703 L14.599,14.5993297 L1.399,14.5993297 L1.399,1.40067031 L14.599,1.40067031 Z M13.399,10.5966703 L2.599,10.5966703 L2.599,13.3993297 L13.399,13.3993297 L13.399,10.5966703 Z M13.399,6.59467031 L2.599,6.59467031 L2.599,9.39667031 L13.399,9.39667031 L13.399,6.59467031 Z M13.399,2.60067031 L2.599,2.60067031 L2.599,5.39467031 L13.399,5.39467031 L13.399,2.60067031 Z"
    />
  </svg>
);

IconListDesktop.propTypes = {
  className: string,
};

IconListDesktop.defaultProps = {
  className: null,
};

module.exports = IconListDesktop;
