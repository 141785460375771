/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { useEffect, useState } = require('react');
const { elementType, node, string } = require('prop-types');

const { Loader: GMapsLoader } = require('../../../lib/google-maps-loader');

const Loader = ({ children, apiKey, language, ErrorComponent }) => {
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loader = new GMapsLoader({ apiKey, language });
    const markAsReady = () => setIsReady(true);

    loader.load().then(markAsReady).catch(setError);
  }, []);

  if (error) {
    return <ErrorComponent error={error} />;
  }

  return isReady ? children : null;
};

Loader.propTypes = {
  apiKey: string.isRequired,
  children: node.isRequired,
  ErrorComponent: elementType.isRequired,
  language: string,
};

module.exports = Loader;
