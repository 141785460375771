const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const classnames = require('classnames');

const IconGalleryDesktop = require('../icons/gallery.desktop');
const IconListDesktop = require('../icons/list.desktop');
const Link = require('../link');
const { stack, gallery, grid, testId } = require('./constants');

const namespace = 'ui-search-view-change';

const getLayoutIconComponent = (id) => {
  switch (id) {
    case gallery:
    case grid:
      return <IconGalleryDesktop />;
    case stack:

    // falls through
    default:
      return <IconListDesktop />;
  }
};

const ViewChange = ({ views, currentLayout }) => (
  <div className={namespace} data-testid={testId} aria-hidden>
    {views?.map((view) => (
      <Link
        href={`${view.url_host}${view.url_path}`}
        keepLayout={false}
        className={classnames(`${namespace}__link`, { [`${namespace}__link--active`]: view.id === currentLayout })}
      >
        {getLayoutIconComponent(view.id)}
      </Link>
    ))}
  </div>
);

ViewChange.propTypes = {
  currentLayout: string.isRequired,
  views: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      url_host: string.isRequired,
      url_path: string.isRequired,
    }),
  ).isRequired,
};

module.exports = ViewChange;
