const React = require('react');
const { arrayOf, func, element, oneOfType, string, shape } = require('prop-types');

const GridLayout = require('../grid-rows');

const ThreeColumnLayout = ({ itemComponent, layout, results }) => (
  <GridLayout itemComponent={itemComponent} layout={layout} results={results} cols={3} />
);

ThreeColumnLayout.propTypes = {
  itemComponent: oneOfType([element, func]).isRequired,
  layout: string.isRequired,
  results: arrayOf(
    shape({
      title: string,
    }),
  ),
};

ThreeColumnLayout.defaultProps = {
  results: null,
};

module.exports = ThreeColumnLayout;
