const React = require('react');
const { string, number } = require('prop-types');

const classnames = require('classnames');

const Price = require('../price');

const namespace = 'ui-search-item__pds-best-price';

const PdsBestPrice = ({ className, amount, currency_id }) => (
  <Price
    amount={amount}
    currency_id={currency_id}
    size="x-tiny"
    className={classnames(namespace, className, 'ui-search-color--black')}
    showDecimals={false}
  />
);

PdsBestPrice.propTypes = {
  amount: number.isRequired,
  className: string,
  currency_id: string.isRequired,
};

PdsBestPrice.defaultProps = {
  className: null,
};

module.exports = PdsBestPrice;
