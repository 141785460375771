const React = require('react');
const { arrayOf, elementType, shape } = require('prop-types');

const { Snackbar } = require('../snackbar/snackbar');
const useMapConfig = require('./hooks/use-map-config');
const { List, Filters, Map, Zrp } = require('./desktop');
const { useMapView } = require('./hooks/use-map-view');
const { useSelectedItem } = require('./hooks/use-selected-item');

const namespace = 'ui-search-layout--map';

const MapDesktopContainer = ({ itemComponent, results }) => {
  const { error, zrp, filters, newFacetedSearch } = useMapConfig();
  const { applyNewFilters, isLoading, fitMarkersInMap, fixedCoords, updateResults, reloadResults, showZrp, showError } =
    useMapView({ results });
  const { selectedMarker, selectedResults, selectedResultsIndex, setSelectedMarker } = useSelectedItem();
  const isClusterSelected = selectedResults && selectedResults.length > 1;
  const handleItemHover = (index) => {
    setSelectedMarker(isClusterSelected ? selectedResultsIndex : index);
  };

  return (
    <>
      <div className={namespace}>
        <div className={`${namespace}__left-content`}>
          {showZrp ? (
            <Zrp title={zrp.title} description={zrp.message} />
          ) : (
            <List
              ItemComponent={itemComponent}
              results={selectedResults || results}
              isClusterSelected={isClusterSelected}
              onItemHover={handleItemHover}
              onUnselectCluster={() => setSelectedMarker(null, null)}
              isLoadingResults={isLoading}
            />
          )}
        </div>
        <div className={`${namespace}__right-content`}>
          <Filters
            isLoading={isLoading}
            filters={filters}
            newFacetedSearch={newFacetedSearch}
            onPrimaryFilterChange={applyNewFilters}
          />
          <div className={`${namespace}__right-content--map`}>
            <Map
              fitMarkersInMap={fitMarkersInMap}
              fixedCoords={fixedCoords}
              isLoadingResults={isLoading}
              markers={results}
              updateResults={updateResults}
              selectedMarker={selectedMarker}
              selectedCluster={selectedResultsIndex}
              setSelectedMarker={setSelectedMarker}
            />
          </div>
        </div>
      </div>

      {showError && (
        <Snackbar
          action={{ text: error.action, onClick: reloadResults }}
          delay={null}
          message={error.message}
          color="red"
        />
      )}
    </>
  );
};

MapDesktopContainer.propTypes = {
  itemComponent: elementType.isRequired,
  results: arrayOf(shape()),
};

MapDesktopContainer.defaultProps = {
  results: [],
};

module.exports = MapDesktopContainer;
