const React = require('react');
const { Component } = require('react');
const { element, string } = require('prop-types');

const ErrorComponent = require('./error-component');

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  /* eslint-disable-next-line class-methods-use-this */
  componentDidCatch(error) {
    const { newrelic } = window || {};

    if (newrelic) {
      newrelic.noticeError(error, {
        stack: error.stack,
        message: error.message,
      });
    }
  }

  render() {
    const { children, siteId } = this.props;

    return this.state.hasError ? <ErrorComponent siteId={siteId} /> : children;
  }
}

ErrorBoundary.propTypes = {
  children: element.isRequired,
  siteId: string.isRequired,
};

module.exports = ErrorBoundary;
