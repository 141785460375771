const React = require('react');
const { string, shape, arrayOf, func } = require('prop-types');

const Button = require('@andes/button');

const namespace = 'ui-search-rescue';
const ActionsRescue = ({ actions, markWithCookie }) => {
  const setCookie = () => {
    if (markWithCookie) {
      markWithCookie('pr_categ', 86400);
    }
  };

  const Actions = actions.map((action) => {
    switch (action.type) {
      case 'button':
        return (
          <Button
            key={action.label.text}
            className={`${namespace}__button`}
            href={action.target}
            hierarchy="loud"
            onClick={setCookie}
          >
            {action.label.text}
          </Button>
        );

      case 'button_self':
        return (
          <Button
            key={action.label.text}
            className={`${namespace}__button`}
            href={action.target}
            hierarchy="loud"
            onClick={() => {
              setCookie();
              action?.onClick();
            }}
          >
            {action.label.text}
          </Button>
        );

      case 'link':
        return (
          <a
            key={action.label.text}
            className={`${namespace}__link--decline`}
            href={action.target}
            target="_blank"
            rel="noreferrer"
          >
            {action.label.text}
          </a>
        );

      default:
        return null;
    }
  });

  return <> {Actions} </>;
};

ActionsRescue.propTypes = {
  actions: arrayOf(
    shape({
      type: string,
      target: string,
      label: shape({
        text: string,
      }),
    }),
  ).isRequired,
  markWithCookie: func,
};

ActionsRescue.defaultProps = {
  actions: [],
};

module.exports = ActionsRescue;
