const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-variations-pill';

const VariationsPill = ({ className, text }) => <div className={classnames(namespace, className)}>{text}</div>;

VariationsPill.propTypes = {
  className: string,
  text: string.isRequired,
};

VariationsPill.defaultProps = {
  className: null,
};

module.exports = VariationsPill;
