const React = require('react');
const { useState } = require('react');
const { node, bool } = require('prop-types');

const EshopsContext = React.createContext({
  isEshopsEnvironment: null,
  setEshopsEnvironment: null,
});

const { Provider, Consumer: EshopsEnvironmentConsumer } = EshopsContext;

const EshopsEnvironmentProvider = ({ children, value }) => {
  const [isEshopsEnvironment, setEshopsEnvironment] = useState(value);

  const state = {
    isEshopsEnvironment,
    setEshopsEnvironment,
  };

  return <Provider value={state}>{children}</Provider>;
};

EshopsEnvironmentProvider.propTypes = {
  children: node.isRequired,
  value: bool,
};

EshopsEnvironmentProvider.defaultProps = {
  value: false,
};

module.exports = EshopsContext;
module.exports.EshopsEnvironmentProvider = EshopsEnvironmentProvider;
module.exports.EshopsEnvironmentConsumer = EshopsEnvironmentConsumer;
