const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--alert-new';

const IconAlertNew = ({ className }) => (
  <svg
    width="21"
    className={classnames(namespace, className)}
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_1049_104165" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4914 12.8471V12.8507V17.8113H18.0887V19.1446H2.75537V17.8113H5.46859V12.8507V12.8471C5.46859 10.0794 7.71226 7.83569 10.48 7.83569C13.2477 7.83569 15.4914 10.0794 15.4914 12.8471Z"
      />
    </mask>
    <path
      d="M15.4914 17.8113H14.2914V19.0113H15.4914V17.8113ZM18.0887 17.8113H19.2887V16.6113H18.0887V17.8113ZM18.0887 19.1446V20.3446H19.2887V19.1446H18.0887ZM2.75537 19.1446H1.55537V20.3446H2.75537V19.1446ZM2.75537 17.8113V16.6113H1.55537V17.8113H2.75537ZM5.46859 17.8113V19.0113H6.66859V17.8113H5.46859ZM16.6914 12.8507V12.8471H14.2914V12.8507H16.6914ZM16.6914 17.8113V12.8507H14.2914V17.8113H16.6914ZM15.4914 19.0113H18.0887V16.6113H15.4914V19.0113ZM16.8887 17.8113V19.1446H19.2887V17.8113H16.8887ZM18.0887 17.9446H2.75537V20.3446H18.0887V17.9446ZM3.95537 19.1446V17.8113H1.55537V19.1446H3.95537ZM2.75537 19.0113H5.46859V16.6113H2.75537V19.0113ZM4.26859 12.8507V17.8113H6.66859V12.8507H4.26859ZM4.26859 12.8471V12.8507H6.66859V12.8471H4.26859ZM10.48 6.63569C7.04952 6.63569 4.26859 9.41663 4.26859 12.8471H6.66859C6.66859 10.7421 8.37501 9.03569 10.48 9.03569V6.63569ZM16.6914 12.8471C16.6914 9.41663 13.9104 6.63569 10.48 6.63569V9.03569C12.585 9.03569 14.2914 10.7421 14.2914 12.8471H16.6914Z"
      fill="#3483FA"
      mask="url(#path-1-inside-1_1049_104165)"
    />
    <path d="M6.68887 15.9112H14.6555V16.0445H6.68887V15.9112Z" stroke="#3483FA" strokeWidth="1.2" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1175 2.81136V5.49426H9.91748V2.81136H11.1175Z" fill="#3483FA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.44 5.55617L14.5429 7.45326L13.6943 6.60474L15.5914 4.70764L16.44 5.55617Z"
      fill="#3483FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.18629 4.60765L7.08339 6.50474L6.23486 7.35327L4.33777 5.45617L5.18629 4.60765Z"
      fill="#3483FA"
    />
  </svg>
);

IconAlertNew.propTypes = {
  className: string,
};

IconAlertNew.defaultProps = {
  className: null,
};

module.exports = IconAlertNew;
