/* eslint-disable react-hooks/exhaustive-deps */
const { useMemo } = require('react');

const { INFOWINDOW_MARGIN } = require('./constants');

const DEFAULT_STYLES = { position: 'absolute', opacity: 0 };
const DEFAULT_PLACEMENT = { top: 0, left: 0 };
const MIN_CONTAINER_HEIGHT = 1;

// calculateTop and calculateLeft transform the `top` and `left` values
// relative to the container to absolute units in reference to the document.
const calculateTop = ({ height, containerHeight, containerY, pointY }) => {
  const positionFactor = pointY / Math.max(containerHeight, MIN_CONTAINER_HEIGHT);
  const verticalOffset = height * positionFactor;
  const verticalPositionInContainer = pointY - verticalOffset;

  return containerY + verticalPositionInContainer;
};

const calculateLeft = ({ width, containerWidth, containerX, pointX }) => {
  const alignmentBreakpoint = containerX + containerWidth / 2;
  const pointPositionInDom = containerX + pointX;
  const isMarkerLeftAligned = pointPositionInDom < alignmentBreakpoint;
  const horizontalOffset = isMarkerLeftAligned ? INFOWINDOW_MARGIN : (width + INFOWINDOW_MARGIN) * -1;

  return pointPositionInDom + horizontalOffset;
};

const useInfowindowStyles = ({ containerMeasurements, domNode, point }) => {
  const { height, width, ...styles } = useMemo(() => {
    if (domNode) {
      const dimensions = domNode.getBoundingClientRect();

      return {
        ...DEFAULT_STYLES,
        opacity: 1,
        height: dimensions.height,
        width: dimensions.width,
      };
    }

    return DEFAULT_STYLES;
  }, [domNode]);

  const coords = useMemo(() => {
    if (!width || !height) {
      return DEFAULT_PLACEMENT;
    }

    const { x: pointX, y: pointY } = point;
    const { height: containerHeight, width: containerWidth, x: containerX, y: containerY } = containerMeasurements;

    return {
      top: calculateTop({ height, containerHeight, containerY, pointY }),
      left: calculateLeft({ width, containerWidth, containerX, pointX }),
    };
  }, [point.x, point.y, width, height]);

  return { ...styles, ...coords };
};

module.exports = useInfowindowStyles;
