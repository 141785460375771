const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--black-friday';

const BlackFriday = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FFF">
        <g transform="translate(-15 -61) translate(11 57) translate(4 4)">
          <path
            fillRule="nonzero"
            d="M8.59 1.695L7.745 0 6 .872 4.256 0 3.41 1.695H1.695V3.41L0 4.256.872 6 0 7.744l1.695.847v1.714H3.41L4.256 12 6 11.127 7.744 12l.847-1.695h1.714V8.59L12 7.744 11.127 6 12 4.256l-1.695-.847V1.695H8.59zm-1.26-.458l.691 1.38h1.361V3.98l1.38.69L10.097 6l.665 1.33-1.38.691v1.361h-1.36l-.691 1.38L6 10.097l-1.331.665-.69-1.38H2.618v-1.36L1.237 7.33 1.903 6l-.666-1.331 1.38-.69V2.618H3.98l.69-1.381L6 1.903l1.33-.666z"
          />
          <path fillRule="nonzero" d="M7.815 3.429L8.451 4.065 4.065 8.451 3.429 7.815z" />
          <circle cx="4.521" cy="4.521" r="1" />
          <circle cx="7.521" cy="7.521" r="1" />
        </g>
      </g>
    </g>
  </svg>
);

BlackFriday.propTypes = {
  className: string,
};

BlackFriday.defaultProps = {
  className: null,
};

module.exports = BlackFriday;
