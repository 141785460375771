const { useSearch } = require('../../hooks/context');

const useValuePropositionsDetails = () => {
  const { value_propositions_details, valuePropositionsDetailsHidden } = useSearch();

  if (!valuePropositionsDetailsHidden && value_propositions_details) {
    return value_propositions_details;
  }

  return null;
};

module.exports = useValuePropositionsDetails;
