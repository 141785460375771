const React = require('react');
const { shape, string } = require('prop-types');

const cx = require('classnames');

const ItemTitle = require('../../../../card/title');
const HightlightLabel = require('../../../../card/highlight/highlight-label');
const { Price } = require('../common/price');
const { Attributes } = require('../common/attributes');
const { namespace } = require('../../constants');
const { DEVICE_TYPES } = require('../../../../../constants');
const { renderHighlightGroup } = require('../../desktop-card-content/common');

const { MOBILE, DESKTOP } = DEVICE_TYPES;
const ITEM_SEPARATOR = '·';

const ResContent = ({
  item: { highlight, media, price, discountSource, from, location, descriptions, title, sub_title, available_units },
  device,
}) => (
  <div className={`${namespace}__content`}>
    {renderHighlightGroup({ highlight })}
    {media && <HightlightLabel {...media} />}
    <ItemTitle
      className={cx(`${namespace}__content-title`, `${namespace}__content-separator--4`)}
      title={device === DESKTOP ? sub_title : title}
    />
    <Price className={`${namespace}__content-separator--4`} price={price} from={from} discountSource={discountSource} />
    {descriptions && (
      <Attributes
        className={cx({
          [`${namespace}__content-separator--2`]: device === MOBILE,
        })}
        attributes={descriptions}
        separator={ITEM_SEPARATOR}
      />
    )}
    <div className={`${namespace}__content-location`}>{location}</div>
    {available_units && <div className={`${namespace}__available-units`}>{available_units}</div>}
  </div>
);

ResContent.propTypes = {
  device: string.isRequired,
  item: shape({
    title: string,
  }).isRequired,
};

module.exports = {
  ResContent,
};
