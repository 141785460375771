/* eslint-disable react/prop-types */
const React = require('react');

const { loadable } = require('nordic/lazy');

const SeoTopKeywordsIntervention = require('../../seo-top-keywords-intervention/seo-top-keywords-intervention.mobile');

const FilterIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-filter" */
    '../../filter-intervention/filter-intervention.mobile'
  ),
);

const QueryIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-query" */
    '../../query/query-intervention.mobile'
  ),
);

const ContentIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-content" */
    '../../content/content-intervention.mobile'
  ),
);

const CarouselIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-carousel" */
    '../../carousel/carousel-intervention'
  ),
);

const BillboardIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-billboard" */
    '../../billboard/billboard-intervention'
  ),
);

const CompatsIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-compats" */
    '../../compats/compats-intervention'
  ),
);

const BrandAdsMobile = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-ads" */
    '../../../brand-ads/brand-ads.mobile'
  ),
);

const ShoppingCartIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-shopping-cart" */
    '../../shopping-cart'
  ),
);

const TrendIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-trend" */
    '../../trend-intervention/trend-intervention.mobile'
  ),
);

const BrandDiscoIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-brand-disco" */
    '../../brand-disco/brand-disco.mobile'
  ),
);

const MeliPlayIntervention = loadable(async () =>
  import(
    /* webpackChunkName: "chunk-intervention-meliplay" */
    '../../meliplay/stack/meliplay-intervention.mobile'
  ),
);

const renderInterventionsMobile = {
  FILTER_INTERVENTION: ({ item }) => <FilterIntervention intervention={item} />,
  QUERY_INTERVENTION: ({ item }) => <QueryIntervention intervention={item} />,
  CONTENT_INTERVENTION: ({ item }) => <ContentIntervention intervention={item} />,
  CAROUSEL_INTERVENTION: ({ item }) => <CarouselIntervention intervention={item} />,
  BILLBOARD_INTERVENTION: ({ item }) => <BillboardIntervention intervention={item} />,
  COMPATS_INTERVENTION: ({ item }) => <CompatsIntervention intervention={item} />,
  BRAND_ADS_INTERVENTION: ({ item }) => <BrandAdsMobile intervention={item} />,
  CART_INTERVENTION: ({ item }) => <ShoppingCartIntervention intervention={item} />,
  TREND_INTERVENTION: ({ item }) => <TrendIntervention intervention={item} />,
  BRAND_DISCO_INTERVENTION: ({ item, position }) => <BrandDiscoIntervention intervention={item} position={position} />,
  MELIPLAY_INTERVENTION: ({ item, position }) => <MeliPlayIntervention intervention={item} position={position} />,
  SEO_TOP_KEYWORDS_INTERVENTION: ({ item }) => <SeoTopKeywordsIntervention {...item} />,
};

module.exports = {
  renderInterventionsMobile,
};
