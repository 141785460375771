const { useContext } = require('react');

const SuggestContext = require('../context/suggest-cpg');

const useSuggestions = () => {
  const { openSuggestions, setOpenSuggestions, suggestions, cartFetching } = useContext(SuggestContext);

  return { openSuggestions, setOpenSuggestions, suggestions, cartFetching };
};

module.exports = useSuggestions;
