const React = require('react');

const { loadable } = require('nordic/lazy');

const useDiscount = require('./use-discount');
const useMessage = require('../../hooks/use-message');

const localStorageKey = 'boleto-discount-message';

const ChunkDiscountMessage = () => {
  const { discount_ticket_data } = useDiscount();
  const DiscountMessage = loadable(async () => import(/* webpackChunkName: "chunk-discount-message" */ './index'));

  const [visible, handleClose] = useMessage(localStorageKey);

  const { label = {} } = discount_ticket_data ? discount_ticket_data.properties : {};

  return discount_ticket_data && visible ? <DiscountMessage handleClose={handleClose} label={label} /> : null;
};

module.exports = ChunkDiscountMessage;
