const React = require('react');

const { loadable } = require('nordic/lazy');

const useCrossSite = require('./use-cross-site');

const ChunkCrossSiteModal = () => {
  const crossSiteProps = useCrossSite();
  const CrossSiteModal = loadable(async () =>
    import(/* webpackChunkName: "chunk-cross-site-modal" */ './cross-site-modal'),
  );

  return crossSiteProps ? <CrossSiteModal {...crossSiteProps} /> : null;
};

module.exports = ChunkCrossSiteModal;
