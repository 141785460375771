const React = require('react');
const { shape, string, number } = require('prop-types');

const ProgressBarDefault = ({ namespace, progress_bar }) => (
  <div className={`${namespace}__progressbar-container`}>
    <div className={`${namespace}__progressbar-sizer`} style={{ width: `${progress_bar.percent}%` }}>
      <div className={`${namespace}__progressbar`} style={{ backgroundColor: progress_bar.color_hex }} />
    </div>
  </div>
);

ProgressBarDefault.propTypes = {
  namespace: string.isRequired,
  progress_bar: shape({
    color_hex: string,
    percent: number,
  }),
};

module.exports = ProgressBarDefault;
