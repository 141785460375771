const React = require('react');
const { arrayOf, shape, string } = require('prop-types');

const FilterRange = require('../../../../../filters/range-filters/range-filters.desktop');

const RenderFilterRange = ({ values, url_templates, labels, modifier, activeCurrencyId, type, fragment }) => (
  <FilterRange
    labels={labels}
    values={values}
    url={url_templates}
    activeCurrencyId={activeCurrencyId}
    type={type}
    id={modifier}
    fragment={fragment}
    className={`ui-search-filter-range--${modifier}`}
  />
);

RenderFilterRange.propTypes = {
  activeCurrencyId: string,
  fragment: string,
  labels: arrayOf(shape({})),
  modifier: string,
  type: string,
  url_templates: string,
  values: arrayOf(shape({})),
};

RenderFilterRange.defaultProps = {
  activeCurrencyId: '',
  fragment: '',
  labels: null,
  modifier: '',
  type: '',
  url_templates: '',
  values: null,
};

module.exports = RenderFilterRange;
