/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */

const React = require('react');
const { string, shape, func, number, oneOf } = require('prop-types');
const { useContext, useEffect } = require('react');

const classnames = require('classnames');

const IconFull = require('../icons/full');
const ShippingTruck = require('../icons/shipping-truck');
const IconEshop = require('../icons/eshop');
const IconClose = require('../icons/close');
const FullFreeshippingContext = require('../context/full-freeshipping');
const IconSupermarketFS = require('../icons/supermarket-fs');
const PogressBarEshops = require('./progress-bar-eshops');
const ProgressBarDefault = require('./progress-bar-default');
const IconFactory = require('../icons/factory-builder')([IconSupermarketFS, IconFull]);

const namespace = 'ui-search-fullsnackbar';

const ESHOPS = 'eshops';
const DEFAULT = 'default';

const progressBarVariant = {
  [ESHOPS]: PogressBarEshops,
  [DEFAULT]: ProgressBarDefault,
};

const getProgressBarVariant = (variant) => progressBarVariant[variant];

const getTypeFromProgressBar = (progress_bar) => {
  if (!progress_bar) {
    return 'zero';
  }

  switch (progress_bar.color) {
    case 'neutral':
      if (progress_bar.percent > 0) {
        return 'progress';
      }

      return 'zero';
    case 'success':
      return 'complete';
    case 'warning':
      return 'error';

    default:
      return 'zero';
  }
};

const FullSnackbar = ({ className, title, progress_bar, epigraph, closeAction, asset_url, variant }) => {
  const { fullOnlyFirstFreeshipping, setFullOnlyFirstFreeshipping } = useContext(FullFreeshippingContext);
  const sucess = progress_bar?.color === 'success';
  const warning = progress_bar?.color === 'warning';
  const firstText = title?.first_text?.trim();
  const lastText = title?.last_text?.trim();

  useEffect(() => {
    if (fullOnlyFirstFreeshipping) {
      setFullOnlyFirstFreeshipping(
        (sucess && fullOnlyFirstFreeshipping.successFirst) || (warning && fullOnlyFirstFreeshipping.warningFirst),
      );
    }

    return () => {
      if (sucess || warning) {
        setFullOnlyFirstFreeshipping({ successFirst: sucess, warningFirst: warning });
      }
    };
  }, [progress_bar]);

  const ProgressBar = getProgressBarVariant(variant);

  return (
    <div
      className={classnames(namespace, className, `ui-search-fullsnackbar--${getTypeFromProgressBar(progress_bar)}`, {
        [`${namespace}--complete-first`]: fullOnlyFirstFreeshipping,
      })}
    >
      {asset_url && (
        <div className={`${namespace}__eshop-container`}>
          <IconEshop image={asset_url} />
        </div>
      )}

      {!asset_url && title && (
        <div className={`${namespace}__shippingtruck-container`} style={{ backgroundColor: progress_bar?.color_hex }}>
          <ShippingTruck />
        </div>
      )}

      <div className={`${namespace}__main`}>
        {title && (
          <div className={`${namespace}__top`}>
            {title.first_text && <span className={`${namespace}__preicon`}>{firstText}</span>}
            {title.asset_image && IconFactory(title.asset_image)}
            {title.last_text && <span className={`${namespace}__posticon`}>{lastText}</span>}
          </div>
        )}
        {progress_bar !== null && progress_bar.percent > 0 && (
          <ProgressBar progress_bar={progress_bar} namespace={namespace} />
        )}
        {epigraph?.first_text && (
          <div className={`${namespace}__bottom`}>
            <span className={`${namespace}__subtext`} dangerouslySetInnerHTML={{ __html: epigraph.first_text }} />
          </div>
        )}
      </div>
      {closeAction && (
        <button className={`${namespace}__close`} onClick={closeAction} type="button" tabIndex={0}>
          <IconClose />
        </button>
      )}
    </div>
  );
};

FullSnackbar.propTypes = {
  asset_url: string,
  className: string,
  closeAction: func,
  epigraph: shape({
    first_text: string,
    last_text: string,
  }),
  progress_bar: shape({
    percent: number,
    color: string,
    color_hex: string,
  }),
  title: shape({
    first_text: string,
    last_text: string,
    asset_image: string,
  }),
  variant: oneOf([ESHOPS, DEFAULT]),
};

FullSnackbar.defaultProps = {
  epigraph: null,
  className: null,
  closeAction: null,
  progress_bar: null,
  title: null,
  variant: DEFAULT,
};

module.exports = FullSnackbar;
