const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--map-zoom-out';

const IconZoomOut = ({ className }) => (
  <svg className={classnames(namespace, className)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
    <path d="M1.99902 8.60039H13.999V7.40039H1.99902V8.60039Z" fill="#3483FA" />
  </svg>
);

IconZoomOut.propTypes = {
  className: string,
};

IconZoomOut.defaultProps = {
  className: null,
};

module.exports = IconZoomOut;
