const React = require('react');
const PropTypes = require('prop-types');

const classNames = require('classnames');

const PricePart = require('./price-part');
const IconLoyalty = require('../../icons/loyalty');
const StaticPropsContext = require('../../context/static-props');

const namespace = 'ui-search-price';

const { bool, string, number, oneOf, shape } = PropTypes;

const Price = ({
  bold,
  className,
  decimalSeparator,
  original_price,
  size,
  currency_id,
  thousandSeparator,
  amount,
  discount_label,
  discountSource,
  from,
  showDecimals,
  showOriginalValue,
  billing,
}) => {
  const { country } = React.useContext(StaticPropsContext);
  const { currencies } = country;
  const { symbol } = currencies[`${currency_id}`];
  const defaultThousandSeparator = country.thousands_separator;
  const defaultDecimalSeparator = country.decimal_separator;
  const computedThousandSeparator = thousandSeparator || defaultThousandSeparator;
  const computedDecimalSeparator = decimalSeparator || defaultDecimalSeparator;

  return (
    <div className={classNames(namespace, `${namespace}--size-${size}`, className, 'shops__price')}>
      {showOriginalValue && (
        <PricePart
          className={`${namespace}__original-value`}
          value={original_price}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          strikeThrough
          billing={billing}
          size="small"
        />
      )}
      {from && <div className={`${namespace}__front-price`}>{from.text}</div>}
      <div
        className={classNames(`${namespace}__second-line`, 'shops__price-second-line', {
          [`${namespace}__second-line--bold`]: bold,
          [`${namespace}__second-line--decimal`]: billing,
        })}
      >
        <PricePart
          value={amount}
          thousandSeparator={computedThousandSeparator}
          decimalSeparator={computedDecimalSeparator}
          currencySymbol={symbol}
          showDecimals={showDecimals}
          billing={billing}
          size={size}
        />
        {(discountSource || discount_label) && (
          <span className={classNames(`${namespace}__second-line__label`, 'shops__price-second-line__label')}>
            {discountSource === 'loyalty' && <IconLoyalty hidden />}
            {discount_label && (
              <>
                {discount_label.prefix && (
                  <span className={`${namespace}__discount_prefix`}>{discount_label.prefix}</span>
                )}
                {discount_label.text && (
                  <span className={classNames(`${namespace}__discount`, 'shops__price-discount')}>
                    {discount_label.text}
                  </span>
                )}
              </>
            )}
          </span>
        )}
      </div>
    </div>
  );
};

Price.propTypes = {
  amount: number.isRequired,
  billing: bool,
  bold: bool,
  className: string,
  currency_id: string.isRequired,
  decimalSeparator: string,
  discount_label: shape({
    text: string.isRequired,
    color: string,
    prefix: string,
    prefix_color: string,
  }),
  discountSource: string,
  from: shape({ text: string }),
  original_price: number,
  showDecimals: bool,
  showOriginalValue: bool.isRequired,
  size: oneOf(['tiny', 'small', 'medium', 'large']),
  thousandSeparator: string,
};

Price.defaultProps = {
  billing: false,
  bold: false,
  className: null,
  discountLabel: null,
  from: null,
  original_price: null,
  size: 'medium',
  thousandSeparator: null,
  decimalSeparator: null,
  discount_label: null,
  discountSource: null,
  showDecimals: true,
};

module.exports = Price;
