const React = require('react');
const { arrayOf, shape, bool } = require('prop-types');

const ProgressIndicatorCircular = require('@andes/progress-indicator-circular');

const AppliedFilters = require('../../../../filters/applied/applied-filters.desktop');
const AvailableFilters = require('../../../../sidebar/components/filters/filters.desktop');

const FiltersContent = ({ isLoading, appliedFilters, availableFilters, availableLabels }) => (
  <>
    {isLoading && <ProgressIndicatorCircular modifier="block" size="large" />}
    <AppliedFilters filters={appliedFilters} />
    <AvailableFilters filters={availableFilters} labels={availableLabels} />
  </>
);

FiltersContent.propTypes = {
  appliedFilters: arrayOf(shape({})).isRequired,
  availableFilters: arrayOf(shape({})).isRequired,
  availableLabels: shape({}).isRequired,
  isLoading: bool,
};

FiltersContent.defaultProps = {
  isLoading: false,
};

module.exports = FiltersContent;
