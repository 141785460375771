/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
const React = require('react');
const { useMemo, useRef } = require('react');
const { bool, func, node, number, shape } = require('prop-types');

const { getPointPosition } = require('./helpers');
const useInfowindowStyles = require('./use-infowindow-styles');

const InfowindowInstance = ({
  children,
  containerMeasurements,
  isMountedInRealDom,
  map,
  lat,
  lng,
  onMouseOut,
  onMouseOver,
}) => {
  const domNode = useRef(null);
  const point = useMemo(() => getPointPosition(map, { lat, lng }), [lat, lng]);
  const styles = useInfowindowStyles({
    containerMeasurements,
    domNode: isMountedInRealDom ? domNode.current : null,
    point,
  });

  return (
    <div style={styles} ref={domNode} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
      {children}
    </div>
  );
};

InfowindowInstance.propTypes = {
  children: node,
  containerMeasurements: shape({
    x: number,
    y: number,
    height: number,
    width: number,
  }).isRequired,
  isMountedInRealDom: bool,
  lat: number.isRequired,
  lng: number.isRequired,
  map: shape().isRequired,
  onMouseOut: func,
  onMouseOver: func,
};

InfowindowInstance.defaultProps = {
  isMountedInRealDom: false,
};

module.exports = InfowindowInstance;
