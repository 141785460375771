const React = require('react');
const { shape, string, func } = require('prop-types');

const Button = require('@andes/button');

/* Default button for when the item is not in the cart */
const NoItemsButton = ({ action, className, handleAddToCartPut }) => {
  const { label } = action;

  return (
    <Button className={className} fullWidth hierarchy="quiet" onClick={(e) => handleAddToCartPut(e)} size="medium">
      {label?.text}
    </Button>
  );
};

/* PropTypes */
NoItemsButton.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
    }),
    url: string,
  }).isRequired,
  className: string,
  handleAddToCartPut: func.isRequired,
};

module.exports = NoItemsButton;
