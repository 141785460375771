const get = require('lodash/get');

const { useStaticProps } = require('../components/context/static-props');
const { AVAILABLE_FILTERS } = require('../components/factory/component-types');
const {
  DEVICE_TYPES: { DESKTOP },
} = require('../constants');
const { useSearch } = require('./context');

const LABELS_PATH = {
  CLIENT_SIDE: 'map_configuration.filters.available_filters.labels',
  DEFAULT: 'filtering_options.labels',
};

const findAvailableFilters = ({ type }) => type === AVAILABLE_FILTERS;

const getDesktopPath = ({ sidebar: { components = [] } = {} }) => {
  const availableFiltersIndex = components.findIndex(findAvailableFilters);

  return availableFiltersIndex > -1 ? `sidebar.components[${availableFiltersIndex}].labels` : undefined;
};

const useFilterLabels = () => {
  const { deviceType } = useStaticProps();
  const searchContext = useSearch();

  if (deviceType === DESKTOP) {
    const path = getDesktopPath(searchContext);
    const clientSideLabels = get(searchContext, LABELS_PATH.CLIENT_SIDE, {});

    return get(searchContext, path, clientSideLabels);
  }

  return get(searchContext, LABELS_PATH.DEFAULT, {});
};

module.exports = useFilterLabels;
