const { useContext } = require('react');

const EshopsContext = require('../context/eshop-environment');

const useEshopsEnvironment = () => {
  const { isEshopsEnvironment, setEshopsEnvironment } = useContext(EshopsContext);

  return { isEshopsEnvironment, setEshopsEnvironment };
};

module.exports = useEshopsEnvironment;
