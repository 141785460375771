const React = require('react');
const { useContext, useState } = require('react');
const { string, bool, shape } = require('prop-types');

const classNames = require('classnames');

const { useSearch } = require('../../hooks/context');
const UserContext = require('../context/user');
const StaticPropsContext = require('../context/static-props');
const IconBookmark = require('../icons/bookmark');
const APIService = require('../../../services/bookmarks');
const { trackEvent } = require('../../lib/analytics');

const namespace = 'ui-search-bookmark';

const redirect = (loginUrl) => {
  window.location.href = loginUrl.replace('loginType=explicit', 'loginType=favorite');
};

const Bookmark = ({ className, bookmarked, item }) => {
  const [bookmarkState, setBookmarkState] = useState(bookmarked);
  const [isFetching, setIsFetching] = useState(false);
  const { loggedIn, loginUrl } = useContext(UserContext);
  const { toggleBookmark, analytics_track, bookmark } = useSearch();
  const { isHoverDisabled } = useContext(StaticPropsContext);
  const [showOnFocus, setFocusVisible] = useState(isHoverDisabled);

  const itemId = item ? item.id || item.item_id : null;

  const getGALabel = () => {
    try {
      if (analytics_track?.pathFromRoot?.length > 0 && analytics_track.pathFromRoot.slice(-1)[0].name) {
        return analytics_track.pathFromRoot.slice(-1)[0].name.replaceAll(',', '').replaceAll(' ', '_').toUpperCase();
      }
    } catch (error) {
      return '';
    }

    return '';
  };
  const handleBookmarkFocus = () => {
    if (!isHoverDisabled) {
      setFocusVisible(!showOnFocus);
    }
  };

  const handleBookmark = async (ev) => {
    ev.preventDefault();

    if (!loggedIn) {
      redirect(loginUrl);

      return;
    }

    if (!isFetching) {
      const newBookmarkState = toggleBookmark(itemId);

      setBookmarkState(newBookmarkState);

      if (newBookmarkState && item.vertical === 'CPG') {
        trackEvent({
          category: 'SUPERMERCADO',
          action: 'ADD_FAVORITE',
          label: (item.product ? 'PDP_' : 'VIP_') + getGALabel(),
        });
      }

      setIsFetching(true);

      try {
        await APIService.toggleBookmarkService(itemId, newBookmarkState);
      } catch (ex) {
        if (ex.response && ex.response.status === 403) {
          redirect(loginUrl);

          return;
        }

        setBookmarkState(!newBookmarkState);
        toggleBookmark(itemId);
      } finally {
        setIsFetching(false);
      }
    }
  };
  const iconFilledClassNames = classNames(`${namespace}__icon-bookmark-fill`, {
    [`${namespace}__icon-bookmark-fill--active`]: bookmarkState,
  });

  return (
    <form
      action={`/search/bookmarks/${itemId}`}
      className={classNames(namespace, className, {
        [`${namespace}--active`]: bookmarkState,
        [`${namespace}--hover-disabled`]: showOnFocus,
      })}
      method="POST"
      onSubmit={(ev) => handleBookmark(ev)}
      onFocus={handleBookmarkFocus}
      onBlur={handleBookmarkFocus}
    >
      <button
        type="submit"
        className={`${namespace}__btn`}
        role="switch"
        aria-checked={bookmarkState}
        aria-label={bookmark?.label?.text}
      >
        <IconBookmark className={`${namespace}__icon-bookmark`} />
        <IconBookmark className={iconFilledClassNames} />
      </button>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input type="hidden" name="method" value={bookmarkState ? 'remove' : 'add'} />
      <input type="hidden" name="itemId" value={itemId} />
    </form>
  );
};

Bookmark.propTypes = {
  bookmarked: bool,
  className: string,
  item: shape().isRequired,
};
Bookmark.defaultProps = {
  bookmarked: false,
  className: '',
};

module.exports = Bookmark;
