const React = require('react');

const { loadable } = require('nordic/lazy');

const usePricingBanner = require('./use-pricing-banner');

const ChunkPricingBannerContainer = () => {
  const pricingBannerProps = usePricingBanner();
  const PricingBanner = loadable(async () => import(/* webpackChunkName: "chunk-pricing-banner" */ './pricing-banner'));

  return pricingBannerProps ? <PricingBanner {...pricingBannerProps} /> : null;
};

module.exports = ChunkPricingBannerContainer;
