const React = require('react');

const Tooltip = require('@andes/tooltip');

const Link = require('../../../../../link/filter-link');
const FiltersColors = require('../../../filters-colors/filters-colors.desktop');
const { colorValuesPropTypes } = require('../../propTypes');

const RenderColorsValues = ({ namespace, id, name, results, url }) => (
  <li className={`${namespace}-dd ${namespace}-dd-color`} key={id} aria-label={name} id={name}>
    <Tooltip side="bottom" content={results} title={name} className={`${namespace}-tooltip`} mouseLeaveDelay={0}>
      <Link href={url} aria-label={`${name} ${results}`} name={name}>
        <FiltersColors name={name} id={id} />
      </Link>
    </Tooltip>
  </li>
);

RenderColorsValues.propTypes = {
  ...colorValuesPropTypes,
};

module.exports = RenderColorsValues;
