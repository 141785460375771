/* eslint-disable react/prop-types */
const React = require('react');

const MeliPlayInterventionMobile = require('../../meliplay/grid/meliplay-intervention.mobile');
const MeliPlayInterventionDesktop = require('../../meliplay/grid/meliplay-intervention.desktop');

const renderItemsIterventionsMobile = {
  MELIPLAY_INTERVENTION: ({ item, idx, imageGridHeight }) => (
    <MeliPlayInterventionMobile intervention={item} position={idx} imageGridHeight={imageGridHeight} />
  ),
};

const renderItemsIterventionsDesktop = {
  MELIPLAY_INTERVENTION: (item, idx) => <MeliPlayInterventionDesktop intervention={item} position={idx} />,
};

module.exports = {
  renderItemsIterventionsMobile,
  renderItemsIterventionsDesktop,
};
