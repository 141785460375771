const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--hot-sale';

const Hotsale = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="17"
    height="13"
    viewBox="0 0 17 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96823 3.91753L12.7067 1.57607C13.2401 1.43313 13.7885 1.74971 13.9314 2.28318L15.4574 7.97821C15.6003 8.51168 15.2837 9.06001 14.7503 9.20296L6.01185 11.5444C5.75567 11.6131 5.48271 11.5771 5.25303 11.4445L2.50855 9.85998C1.55196 9.3077 1.22421 8.08452 1.7765 7.12793L3.36102 4.38346C3.49363 4.15377 3.71205 3.98617 3.96823 3.91753ZM2.495 3.88346C2.76022 3.42409 3.19705 3.08889 3.70941 2.9516L12.4478 0.610149C13.5148 0.324265 14.6114 0.95743 14.8973 2.02436L16.4233 7.71939C16.7092 8.78633 16.076 9.883 15.0091 10.1689L6.27067 12.5103C5.75831 12.6476 5.2124 12.5758 4.75303 12.3105L2.00855 10.726C0.573671 9.89758 0.0820471 8.06281 0.910474 6.62793L2.495 3.88346ZM4.00169 9.01785C4.52961 8.8764 4.8429 8.33377 4.70144 7.80585C4.55999 7.27794 4.01736 6.96465 3.48944 7.10611C2.96153 7.24756 2.64824 7.79019 2.78969 8.31811C2.93115 8.84602 3.47378 9.15931 4.00169 9.01785Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="17" height="13" fill="white" transform="translate(0 0.0600586)" />
      </clipPath>
    </defs>
  </svg>
);

Hotsale.propTypes = {
  className: string,
};

Hotsale.defaultProps = {
  className: null,
};

module.exports = Hotsale;
