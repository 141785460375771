const React = require('react');
const { string, shape } = require('prop-types');

const StyledLabel = require('../../styled-label/styled-label');

const namespace = 'ui-search-item__promise-message';

const PromiseMessage = ({ manufacturing_time }) => {
  const { font_size: size, ...rest } = manufacturing_time;

  return manufacturing_time ? <StyledLabel className={namespace} size={size} {...rest} /> : null;
};

PromiseMessage.propTypes = {
  manufacturing_time: shape({
    id: string,
    text: string,
    color: string,
    font_size: string,
    font_weight: string,
  }).isRequired,
};

PromiseMessage.defaultProps = {
  manufacturing_time: null,
};

module.exports = PromiseMessage;
