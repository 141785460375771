const { useContext } = require('react');

const { useSearch } = require('../../hooks/context');
const StaticPropsContext = require('../context/static-props');

const useCarousel = () => {
  const { carousel } = useSearch();
  const { lowEnd } = useContext(StaticPropsContext);

  return lowEnd ? null : carousel;
};

module.exports = useCarousel;
