const React = require('react');
const { arrayOf, func, shape, string, oneOfType, node, number } = require('prop-types');

const LoaderError = require('./loader-error');
const { useStaticProps } = require('../context/static-props');
const { Loader, Map } = require('./lib');
const { INITIAL_MAP_STATUS } = require('./constants');
const { LANGUAGE_TO_MAP_LANGUAGE } = require('./lib/constants');
const MarkerGroup = require('./marker-group');

const getMapLanguage = (languageLocale = '') => {
  const [language] = languageLocale.split('_');

  return LANGUAGE_TO_MAP_LANGUAGE[language];
};

const FullMap = ({
  className,
  markers,
  children,
  handleBoundsChanged,
  handleMapLoad,
  handleDragEnd,
  handleZoomChanged,
  handleMapClick,
  handleMarkerClick,
  mapStatus: { bounds, zoom },
  selectedCluster,
  selectedMarker,
  handleMarkerHover,
}) => {
  const { mapApiKey, country: { locale } = {} } = useStaticProps();

  return (
    <Loader apiKey={mapApiKey} language={getMapLanguage(locale)} ErrorComponent={LoaderError}>
      <Map
        className={className}
        height="100%"
        onBoundsChanged={handleBoundsChanged}
        onClick={handleMapClick}
        onLoad={handleMapLoad}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChanged}
      >
        <MarkerGroup
          bounds={bounds}
          markers={markers}
          selectedCluster={selectedCluster}
          selectedMarker={selectedMarker}
          onMarkerSelect={handleMarkerClick}
          onMarkerHover={handleMarkerHover}
          zoom={zoom}
        />
        {children}
      </Map>
    </Loader>
  );
};

FullMap.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  className: string,
  handleBoundsChanged: func.isRequired,
  handleDragEnd: func.isRequired,
  handleMapClick: func.isRequired,
  handleMapLoad: func.isRequired,
  handleMarkerClick: func.isRequired,
  handleMarkerHover: func,
  handleZoomChanged: func.isRequired,
  mapStatus: shape({
    bounds: shape({
      top: number,
      right: number,
      bottom: number,
      left: number,
    }),
    zoom: number,
  }),
  markers: arrayOf(shape()),
  selectedCluster: number,
  selectedMarker: number,
};

FullMap.defaultProps = {
  children: null,
  className: null,
  mapStatus: INITIAL_MAP_STATUS,
  markers: [],
  selectedCluster: null,
  selectedMarker: null,
  handleMarkerHover: () => undefined,
};

module.exports = FullMap;
