/* eslint-disable react/prop-types */
const React = require('react');
const { useEffect, useState } = require('react');
const { bool, func, number, shape } = require('prop-types');

const AndesTooltip = require('@andes/tooltip');
const classnames = require('classnames');

const useTooltip = require('../../../../hooks/use-tooltip');
const Actions = require('../../../rescue/components/actions');
const { getFilterTooltipKey } = require('./helpers');
const { MOBILE_DEFAULT_PROPS, MOBILE_PROP_TYPES } = require('./constants');

const namespace = 'ui-search-tooltip-filter';

const FilterTooltip = ({
  className,
  children,
  mask,
  maskClosable,
  onClose,
  tooltip: { title, offset_x, offset_y, content, filter_content_id, max_print, actions, side },
  tooltipStatus: { wasClosed, views },
}) => {
  const [visible, setVisible] = useState(false);
  const isNoLongerVisible = wasClosed || views > max_print || !filter_content_id;

  const handleCloseTooltip = () => {
    setVisible(false);
    onClose();
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <AndesTooltip
      actions={<Actions actions={actions} actionButton={handleCloseTooltip} />}
      className={classnames(namespace, className)}
      closable
      onClose={handleCloseTooltip}
      title={title}
      content={content}
      offsetX={offset_x}
      offsetY={offset_y}
      mask={mask}
      maskClosable={maskClosable}
      side={side}
      type="highlight"
      trigger={isNoLongerVisible ? 'click' : 'no-trigger'}
      visible={!isNoLongerVisible}
    >
      <div className={classnames({ 'andes-tooltip__trigger--visible': visible })}>{children}</div>
    </AndesTooltip>
  );
};

const FilterTooltipContainer = ({ tooltip, ...props }) => {
  const localStorageKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, handleClose] = useTooltip(localStorageKey);

  return <FilterTooltip {...props} tooltip={tooltip} tooltipStatus={tooltipStatus} onClose={handleClose} />;
};

FilterTooltip.propTypes = {
  ...MOBILE_PROP_TYPES,
  tooltipStatus: shape({
    wasClosed: bool.isRequired,
    views: number.isRequired,
  }).isRequired,
  onClose: func.isRequired,
};
FilterTooltip.defaultProps = MOBILE_DEFAULT_PROPS;

FilterTooltipContainer.propTypes = MOBILE_PROP_TYPES;
FilterTooltipContainer.defaultProps = MOBILE_DEFAULT_PROPS;

module.exports = FilterTooltipContainer;
module.exports.FilterTooltip = FilterTooltip;
