const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--question';

const Question = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58245 8.67599C7.58245 7.81271 8.19545 7.37086 8.75058 6.97071L8.76545 6.95999L8.77238 6.95512C9.25057 6.61911 9.67545 6.32056 9.67545 5.81599C9.67545 5.30899 9.28545 4.87999 8.44045 4.87999C7.64745 4.87999 7.07545 5.24399 6.63345 5.78999L5.76245 4.81499C6.42545 4.00899 7.42645 3.52799 8.59645 3.52799C10.2345 3.52799 11.2355 4.39899 11.2355 5.56899C11.2355 6.64799 10.5075 7.16799 9.85745 7.62299L9.79654 7.6678C9.32668 8.01309 8.90845 8.32045 8.90845 8.81899C8.90845 9.02699 9.02545 9.26099 9.19445 9.40399L8.05045 9.78099C7.73845 9.48199 7.58245 9.10499 7.58245 8.67599Z"
      fill="#3483FA"
    />
    <path
      d="M7.60845 11.562C7.60845 11.068 8.02445 10.652 8.51845 10.652C9.01245 10.652 9.42845 11.068 9.42845 11.562C9.42845 12.056 9.01245 12.472 8.51845 12.472C8.02445 12.472 7.60845 12.056 7.60845 11.562Z"
      fill="#3483FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50002 15.8C12.8079 15.8 16.3001 12.3078 16.3001 7.99993C16.3001 3.69207 12.8079 0.19986 8.50002 0.19986C4.19216 0.19986 0.699951 3.69207 0.699951 7.99993C0.699951 12.3078 4.19216 15.8 8.50002 15.8ZM8.50002 14.6C4.8549 14.6 1.89995 11.645 1.89995 7.99993C1.89995 4.35481 4.8549 1.39986 8.50002 1.39986C12.1451 1.39986 15.1001 4.35481 15.1001 7.99993C15.1001 11.645 12.1451 14.6 8.50002 14.6Z"
      fill="#3483FA"
    />
  </svg>
);

Question.propTypes = {
  className: string,
};

Question.defaultProps = {
  className: null,
};

module.exports = React.memo(Question);
