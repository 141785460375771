const React = require('react');

const useSearchAds = require('./use-search-ads');
const SearchShopsAds = require('./search-shops-ads');

const SearchShopsAdsContainer = () => {
  const bottomAds = useSearchAds();

  if (!bottomAds) {
    return null;
  }

  return <SearchShopsAds {...bottomAds} />;
};

module.exports = SearchShopsAdsContainer;
