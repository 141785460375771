/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-unstable-nested-components */

const React = require('react');
const { memo, useEffect, useState, useMemo, createRef } = require('react');
const { arrayOf, number, element, func, shape, oneOfType } = require('prop-types');

const cx = require('classnames');

const MobileCarousel = require('../../carousel/carousel.mobile');
const { NAMESPACE, SLIDER_PROPS, STOPPED_SPEED, ANIMATED_SPEED, LAZY_THRESHOLD } = require('./constants');

/* Workaround to force component remount after results changes.
   Base carrusel currently doesn't update on slides changes.
*/
const buildCarousellKey = (results) => results.map((result) => result.id).join('-');

const isValidPage = (selectedItemIndex, results) => {
  const hasValue = selectedItemIndex !== null;
  const inRange = selectedItemIndex >= 0 && selectedItemIndex < results.length;

  return hasValue && inRange;
};

const getInitialPagesRefs = (results) =>
  results.reduce(
    (acum, result, index) => ({
      ...acum,
      [result.id]: { index, ref: createRef() },
    }),
    {},
  );

const getItemsVisibility = (results, visible) =>
  results.reduce(
    (acum, result) => ({
      ...acum,
      [result.id]: visible,
    }),
    {},
  );

const Carousel = ({ ItemComponent, results, onSlideChange, selectedItemIndex }) => {
  const carouselKey = useMemo(() => buildCarousellKey(results), [results]);
  const pagesRef = useMemo(() => getInitialPagesRefs(results), [results]);
  const [hasSliderAnimation, toggleSliderAnimation] = useState(false);
  const [visibility, setVisibility] = useState(getItemsVisibility(results, false));
  const hasItemSelected = isValidPage(selectedItemIndex, results);
  const hasMultiplePages = results.length > 1;

  const buildPageElement = (pageIndex) => <div ref={pagesRef[results[pageIndex].id].ref} />;
  const goToPage = () => {
    if (hasItemSelected && hasMultiplePages) {
      pagesRef[results[selectedItemIndex].id].ref.current.click();
    }
  };

  const setItemsVisibility = () => {
    if (hasItemSelected) {
      const lowerLimit = Math.max(0, selectedItemIndex - LAZY_THRESHOLD);
      const upperLimit = Math.min(results.length, selectedItemIndex + LAZY_THRESHOLD + 1);
      const visibleItems = results.slice(lowerLimit, upperLimit);

      setVisibility({
        ...visibility,
        ...getItemsVisibility(visibleItems, true),
      });
    }
  };

  useEffect(goToPage, [selectedItemIndex]);

  useEffect(() => toggleSliderAnimation(hasItemSelected), [hasItemSelected]);

  useEffect(() => setItemsVisibility(), [results, hasItemSelected, selectedItemIndex]);

  if (results.length === 0) {
    return null;
  }

  return (
    <div className={cx(NAMESPACE, { [`${NAMESPACE}--show`]: hasItemSelected })} key={carouselKey}>
      <MobileCarousel
        className={`${NAMESPACE}__carousel`}
        settings={{
          ...SLIDER_PROPS,
          Slide: (item) => <ItemComponent item={item} options={{ mounted: visibility[item.id], lazyload: 'off' }} />,
          customPaging: buildPageElement,
          speed: hasSliderAnimation ? ANIMATED_SPEED : STOPPED_SPEED,
          dots: hasMultiplePages,
          initialSlide: selectedItemIndex,
        }}
        slideChange={onSlideChange}
        slides={results}
      />
    </div>
  );
};

Carousel.propTypes = {
  ItemComponent: oneOfType([element, func]).isRequired,
  results: arrayOf(shape({})),
  selectedItemIndex: number,
  onSlideChange: func,
};

Carousel.defaultProps = {
  results: [],
  selectedItemIndex: null,
  onSlideChange: () => undefined,
};

module.exports = {
  Carousel: memo(Carousel),
};
