const React = require('react');

const { loadable } = require('nordic/lazy');

const useListingDisclaimer = require('./use-listing-disclaimer');

const ChunkListingDisclaimer = () => {
  const listingDisclaimerProps = useListingDisclaimer();
  const ListingDisclaimer = loadable(async () =>
    import(/* webpackChunkName: "chunk-listing-disclaimer" */ './listing-disclaimer.mobile'),
  );

  return listingDisclaimerProps ? <ListingDisclaimer {...listingDisclaimerProps} /> : null;
};

module.exports = ChunkListingDisclaimer;
