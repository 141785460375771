const React = require('react');

const { GROUPED } = require('./component-types');

const componentDependsOnFactory = (component) => component.type === GROUPED;

function componentFactoryFrom(sources) {
  const ComponentFactory = (component) => {
    const source =
      sources['component_visible' in component && component.type === 'ALERT' ? `${component.type}_V2` : component.type];

    if (!source) {
      return null;
    }

    const props = componentDependsOnFactory(component)
      ? { ...component, factory: componentFactoryFrom(sources) }
      : component;

    const componentName = component.id ? `${component.id} - ${component.type}` : component.type;

    source.displayName = `Component(${componentName})`;

    return React.createElement(source, props);
  };

  return ComponentFactory;
}

module.exports = componentFactoryFrom;
