const { trackEvent: trackAnalyticsEvent } = require('../../../lib/analytics');
const { trackEvent: trackMelidataEvent } = require('../../../lib/melidata');

const trackEvent = (analytics, melidata_track) => {
  if (melidata_track) {
    trackMelidataEvent(melidata_track);
  }

  if (analytics) {
    trackAnalyticsEvent({
      category: analytics.analytics_track_category,
      action: analytics.analytics_track_action,
      label: analytics.analytics_track_label,
    });
  }
};

module.exports = {
  trackEvent,
};
