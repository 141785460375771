const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--alert-new';

const IconAlertNew = ({ className }) => (
  <svg
    data-testid="create_alert_icon"
    className={classnames(namespace, className)}
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1.0" fillRule="evenodd">
      <path d="M15.867 20.4h-1.733l-0.267 1.733c-0.133 1.067-0.933 1.733-2.133 1.733s-2-0.667-2.133-1.733l-0.267-1.733h-7.333v-0.133c2.133-1.067 5.067-3.6 4.933-7.733v-1.6c0.133-2.533 2.133-4.4 4.8-4.4s4.667 1.867 4.8 4.4v1.6c0 1.2 0.133 2.133 0.533 3.067 0.4-0.8 0.933-1.467 1.467-2.133 0-0.267 0-0.533 0-0.933v-1.6c-0.133-3.6-3.2-6.4-6.8-6.4s-6.667 2.667-6.8 6.4v1.733c0.133 4.4-4.133 6-4.4 6.133l-0.533 0.133v3.467h7.733c0.267 2.133 2 3.467 4.133 3.467s3.733-1.333 4.133-3.467h2c-0.4-0.533-0.8-1.333-1.067-2 0 0-1.067 0-1.067 0zM27.467 21.2c0.533-0.8 0.8-1.733 0.8-2.8 0-2.8-2.267-5.067-4.933-5.067-2.8 0-4.933 2.267-4.933 5.067s2.267 5.067 4.933 5.067c1.067 0 2-0.267 2.8-0.8l4.533 4.4 1.333-1.467-4.533-4.4zM23.2 21.467c-1.6 0-2.933-1.333-2.933-3.067s1.333-3.067 2.933-3.067c1.6 0 2.933 1.333 2.933 3.067s-1.2 3.067-2.933 3.067z" />
    </g>
  </svg>
);

IconAlertNew.propTypes = {
  className: string,
};

IconAlertNew.defaultProps = {
  className: null,
};

module.exports = IconAlertNew;
