const React = require('react');
const { shape, arrayOf, string, oneOf, node, oneOfType, number, bool } = require('prop-types');

const classnames = require('classnames');

const ComposedLabelAlternatives = require('./composed-label-alternative');
const Link = require('../link');
const { GRID } = require('../layout/renderer/constants');

const namespace = 'ui-search-winner-alternatives';
const renderLabel = (component) => {
  switch (component.id) {
    case 'TEXT':
    case 'PRICE': {
      const { text, values, ...props } = component.label;

      return <ComposedLabelAlternatives text={text} values={values} {...props} />;
    }

    default:
      return null;
  }
};

const ChevronRight = () => (
  <svg width="40" height="42" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.42834 5.69496L8.27686 4.84644L13.921 10.4906L8.27187 16.1397L7.42334 15.2912L12.224 10.4906L7.42834 5.69496Z"
      fill="#3483FA"
    />
  </svg>
);

const WithBorderType = ({ type, children, layout }) => {
  const classname = classnames(
    { [`${namespace}--${type}`]: layout !== GRID },
    { [`${namespace}--${type}--grid`]: layout === GRID },
  );

  if (type === 'chevron') {
    return (
      <div className={classname}>
        {' '}
        <div>{children}</div> <ChevronRight />{' '}
      </div>
    );
  }

  return <div className={classname}> {children}</div>;
};

WithBorderType.propTypes = {
  children: node.isRequired,
  layout: string,
  type: string,
};

const WinnerAlternatives = ({ className, alternatives, layout }) => (
  <ul className={classnames(namespace, className)}>
    {alternatives?.map((alternative) => {
      let children = (
        <WithBorderType type={alternative.type} layout={layout}>
          {alternative.components.map((component, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`${component.id}_${i}`}>{renderLabel(component)}</React.Fragment>
          ))}
        </WithBorderType>
      );

      if (alternative.permalink) {
        children = <Link href={alternative.permalink}>{children}</Link>;
      }

      return (
        <li key={alternative.id} className={`${namespace}__item`}>
          {children}
        </li>
      );
    })}
  </ul>
);

WinnerAlternatives.propTypes = {
  alternatives: arrayOf(
    shape({
      id: string,
      type: oneOf(['sandwich', 'chevron', 'borderless', 'border-blue', 'border-grey', 'separator-top']).isRequired,
      permalink: string,
      components: arrayOf(
        shape({
          id: string,
          label: shape({
            text: string.isRequired,
            color: string,
            font_weight: string,
            font_size: oneOfType([oneOf(['X_SMALL', 'XX_SMALL', 'XXX_SMALL']), number]),
            margin: shape({
              bottom: number,
              top: number,
            }),
            values: arrayOf(
              shape({
                type: oneOf(['price', 'text', 'icon']),
                key: string,
                amount: number,
                previous: bool,
                font_size: oneOfType([string, number]),
                id: string,
                font_weight: string,
              }),
            ),
          }),
        }),
      ),
    }),
  ),
  className: string,
  layout: string.isRequired,
};

module.exports = WinnerAlternatives;
