const React = require('react');
const { shape, string, arrayOf } = require('prop-types');

const classnames = require('classnames');

const { namespace } = require('../../constants');

const WHITE_SPACE = '\xa0';
const ITEM_SEPARATOR = '·';

const Attributes = ({ className, attributes, separator }) => (
  <div className={classnames(className, `${namespace}__content-attributes`)}>
    {attributes.map(({ label }) => label).join(`${WHITE_SPACE}${separator}${WHITE_SPACE}`)}
  </div>
);

Attributes.propTypes = {
  attributes: arrayOf(
    shape({
      label: string.isRequired,
    }),
  ),
  className: string,
  separator: string,
};

Attributes.defaultProps = {
  attributes: [],
  className: null,
  separator: ITEM_SEPARATOR,
};

module.exports = {
  Attributes,
};
