const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--close';

const IconClose = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1024 1024"
  >
    <path d="M409.6 512l-358.4 358.4 102.4 102.4 358.4-358.4 358.4 358.4 102.4-102.4-358.4-358.4 358.4-358.4-102.4-102.4-358.4 358.4-358.4-358.4-102.4 102.4z" />
  </svg>
);

IconClose.propTypes = {
  className: string,
};

IconClose.defaultProps = {
  className: null,
};

module.exports = React.memo(IconClose);
