const React = require('react');
const { arrayOf, shape, func, string } = require('prop-types');

const Tag = require('@andes/tag');
const CarouselFree = require('@andes/carousel-free');

const Link = require('../../link');

const { CarouselFreeSlide } = CarouselFree;
const namespace = 'ui-search-text-icon-filter-intervention';

const TextIconCarousel = ({ tags, Icon }) => (
  <div className={namespace}>
    <CarouselFree className={`${namespace}-carousel`}>
      {tags.map((tag) => (
        <CarouselFreeSlide key={tag.id} className={`${namespace}-item-slide`}>
          <Link className={`${namespace}__link`} href={tag.url}>
            <Tag label={tag.label} asset={{ icon: <Icon /> }} />
          </Link>
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  </div>
);

TextIconCarousel.propTypes = {
  Icon: func.isRequired,
  tags: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      url: string.isRequired,
    }),
  ).isRequired,
};

module.exports = TextIconCarousel;
