const { useFilters } = require('./context');
const useClientSideOptions = require('./use-client-side-options');

const useFilterSearch = (href, id) => {
  const { isClientSideSearch } = useClientSideOptions();
  const { filterSearch } = useFilters();

  return isClientSideSearch ? filterSearch(href, id) : null;
};

module.exports = useFilterSearch;
