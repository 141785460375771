/* eslint-disable react-hooks/exhaustive-deps */

// Tomado de vpp_frontend
const { useState, useEffect } = require('react');

const DEFAULT_IO_OPTIONS = {
  root: null,
  rootMargin: '0px',
  threshold: 1.0,
};

/**
 * @TODO
 * make a box for actions so it sostaints its height
 */
const useIntersectionObserver = (ref, options = {}, minIntersection = 0, defaultValue = false) => {
  const [isIntersecting, setIntersecting] = useState(defaultValue);

  const ioOptions = {
    ...DEFAULT_IO_OPTIONS,
    ...options,
  };

  useEffect(() => {
    const observer = new window.IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting && entry.intersectionRatio > minIntersection);
    }, ioOptions);

    const { current } = ref;

    if (!current) {
      // este chequeo es para evitar romper todo el contexto de React si nos pasaron un ref inválido.

      // eslint-disable-next-line no-console
      console.error('Intersection Observer not connected because current ref is null');

      return () => {};
    }

    observer.observe(current);

    return () => observer.unobserve(current);
  }, [ref, ioOptions, minIntersection]);

  return [isIntersecting];
};

module.exports = useIntersectionObserver;
