const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const ICON_ID = 'recommendations-full_icon';
const namespace = 'ui-search-icon ui-search-icon--full';

const IconFullfillment = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 41 13" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="nonzero"
      fill="#00A650"
      d="M2.628 0h5.255L5.255 4.643h4.38L2.628 13l1.751-5.571H0L2.628 0zm11.589 9.533h-1.959l1.674-7.515H19.5l-.376 1.69h-3.61l-.25 1.172h3.519l-.376 1.69h-3.53l-.66 2.963zm9.468.136c-2.334 0-3.484-1.105-3.484-2.682 0-.124.034-.383.057-.496l1.002-4.473h1.992l-.99 4.428c-.012.057-.034.18-.034.316.011.62.49 1.217 1.457 1.217 1.048 0 1.583-.654 1.776-1.533l.991-4.428h1.981l-.99 4.462c-.41 1.825-1.412 3.189-3.758 3.189zm10.118-.136h-5.01l1.673-7.515h1.959l-1.287 5.825h3.04l-.375 1.69zm6.678 0h-5.01l1.674-7.515h1.959l-1.287 5.825h3.04l-.376 1.69z"
    />
  </svg>
);

IconFullfillment.propTypes = {
  className: string,
};

IconFullfillment.defaultProps = {
  className: null,
};

module.exports = React.memo(IconFullfillment);
module.exports.ICON_ID = ICON_ID;
