const React = require('react');
const { string, bool, func, shape } = require('prop-types');

const Tooltip = require('@andes/tooltip');
const classnames = require('classnames');

const namespace = 'ui-search-alert';

const AlertInfoTooltip = ({
  description,
  location,
  style,
  localStorageInfoTooltip,
  isTooltipInfoVisible,
  className,
}) => {
  const { isAvailable, handleClose } = localStorageInfoTooltip;

  if (isAvailable && isTooltipInfoVisible) {
    return (
      <Tooltip
        id="tooltip-id"
        title=""
        content={description}
        side={location}
        type={style}
        onClose={handleClose}
        trigger="no-trigger"
        visible
        closable
        closeIconLabel="Close"
        offsetX={1}
        offsetY={31}
        className={classnames(`${namespace}__tooltip-info`, className)}
      />
    );
  }

  return null;
};

AlertInfoTooltip.propTypes = {
  className: string,
  description: string,
  isTooltipInfoVisible: bool,
  localStorageInfoTooltip: shape({
    handleClose: func,
    isAvailable: bool,
  }),
  location: string,
  style: string,
};

AlertInfoTooltip.defaultProps = {
  className: null,
  action: null,
};

module.exports = AlertInfoTooltip;
