/* eslint-disable react/prop-types */
const React = require('react');
const { useContext, useState } = require('react');
const { func } = require('prop-types');

const Button = require('@andes/button');
const classnames = require('classnames');

const UserContext = require('../context/user');
const SnackbarContext = require('../context/snackbar');
const APIService = require('../../../services/alert');
const IconAlertEdit = require('../icons/alert-edit-v2');
const IconAlertNew = require('../icons/alert-new-v2');
const AlertEditionTooltip = require('./components/alert-edition-tooltip');
const AlertInfoTooltip = require('./components/alert-info-tooltip');
const AlertSaveTooltip = require('./components/alert-save-tooltip');
const useTimesViewed = require('../../hooks/use-times-viewed');
const { trackEvent } = require('./trackings/track');

const ALERT_TYPE_NEW = 'new';
const ALERT_TYPE_EDIT = 'created';
const ALERT_TYPE_PERIODICITY = 'select_periodicity';

const namespace = 'ui-search-alert';

const redirect = (loginUrl) => {
  window.location.href = loginUrl;
};

const Actions = ({ tracksAlertSave, alertFrequency, alertFrequencyStatus, isLoading, buttonText }) => (
  <Button
    type="submit"
    hierarchy="loud"
    size="medium"
    loading={isLoading}
    srAnnouncement="Loading"
    data-testid="add_alert_button"
    onClick={() => {
      if (tracksAlertSave) {
        const tracks = {
          ...tracksAlertSave,
          melidata_track: {
            ...tracksAlertSave.melidata_track,
            event_data: {
              frequency: alertFrequency,
              status: alertFrequencyStatus,
            },
          },
        };
        const { analytics, melidata_track } = tracks;

        trackEvent(analytics, melidata_track);
      }
    }}
  >
    {buttonText}
  </Button>
);

const renderAlertEdit = ({ classNames, label, andes_tooltip }) => (
  <div className={classNames}>
    <span>
      {andes_tooltip ? (
        <AlertEditionTooltip
          trigger={
            <div className={`${namespace}__alert-tooltip`} data-testid="alert-created">
              <IconAlertEdit className={`${namespace}__icon-alert-edit`} />
              <p className={`${namespace}__alert-edit-text`}>{label.text}</p>
            </div>
          }
          {...andes_tooltip}
        />
      ) : (
        <IconAlertEdit className={`${namespace}__icon-alert-edit`} />
      )}
    </span>
  </div>
);

const renderAlertNew = (props) => {
  const {
    classNames,
    action,
    btnActionInfoTooltip,
    selectPeriodicityTooltip,
    alertComponentEdit,
    loginUrl,
    isLoading,
    setIsLoading,
    localStorageInfoTooltip,
    isTooltipInfoVisible,
    setTooltipInfoVisible,
    tracksAlertIntention,
    tracksAlertSave,
    alertCreationData,
    alertFrequency,
    alertFrequencyStatus,
    handleChangeAlertFrequency,
    handleAlertCreationSuccess,
    handleAlertCreationError,
  } = props;

  const label = btnActionInfoTooltip?.label || null;

  const andes_tooltip = btnActionInfoTooltip?.andes_tooltip || null;

  const {
    bottom_sheet,
    bottom_sheet: {
      content: {
        action: {
          label: { text: buttonSaveText },
        },
      },
    },
  } = selectPeriodicityTooltip;

  const handleAlert = (e) => {
    setIsLoading(true);

    if (e) {
      e.preventDefault();
    }

    const alertCreationPayload = {
      ...alertCreationData,
      frequency: alertFrequency,
      status: alertFrequencyStatus,
    };

    APIService.addAlert(alertCreationPayload)
      .then(() => {
        setIsLoading(false);
        handleAlertCreationSuccess(alertComponentEdit);
      })
      .catch((ex) => {
        if (ex?.response && ex?.response.status === 403) {
          redirect(loginUrl);
        }

        setIsLoading(false);
        handleAlertCreationError();
      });
  };

  const handleOpenPeriodicityTooltip = () => {
    if (tracksAlertIntention) {
      const { melidata_track, analytics } = tracksAlertIntention;

      trackEvent(analytics, melidata_track);
    }

    // Closes info tooltip
    localStorageInfoTooltip.handleClose();
    setTooltipInfoVisible(false);
  };

  return (
    <form action="/search/alert" className={classNames} method="POST" onSubmit={handleAlert}>
      <div id="tooltips-container">
        {andes_tooltip && (
          <AlertInfoTooltip
            description={andes_tooltip?.description}
            location={andes_tooltip?.location}
            style={andes_tooltip?.style}
            localStorageInfoTooltip={localStorageInfoTooltip}
            isTooltipInfoVisible={isTooltipInfoVisible}
          />
        )}
        <AlertSaveTooltip
          actions={
            <Actions
              tracksAlertSave={tracksAlertSave}
              alertFrequency={alertFrequency}
              alertFrequencyStatus={alertFrequencyStatus}
              isLoading={isLoading}
              buttonText={buttonSaveText}
            />
          }
          handleChangeAlertFrequency={handleChangeAlertFrequency}
          trigger={
            <button
              type="button"
              className={`${namespace}__btn-alert`}
              data-testid="open_alert_button"
              onClick={handleOpenPeriodicityTooltip}
            >
              <IconAlertNew className={`${namespace}__icon-alert`} />
              <p>{label?.text}</p>
            </button>
          }
          {...bottom_sheet}
        />
      </div>
      <input type="hidden" name="logginUrl" value={loginUrl} />
      <input
        type="hidden"
        name="alertCreationData"
        value={action?.alert_creation_data ? encodeURIComponent(JSON.stringify(action.alert_creation_data)) : ''}
      />
    </form>
  );
};

const renderAlertNotLogged = (props) => {
  const {
    btnActionInfoTooltip,
    localStorageInfoTooltip,
    isTooltipInfoVisible,
    setTooltipInfoVisible,
    loggedIn,
    loginUrl,
  } = props;

  const label = btnActionInfoTooltip?.label || null;

  const andes_tooltip = btnActionInfoTooltip?.andes_tooltip || null;

  const handleCheckIfUserLogged = () => {
    if (!loggedIn) {
      redirect(loginUrl);

      return;
    }

    setTooltipInfoVisible(false);
  };

  return (
    <div id="tooltips-container" className={`${namespace}__not_logged`}>
      {andes_tooltip && (
        <AlertInfoTooltip
          title={andes_tooltip?.title}
          description={andes_tooltip?.description}
          location={andes_tooltip?.location}
          style={andes_tooltip?.style}
          localStorageInfoTooltip={localStorageInfoTooltip}
          isTooltipInfoVisible={isTooltipInfoVisible}
        />
      )}
      <button
        type="button"
        className={`${namespace}__btn-alert`}
        data-testid="open_alert_button"
        onClick={handleCheckIfUserLogged}
      >
        <IconAlertNew className={`${namespace}__icon-alert`} />
        <p>{label?.text}</p>
      </button>
    </div>
  );
};

const AlertV2 = (props) => {
  const { setSnackbar } = useContext(SnackbarContext);
  const { loggedIn, loginUrl } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState(props.component_visible);
  const [isTooltipInfoVisible, setTooltipInfoVisible] = useState(true);
  const alertCreationData =
    props.components.find((item) => item.type === ALERT_TYPE_PERIODICITY)?.bottom_sheet?.content?.action
      ?.alert_creation_data || null;
  const [alertFrequency, setAlertFrequency] = useState(alertCreationData?.frequency || null);
  const [alertFrequencyStatus, setAlertFrequencyStatus] = useState(alertCreationData?.status || null);

  const classNames = classnames({
    [`${namespace}__card--edit`]: alertType === ALERT_TYPE_EDIT,
    [`${namespace}__card--edit-alert`]: alertType === ALERT_TYPE_EDIT,
    [`${namespace}__card--alert`]: alertType === ALERT_TYPE_NEW,
    [`${namespace}__card--new`]: alertType === ALERT_TYPE_NEW,
  });

  const alertComponentNew = props.components.find((item) => item.type === ALERT_TYPE_NEW);

  const btnActionInfoTooltip = alertComponentNew?.action || null;
  const tracksAlertIntention = alertComponentNew?.action?.tracks || null;

  const andesTooltip = alertComponentNew?.action?.andes_tooltip || null;

  const localStorageInfoTooltip = useTimesViewed('infoTooltipTimes', andesTooltip?.max_print);

  if (!loggedIn) {
    const notLoggedParams = {
      btnActionInfoTooltip,
      localStorageInfoTooltip,
      isTooltipInfoVisible,
      setTooltipInfoVisible,
      loggedIn,
      loginUrl,
    };

    return renderAlertNotLogged(notLoggedParams);
  }

  const selectPeriodicityTooltip = props.components.find((item) => item.type === ALERT_TYPE_PERIODICITY);
  const alertComponentEdit = props.components.find((item) => item.type === ALERT_TYPE_EDIT);

  const {
    bottom_sheet: {
      content: {
        action,
        action: { tracks: tracksAlertSave },
      },
    },
    snackbar_success: { tracks: tracksAlertCongrats },
  } = selectPeriodicityTooltip;

  const {
    action: { label, andes_tooltip },
  } = alertComponentEdit;

  const renderAlertEditParams = {
    classNames,
    label,
    andes_tooltip,
  };

  function handleAlertCreationSuccess(alertCreationNext) {
    setAlertType(alertCreationNext.type);

    setSnackbar({
      color: 'green',
      message: selectPeriodicityTooltip.snackbar_success.label.text,
      className: 'ui-search-snackbar--alert-creation-success',
      action: {
        text: selectPeriodicityTooltip.snackbar_success.action.label.text,
        onClick: () => {
          window.location.href = selectPeriodicityTooltip.snackbar_success.action.target;
        },
      },
    });

    if (tracksAlertCongrats) {
      const tracks = {
        ...tracksAlertCongrats,
        melidata_track: {
          ...tracksAlertCongrats.melidata_track,
          event_data: {
            frequency: alertFrequency,
            status: alertFrequencyStatus,
          },
        },
      };
      const { analytics, melidata_track } = tracks;

      trackEvent(analytics, melidata_track);
    }
  }

  function handleAlertCreationError() {
    setSnackbar({
      color: 'red',
      message: selectPeriodicityTooltip.snackbar_error.label.text,
      className: 'ui-search-snackbar--alert-creation-error',
    });
  }

  const handleChangeAlertFrequency = (frequency, status) => {
    if (frequency) {
      setAlertFrequency(frequency);
    }

    if (status) {
      setAlertFrequencyStatus(status);
    }
  };

  const renderAlertNewParams = {
    classNames,
    action,
    btnActionInfoTooltip,
    selectPeriodicityTooltip,
    alertComponentEdit,
    loginUrl,
    isLoading,
    setIsLoading,
    localStorageInfoTooltip,
    isTooltipInfoVisible,
    setTooltipInfoVisible,
    tracksAlertIntention,
    tracksAlertSave,
    alertCreationData,
    alertFrequency,
    alertFrequencyStatus,
    handleChangeAlertFrequency,
    handleAlertCreationSuccess: props.handleAlertCreationSuccess || handleAlertCreationSuccess,
    handleAlertCreationError: props.handleAlertCreationError || handleAlertCreationError,
  };

  switch (alertType) {
    case ALERT_TYPE_EDIT:
      return renderAlertEdit(renderAlertEditParams);
    case ALERT_TYPE_NEW:
      return renderAlertNew(renderAlertNewParams);

    default:
      return null;
  }
};

AlertV2.propTypes = {
  handleAlertCreationError: func,
  handleAlertCreationSuccess: func,
};

AlertV2.defaultProps = {
  handleAlertCreationError: null,
  handleAlertCreationSuccess: null,
};

module.exports = AlertV2;
