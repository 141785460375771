const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-icon ui-search-icon--bookmark';

const IconBookmark = ({ className }) => (
  <svg className={classnames(namespace, className)} viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
    <use href="#bookmark" />
  </svg>
);

IconBookmark.propTypes = {
  className: string,
};

IconBookmark.defaultProps = {
  className: null,
};

module.exports = IconBookmark;
