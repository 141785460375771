const { useSearch } = require('../../../../hooks/context');

const clientName = 'SEARCH_SHOPS_ADS';

const getMiddleware = (response) => {
  const returnValue = response.components.find((element) => element.id === clientName);

  return returnValue;
};
const useSearchAds = () => {
  const { sidebar } = useSearch();
  const response = getMiddleware(sidebar);

  return response;
};

module.exports = useSearchAds;
