const React = require('react');
const { string, shape } = require('prop-types');

const classnames = require('classnames');
const Image = require('nordic/image');
const Tooltip = require('@andes/tooltip');

const Link = require('../../../link/filter-link');
const ProcessedDescription = require('./processed-description.desktop');

const namespace = 'ui-search-filter-official-store';

const FilterOfficialStore = ({ id, name, expanded_values, className }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)}>
    {expanded_values.map((value) => (
      <div className={`${namespace}__container`}>
        <Link title={value.name} href={value.url}>
          <Tooltip content={<ProcessedDescription {...value} />} side="bottom" className={`${namespace}__tooltip`}>
            <Image alt={name} className={`${namespace}__image`} src={value.logo_url} lowEnd />
          </Tooltip>
        </Link>
      </div>
    ))}
  </div>
);

FilterOfficialStore.propTypes = {
  className: string,
  expanded_values: shape({}),
  id: string,
  name: string,
};

FilterOfficialStore.defaultProps = {
  className: '',
  expanded_values: null,
  id: '',
  name: '',
};

module.exports = FilterOfficialStore;
