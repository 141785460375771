const FILTERS = {
  IDS: {
    FULFILLMENT: 'fulfillment',
    RELATED_SEARCHES: 'related_searches',
    COLOR: 'MAIN_COLOR',
    OFFICIAL_STORE: 'official_store',
    PRICE: 'price',
    VEHICLE_YEAR: 'VEHICLE_YEAR',
    VEHICLE_BODY_TYPE: 'VEHICLE_BODY_TYPE',
    STATE: 'state',
    CITY: 'city',
    NEIGHBORHOOD: 'neighborhood',
    LOCATION: 'LOCATION',
    ITEM_LOCATION: 'item_location',
    DISPLAY_TYPE: 'DisplayType',
    OPERATION: 'OPERATION',
    CATEGORY: 'category',
    PROPERTY_TYPE: 'PROPERTY_TYPE',
    OPERATION_SUBTYPE: 'OPERATION_SUBTYPE',
    PROJECTS: '245034',
  },
  TYPES: {
    RANGE: 'range',
    PRICE_WITH_CONVERSION: 'PRICE_WITH_CONVERSION',
    WITH_PICTURES: 'with_pictures',
    HIGHLIGHTED: 'highlighted',
    HIDDEN: 'hidden',
    GROUP: 'group',
  },
};

const DEVICE_TYPES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
};

const DISPLAY_TYPES = {
  MAP: 'M',
};

const LAYOUTS = {
  TYPE_STACK: 'stack',
  TYPE_MOSAIC: 'mosaic',
  TYPE_GALLERY: 'gallery',
  TYPE_GRID: 'grid',
  TYPE_MAP: 'map',
};

const VISUAL_IDS = {
  DEFAULT: 'default',
  PI: 'pi',
};
const OFFICIAL_STORE_ALL = 'all';
const OFFICIAL_STORE_KEYNAME = 'tienda';

const TYPE_RESULTS = {
  RESULT_ITEM: 'SEARCH_RESULT_ITEM',
  FILTER_INTERVENTION: 'FILTER_INTERVENTION',
  CONTENT_INTERVENTION: 'CONTENT_INTERVENTION',
  QUERY_INTERVENTION: 'QUERY_INTERVENTION',
  CAROUSEL_INTERVENTION: 'CAROUSEL_INTERVENTION',
  BILLBOARD_INTERVENTION: 'BILLBOARD_INTERVENTION',
  COMPATS_INTERVENTION: 'COMPATS_INTERVENTION',
  BRAND_ADS_INTERVENTION: 'BRAND_ADS_INTERVENTION',
  CART_INTERVENTION: 'CART_INTERVENTION',
  TREND_INTERVENTION: 'TREND_INTERVENTION',
  SEO_TEXTBOX_INTERVENTION: 'SEO_TEXTBOX_INTERVENTION',
  BRAND_DISCO_INTERVENTION: 'BRAND_DISCO_INTERVENTION',
  MELIPLAY_INTERVENTION: 'MELIPLAY_INTERVENTION',
  SEO_TOP_KEYWORDS_INTERVENTION: 'SEO_TOP_KEYWORDS_INTERVENTION',
  SEO_BLOGS_INTERVENTION: 'SEO_BLOGS_INTERVENTION',
  VEHICLE_BODY_SELECTOR_INTERVENTION: 'VEHICLE_BODY_SELECTOR_INTERVENTION',

  // types of category page and best sellers
  HIGHER_GROWTH_TREND: 'HIGHER_GROWTH',
  MOST_WANTED_TREND: 'MOST_WANTED',
  MOST_POPULAR_TREND: 'MOST_POPULAR',
  MANUAL_TRENDS: 'MANUAL_TRENDS',
  CONSOLIDATED_TRENDS: 'CONSOLIDATED_TRENDS',
  OTHER_FILLED_TRENDS: 'OTHER_FILLED_TRENDS',
  BEST_SELLERS_ITEMS: 'BEST_SELLER',
  RECOMMENDED_ITEMS: 'RECOMMENDED_ITEMS',
  SEO_BLOGS: 'SEO_BLOGS',
};

const ITEM_INTERVENTIONS = [TYPE_RESULTS.MELIPLAY_INTERVENTION];

const INTERVENTIONS_IDS = [
  TYPE_RESULTS.FILTER_INTERVENTION,
  TYPE_RESULTS.QUERY_INTERVENTION,
  TYPE_RESULTS.CONTENT_INTERVENTION,
  TYPE_RESULTS.CAROUSEL_INTERVENTION,
  TYPE_RESULTS.BILLBOARD_INTERVENTION,
  TYPE_RESULTS.COMPATS_INTERVENTION,
  TYPE_RESULTS.BRAND_ADS_INTERVENTION,
  TYPE_RESULTS.TREND_INTERVENTION,
  TYPE_RESULTS.CART_INTERVENTION,
  TYPE_RESULTS.SEO_TEXTBOX_INTERVENTION,
  TYPE_RESULTS.HIGHER_GROWTH_TREND,
  TYPE_RESULTS.MOST_WANTED_TREND,
  TYPE_RESULTS.MOST_POPULAR_TREND,
  TYPE_RESULTS.MANUAL_TRENDS,
  TYPE_RESULTS.CONSOLIDATED_TRENDS,
  TYPE_RESULTS.OTHER_FILLED_TRENDS,
  TYPE_RESULTS.BEST_SELLERS_ITEMS,
  TYPE_RESULTS.RECOMMENDED_ITEMS,
  TYPE_RESULTS.BRAND_DISCO_INTERVENTION,
  TYPE_RESULTS.SEO_BLOGS,
  TYPE_RESULTS.MELIPLAY_INTERVENTION,
  TYPE_RESULTS.SEO_BLOGS_INTERVENTION,
  TYPE_RESULTS.SEO_TOP_KEYWORDS_INTERVENTION,
  TYPE_RESULTS.VEHICLE_BODY_SELECTOR_INTERVENTION,
];

const FILTER_INTERVENTION = {
  TYPES: {
    FILTER_TEXT: 'FILTER_TEXT',
    FILTER_SPECIALIZED: 'FILTER_SPECIALIZED',
    FILTER_COLOR: 'FILTER_COLOR',
  },
};

const TREND_INTERVENTION = {
  TYPES: {
    TREND_UNIT: 'TREND_UNIT',
    TREND_CENTER: 'TREND_CENTER',
    TREND_CAROUSEL: 'TREND_CARPUISEL',
  },
};

const BRAND_DISCO_INTERVENTION = {
  TYPES: {
    BRAND_DISCO_INTERVENTION: 'BRAND_DISCO_INTERVENTION',
  },
};

const VISIBLE = 'VISIBLE';

const MIN_INPUT_NAME = 'Minimum';
const SINCE_ITEM_NAME = 'From';
const UNTIL_ITEM_NAME = 'To';
const MAX_INPUT_NAME = 'Maximum';

const TYPE_RANGE = {
  DROPDOWN: 'dropdown',
  INPUT: 'input',
};

const MAPPED_FACETED_FILTER_IDS = {
  only_new: 'OPERATION_SUBTYPE',
  operation: 'OPERATION',
  property: 'PROPERTY_TYPE',
};

const DEFAULT_LOCATION_FILTER_KEY = 'ui-search-filters__location-filter';

const FILTER_GROUP_SEPARATOR = ':';

module.exports = {
  FILTERS,
  DEVICE_TYPES,
  DISPLAY_TYPES,
  LAYOUTS,
  VISUAL_IDS,
  OFFICIAL_STORE_ALL,
  OFFICIAL_STORE_KEYNAME,
  TYPE_RESULTS,
  VISIBLE,
  FILTER_INTERVENTION,
  INTERVENTIONS_IDS,
  TYPE_RANGE,
  MIN_INPUT_NAME,
  SINCE_ITEM_NAME,
  UNTIL_ITEM_NAME,
  MAX_INPUT_NAME,
  MAPPED_FACETED_FILTER_IDS,
  DEFAULT_LOCATION_FILTER_KEY,
  FILTER_GROUP_SEPARATOR,
  TREND_INTERVENTION,
  ITEM_INTERVENTIONS,
  BRAND_DISCO_INTERVENTION,
};
