const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-map-link';

const IconMap = ({ className }) => (
  <svg
    data-testid="link-map"
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="18"
    viewBox="0 0 20 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M10 1c3.505 0 6.347 2.842 6.347 6.347 0 3.043-1.975 6.838-5.89 11.432l-.457.536-.457-.536c-3.915-4.594-5.89-8.389-5.89-11.432C3.653 3.842 6.495 1 10 1zm0 1.2c-2.843 0-5.147 2.304-5.147 5.147 0 2.578 1.706 5.963 5.147 10.11 3.441-4.147 5.147-7.532 5.147-10.11C15.147 4.504 12.843 2.2 10 2.2zm0 2.985c1.331 0 2.41 1.079 2.41 2.41 0 1.331-1.079 2.41-2.41 2.41-1.331 0-2.41-1.079-2.41-2.41 0-1.331 1.079-2.41 2.41-2.41zm0 1.2c-.668 0-1.21.542-1.21 1.21 0 .668.542 1.21 1.21 1.21.668 0 1.21-.542 1.21-1.21 0-.668-.542-1.21-1.21-1.21z"
                transform="translate(-187 -330) translate(124 202) translate(0 114) translate(63.635 14)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

IconMap.propTypes = {
  className: string,
};

IconMap.defaultProps = {
  className: null,
};

module.exports = React.memo(IconMap);
