const { useEffect, useRef, useState } = require('react');

const { supportsIntersectionObserver } = require('../../lib/dom-utils');

const prefetchIntersectionObserverStrategy = (node, setIntersecting, rootMargin) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        setIntersecting(entry.isIntersecting);
        observer.unobserve(node);
      }
    },
    {
      rootMargin,
    },
  );

  observer.observe(node);

  return () => observer.unobserve(node);
};

const createPrefetchLink = (href) => {
  const link = document.createElement('link');

  link.setAttribute('rel', 'prefetch');
  link.setAttribute('href', href);

  const [head] = document.getElementsByTagName('head') || [];

  if (head) {
    head.appendChild(link);
  }
};

const usePrefetch = (prefetchUrl = null, rootMargin = '0px') => {
  const ref = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const { current } = ref;
    const canAddObserver = current && supportsIntersectionObserver;

    return canAddObserver ? prefetchIntersectionObserverStrategy(current, setIsIntersecting, rootMargin) : () => {};
  }, []);

  if (prefetchUrl && isIntersecting) {
    createPrefetchLink(prefetchUrl);
  }

  return ref;
};

/* eslint-enable */
module.exports = usePrefetch;
