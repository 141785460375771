const React = require('react');
const { string, shape, bool } = require('prop-types');

const sources = require('./utils/component-types');
const resources = require('./utils/map-components');
const { SEARCH_API_BASE_PATH } = require('../../../services/constants/paths');

const wrapSticky = (comp) => <div className="wrapper-sticky">{comp}</div>;

const ComponentFactory = ({ component, properties, children, hidden }) => {
  const source = resources[component];

  if (hidden || !source) {
    return null;
  }

  const isPH = component === sources.PROFILE_HEADER;
  const props = isPH ? { ...properties, baseURL: `${SEARCH_API_BASE_PATH}/` } : properties;

  const params =
    children?.length > 0 ? [source, props, children?.map((ch) => <ComponentFactory {...ch} />)] : [source, props];

  if (isPH) {
    return wrapSticky(React.createElement(...params));
  }

  return React.createElement(...params);
};

ComponentFactory.propTypes = {
  children: shape.isRequired,
  component: string.isRequired,
  hidden: bool.isRequired,
  properties: shape.isRequired,
};

module.exports = ComponentFactory;
