const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const namespace = 'ui-search-search-result';

const SearchResult = ({ text }) => (
  <div className={classnames(namespace, 'shops__result')}>
    <span className={classnames(`${namespace}__quantity-results`, 'shops-custom-secondary-font')}>{text}</span>
  </div>
);

SearchResult.defaultProps = {
  text: '',
};

SearchResult.propTypes = {
  text: string.isRequired,
};

module.exports = SearchResult;
