const React = require('react');
const { string, bool } = require('prop-types');

const { useSearch } = require('../../../hooks/context');
const Link = require('../../link');

const AdLabel = ({ text, adVersion, shouldRenderLinks }) => {
  const { ads_metadata } = useSearch();
  const labelContent = (
    <span className={`ui-search-item__pub-label ${adVersion === 'v2' ? 'ui-search-item__pub-label--blue' : ''}`}>
      {text}
    </span>
  );

  if (ads_metadata?.landing && shouldRenderLinks) {
    return (
      <div className="ui-search-item__pub-container">
        <Link className="ui-search-item__pub-link" href={ads_metadata.landing} adLabel>
          {labelContent}
        </Link>
      </div>
    );
  }

  return <div className="ui-search-item__pub-container">{labelContent}</div>;
};

AdLabel.propTypes = {
  adVersion: string,
  shouldRenderLinks: bool,
  text: string,
};

AdLabel.defaultProps = {
  adVersion: '',
  shouldRenderLinks: false,
  text: '',
};

module.exports = AdLabel;
