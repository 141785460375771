const React = require('react');

const EshopBanner = require('./eshop/eshop-banner.desktop');
const ShowcaseBanner = require('./showcase/showcase-banner.desktop');
const useBanner = require('./use-banner');
const { HEADER_TYPES } = require('../header/constants');

const getBannerByType = (sub_type) => {
  switch (sub_type) {
    case HEADER_TYPES.ESHOP:
      return EshopBanner;
    case HEADER_TYPES.SHOWCASE:
      return ShowcaseBanner;

    default:
      return null;
  }
};

const BannerContainer = () => {
  const banner = useBanner();

  if (!banner) {
    return null;
  }

  const BannerComponent = getBannerByType(banner.sub_type);

  return BannerComponent && <BannerComponent className={`ui-search-banner--${banner.sub_type}`} {...banner} />;
};

module.exports = BannerContainer;
