const React = require('react');
const { string } = require('prop-types');

const classnames = require('classnames');

const ICON_ID = 'ic_ahora30';
const namespace = 'ui-search-icon ui-search-icon--ahora30';

const IconAhora30 = ({ className }) => (
  <svg width="5" height="8" viewBox="0 0 5 8" fill="none" className={classnames(namespace, className)}>
    <path
      d="M4.61592 3.57431C4.86136 3.80672 4.86136 4.19328 4.61592 4.42569L1.01662 7.83375C0.635217 8.19489 6.51837e-07 7.92891 6.05563e-07 7.40806L0 0.591937C-4.62736e-08 0.0710884 0.635217 -0.194894 1.01662 0.166249L4.61592 3.57431Z"
      fill="white"
    />
  </svg>
);

IconAhora30.propTypes = {
  className: string,
};

IconAhora30.defaultProps = {
  className: null,
};

module.exports = React.memo(IconAhora30);
module.exports.ICON_ID = ICON_ID;
