const PREFETCH_THRESHOLD = '1000px';

const ARROW_DIRECTIONS = {
  BACK: 'back',
  NEXT: 'next',
};

module.exports = {
  PREFETCH_THRESHOLD,
  ARROW_DIRECTIONS,
};
