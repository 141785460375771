const React = require('react');
const { arrayOf, elementType, func, number, shape } = require('prop-types');

const classnames = require('classnames');

const { namespace } = require('../../constants');

const IMAGE_OPTIONS = { lazyload: 'on', mounted: true };

const ItemsMap = ({ results, ItemComponent, onItemHover, offset }) => (
  <div>
    {results.map((item, index) => (
      <div
        id={item.id}
        className={classnames(namespace, `${namespace}__item`, {
          [`${namespace}__item--development`]: item.is_development,
        })}
        onMouseEnter={() => onItemHover(index + offset)}
        onMouseLeave={() => onItemHover(null)}
        key={item.id}
      >
        <ItemComponent item={item} options={IMAGE_OPTIONS} />
      </div>
    ))}
  </div>
);

ItemsMap.propTypes = {
  ItemComponent: elementType.isRequired,
  offset: number,
  results: arrayOf(shape()),
  onItemHover: func.isRequired,
};

ItemsMap.defaultProps = {
  results: [],
  offset: 0,
};

module.exports = ItemsMap;
