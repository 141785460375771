const React = require('react');
const { string, shape, number } = require('prop-types');

const classnames = require('classnames');

const Price = require('../price');
const { MOSAIC } = require('../../layout/renderer/constants');

const namespace = 'ui-search-installments';

const PRICE_FORMAT_SPECIFIER = '{price}';

const Installments = ({ className, installments, price, layout }) => {
  const { text, amount, currency_id, color } = installments;
  const priceIndex = text.indexOf(PRICE_FORMAT_SPECIFIER);
  const installmentsClassName = classnames(className, namespace, `ui-search-color--${color}`);
  const showDecimals = amount && parseInt(amount.toFixed(2).split('.')[0], 10) < 1000;

  if (priceIndex > 0) {
    return (
      <span className={installmentsClassName}>
        {price.rebate_price && layout !== MOSAIC && (
          <div className={`${namespace}-prefix`}>
            {price.rebate_price.amount && (
              <Price
                amount={price.rebate_price.amount}
                currency_id={currency_id}
                size="x-tiny"
                showDecimals={showDecimals}
              />
            )}
            <span>{price.rebate_price.text}</span>
          </div>
        )}
        {text.substring(0, priceIndex)}
        <Price
          amount={amount}
          currency_id={currency_id}
          size="x-tiny"
          className={`ui-search-color--${color}`}
          showDecimals={showDecimals}
        />
        {text.substring(priceIndex + PRICE_FORMAT_SPECIFIER.length).trim()}
      </span>
    );
  }

  return <span className={installmentsClassName}>{text}</span>;
};

Installments.propTypes = {
  className: string,
  installments: shape({
    text: string.isRequired,
    amount: number,
    currency_id: string,
    color: string.isRequired,
  }).isRequired,
  layout: string,
  price: shape({}),
};

Installments.defaultProps = {
  className: null,
  price: {},
  layout: null,
};

module.exports = Installments;
